import apis from "../../utils/apis"
import { showFeedbackModal } from "./UserFeedbackActions"
import { hideModal, showModal } from "./modalActions"
import { SET_SESSIONS } from "../types"
import { userInformation } from "./DataAction"
import { handleApiError } from "./utils/utils"


export const setSessions = (sessions: any[] | []) => {
    return {
        type: SET_SESSIONS,
        sessions: sessions
    };
};

export const generateCode = (method: string, t: any) => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "security/generateCode",
                data: {
                    method
                }
            })
            if (response && response.status === 200) {

                dispatch(showModal({
                    showModal: true,
                    type: 'otp-verification',
                    message: '',
                    onConfirm: async (verificationCode: File | string) => {
                        await dispatch(VerifyCode(response.data.verification_token, verificationCode as string))
                        dispatch(userInformation());
                    },
                    onCancel: () => hideModal(),
                    inputData: method
                }));

                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (response.data.message),
                    modalType: 'success',
                    duration: 3000
                }));
                return true

            } else {
                handleApiError(response, dispatch)
            }

        } catch (err) {
            handleApiError(err, dispatch)
        }
    }
}


export const VerifyCode = (Token: string, Code: string) => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "security/email/verifyCode",
                data: {
                    Token,
                    Code
                }
            })
            if (response && response.status === 200) {
                dispatch(hideModal())
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: response.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
            } else {
                handleApiError(response, dispatch)
            }

        } catch (err) {
            handleApiError(err, dispatch)
        }
    }
}

export const getActiveSessions = () => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'GET',
                url: "/security/fetch_all_sessions",
            })
            if (response && response.status === 200) {
                dispatch(setSessions(response.data.session_list))
            } else {
                handleApiError(response, dispatch)
            }

        } catch (err) {
            handleApiError(err, dispatch)
        }
    }
}
