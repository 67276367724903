import React from 'react';
import clsx from 'clsx';
import Badge from './Badge';
import { BadgeColor } from './types/badgeTypes';

interface TableProps {
  columns: string[];
  data: Record<string, any>[];
  hiddenColumns?: Set<string>;
  fixedHeader?: boolean;
  onRowClick?: (row: any) => void;
  renderRow?: (row: any, rowIndex: number) => React.ReactNode;
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

const Table: React.FC<TableProps> = ({
  columns,
  data,
  hiddenColumns = new Set(),
  fixedHeader = false,
  onRowClick,
  renderRow,
}) => {
  // Map statuses to display labels and badge colors.
  const statusBadgeMap: Record<string, { label: string; color: string }> = {
    Draft: { label: 'Draft', color: 'yellow' },
    EngagementOngoing: { label: 'Active', color: 'blue' },
    Active: { label: 'Active', color: 'blue' },
    Completed: { label: 'Completed', color: 'gray' },
    Overdue: { label: 'Overdue', color: 'red' },
    Closed: { label: 'Closed', color: 'dark' },
  };

  return (
    <div
      className={clsx(
        'flex-1 overflow-x-auto overflow-y-auto -mx-4 mt-4 ring-1 ring-gray-300 dark:ring-gray-700 sm:mx-0 shadow-md dark:shadow-dark-md',
        'transition-all duration-300 animate-fade-in-up' 
      )}
    >
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 shadow-md dark:shadow-lg">
        <thead className="bg-gray-100 dark:bg-gray-700">
          <tr>
            {columns.map((col) => (
              <th
                key={col}
                scope="col"
                className={classNames(
                  'px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider',
                  hiddenColumns.has(col) ? 'hidden lg:table-cell' : ''
                )}
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {data.map((row, rowIdx) => {
            if (renderRow) {
              return renderRow(row, rowIdx);
            }
            return (
              <tr
                key={row.id || rowIdx}
                className="cursor-pointer bg-primary-50 dark:bg-primary-900 hover:bg-primary-100 dark:hover:bg-primary-700 transition-all duration-300"
                onClick={() => onRowClick && onRowClick(row)}
              >
                {columns.map((col) => {
                  let cellContent = row[col];
                  // For the Status column, render a badge.
                  if (col === 'Status') {
                    const mapping = statusBadgeMap[row[col]] || { label: row[col] || 'N/A', color: 'gray' };
                    cellContent = <Badge color={mapping.color as BadgeColor}>{mapping.label}</Badge>;
                  }
                  return (
                    <td
                      key={col}
                      className={clsx(
                        'px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200',
                        hiddenColumns.has(col) ? 'hidden lg:table-cell' : ''
                      )}
                    >
                      {cellContent}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
