import { Dispatch } from 'redux';
import apis from '../../../utils/apis';
import {
  FETCH_EMPLOYEES_REQUEST, FETCH_EMPLOYEES_SUCCESS, FETCH_EMPLOYEES_FAILURE, ONBOARD_EMPLOYEE_REQUEST, ONBOARD_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_DETAILS_FAILURE, FETCH_EMPLOYEE_DETAILS_REQUEST, FETCH_EMPLOYEE_DETAILS_SUCCESS, AppThunk
} from '../../types';
import { getUserType } from '../ChatAction';
import { showFeedbackModal } from '../UserFeedbackActions';
import { handleApiError } from '../utils/utils';

export const fetchAllEmployees = (params: {
  searchTerm?: string;
  filters?: { [key: string]: string[] };
  sortBy?: string;
  sortOrder?: string;
  page?: number;
  limit?: number;
} = {}): AppThunk => async (dispatch) => {
  dispatch({ type: FETCH_EMPLOYEES_REQUEST });
  try {
    const {
      searchTerm = '',
      filters = {},
      sortBy = '',
      sortOrder = 'asc',
      page = 1,
      limit = 10,
    } = params;

    const filtersJson = JSON.stringify(filters);

    const response = await apis({
      method: 'GET',
      url: '/users/all_employees',
      params: {
        searchTerm,
        filters: filtersJson,
        sortBy,
        sortOrder,
        page,
        limit
      }
    });

    if (response && response.status === 200) {
      const employees = response.data.employees || [];
      const total = response.data.total || 0;
      const currentPage = response.data.currentPage || 1;
      const totalPages = response.data.totalPages || 1;

      dispatch({
        type: FETCH_EMPLOYEES_SUCCESS,
        payload: {
          employees,
          total,
          currentPage,
          totalPages
        },
      });
    } else {
      dispatch({
        type: FETCH_EMPLOYEES_FAILURE,
        payload: 'Failed to fetch employees.',
      });
      handleApiError(response, dispatch);
    }
  } catch (error: any) {
    handleApiError(error, dispatch);
    dispatch({
      type: FETCH_EMPLOYEES_FAILURE,
      payload: error.message || 'An error occurred while fetching employees.',
    });
  }
};


export const onboardEmployee = (employeeData: any) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const state = getState();
    const userType = state.user.userType || getUserType();

    dispatch({ type: ONBOARD_EMPLOYEE_REQUEST });
    try {
      let response;

      // Set the UserTypeID based on the admin's type
      if (userType === 'LawFirmAdmin') {
        employeeData.UserTypeID = '5'; // LawFirmEmployee
        response = await apis.post('/user/onboard_lawfirm_employee', employeeData);
      } else if (userType === 'BusinessAdmin') {
        employeeData.UserTypeID = '3'; // BusinessEmployee
        response = await apis.post('/businessadmin/onboard-employee', employeeData);
      } else {
        throw new Error('User does not have permission to onboard employees.');
      }

      if (response && response.status === 200) {
        dispatch({
          type: ONBOARD_EMPLOYEE_SUCCESS
        });
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: 'Employee onboarded successfully.',
            showModal: true,
            duration: 3000,
          })
        );
        // Refresh employee list
        dispatch(fetchAllEmployees());
      } else {
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
    }
  };
};

export const fetchEmployeeDetails = (employeeId: string) => async (dispatch: any) => {
  dispatch({ type: FETCH_EMPLOYEE_DETAILS_REQUEST });
  try {
    const response = await apis.post('/users/employee_details', { EmployeeUserID: employeeId });
    if (response && response.status === 200) {
      dispatch({
        type: FETCH_EMPLOYEE_DETAILS_SUCCESS,
        payload: response.data,
      });
      return response.data
    }
    else {
      handleApiError(response, dispatch);
      return null
    }

  } catch (error: any) {
    dispatch({
      type: FETCH_EMPLOYEE_DETAILS_FAILURE,
      payload: error.message,
    });
    handleApiError(error, dispatch);
  }
};
