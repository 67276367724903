import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useTranslation } from 'react-i18next';
import { FaGavel, FaFileContract, FaFileInvoiceDollar, FaFileSignature } from 'react-icons/fa';
import CompanyAgent from './CompanyAgent/CompanyAgent';
import CustomAgent from './CustomAgent/CustomAgent';
import FileUploader from '../shared/TailwindComponents/FileUploader2';
import { DocumentCurrencyDollarIcon, DocumentMagnifyingGlassIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { LiaFileContractSolid, LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { PiFilesLight } from 'react-icons/pi';

interface WelcomeScreenProps {
  onFileSelect: (index: number, file: File | null) => void;
  files: (File | null)[];
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({
  onFileSelect,
  files,
}) => {
  const { t } = useTranslation();
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);

  const getWelcomeContent = () => {
    switch (selectedModel.model_display_name) {
      case 'Law Agent':
        return {
          title: t('PONS Law Agent'),
          description: t('Gain quick insights into laws and regulations.'),
          icon:   <AiOutlineFileSearch className="h-12 w-12 text-primary-500" />,
        };
      case 'Tax Agent':
        return {
          title: t('PONS Tax Agent'),
          description: t('Find comprehensive information on tax laws and regulations.'),
          icon: <LiaFileInvoiceDollarSolid className="h-12 w-12 text-indigo-500" />,
        };
      case 'Case Agent':
        return {
          title: t('PONS Case Agent'),
          description: t('Explore and summarize past cases and outcomes.'),
          icon: <PiFilesLight className="h-12 w-12 text-danger-500" />,
        };
      case 'Contract Analysis':
        return {
          title: t('PONS Contract Analysis'),
          description: t('Upload one or multiple contracts for analysis or comparison.'),
          icon: <LiaFileContractSolid size={26} className="h-12 w-12 text-primary-500" />,
        };
      case 'Company Agent':
        return {
          title: '',
          description: '',
          icon: <CompanyAgent/>,
        };
      case 'Custom Agent':
        return {
          title: '',
          description: '',
          icon: <CustomAgent/>,
        };
      default:
        return {
          title: t('Welcome!'),
          description: t('Select a service to get started.'),
          icon: <i className="fa-kit fa-logo text-6xl dark:text-sky-400/100 text-primary-500"></i>,
        };
    }
  };

  const { title, description, icon } = getWelcomeContent();

  // Convert the (File | null)[] to File[] for the FileUploader
  const currentFiles = files.filter((f): f is File => f !== null);

  return (
    <div className="p-4 h-full w-full flex flex-col justify-center items-center space-y-4">
      {icon}
      {title && <h1 className="text-2xl font-medium text-gray-900 dark:text-white">{title}</h1>}
      {description && <p className="text-center text-gray-600 dark:text-gray-300">{description}</p>}
      {selectedModel.model_display_name === 'Contract Analysis' && (
        <div className="w-full max-w-xl">
          <FileUploader
            files={currentFiles}
            onFilesChange={(updatedFiles: File[]) => {
              // If we still want to keep track of first two files separately:
              onFileSelect(0, updatedFiles[0] || null);
              onFileSelect(1, updatedFiles[1] || null);
            }}
            layout="row"
          />
        </div>
      )}
    </div>
  );
};

export default WelcomeScreen;
