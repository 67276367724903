import React, { useRef } from 'react';
import FileInputWithPreview from './FileInputWithPreview';
import GoogleDrivePickerButton from '../../../Globals/GoogleDrivePickerButton';
import OneDrivePickerButton from '../../../Globals/OneDrivePickerButton';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useAppDispatch } from '../../../store';
import { showModal } from '../../../store/actions/modalActions';
import clsx from 'clsx';
import { PiCloudArrowUpLight } from "react-icons/pi";
import { CiLock } from 'react-icons/ci';

interface FileUploaderProps {
  files: File[];
  onFilesChange: (files: File[]) => void;
  layout?: 'row' | 'column';
}

const baseBtnClasses = "inline-flex justify-between items-center px-3 py-2 font-medium rounded-md transition text-sm";
const uploadBtnClasses = "bg-primary-500 hover:bg-primary-600 text-white";
const vaultBtnClasses = "bg-neutral-200 hover:bg-neutral-300 dark:bg-neutral-800 dark:hover:bg-neutral-900 text-gray-700 dark:text-gray-200";
const googleBtnClasses = "bg-green-200 hover:bg-green-300 text-green-800";
const oneDriveBtnClasses = "bg-blue-200 hover:bg-blue-300 text-blue-800";

const FileUploader: React.FC<FileUploaderProps> = ({ files, onFilesChange, layout = 'row' }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleImportFromVault = () => {
    dispatch(
      showModal({
        type: 'vaultFilePicker',
        onConfirm: (selectedFile: File | { id: string; name: string; url?: string }) => {
          if (selectedFile instanceof File) {
            onFilesChange([...files, selectedFile]);
          } else {
            console.log('Vault selected:', selectedFile);
          }
        },
        showModal: true,
        message: ''
      })
    );
  };

  // Opens the hidden file input inside FileInputWithPreview.
  const handleOpenFileDialog = () => {
    const input = containerRef.current?.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = ''; // Clear old value so same file can be re-selected if needed
      input.click();
    }
  };

  return (
    <div
      ref={containerRef}
      className={clsx(
        'border-gray-300 dark:border-gray-600 rounded p-4 flex-col space-y-2'
      )}
    >
      <FileInputWithPreview
        files={files}
        onFilesChange={onFilesChange}
      />

      <div
        className={clsx(
          'flex-shrink-0 border-t border-gray-200 dark:border-gray-600 pt-2',
          layout === 'row' ? 'flex flex-col items-center space-y-4' : 'border-t-0 border-t pt-4'
        )}
      >
        <div className="grid grid-cols-2 gap-2 w-full">
          <button
            type='button'
            className={clsx(baseBtnClasses, uploadBtnClasses)}
            onClick={handleOpenFileDialog}
          >
            <span>{t('Upload File/Folder')}</span>
            <PiCloudArrowUpLight size={20}/>
            {/* <i className="fas fa-cloud-arrow-up mr-1.5"></i> */}
          </button>

          <button
            onClick={handleImportFromVault}
            className={clsx(baseBtnClasses, vaultBtnClasses)}
            type='button'
          >
            {t('Vault')}
            <CiLock size={20} />
          </button>

          <GoogleDrivePickerButton onFilePicked={(file: File) => onFilesChange([...files, file])} />
          <OneDrivePickerButton onFileSelected={(file: File) => onFilesChange([...files, file])} />
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
