import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import {
  getLawyerProfile,
  updateLawyerProfile,
} from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';

import Input from '../../shared/TailwindComponents/Input';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';
import TagInput from '../../shared/TailwindComponents/TagInput';
import Toggle from '../../shared/TailwindComponents/Toggle';
import { showModal } from '../../../store/actions/modalActions';
import LawyerProfileComponent from '../Client/LawyerProfile';
import { showFileReaderSidePanel } from '../../../store/actions/sidePanelActions';
import { useTranslation } from 'react-i18next';
import Badge from '../../shared/TailwindComponents/Badge';
import SingleFileInputWithPreview from '../../shared/TailwindComponents/SingleFileInputWithPreview';
import ApiLoader from '../../ApiLoader';

import { useFormik } from 'formik';
import * as Yup from 'yup';

interface FileItem {
  title: string;
  fileUrl?: string;  // existing file URL if any
  file?: File;       // new file if any
}

interface PublicationItem {
  title: string;
  fileUrl?: string;  // For publications, we’ll store the link as a string
}

const LawyerProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const userId = useSelector((state: RootState) => state.user.userInfo.UserID);

  // Raw profile data from backend
  const [lawyerProfile, setLawyerProfile] = useState<any>(null);

  // Single-file fields
  const [profilePictureFile, setProfilePictureFile] = useState<File | null>(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState<string | null>(null);

  const [termsOfServiceFile, setTermsOfServiceFile] = useState<File | null>(null);
  const [termsOfServiceFileName, setTermsOfServiceFileName] = useState<string | null>(null);

  // Multi-item fields (title + optional file)
  const [awards, setAwards] = useState<FileItem[]>([]);
  const [publications, setPublications] = useState<PublicationItem[]>([]);
  const [certifications, setCertifications] = useState<FileItem[]>([]);
  const [portfolio, setPortfolio] = useState<FileItem[]>([]);

  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  // ------------------------------------------------
  // 1) Fetch profile on mount
  // ------------------------------------------------
  const fetchDetails = useCallback(async () => {
    setLoading(true);
    const resp = await dispatch(getLawyerProfile(userId));
    if (resp) {
      const profileData = resp;
      setLawyerProfile(profileData);

      // Awards
      if (Array.isArray(profileData.Awards)) {
        setAwards(
          profileData.Awards.map((item: any) => ({
            title: item.title || '',
            fileUrl: item.fileUrl || '',
            file: undefined,
          }))
        );
      } else {
        setAwards([]);
      }

      // Publications
      if (Array.isArray(profileData.Publications)) {
        setPublications(
          profileData.Publications.map((item: any) => ({
            title: item.title || '',
            fileUrl: item.fileUrl || '',
          }))
        );
      } else {
        setPublications([]);
      }

      // Certifications
      if (Array.isArray(profileData.Certifications)) {
        setCertifications(
          profileData.Certifications.map((item: any) => ({
            title: item.title || '',
            fileUrl: item.fileUrl || '',
            file: undefined,
          }))
        );
      } else {
        setCertifications([]);
      }

      // Portfolio
      if (Array.isArray(profileData.Portfolio)) {
        setPortfolio(
          profileData.Portfolio.map((item: any) => ({
            title: item.title || '',
            fileUrl: item.fileUrl || '',
            file: undefined,
          }))
        );
      } else {
        setPortfolio([]);
      }
    }
    setLoading(false);
  }, [dispatch, userId]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  // ------------------------------------------------
  // 2) On lawyerProfile load, set single-file previews
  // ------------------------------------------------
  useEffect(() => {
    if (!lawyerProfile) return;
    if (lawyerProfile.ProfilePicture) {
      setProfilePicturePreview(lawyerProfile.ProfilePicture);
    } else {
      setProfilePicturePreview(null);
    }
    if (lawyerProfile.TermsOfServiceURL) {
      // Derive a short name from the URL
      const urlParts = lawyerProfile.TermsOfServiceURL.split('/');
      const fileName = urlParts[urlParts.length - 1].split('?')[0] || 'TOS';
      setTermsOfServiceFileName(fileName);
    } else {
      setTermsOfServiceFileName(null);
    }
  }, [lawyerProfile]);

  // ------------------------------------------------
  // 3) Formik for simpler fields
  // ------------------------------------------------
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      HourlyRate: lawyerProfile?.HourlyRate || '',
      Bio: lawyerProfile?.Bio || '',
      ExperienceYears: lawyerProfile?.ExperienceYears || '',
      Specializations: lawyerProfile?.Specializations || [],
      LanguagesSpoken: lawyerProfile?.LanguagesSpoken || [],
      Education: lawyerProfile?.Education || '',
      ProfessionalMemberships: lawyerProfile?.ProfessionalMemberships || [],
      AvailabilityStatus: lawyerProfile?.AvailabilityStatus || '',
      is_marketplace_active: lawyerProfile?.is_marketplace_active || false,
      PreferredCaseTypes: lawyerProfile?.PreferredCaseTypes || [],
      PreferredBudgetMin: lawyerProfile?.PreferredBudgetMin || '',
      PreferredBudgetMax: lawyerProfile?.PreferredBudgetMax || '',
      PreferredLocations: lawyerProfile?.PreferredLocations || [],
      PreferredKeywords: lawyerProfile?.PreferredKeywords || [],
    },
    validationSchema: Yup.object({
      HourlyRate: Yup.number().required('Required'),
      Bio: Yup.string().required('Required'),
      ExperienceYears: Yup.number().required('Required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const updateData = new FormData();

        // 3a) Attach simpler fields
        Object.keys(values).forEach((key) => {
          const val = (values as any)[key];
          if (Array.isArray(val)) {
            // For arrays, append individually
            val.forEach((item: string) => updateData.append(key, item));
          } else {
            updateData.append(key, val);
          }
        });

        // 3b) Single-file fields
        if (profilePictureFile) {
          updateData.append('ProfilePicture', profilePictureFile);
        }
        if (termsOfServiceFile) {
          updateData.append('TermsOfServiceFile', termsOfServiceFile);
        }

        // 3c) Multi-item fields (Awards, Certifications, Portfolio)
        //    -> We send JSON for the “title + fileUrl” parts,
        //       and also attach actual File objects (if any).
        // --- Awards
        const awardsJsonPayload = awards.map((item) => ({
          title: item.title,
          fileUrl: item.fileUrl || '',
        }));
        updateData.append('AwardsJson', JSON.stringify(awardsJsonPayload));
        awards.forEach((item, idx) => {
          if (item.file) {
            // This means the user selected a new file for this item
            updateData.append(`Awards[${idx}][file]`, item.file);
          }
        });

        // --- Publications (no file, just a URL or link)
        updateData.append('PublicationsJson', JSON.stringify(publications));

        // --- Certifications
        const certsJsonPayload = certifications.map((item) => ({
          title: item.title,
          fileUrl: item.fileUrl || '',
        }));
        updateData.append('CertificationsJson', JSON.stringify(certsJsonPayload));
        certifications.forEach((item, idx) => {
          if (item.file) {
            updateData.append(`Certifications[${idx}][file]`, item.file);
          }
        });

        // --- Portfolio
        const portfolioJsonPayload = portfolio.map((item) => ({
          title: item.title,
          fileUrl: item.fileUrl || '',
        }));
        updateData.append('PortfolioJson', JSON.stringify(portfolioJsonPayload));
        portfolio.forEach((item, idx) => {
          if (item.file) {
            updateData.append(`Portfolio[${idx}][file]`, item.file);
          }
        });

        // 4) Dispatch update
        await dispatch(updateLawyerProfile(updateData));

      } catch (error) {
        console.error("Submission failed", error);
      } finally {
        setSubmitting(false);
        await fetchDetails();
      }
    },
  });

  // ------------------------------------------------
  // 4) Single-file logic
  // ------------------------------------------------
  const handleProfilePictureChange = (file: File | null) => {
    setProfilePictureFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setProfilePicturePreview(reader.result as string);
      reader.readAsDataURL(file);
    } else {
      setProfilePicturePreview(null);
    }
  };

  const handleTermsOfServiceChange = (file: File | null) => {
    setTermsOfServiceFile(file);
    setTermsOfServiceFileName(file ? file.name : null);
  };

  // ------------------------------------------------
  // 5) "Preview Profile"
  // ------------------------------------------------
  const handlePreviewProfile = () => {
    if (!lawyerProfile) return;
    dispatch(
      showModal({
        type: 'custom',
        message: 'Your profile',
        showModal: true,
        content: <LawyerProfileComponent lawyerProfile={lawyerProfile} />,
        width: '100vw',
      })
    );
  };

  // ------------------------------------------------
  // 6) "View Terms of Service"
  // ------------------------------------------------
  const handleViewTermsOfService = () => {
    if (lawyerProfile?.TermsOfServiceURL) {
      dispatch(showFileReaderSidePanel(lawyerProfile.TermsOfServiceURL));
    }
  };

  // ------------------------------------------------
  // 7) Helper for multi-item fields
  // ------------------------------------------------
  const addNewItem = <T extends FileItem | PublicationItem>(
    list: T[],
    setList: React.Dispatch<React.SetStateAction<T[]>>,
    defaultTitle = ''
  ) => {
    // For Publications, no "file" property
    const newItem = { title: defaultTitle } as T;
    if ('fileUrl' in newItem) {
      (newItem as FileItem).fileUrl = '';
    }
    setList([...list, newItem]);
  };

  const removeItem = <T extends FileItem | PublicationItem>(
    list: T[],
    setList: React.Dispatch<React.SetStateAction<T[]>>,
    idx: number
  ) => {
    const updated = [...list];
    updated.splice(idx, 1);
    setList(updated);
  };

  const updateItemTitle = (
    list: FileItem[] | PublicationItem[],
    setList: React.Dispatch<React.SetStateAction<FileItem[] | PublicationItem[]>>,
    idx: number,
    newTitle: string
  ) => {
    const updated = [...list];
    updated[idx].title = newTitle;
    setList(updated);
  };

  const updateItemFile = (
    list: FileItem[],
    setList: React.Dispatch<React.SetStateAction<FileItem[]>>,
    idx: number,
    file?: File | null,
    fileUrl?: string
  ) => {
    const updated = [...list];
    if (fileUrl !== undefined) {
      updated[idx].fileUrl = fileUrl || '';
    }
    if (file !== undefined) {
      updated[idx].file = file || undefined;
    }
    setList(updated);
  };

  const handleOpenFile = (fileUrl?: string) => {
    if (!fileUrl) return;
    dispatch(showFileReaderSidePanel(fileUrl));
  };

  // ------------------------------------------------
  // 8) Render
  // ------------------------------------------------
  if (isLoading) {
    return <ApiLoader />;
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-lg font-semibold">{t('My Marketplace Profile')}</h2>
          <p className="text-xs text-gray-700 dark:text-gray-200 text-left">
            {t('Edit and showcase your professional details for the marketplace.')}
          </p>
        </div>
        <Button onClick={handlePreviewProfile} variant="primary">
          {t('Preview Profile')}
        </Button>
      </div>

      <form onSubmit={formik.handleSubmit} className="space-y-6">
        {/* Profile Picture */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Profile Picture')}
          </label>
          <div className="flex items-center mt-1">
            <SingleFileInputWithPreview
              file={profilePictureFile}
              onFileChange={handleProfilePictureChange}
            />
            {profilePicturePreview && (
              <img
                src={profilePicturePreview}
                alt="Profile Preview"
                className="h-16 w-16 rounded-full object-cover ml-4"
              />
            )}
          </div>
        </div>

        {/* Terms of Service */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Terms of Service')}
          </label>
          <div className="flex items-center mt-1 space-x-3">
            {termsOfServiceFileName ? (
              <button
                type="button"
                onClick={handleViewTermsOfService}
                className="text-blue-600 hover:underline"
              >
                {termsOfServiceFileName}
              </button>
            ) : (
              <Badge color="gray">{t('No file uploaded')}</Badge>
            )}

            <SingleFileInputWithPreview
              file={termsOfServiceFile}
              onFileChange={handleTermsOfServiceChange}
            />
          </div>
        </div>

        {/* Marketplace Active */}
        <div className="flex flex-col mt-2">
          <div className="mb-2">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-100">
              {t('Active on Marketplace')}
            </label>
            <p className="text-sm text-gray-700 dark:text-gray-200 text-left">
              {t('Toggle to display your profile on the marketplace.')}
            </p>
          </div>
          <Toggle
            enabled={formik.values.is_marketplace_active}
            onToggle={(value) => formik.setFieldValue('is_marketplace_active', value)}
            value={formik.values.is_marketplace_active}
          />
        </div>

        {/* Hourly Rate */}
        <Input
          label={t('Hourly Rate')}
          description={t('Set your rate per hour for legal services.')}
          name="HourlyRate"
          value={formik.values.HourlyRate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="number"
          error={formik.touched.HourlyRate && Boolean(formik.errors.HourlyRate)}
          helperText={formik.touched.HourlyRate && (formik.errors.HourlyRate as string)}
        />

        {/* Bio */}
        <Textarea
          label={t('Bio')}
          description={t('Write a brief introduction about yourself and your expertise.')}
          name="Bio"
          value={formik.values.Bio}
          onChange={formik.handleChange}
          id="bio"
          rows={5}
          error={formik.touched.Bio && Boolean(formik.errors.Bio)}
        />

        {/* Experience Years */}
        <Input
          label={t('Experience Years')}
          description={t('Indicate your total years of legal experience.')}
          name="ExperienceYears"
          value={formik.values.ExperienceYears}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="number"
          error={formik.touched.ExperienceYears && Boolean(formik.errors.ExperienceYears)}
          helperText={formik.touched.ExperienceYears && (formik.errors.ExperienceYears as string)}
        />

        {/* Specializations (Tags) */}
        <TagInput
          label={t('Specializations')}
          tags={formik.values.Specializations}
          onChange={(values) => formik.setFieldValue('Specializations', values)}
          id="specializations"
        />

        {/* LanguagesSpoken */}
        <TagInput
          label={t('Languages Spoken')}
          tags={formik.values.LanguagesSpoken}
          onChange={(values) => formik.setFieldValue('LanguagesSpoken', values)}
          id="languagesSpoken"
        />

        {/* Education */}
        <Input
          label={t('Education')}
          description={t('Add your academic qualifications.')}
          name="Education"
          value={formik.values.Education}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="text"
          error={formik.touched.Education && Boolean(formik.errors.Education)}
          helperText={formik.touched.Education && (formik.errors.Education as string)}
        />

        {/* Professional Memberships */}
        <TagInput
          label={t('Professional Memberships')}
          tags={formik.values.ProfessionalMemberships}
          onChange={(values) => formik.setFieldValue('ProfessionalMemberships', values)}
          id="professionalMemberships"
        />

        {/* Awards */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Awards')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-300">
            {t('Add your notable awards. One file per item.')}
          </p>
          <div className="space-y-3 mt-2">
            {awards.map((award, idx) => (
              <div
                key={idx}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 bg-gray-50 dark:bg-gray-700 p-3 rounded"
              >
                <Input
                  type="text"
                  value={award.title}
                  onChange={(e) => updateItemTitle(awards, setAwards, idx, e.target.value)}
                  placeholder={t('Award Title') || 'Award Title'}
                  className="sm:w-1/2 mb-2 sm:mb-0"
                />

                <div className="flex items-center space-x-3">
                  {award.fileUrl && (
                    <button
                      type="button"
                      className="underline text-blue-600"
                      onClick={() => handleOpenFile(award.fileUrl)}
                    >
                      {t('Open File')}
                    </button>
                  )}

                  <SingleFileInputWithPreview
                    file={award.file || null}
                    onFileChange={(file) => updateItemFile(awards, setAwards, idx, file)}
                  />

                  <button
                    type="button"
                    className="text-red-600 hover:text-red-800 text-sm"
                    onClick={() => removeItem(awards, setAwards, idx)}
                  >
                    {t('Remove')}
                  </button>
                </div>
              </div>
            ))}

            <Button variant="tertiary" size="small" onClick={() => addNewItem(awards, setAwards, '')}>
              + {t('Add Award')}
            </Button>
          </div>
        </div>

        {/* Publications */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Publications')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-300">
            {t('Add your publications as Title + URL.')}
          </p>
          <div className="space-y-3 mt-2">
            {publications.map((pub, idx) => (
              <div
                key={idx}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 bg-gray-50 dark:bg-gray-700 p-3 rounded"
              >
                <Input
                  type="text"
                  value={pub.title}
                  onChange={(e) => updateItemTitle(publications, setPublications, idx, e.target.value)}
                  placeholder={t('Publication Title') || 'Publication Title'}
                  className="sm:w-2/3 mb-2 sm:mb-0"
                />

                <div className="sm:w-2/3 flex items-center space-x-3">

                  {
                    pub.fileUrl && (
                      <Button
                        variant="tertiary"
                        className='hover:underline truncate sm:w-1/3 mb-2 sm:mb-0'
                        // size='small'
                        onClick={() => window.open(pub.fileUrl, "_blank", "noopener,noreferrer")}
                      >
                        {pub.fileUrl}
                        
                      </Button>
                    )
                  }


                  <Input
                    type="url"
                    value={pub.fileUrl}
                    onChange={(e) =>
                      updateItemFile(publications as any, setPublications as any, idx, null, e.target.value)
                    }
                    placeholder={t('Please enter a valid URL (e.g. https://example.com)')}
                    pattern="https?://.*"
                    className="sm:w-2/3 mb-2 sm:mb-0"
                  />

                  <button
                    type="button"
                    className="text-red-600 hover:text-red-800 text-sm mt-2 sm:mt-0"
                    onClick={() => removeItem(publications, setPublications, idx)}
                  >
                    {t('Remove')}
                  </button>
                </div>

              </div>
            ))}
            <Button
              variant="tertiary"
              size="small"
              onClick={() => addNewItem(publications, setPublications)}
            >
              + {t('Add Publication')}
            </Button>
          </div>
        </div>

        {/* Certifications */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Certifications')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-300">
            {t('List your certifications here. One file per item if needed.')}
          </p>
          <div className="space-y-3 mt-2">
            {certifications.map((cert, idx) => (
              <div
                key={idx}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 bg-gray-50 dark:bg-gray-700 p-3 rounded"
              >
                <Input
                  type="text"
                  value={cert.title}
                  onChange={(e) => updateItemTitle(certifications, setCertifications, idx, e.target.value)}
                  placeholder={t('Certification Title') || 'Certification Title'}
                  className="sm:w-1/2 mb-2 sm:mb-0"
                />

                <div className="flex items-center space-x-3">
                  {cert.fileUrl && (
                    <button
                      type="button"
                      className="underline text-blue-600"
                      onClick={() => handleOpenFile(cert.fileUrl)}
                    >
                      {t('Open File')}
                    </button>
                  )}

                  <SingleFileInputWithPreview
                    file={cert.file || null}
                    onFileChange={(file) => updateItemFile(certifications, setCertifications, idx, file)}
                  />

                  <button
                    type="button"
                    className="text-red-600 hover:text-red-800 text-sm"
                    onClick={() => removeItem(certifications, setCertifications, idx)}
                  >
                    {t('Remove')}
                  </button>
                </div>
              </div>
            ))}

            <Button
              variant="tertiary"
              size="small"
              onClick={() => addNewItem(certifications, setCertifications)}
            >
              + {t('Add Certification')}
            </Button>
          </div>
        </div>

        {/* Portfolio */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
            {t('Portfolio')}
          </label>
          <p className="text-xs text-gray-500 dark:text-gray-300">
            {t('Upload images or documents that showcase your work. One file per item.')}
          </p>
          <div className="space-y-3 mt-2">
            {portfolio.map((p, idx) => (
              <div
                key={idx}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 bg-gray-50 dark:bg-gray-700 p-3 rounded"
              >
                <Input
                  type="text"
                  value={p.title}
                  onChange={(e) => updateItemTitle(portfolio, setPortfolio, idx, e.target.value)}
                  placeholder={t('Portfolio Title') || 'Portfolio Title'}
                  className="sm:w-1/2 mb-2 sm:mb-0"
                />

                <div className="flex items-center space-x-3">
                  {p.fileUrl && (
                    <button
                      type="button"
                      className="underline text-blue-600"
                      onClick={() => handleOpenFile(p.fileUrl)}
                    >
                      {t('Open File')}
                    </button>
                  )}

                  <SingleFileInputWithPreview
                    file={p.file || null}
                    onFileChange={(file) => updateItemFile(portfolio, setPortfolio, idx, file)}
                  />

                  <button
                    type="button"
                    className="text-red-600 hover:text-red-800 text-sm"
                    onClick={() => removeItem(portfolio, setPortfolio, idx)}
                  >
                    {t('Remove')}
                  </button>
                </div>
              </div>
            ))}

            <Button
              variant="tertiary"
              size="small"
              onClick={() => addNewItem(portfolio, setPortfolio)}
            >
              + {t('Add Portfolio Item')}
            </Button>
          </div>
        </div>

        {/* Preferred Budget Min/Max */}
        <div className="grid grid-cols-2 gap-4 mt-4">
          <Input
            label={t('Preferred Budget Min')}
            name="PreferredBudgetMin"
            description={t('Define the minimum budget range for cases you accept.')}
            value={formik.values.PreferredBudgetMin}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            error={formik.touched.PreferredBudgetMin && Boolean(formik.errors.PreferredBudgetMin)}
            helperText={
              formik.touched.PreferredBudgetMin && (formik.errors.PreferredBudgetMin as string)
            }
          />
          <Input
            label={t('Preferred Budget Max')}
            description={t('Set the maximum budget range for cases you consider.')}
            name="PreferredBudgetMax"
            value={formik.values.PreferredBudgetMax}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            error={formik.touched.PreferredBudgetMax && Boolean(formik.errors.PreferredBudgetMax)}
            helperText={
              formik.touched.PreferredBudgetMax && (formik.errors.PreferredBudgetMax as string)
            }
          />
        </div>

        {/* Preferred Locations (TagInput) */}
        <TagInput
          label={t('Preferred Locations')}
          tags={formik.values.PreferredLocations}
          onChange={(values) => formik.setFieldValue('PreferredLocations', values)}
          id="preferredLocations"
        />

        {/* Preferred Keywords (TagInput) */}
        <TagInput
          label={t('Preferred Keywords')}
          tags={formik.values.PreferredKeywords}
          onChange={(values) => formik.setFieldValue('PreferredKeywords', values)}
          id="preferredKeywords"
        />

        {/* Submit */}
        <div className="flex justify-end mt-4">
          <Button type="submit" variant="primary" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? t('Saving...') : t('Save Profile')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LawyerProfile;
