import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  FunnelIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/20/solid';
import Badge from './Badge';
import { useTranslation } from 'react-i18next';
import { PiArrowsDownUpLight, PiFunnelLight } from "react-icons/pi";

type FilterOption = {
  value: string;
  label: string;
  checked?: boolean;
};

type FilterSection = {
  id: string;
  name: string;
  options: FilterOption[];
};

type SortOption = {
  value: string;
  label: string;
  current: boolean;
};

type FilterBarProps = {
  filters: FilterSection[];
  sortOptions: SortOption[];
  selectedFilters: { [key: string]: string[] };
  onFilterChange: (filterName: string, selectedValues: string[]) => void;
  onSortChange: (value: string) => void;
  onSearchChange: (searchQuery: string) => void;
  sortValue?: string;
  onClearAllFilters?: () => void;
  showSearchBar?: boolean;
  extraControls?: React.ReactNode;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const FilterSearchBar: React.FC<FilterBarProps> = ({
  filters,
  sortOptions,
  selectedFilters,
  onFilterChange,
  onSortChange,
  onSearchChange,
  sortValue,
  onClearAllFilters,
  showSearchBar = true,
  extraControls
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearchChange(query);
  };

  // Get current sort label for display
  const currentSortLabel =
    sortOptions.find((option) => option.value === sortValue)?.label || 'Sort';

  // Count how many total filters are active
  const totalActiveFilters = Object.values(selectedFilters).reduce(
    (acc, arr) => acc + arr.length,
    0
  );

  return (
    <div className="bg-white dark:bg-gray-800 shadow-light-md dark:shadow-dark-md rounded-lg p-4">
      {/* Main Row: Filters & Sort on the left, Search & Extra Controls on the right */}
      <div className="flex flex-wrap items-center justify-between gap-4">
        {/* LEFT SIDE: Filter Menus, Sort Menu, and selected filter badges */}
        <div className="flex items-center flex-wrap gap-4">
          <div className="flex items-center gap-2 flex-wrap">
            {/* FILTERS */}
            {filters.map((section) => (
              <Menu
                as="div"
                key={section.id}
                className="relative inline-block text-left"
              >
                <div>
                  <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-sm font-medium bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none transition-colors">
                    <PiFunnelLight className="mr-1 h-4 w-4" aria-hidden="true" />
                    {t(section.name)}
                    <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Menu.Items
                    className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-10 custom-scrollbar"
                    style={{ maxHeight: '200px', overflowY: 'auto' }}
                  >
                    <div className="py-1">
                      {section.options.map((option) => {
                        const isChecked = selectedFilters[section.id]?.includes(
                          option.value
                        );
                        return (
                          <Menu.Item key={option.value}>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                  'flex items-center px-4 py-2 cursor-pointer transition-colors'
                                )}
                                onClick={() => {
                                  const currentSelections =
                                    selectedFilters[section.id] || [];
                                  const updatedSelections = isChecked
                                    ? currentSelections.filter(
                                        (val) => val !== option.value
                                      )
                                    : [...currentSelections, option.value];
                                  onFilterChange(section.id, updatedSelections);
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => {}}
                                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-0"
                                />
                                <span className="ml-2 text-gray-700 dark:text-gray-200 text-sm">
                                  {t(option.label)}
                                </span>
                              </div>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            ))}

            {/* SORT */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 dark:border-gray-600 px-3 py-2 text-sm font-medium bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none transition-colors">
                <PiArrowsDownUpLight size={18} style={{ marginRight: 5 }} /> {t(currentSortLabel)}
                  <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Menu.Items
                  className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-10 custom-scrollbar"
                  style={{ maxHeight: '200px', overflowY: 'auto' }}
                >
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.value}>
                        {({ active }) => (
                          <div
                            onClick={() => onSortChange(option.value)}
                            className={classNames(
                              active ? 'bg-gray-100 dark:bg-gray-600' : '',
                              'cursor-pointer px-4 py-2 text-sm text-gray-700 dark:text-gray-200 transition-colors',
                              option.value === sortValue ? 'font-medium' : ''
                            )}
                          >
                            {t(option.label)}
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          {/* Display Selected Filters as Badges */}
          <div className="flex items-center gap-2 flex-wrap">
            {Object.entries(selectedFilters).map(([filterName, values]) =>
              values.map((value) => (
                <Badge
                  key={`${filterName}-${value}`}
                  color="indigo"
                  onClose={() => {
                    const updatedValues = selectedFilters[filterName].filter(
                      (v) => v !== value
                    );
                    onFilterChange(filterName, updatedValues);
                  }}
                >
                  {`${t(value)}`}
                </Badge>
              ))
            )}

            {/* "Clear All" Button (appears if any filter or search is active) */}
            {(totalActiveFilters > 0 || searchQuery) && onClearAllFilters && (
              <button
                onClick={() => {
                  onClearAllFilters();
                }}
                className="text-sm text-indigo-600 dark:text-indigo-300 hover:underline"
              >
                {t('Clear All')}
              </button>
            )}
          </div>
        </div>

        {/* RIGHT SIDE: Extra Controls & Search */}
        <div className="flex items-center gap-4">
          {/* Extra controls if any */}
          {extraControls && <div className="flex items-center">{extraControls}</div>}

          {/* Search Bar */}
          {showSearchBar && (
            <div className="w-64 relative">
              <label htmlFor="search" className="sr-only">
                {t('Search')}
              </label>
              <div className="relative text-gray-400 focus-within:text-gray-600 dark:text-gray-500">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search"
                  className="block w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md py-2 pl-10 pr-8 leading-5 text-gray-900 dark:text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm transition"
                  placeholder={t('Search')}
                  type="search"
                  name="search"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                {searchQuery && (
                  <button
                    type="button"
                    className="absolute inset-y-0 right-2 flex items-center"
                    onClick={() => {
                      setSearchQuery('');
                      onSearchChange('');
                    }}
                  >
                    <svg
                      className="h-4 w-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterSearchBar;
