import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { TbProgressAlert, TbProgressCheck, TbProgressX } from 'react-icons/tb';

interface TabStatus {
  label: string;
  status: string;
  tooltip?: string;
}

interface TabStatusBarProps {
  tabs: TabStatus[];
}

const TabStatusBar: React.FC<TabStatusBarProps> = ({ tabs }) => {
  const { t } = useTranslation();

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'Completed':
        return <TbProgressCheck className="h-6 w-6 text-green-500" />;
      case 'Pending':
        return <TbProgressAlert className="h-6 w-6 text-yellow-500" />;
      default:
        return <TbProgressX className="h-6 w-6 text-gray-400" />;
    }
  };

  return (
    <div className="w-full p-4 bg-white dark:bg-gray-800 shadow flex space-x-4 overflow-x-auto">
      {tabs.map((tab, index) => (
        <div key={index} className="flex items-center space-x-2">
          <div className="relative group">
            <Tooltip placement='top' title={tab.tooltip ? tab.tooltip : ''} >
            {getStatusIcon(tab.status)}
            </Tooltip>
          </div>
          <p className="text-sm font-medium">{t(tab.label)}</p>
        </div>
      ))}
    </div>
  );
};

export default TabStatusBar;
