import React from 'react';
import Badge from './Badge';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Case } from '../../Marketplace/shared/types';
import Tooltip from '@mui/material/Tooltip';
import { PiCalendarCheckLight, PiMoneyLight } from 'react-icons/pi';

interface CaseCardProps {
  caseItem: Case;
}

const CaseCard: React.FC<CaseCardProps> = ({ caseItem }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleViewCase = () => {
    history.push({
      pathname: `/marketplace/case/${caseItem.CaseID}`,
      state: { caseItem },
    });
  };

  const { CaseType, BudgetRange, Deadline, Categories, Tags, AdditionalNotes, AnonymizedDescription } = caseItem;

  // Format budget range using Intl for proper currency formatting
  const formattedBudget = BudgetRange
    ? `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(BudgetRange.Min)} – ${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(BudgetRange.Max)}`
    : '';

  // Format deadline as a short, friendly date
  const formattedDeadline = Deadline
    ? new Date(Deadline).toLocaleDateString('en-UK', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : '';

  return (
    <Tooltip title={AnonymizedDescription}>
    <div
      onClick={handleViewCase}
      className="relative cursor-pointer bg-primary-50 dark:bg-primary-900 border border-primary-200 dark:border-primary-700 shadow hover:shadow-xl dark:hover:shadow-dark-md rounded-lg p-4 transition-all duration-300 animate-fade-in-up flex flex-row justify-between"
    >
      <div>
      {/* Header */}
      <div className="mb-6">
        <h3 className="text-lg font-bold text-gray-900 dark:text-white">
          {CaseType || t('Case')}
        </h3>
        {BudgetRange && (
    <Badge color='green' tooltip={t('Budget')}>
              <div className="flex items-center justify-between space-x-2">
              <span className="text-base text-sm text-green-700">
                {formattedBudget}
              </span>
              <PiMoneyLight className="text-green-600" size={20} />
            </div>
            </Badge>
        )}
      </div>


      {/* Categories */}
      {Categories && Categories.length > 0 && (
        <div className="mb-4">
          <div className="text-sm font-semibold text-gray-700 dark:text-gray-200 mb-2">
            {t('Categories')}
          </div>
          <div className="flex flex-wrap gap-2">
            {Categories.map((category, index) => (
              <Badge key={index} color="blue">
                {category}
              </Badge>
            ))}
          </div>
        </div>
      )}

      {/* Tags */}
      {/* {Tags && Tags.length > 0 && (
        <div className="mb-4">
          <div className="text-sm font-semibold text-primary-900 dark:text-white mb-2">
            {t('Tags')}
          </div>
          <div className="flex flex-wrap gap-2">
            {Tags.map((tag, index) => (
              <Badge key={index} color="indigo">
                {tag}
              </Badge>
            ))}
          </div>
        </div>
      )} */}

      {/* Additional Notes */}
      {AdditionalNotes && AdditionalNotes.trim() !== '' && (
        <p className="text-sm text-primary-900 dark:text-gray-300 italic">
          {t('Notes')}: {AdditionalNotes}
        </p>
      )}
      </div>
      <div>
      {/* Budget and Deadline Row */}
      <div className="absolute top-4 right-2">
        {Deadline && (
            <Badge color='yellow' tooltip={t('Deadline')}>
        <div className="flex items-center justify-between space-x-2">
                <span className="text-base text-sm text-yellow-700">
                {formattedDeadline}
              </span>
              <PiCalendarCheckLight className="text-yellow-600" size={20} />
            </div>
            </Badge>
        )}
        </div>
      </div>
    </div>
    </Tooltip>
  );
};

export default CaseCard;
