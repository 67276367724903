import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { EngagementTemplate, fetchClientAnalysisForLawyer, getEngagementTemplate, getProviderEngagementDetails, getProviderEngagementTerms, setEngagementTerms } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import { getCaseDetails } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import { fetchCaseAnalysisData } from '../../../store/actions/marketplace2/caseAnalysisActions';
import { sendMessage } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import { showModal, hideModal } from '../../../store/actions/modalActions';
import Badge from '../../shared/TailwindComponents/Badge';
import Button from '../../shared/TailwindComponents/Button';
import Tabs from '../../shared/TailwindComponents/Tabs';
import TabStatusBar from '../../shared/TailwindComponents/TabStatusBar';
import CaseInformation from './CaseInformation';
import ActivityFeed from '../shared/ActivityFeed';
import Textarea from '../../shared/TailwindComponents/Textarea';
import ApiLoader from '../../ApiLoader';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { EngagementStatus } from '../shared/engagementStatus';
import EngagementTemplateForm, { EngagementLetterResponse } from './EngagementTermsForm';
import { PiArrowsCounterClockwiseLight } from 'react-icons/pi';

// Define route params interface
interface RouteParams {
  engagementId: string;
}

// New helper: Allow setting engagement terms only if the engagement status allows it.
// For example, if the lawyer already has access and terms are not yet confirmed.
const canSetEngagementTerms = (status: string): boolean => {
  return ['AccessApproved', 'InvitationAccepted', 'EngagementOngoing', 'TermsSubmitted'].includes(status);
};

const LawyerEngagementDetails: React.FC = () => {
  const { engagementId } = useParams<RouteParams>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.userInfo);
  const fullName = `${user.FirstName} ${user.LastName}`;

  // Local state
  const [engagement, setEngagement] = useState<any>(null);
  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [analysisData, setAnalysisData] = useState<any>(null);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  // New tabs: "Case Terms Info", "Case Analysis", "Engagement Terms", "Messages"
  const [activeTab, setActiveTab] = useState<string>('caseTerms');
  const [isLoading, setIsLoading] = useState(true);
  const [draftTemplate, setDraftTemplate] = useState<any | null>(null);
  const [isDraftLoading, setIsDraftLoading] = useState(false);
  const [existingTerms, setExistingTerms] = useState<any>(null);


  // Set breadcrumbs on mount
  useEffect(() => {
    setBreadcrumbs([
      { name: t('Marketplace'), href: '/marketplace', current: false },
      { name: t('Engagement Details'), href: `/marketplace/engagement/${engagementId}`, current: true },
    ]);
    return () => {
      setBreadcrumbs([]);
    };
  }, [setBreadcrumbs, engagementId, t]);

  useEffect(() => {
    if (engagement) {
      dispatch(getProviderEngagementTerms(engagement.EngagementID)).then((terms: any) => {
        setExistingTerms(terms);
      });
    }
  }, [engagement, dispatch]);
  

  // Fetch engagement details from provider API
  const fetchEngagement = async () => {
    setIsLoading(true);
    try {
      const fetchedEngagement = await dispatch(getProviderEngagementDetails(engagementId));
      if (fetchedEngagement) {
        setEngagement(fetchedEngagement);
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to load engagement details.'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    } catch (error) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Failed to load engagement details.'),
          modalType: 'error',
          duration: 3000,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEngagement();
  }, [engagementId, dispatch]);

  // If access is granted, fetch the case details.
  // useEffect(() => {
  //   if (engagement && canViewCaseDetails(engagement.Status)) {
  //     dispatch(getCaseDetails(engagement.CaseID)).then((response: any) => {
  //       setCaseDetails(response);
  //     });
  //   }
  // }, [engagement, dispatch]);

  // Fetch case analysis data when the “Case Analysis” tab is active
  useEffect(() => {
    if (activeTab === 'analysis' && engagement && engagement.CaseID && canViewCaseDetails(engagement.Status)) {
      dispatch(fetchClientAnalysisForLawyer(engagement.CaseID)).then((data: any) => {
        setAnalysisData(data);
      });
    }
  }, [activeTab, engagement, dispatch]);

  // Handler for sending messages (same as before)
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    setIsSending(true);
    try {
      await dispatch(sendMessage(engagement.EngagementID, newMessage));
      const newMsg = {
        MessageID: Math.random().toString(36).substr(2, 9),
        EngagementID: engagement.EngagementID,
        SenderID: user.UserID,
        SenderName: fullName,
        Message: newMessage,
        CreatedAt: new Date().toISOString(),
      };
      setEngagement({
        ...engagement,
        Messages: [...(engagement.Messages || []), newMsg],
      });
      setNewMessage('');
    } catch (error) {
      // Handle error if needed
    } finally {
      setIsSending(false);
    }
  };

  // Handler for setting engagement terms. This is invoked from our new form.
  const handleSetEngagementTerms = async (termsData: any) => {
    try {
      // Pass two arguments instead of one object
      const success = await dispatch(setEngagementTerms(engagement.EngagementID, termsData));
  
      if (success) {
        // Optionally refetch the engagement
        fetchEngagement(); 
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Engagement terms set successfully.'),
            modalType: 'success',
            duration: 3000,
          })
        );
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to set engagement terms.'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    } catch (error) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Failed to set engagement terms.'),
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  };
  

  const handleDraftTerms = async () => {
    setIsDraftLoading(true);
    try {
      const template = await dispatch(getEngagementTemplate(engagement.CaseID, engagement.EngagementID));
      if (template) {
        setDraftTemplate(template);
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Failed to fetch draft terms.',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    } catch (error) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Failed to fetch draft terms.',
          modalType: 'error',
          duration: 3000,
        })
      );
    } finally {
      setIsDraftLoading(false);
    }
  };
  // New tabs for lawyer view.
  const tabs = [
    { label: t('Case Terms Info'), value: 'caseTerms', disabled: false },
    {
      label: t('Case Analysis'),
      value: 'analysis',
      disabled: !(engagement && canViewCaseDetails(engagement.Status)),
      tooltip: !(engagement && canViewCaseDetails(engagement.Status))
        ? t('Case analysis is not accessible until access is granted.')
        : undefined,
    },
    {
      label: t('Engagement Terms'),
      value: 'engagementTerms',
      disabled: !(engagement && canViewCaseDetails(engagement.Status) && canSetEngagementTerms(engagement.Status)),
      tooltip:
        !(engagement && canViewCaseDetails(engagement.Status))
          ? t('Engagement terms are not accessible until access is granted.')
          : engagement.Status === 'Active'
          ? t('Engagement terms have been confirmed and cannot be updated.')
          : undefined,
    },
    {
      label: t('Deposit'),
      value: 'deposit',
      disabled: !(engagement && canViewCaseDetails(engagement.Status) && canSetEngagementTerms(engagement.Status)),
      tooltip:
        !(engagement && canViewCaseDetails(engagement.Status))
          ? t('Deposit is not accessible until engagement terms are set.')
          : engagement.Status === 'Active'
          ? t('Deposit is paid.')
          : undefined,
    },
    {
      label: t('Messages'),
      value: 'messages',
      disabled: !(engagement && canViewActivityFeed(engagement.Status)),
      tooltip: !(engagement && canViewActivityFeed(engagement.Status))
      ? t('Messages are not accessible at this stage.')
      : undefined,
      rightAligned: true,
    },
  ];

  // Status bar for engagement steps
  const getTabStatuses = () => {
    const steps = [
      { label: t('Invitation/Access Request'), status: 'Not Started' },
      { label: t('Access Granted'), status: 'Not Started' },
      { label: t('Terms Set'), status: 'Not Started' },
      { label: t('Active Engagement'), status: 'Not Started' },
    ];

    switch (engagement?.Status) {
      case EngagementStatus.Invited:
      case EngagementStatus.AccessRequested:
        steps[0].status = 'Pending';
        break;
      case EngagementStatus.InvitationAccepted:
      case EngagementStatus.AccessApproved:
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        break;
      case 'TermsSubmitted':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Completed';
        break;
      case 'EngagementOngoing':
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Completed';
        steps[3].status = 'Completed';
        break;
      default:
        break;
    }
    return steps;
  };

  // Helper functions for view permissions
  function canViewCaseDetails(status: string): boolean {
    return [
      EngagementStatus.InvitationAccepted,
      EngagementStatus.AccessApproved,
      EngagementStatus.ProposalSubmitted,
      EngagementStatus.EngagementOngoing,
      'TermsSubmitted',
      'Active',
    ].includes(status);
  }
  function canViewActivityFeed(status: string): boolean {
    return [
      EngagementStatus.ProposalSubmitted,
      EngagementStatus.TermsSubmitted,
      EngagementStatus.EngagementOngoing,
      EngagementStatus.InvitationAccepted,
      EngagementStatus.AccessApproved,
      EngagementStatus.ProposalAccepted,
      'Active',
    ].includes(status);
  }

  // Render for each tab
  const renderCaseTermsInfo = () => {
    return (
      <div className="mt-4">
      {engagement ? <CaseInformation engagement={engagement} /> : <div className="text-center text-gray-500">{t('Case details are not available yet.')}</div>}
    </div>
    );
  };

  const renderCaseAnalysis = () => {

    if (!analysisData || Object.keys(analysisData).length === 0) {
      return (
        <div className="mt-4 text-center text-gray-500">
          {t('No analysis data available')}
        </div>
      );
    }
  
    const analysisItems = Object.entries(analysisData.clientAnalysis)
      .map(([key, value]) => ({
        key,
        label: key.replace(/([A-Z])/g, ' $1').trim(), 
        content: value ? String(value).trim() : ''
      }))
      
      .filter(item => item.content !== '');
  
    if (analysisItems.length === 0) {
      return (
        <div className="mt-4 text-center text-gray-500">
          {t('No analysis data available')}
        </div>
      );
    }
  
    return (
      <div className="mt-4 space-y-6">
        {analysisItems.map(({ key, label, content }) => (
          <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h4 className="text-lg font-medium mb-1">{t(label)}</h4>
            <div className="mt-2 text-sm prose dark:!prose-invert">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
    );
  };
  

  const renderEngagementTerms = () => {
    // 1) If there's no existing terms and status isn't active, show your current "Draft Terms" UI:
    if (!existingTerms && engagement.Status !== 'Active') {
      return (
        <div className="mt-4">
          <div className="flex justify-end mb-2">
            <Button onClick={handleDraftTerms} variant="tertiary" size="small">
              {isDraftLoading ? t('Drafting Terms...') : t('Draft Terms')}
            </Button>
          </div>
          {isDraftLoading && (
            <div className="flex justify-center items-center h-64">
              <PiArrowsCounterClockwiseLight className="h-6 w-6 text-blue-500 animate-spin" />
            </div>
          )}
          {draftTemplate && !isDraftLoading && (
            <EngagementTemplateForm
              engagementLetter={draftTemplate}
              onSave={(updatedTemplate) => handleSetEngagementTerms(updatedTemplate as any)}
            />
          )}
          {!draftTemplate && !isDraftLoading && (
            <p className="text-center text-gray-500">{t('Please click "Draft Terms" to generate a draft.')}</p>
          )}
        </div>
      );
    }
  
    // 2) If we have existingTerms, let's render them in a robust manner
    //    so the lawyer can see what was previously set.
  
    if (!existingTerms && engagement.Status === 'Active') {
      // Edge case: status is active but no AdditionalTerms for some reason
      return (
        <div className="mt-4 text-gray-500">
          {t('No engagement terms are available (though the engagement is active).')}
        </div>
      );
    }
  
    // From here on, we know existingTerms != null
    // Let's handle possible shapes:
    //  - string only
    //  - an object with { engagementLetter: {...} }
    //  - an object that *is* the letter
  
    // If it’s a string, just show it
    if (typeof existingTerms === 'string') {
      return (
        <div className="mt-4 space-y-4 bg-white dark:bg-gray-800 p-4 rounded-md shadow">
          <h2 className="text-xl font-semibold mb-2">{t('Existing Engagement Terms')}</h2>
          <div className="border-t pt-4 mt-4">
            <h3 className="text-lg font-semibold mb-2">{t('Full Engagement Details')}</h3>
            <div className="mt-2 prose dark:prose-invert">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {existingTerms}
              </ReactMarkdown>
            </div>
          </div>
  
          {/* If the status isn't "Active," we might allow editing */}
          {engagement.Status !== 'Active' && (
            <div className="mt-4">
              <Button variant="neutral" onClick={() => {/* open form to edit terms */}}>
                {t('Edit Terms')}
              </Button>
            </div>
          )}
        </div>
      );
    }
  
    // Otherwise, treat existingTerms as an object
    let doc: any = existingTerms;
    if (existingTerms.engagementLetter) {
      doc = existingTerms.engagementLetter;
    }
  
    // If doc is still not an object, show fallback
    if (typeof doc !== 'object' || Array.isArray(doc) || !doc) {
      return (
        <div className="mt-4 text-gray-500">
          {t('Invalid engagement terms format.')}
        </div>
      );
    }
  
    // Extract fields from doc
    const {
      clientName,
      lawyerName,
      jurisdiction,
      subject,
      estimatedTimeline,
      paymentDetails,
      engagementDetails,
      caseTermInfo
    } = doc;
  
    return (
      <div className="mt-4 space-y-4 bg-white dark:bg-gray-800 p-4 rounded-md shadow">
        <h2 className="text-xl font-semibold mb-2">{t('Existing Engagement Terms')}</h2>
  
        {/* Basic info in a grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  
          {/* Example: Only display CaseType from caseTermInfo, not CaseID */}
          {caseTermInfo && caseTermInfo.CaseType && (
            <div>
              <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t('Case Type')}:
              </strong>
              <div className="text-gray-900 dark:text-gray-100">
                {caseTermInfo.CaseType}
              </div>
            </div>
          )}
  
          <div>
            <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {t('Client Name')}:
            </strong>
            <div className="text-gray-900 dark:text-gray-100">
              {clientName || '-'}
            </div>
          </div>
  
          <div>
            <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {t('Lawyer Name')}:
            </strong>
            <div className="text-gray-900 dark:text-gray-100">
              {lawyerName || '-'}
            </div>
          </div>
  
          <div>
            <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {t('Jurisdiction')}:
            </strong>
            <div className="text-gray-900 dark:text-gray-100">
              {jurisdiction || '-'}
            </div>
          </div>
  
          <div>
            <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {t('Subject')}:
            </strong>
            <div className="text-gray-900 dark:text-gray-100">
              {subject || '-'}
            </div>
          </div>
  
          {estimatedTimeline && (
            <div>
              <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t('Estimated Timeline')}:
              </strong>
              <div className="text-gray-900 dark:text-gray-100">
                {estimatedTimeline}
              </div>
            </div>
          )}
        </div>
  
        {/* Payment details */}
        {paymentDetails && typeof paymentDetails === 'object' && (
          <div className="border-t pt-4 mt-4">
            <h3 className="text-lg font-semibold mb-2">{t('Payment Details')}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Billing Method')}:
                </strong>
                <div className="text-gray-900 dark:text-gray-100">
                  {paymentDetails.BillingMethod || '-'}
                </div>
              </div>
              <div>
                <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Hourly Rate')}:
                </strong>
                <div className="text-gray-900 dark:text-gray-100">
                  {paymentDetails.HourlyRate || '-'}
                </div>
              </div>
              <div>
                <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Agreed Fee')}:
                </strong>
                <div className="text-gray-900 dark:text-gray-100">
                  {paymentDetails.AgreedFee || '-'}
                </div>
              </div>
              <div>
                <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t('Deposit')}:
                </strong>
                <div className="text-gray-900 dark:text-gray-100">
                  {String(paymentDetails.Deposit ?? '-')}
                </div>
              </div>
              {paymentDetails.PaymentInterval && (
                <div>
                  <strong className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {t('Payment Interval')}:
                  </strong>
                  <div className="text-gray-900 dark:text-gray-100">
                    {paymentDetails.PaymentInterval}
                  </div>
                </div>
              )}
                          </div>
          </div>
        )}
  
        {/* The big engagementDetails text (markdown) */}
        {engagementDetails && (
          <div className="border-t pt-4 mt-4">
            <h3 className="text-lg font-semibold mb-2">{t('Full Engagement Details')}</h3>
            <div className="mt-2 prose dark:prose-invert">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {engagementDetails}
              </ReactMarkdown>
            </div>
          </div>
        )}
  
        {/* If the status isn't Active, allow editing */}
        {engagement.Status !== 'Active' && (
          <div className="mt-4">
            <Button variant="neutral" onClick={() => {
              // e.g. re-display the EngagementTemplateForm or handleSetEngagementTerms
            }}>
              {t('Edit Terms')}
            </Button>
          </div>
        )}
      </div>
    );
  };
  

  const renderMessages = () => {
    return (
      <div>
        {(!engagement.Messages || engagement.Messages.length === 0) ? (
          <div className="text-center text-gray-500 mt-4">{t('No messages yet')}</div>
        ) : (
          <ActivityFeed messages={engagement.Messages} />
        )}
        <div className="mt-6 flex gap-x-3">
          <img
            alt=""
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(fullName)}&background=random`}
            className="h-10 w-10 flex-none rounded-full bg-gray-50"
          />
          <div className="relative flex-auto">
            <Textarea
              name="newMessage"
              placeholder={t('Type your message...')}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              rows={2}
              id={''}
              label={''}
            />
            <div className="absolute right-0 bottom-0 flex items-center space-x-2 p-2">
              <Button type="button" onClick={handleSendMessage} disabled={isSending || newMessage.trim() === ''} variant="primary">
                {isSending ? t('Sending...') : t('Send')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <ApiLoader />;
  }
  if (!engagement) {
    return null;
  }

  return (
    <div className="px-4 py-6">
      <div className="px-4">
        <TabStatusBar tabs={getTabStatuses()} />
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={(tabValue) => {
            const selectedTab = tabs.find((tab) => tab.value === tabValue);
            if (selectedTab && selectedTab.disabled) return;
            setActiveTab(tabValue);
          }}
        />
      </div>
      {activeTab === 'caseTerms' && renderCaseTermsInfo()}
      {activeTab === 'analysis' &&
        (canViewCaseDetails(engagement.Status) ? renderCaseAnalysis() : (
          <div className="mt-4 text-center text-gray-500">
            {t('Case analysis is restricted until access is granted.')}
          </div>
        ))}
      {activeTab === 'engagementTerms' &&
        (canViewCaseDetails(engagement.Status) ? renderEngagementTerms() : (
          <div className="mt-4 text-center text-gray-500">
            {t('Engagement terms are restricted until access is granted.')}
          </div>
        ))}
      {activeTab === 'messages' &&
        (canViewActivityFeed(engagement.Status) ? renderMessages() : (
          <div className="mt-4 text-center text-gray-500">
            {t('Messages are restricted at this stage.')}
          </div>
        ))}
    </div>
  );
};

export default LawyerEngagementDetails;
