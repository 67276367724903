import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Timekeeping from '../components/TimeTracker/TimeKeeping';
import TimeKeepingLogs from '../components/TimeTracker/TimeKeepingLogs';
import Tabs from '../components/shared/TailwindComponents/Tabs';
import { isBetaEnv } from '../utils/authUtils';
import Badge from '../components/shared/TailwindComponents/Badge';

const TimeKeepingPage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('time-tracking');

  const tabs = [
    { label: t('Time Tracking'), value: 'time-tracking' },
    { label: t('Time Logs'), value: 'time-logs' },
  ];

  const handleTabClick = (tabValue: string) => {
    setActiveTab(tabValue);
  };

  return (
    <div className="h-[calc(100vh-90px)] flex flex-col px-4">
      {isBetaEnv ? (
        <div className="flex h-calc-100vh-90px justify-center items-center"><Badge color='gray'>{t('Coming soon')}</Badge></div>
      ) : (
        <>  
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
      <div className="flex-grow overflow-y-auto">
        {activeTab === 'time-tracking' && <Timekeeping />}
        {activeTab === 'time-logs' && <TimeKeepingLogs />}
      </div>
      </>
      )}
    </div>  
  );
};

export default TimeKeepingPage;
