import { EngagementStatus } from './engagementStatus';


export function getStatusColor(status: string): 'green' | 'yellow' | 'red' | 'gray' | 'blue' {
    switch (status) {
      case EngagementStatus.EngagementOngoing:
        return 'blue';
      case EngagementStatus.InvitationAccepted:
      case EngagementStatus.ProposalAccepted:
        return 'green';
    //   case EngagementStatus.Pending: 
      case EngagementStatus.AccessRequested:
      case EngagementStatus.ProposalSubmitted:
      case EngagementStatus.Invited:
        return 'yellow';
      case EngagementStatus.InvitationDeclined:
      case EngagementStatus.ProposalDeclined:
      case EngagementStatus.AccessDeclined:
        return 'red';
      default:
        return 'gray';
    }
  }

  export interface StepStatus {
    label: string;
    status: 'Not Started' | 'Pending' | 'Completed';
    tooltip?: string;
  }
  
  export function getTabStatuses(currentStatus: string): StepStatus[] {
    // Define the steps for the engagement process.
    const steps: StepStatus[] = [
      { label: 'Invitation/Request', status: 'Not Started' },
      { label: 'Access Granted', status: 'Not Started' },
      { label: 'Proposal', status: 'Not Started' },
      { label: 'Ongoing Engagement', status: 'Not Started' },
    ];
  
    switch (currentStatus) {
      // When an invitation or access request is sent,
      // mark the first step as Completed since it has been sent,
      // but the next step is still pending.
      case EngagementStatus.Invited:
      case EngagementStatus.AccessRequested:
        steps[0].status = 'Completed';
        steps[1].status = 'Pending';
        break;
        
      // When access has been granted
      case EngagementStatus.InvitationAccepted:
      case EngagementStatus.AccessApproved:
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        break;
        
      // When a proposal has been submitted
      case EngagementStatus.ProposalSubmitted:
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Pending';
        break;
        
      // When the proposal is accepted
      case EngagementStatus.ProposalAccepted:
        steps[0].status = 'Completed';
        steps[1].status = 'Completed';
        steps[2].status = 'Completed';
        steps[3].status = 'Pending';
        break;
        
      // When the engagement is ongoing
      case EngagementStatus.EngagementOngoing:
        for (let i = 0; i < steps.length; i++) {
          steps[i].status = 'Completed';
        }
        break;
        
      default:
        break;
    }
    return steps;
  }