import React, { useContext } from 'react';
import { ClipLoader } from 'react-spinners';
import { ThemeContext } from '../contexts/ThemeContext'; 

const ApiLoader: React.FC = () => {
  const { theme } = useContext(ThemeContext);

  const loaderColor = theme === 'dark' ? '#fff' : '#000';

  return (
    <div className="flex items-center justify-center h-64">
      <ClipLoader color={loaderColor} size={20} />
    </div>
  );
};

export default ApiLoader;
