
import { Dispatch } from 'redux';
import apis from '../../../utils/apis';
import {
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  FETCH_TEAM_DETAILS_REQUEST,
  FETCH_TEAM_DETAILS_SUCCESS,
} from '../../types';
import { showFeedbackModal } from '../UserFeedbackActions';
import { handleApiError } from '../utils/utils';

export const fetchAllTeams = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: FETCH_TEAMS_REQUEST });
    try {
      const response = await apis.get('/lawyerteams/get_legal_teams');
      if (response && response.status === 200) {
        dispatch({
          type: FETCH_TEAMS_SUCCESS,
          payload: response.data.legal_teams || [],
        });
      } else {
       handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
    }
  };
};

export const addTeam = (teamData: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ADD_TEAM_REQUEST });
    try {
      const response = await apis.post('/lawyerteams/add_team', teamData);
      if (response && response.status === 200) {
        dispatch({
          type: ADD_TEAM_SUCCESS,
          payload: response.data.team,
        });
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: 'Team added successfully.',
            showModal: true,
            duration: 3000,
          })
        );
        // Refresh teams list
        dispatch(fetchAllTeams());
      } else {
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
    }
  };
};


export const fetchTeamDetails = (teamId: string) => {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: FETCH_TEAM_DETAILS_REQUEST });
      try {
        const response = await apis.post('/lawyerteams/get_team_details', { LegalTeamID: teamId });
        if (response && response.status === 200) {
          dispatch({
            type: FETCH_TEAM_DETAILS_SUCCESS,
            payload: response.data.team,
          });
        } else {
          handleApiError(response, dispatch);
        }
      } catch (error: any) {
        handleApiError(error, dispatch);
      }
    };
  };
  