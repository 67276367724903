import React, { useState } from 'react';
import '../Settings.css';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { EditUser, userInformation } from '../../../store/actions/DataAction';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { showModal, showPasswordProfileModal } from '../../../store/actions/modalActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import ApiLoader from '../../ApiLoader';
import Input from '../../shared/TailwindComponents/Input';
import Button from '../../shared/TailwindComponents/Button';
import { getActiveSessions } from '../../../store/actions/SecurityActions';
import apis from '../../../utils/apis';
import { useHistory } from 'react-router-dom';
import Badge from '../../shared/TailwindComponents/Badge';
import { Tooltip } from '@mui/material';
import SocketServices from '../../../utils/SocketServices';

export interface UserProps {
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  StreetAddress: string;
  Email: string;
  CountryOfJurisdiction: string;
  ZipCode?: string;
  ProfilePicture?: string;
  City?: string;
  Country?: string;
  State?: string;
  DateOfBirth?: string;
  Password?: string;
  Biography?: string;
  HourlyRate?: string;
}

const PersonalInfoAndProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useSelector((state: RootState) => state.user.userInfo) || {};
  const userType = getUserType();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const devices = useSelector((state: RootState) => state.security.sessions);
  const ownSessionId = sessionStorage.getItem('session_id');
  const userId = getUserID();
  const [signature, setSignature] = useState<string | null>(null);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const history = useHistory();
  const validationSchema = yup.object({
    FirstName: yup.string().required('Required'),
    LastName: yup.string().required('Required'),
    PhoneNumber: yup.string().required('Required'),
    // Email is now static and excluded from validation
    Address: yup.string(),
    Country: yup.string(),
    State: yup.string(),
    City: yup.string(),
    ZipCode: yup.string(),
    ProfilePicture: yup.string(),
    DateOfBirth: yup
      .string()
      .required(t('Date of Birth is required'))
      .test('is-valid-date', t('Invalid date'), (value) =>
        dayjs(value, 'DD-MM-YYYY', true).isValid()
      ),
  });

  const formik = useFormik({
    initialValues: {
      FirstName: userData.FirstName || '',
      LastName: userData.LastName || '',
      PhoneNumber:
        userType === 'LawFirmAdmin' || userType === 'BusinessAdmin'
          ? userData.PrimaryContact
          : userData.PhoneNumber || '',
      StreetAddress: userData.StreetAddress || '',
      Email: userData.Email || '',
      ZipCode: userData.ZipCode || '',
      City: userData.City || '',
      Country: userData.Country || '',
      State: userData.State || '',
      DateOfBirth: userData.DateOfBirth || '',
      CountryOfJurisdiction: 'Norway',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: UserProps) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('UserID', userData.UserID);
      formData.append('UserType', userType);

      Object.keys(values).forEach((key) => {
        formData.append(key, (values as any)[key] ?? '');
      });

      const resp = await dispatch(EditUser(formData, onSuccess));
      if (resp) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    },
  });

  const onSuccess = () => {
    dispatch(userInformation());
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: t('Account updated'),
        modalType: 'success',
        duration: 3000,
      })
    );
  };

  const passwordFormik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      currentPassword: yup.string().required('Required'),
      newPassword: yup.string().required('Required'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      setIsChangingPassword(true);
      // Implement your change password API call here
      try {
        const response = await apis({
          method: 'POST',
          url: '/account/change_password',
          data: {
            CurrentPassword: values.currentPassword,
            NewPassword: values.newPassword,
          },
        });
        if (response && response.status === 200) {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: t('Password changed successfully'),
              modalType: 'success',
              duration: 3000,
            })
          );
          passwordFormik.resetForm();
        }
      } catch (error: any) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Failed to change password'),
            modalType: 'error',
            duration: 3000,
          })
        );
      }
      setIsChangingPassword(false);
    },
  });


  if (!userData) {
    return <div>{t('Loading...')}</div>;
  }

  const handleDOBChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('DD-MM-YYYY'); // Ensure consistent format
      formik.setFieldValue('DateOfBirth', formattedDate);
    } else {
      formik.setFieldValue('DateOfBirth', '');
    }
  };
  
  const handleEditSubmit = () => {
    dispatch(showPasswordProfileModal(formik.values, userData.UserID, userType, onSuccess));
  };

  const formatDate = (dateString?: string | null): string => {
    if (!dateString) {
      return 'No Date';
    }

    const inputFormat = 'MM-DD-YYYY';
    const date = dayjs(dateString, inputFormat, true);

    if (!date.isValid()) {
      return 'Invalid Date';
    }

    const outputFormat = 'DD MMMM YYYY'; 
    return date.format(outputFormat);
  };

  const handleSignOutConfirm = (sessionId: string) => {
    dispatch(
      showModal({
        type: 'alert',
        message: t('Are you sure you want to sign out from this device?'),
        onConfirm: () => {
          SocketServices.emit('logout_session', { session_id: sessionId });
          if (ownSessionId !== sessionId) {
            dispatch(getActiveSessions());
          }
        },
        inputData: sessionId,
        showModal: true,
      })
    );
  };

  const handleSignOutAllAccounts = () => {
    dispatch(
      showModal({
        type: 'alert',
        message: t('Are you sure you want to sign out from all devices?'),
        onConfirm: () => {
          SocketServices.emit('sign_out_all_accounts', { userId: userData.UserUID });
        },
        inputData: userId,
        showModal: true,
      })
    );
  };

  const handleDeleteAccount = () => {
    dispatch(
      showModal({
        type: 'alert',
        message: t(
          'Are you sure you want to delete your account? This action is irreversible.'
        ),
        onConfirm: async () => {
          try {
            const response = await apis({
              method: 'DELETE',
              url: '/account/delete_account',
            });
            if (response && response.status === 200) {
              dispatch(
                showFeedbackModal({
                  modalType: 'success',
                  message: t('Account deleted successfully.'),
                  showModal: true,
                  duration: 3000,
                })
              );
              // Clear session and redirect to login page
              sessionStorage.clear();
              history.push('/');
            }
          } catch (error: any) {
            dispatch(
              showFeedbackModal({
                modalType: 'error',
                message: t('Failed to delete account.'),
                showModal: true,
                duration: 3000,
              })
            );
          }
        },
        onCancel: () => {},
        showModal: true,
      })
    );
  };

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700">
      {/* Personal Information Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Personal Information')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Set your personal details and profile information.')}
          </p>
        </div>
        <div className="md:col-span-2">
          {isLoading && (
            <div className="flex justify-center items-center">
              <ApiLoader />
            </div>
          )}
          <form onSubmit={formik.handleSubmit} className="space-y-8">
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
              {/* First Name */}
              <div className="sm:col-span-3">
                <Input
                  label={t('First Name')}
                  id="FirstName"
                  name="FirstName"
                  type="text"
                  placeholder={t('Enter your first name')}
                  value={formik.values.FirstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.FirstName && Boolean(formik.errors.FirstName)}
                  helperText={formik.touched.FirstName && formik.errors.FirstName}
                  italicTxt='Your first name'
                />
              </div>
              {/* Last Name */}
              <div className="sm:col-span-3">
                <Input
                  label={t('Last Name')}
                  id="LastName"
                  name="LastName"
                  type="text"
                  placeholder={t('Enter your last name')}
                  value={formik.values.LastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.LastName && Boolean(formik.errors.LastName)}
                  helperText={formik.touched.LastName && formik.errors.LastName}
                  italicTxt='Your last name.'
                />
              </div>
              {/* Email */}
              <Tooltip title='You cannot change this field. If you need to change it please contact support.' placement='bottom'>
              <div className="col-span-full">
                <Input
                  label={t('Email')}
                  id="Email"
                  name="Email"
                  type="email"
                  placeholder={t('Enter your email')}
                  value={formik.values.Email}
                  disabled
                  italicTxt='Your registered email address.'
                  className="bg-gray-200 dark:bg-gray-700"
                  />
              </div>
                  </Tooltip>
              {/* Phone Number */}
              <div className="col-span-full">
                <Input
                  label={t('Phone Number')}
                  id="PhoneNumber"
                  name="PhoneNumber"
                  type="text"
                  placeholder={t('Enter your phone number')}
                  value={formik.values.PhoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.PhoneNumber && Boolean(formik.errors.PhoneNumber)}
                  helperText={formik.touched.PhoneNumber && formik.errors.PhoneNumber}
                  italicTxt='Your contact phone number.'
                />
              </div>
              {/* Street Address */}
              {/* <div className="col-span-full">
                <Input
                  label={t('Street Address')}
                  id="StreetAddress"
                  name="StreetAddress"
                  type="text"
                  placeholder={t('Enter your street address')}
                  value={formik.values.StreetAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* City 
              <div className="sm:col-span-2">
                <Input
                  label={t('City')}
                  id="City"
                  name="City"
                  type="text"
                  placeholder={t('Enter your city')}
                  value={formik.values.City}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* State 
              <div className="sm:col-span-2">
                <Input
                  label={t('State')}
                  id="State"
                  name="State"
                  type="text"
                  placeholder={t('Enter your state')}
                  value={formik.values.State}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Zip Code 
              <div className="sm:col-span-2">
                <Input
                  label={t('Zip Code')}
                  id="ZipCode"
                  name="ZipCode"
                  type="text"
                  placeholder={t('Enter your zip code')}
                  value={formik.values.ZipCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Country 
              <div className="col-span-full">
                <Input
                  label={t('Country')}
                  id="Country"
                  name="Country"
                  type="text"
                  placeholder={t('Enter your country')}
                  value={formik.values.Country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Date of Birth 
              <div className="sm:col-span-3">
                <Input
                  label={t('Date of Birth')}
                  id="DateOfBirth"
                  name="DateOfBirth"
                  type="date"
                  placeholder={t('Enter your date of birth')}
                  value={formik.values.DateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.DateOfBirth && Boolean(formik.errors.DateOfBirth)}
                  helperText={formik.touched.DateOfBirth && formik.errors.DateOfBirth}
                />
              </div> */}
            </div>

            {/* Save and Cancel Buttons */}
            <div className="mt-8 flex gap-x-4">
              <Button variant="primary" onClick={handleEditSubmit} disabled={!formik.dirty || isLoading}>
                {t('Save')}
              </Button>
              {formik.dirty && (
                <Button variant="destructive" onClick={() => formik.resetForm()}>
                  {t('Cancel')}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>

      {/* Change Password Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Change password')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
          {t('Set a new password for your account.')}
                    </p>
        </div>
        <div className="md:col-span-2">
          <form onSubmit={passwordFormik.handleSubmit} className="space-y-8">
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
              <div className="col-span-full">
                <Input
                  label="Current Password"
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  italicTxt='Enter your current password.'
                  placeholder="********"
                  value={passwordFormik.values.currentPassword}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={
                    passwordFormik.touched.currentPassword &&
                    Boolean(passwordFormik.errors.currentPassword)
                  }
                  helperText={
                    passwordFormik.touched.currentPassword &&
                    passwordFormik.errors.currentPassword
                  }
                />
              </div>
              <div className="col-span-full">
                <Input
                  label="New Password"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  italicTxt='Enter your new password.'
                  placeholder="********"
                  value={passwordFormik.values.newPassword}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={
                    passwordFormik.touched.newPassword &&
                    Boolean(passwordFormik.errors.newPassword)
                  }
                  helperText={
                    passwordFormik.touched.newPassword && passwordFormik.errors.newPassword
                  }
                />
              </div>
              <div className="col-span-full">
                <Input
                  label="Confirm Password"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  italicTxt='Enter your new password again.'
                  placeholder="********"
                  value={passwordFormik.values.confirmPassword}
                  onChange={passwordFormik.handleChange}
                  onBlur={passwordFormik.handleBlur}
                  error={
                    passwordFormik.touched.confirmPassword &&
                    Boolean(passwordFormik.errors.confirmPassword)
                  }
                  helperText={
                    passwordFormik.touched.confirmPassword &&
                    passwordFormik.errors.confirmPassword
                  }
                />
              </div>
            </div>
            <div className="mt-8 flex">
              <Button variant="primary" type="submit" disabled={isChangingPassword}>
                {t('Save')}
              </Button>
            </div>
          </form>
        </div>
      </div>

      {/* Log out other sessions Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Log out other sessions')}
          </h2>
              <p className="text-sm text-gray-700 dark:text-gray-300">
                {t('Control and monitor devices connected to your account.')}
                </p>
          {/* <p className="text-xs text-gray-500 dark:text-gray-400 italic">
                {t("End all other active sessions to maintain account security.")}
                </p> */}
        </div>
        <div className="md:col-span-2">
          <div className="space-y-4">
            {devices && devices.length > 0 ? (
              devices.map((device: any, index: number) => (
                <div
                  key={index}
                  className="flex justify-between items-center bg-gray-100 dark:bg-gray-800 p-4 rounded-lg"
                >
                  <div>
                    <p className="font-medium">
                      {device.os || device.mobileModel}
                    </p>
                    <p className="text-sm text-gray-500">
                      {device.browser}
                    </p>
                    <p className="text-sm text-green-500">{device.status}</p>
                    <Badge color='indigo'>
                    {dayjs(device.lastActive).format('MMMM D, YYYY h:mm A')}
                    </Badge>
                  </div>
                  <Button
                    variant="danger"
                    onClick={() => handleSignOutConfirm(device.session_id)}
                  >
                    {t('Sign out')}
                  </Button>
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-500">
                {t('No devices currently connected.')}
              </p>
            )}
          </div>
          <div className="mt-8 flex">
            <Button variant="danger" onClick={handleSignOutAllAccounts}>
              {t('Sign out of all devices')}
            </Button>
          </div>
        </div>
      </div>

      {/* Delete Account Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('Delete account')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('No longer want to use our service? You can delete your account here. This action is not reversible. All information related to this account will be deleted permanently.')}
          </p>
        </div>
        <div className="md:col-span-2 flex items-start">
          <Button variant="danger" disabled onClick={handleDeleteAccount}>
            {t('Yes, delete my account')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoAndProfile;