import { Dispatch } from 'redux';
import apis from '../../../utils/apis';
import {
  FETCH_COMPANY_SETTINGS_REQUEST,
  FETCH_COMPANY_SETTINGS_SUCCESS,
  FETCH_COMPANY_SETTINGS_FAILURE,
  UPDATE_COMPANY_SETTINGS_REQUEST,
  UPDATE_COMPANY_SETTINGS_SUCCESS,
  UPDATE_COMPANY_SETTINGS_FAILURE,
} from '../../types';
import { showFeedbackModal } from '../UserFeedbackActions';
import { handleApiError } from '../utils/utils';

export const fetchCompanySettingsRequest = () => ({
  type: FETCH_COMPANY_SETTINGS_REQUEST,
});

export const fetchCompanySettingsSuccess = (settings: any) => ({
  type: FETCH_COMPANY_SETTINGS_SUCCESS,
  payload: settings,
});

export const fetchCompanySettingsFailure = (error: string) => ({
  type: FETCH_COMPANY_SETTINGS_FAILURE,
  payload: error,
});

export const updateCompanySettingsRequest = () => ({
  type: UPDATE_COMPANY_SETTINGS_REQUEST,
});

export const updateCompanySettingsSuccess = (settings: any) => ({
  type: UPDATE_COMPANY_SETTINGS_SUCCESS,
  payload: settings,
});

export const updateCompanySettingsFailure = (error: string) => ({
  type: UPDATE_COMPANY_SETTINGS_FAILURE,
  payload: error,
});

export const getOrganizationSettings = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchCompanySettingsRequest());
    try {
      const response = await apis({
        method: 'GET',
        url: '/organizations/get_settings',
      });
      if(response && response.status === 200){
        const settings = response.data.organization_settings;
        dispatch(fetchCompanySettingsSuccess(settings));

      }else{
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      dispatch(fetchCompanySettingsFailure(error.toString()));
      handleApiError(error, dispatch);
    }
  };
};

export const updateOrganizationSettings = (formData: FormData) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(updateCompanySettingsRequest());
    try {
      const response = await apis({
        method: 'POST',
        url: '/organizations/update_settings',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if(response && response.status === 200){
        const settings = response.data;
        dispatch(updateCompanySettingsSuccess(settings));
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: 'Organization settings updated successfully.',
            showModal: true,
            duration: 3000,
          })
        );

      }else{
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      dispatch(updateCompanySettingsFailure(error.toString()));
      handleApiError(error, dispatch);
    }
  };
};
