import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import ToggleButton from './ToggleButton';
import './MessageCenter.css';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { Lawyer } from '../Dashboard/AdminFirm/interface/IFirmLawyer';
import { getAllLawyers } from '../../store/actions/organization/LawfirmActions';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import SocketServices from '../../utils/SocketServices';
import { fetchCHatList, fetchMessageList } from '../../store/actions/DataAction';
import { useTranslation } from 'react-i18next';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';

import {
  decryptMessage,
  deriveEphemeralAESKey,
  encryptMessage,
  generateAndRegisterLongTermKeys,
  getPrivateKey,
  getRecipientPublicKey
} from './E2EEHelpers';

import { getUserData } from '../../utils/authUtils';

const MessageCenter: React.FC = () => {
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
  const [isEmployeeView, setIsEmployeeView] = useState(true);
  const [openListView, setOpenListView] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [newChat, setNewChat] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState<any>({});
  const [messages, setMessages] = useState<any[]>([]);

  const chatList = useSelector((state: RootState) => state.lists.chatList);
  const MessageList = useSelector((state: RootState) => state.lists.MessageList);
  const selectedChat: any = chatList.find((chat: any) => chat.ChatID === selectedChatId);

  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const userType = getUserType();
  const userID = getUserID();

  // Grab token from userData
  const userData = getUserData();
  const token = userData?.token || '';

  /*****************************
   * 1) KEY GENERATION (ON LOAD)
   *****************************/
  useEffect(() => {
    async function bootstrapKeys() {
      try {
        const existingPrivKey = await getPrivateKey(userInfo.UserUID);
        if (!existingPrivKey) {
          await generateAndRegisterLongTermKeys(userInfo.UserUID, token);
          console.log('[E2EE] Generated & registered new keys for user:', userInfo.UserUID);
        } else {
          console.log('[E2EE] Found existing private key in IndexedDB.');
        }
      } catch (error) {
        console.error('[E2EE] Error during bootstrapKeys:', error);
      }
    }
    if (userInfo.UserUID) {
      bootstrapKeys();
    }
  }, [userInfo.UserUID, token]);

  /******************************************
   * 2) LOAD CHATS, EMPLOYEES, ETC.
   ******************************************/
  useEffect(() => {
    // Load any stored unread counts from localStorage
    const storedUnreadCounts = localStorage.getItem('unreadCounts');
    if (storedUnreadCounts) {
      setUnreadCounts(JSON.parse(storedUnreadCounts));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('unreadCounts', JSON.stringify(unreadCounts));
  }, [unreadCounts]);

  useEffect(() => {
    if (userType === 'LawFirmEmployee') {
      dispatch(fetchAllEmployees());
    } else {
      dispatch(fetchAllEmployees());
    }
    dispatch(fetchCHatList());
  }, [dispatch, userID, userType]);

  /******************************************
   * 3) SOCKET EVENTS
   ******************************************/
  const recievePersonalMessage = useCallback(
    async (data: any) => {
      const { message, img_urls, chatId } = data;
      // The 'message' field should be ciphertext JSON
      try {
        const msgObj = JSON.parse(message);
        const myPrivateKey = await getPrivateKey(userInfo.UserUID);
        if (!myPrivateKey) {
          console.error('[E2EE] No private key to decrypt an incoming message!');
          return;
        }

        // Decrypt
        const plainText = await decryptMessage(
          msgObj.ephemeralPubKey,
          myPrivateKey,
          msgObj.iv,
          msgObj.ct
        );

        // If the currently active chat is the same chatId, push it to local message state
        if (selectedChatId === chatId) {
          setMessages((prev) => [
            ...prev,
            {
              id: prev.length + 1,
              content: plainText,
              timestamp: new Date(),
              sender: 'otherUser',
              attachments: img_urls,
            },
          ]);
        } else {
          // otherwise increment unread
          setUnreadCounts((prevCounts: any) => ({
            ...prevCounts,
            [chatId]: (prevCounts[chatId] || 0) + 1,
          }));
        }
      } catch (err) {
        console.error('[E2EE] Decryption failed or non-JSON message:', err);
      }
    },
    [selectedChatId, userInfo.UserUID]
  );

  const emitUserJoin = useCallback(() => {
    SocketServices.emit('user_join', { username: userInfo.UserUID, caseid: '' });
  }, [userInfo.UserUID]);

  // If a new chat was started server-side
  const newChatStarted = useCallback(
    (data: any) => {
      const { chat_id } = data;
      if (chat_id) {
        dispatch(fetchCHatList());
        setSelectedChatId(chat_id);
      }
    },
    [dispatch]
  );

  /**
   * On "personal_chat_list" we receive the entire conversation for a ChatID
   * We must map & decrypt each message that’s ciphertext.
   */
  const recieve_personal_chat_list = useCallback(
    async (data: { messages: any[] }) => {
      console.log('[Socket] personal_chat_list => raw messages:', data.messages);
  
      setIsChatLoading(false);  // Stop any loading spinner
      const myPrivateKey = await getPrivateKey(userInfo.UserUID);
      if (!myPrivateKey) {
        console.warn('[E2EE] No private key available, messages will remain encrypted if ephemeral.');
      }
  
      const decryptedMessages = [];
  
      for (const msg of data.messages) {
        let decryptedContent = msg.content;  // default if not ephemeral or fails parse
  
        try {
          // Attempt parse as JSON containing { ephemeralPubKey, iv, ct } ...
          const ciphertextObj = JSON.parse(msg.content);
          if (ciphertextObj?.ephemeralPubKey && ciphertextObj?.iv && ciphertextObj?.ct) {
            if (myPrivateKey) {
              // decrypt
              const plain = await decryptMessage(
                ciphertextObj.ephemeralPubKey,
                myPrivateKey,
                ciphertextObj.iv,
                ciphertextObj.ct
              );
              decryptedContent = plain;
            } else {
              // No private key — keep as-is or mark “Encrypted”
              decryptedContent = '🔒 Encrypted message (no private key on this device).';
            }
          }
        } catch (err) {
          // JSON.parse might fail => means it’s probably old plaintext
          // or non-ephemeral content. So we do nothing
        }
  
        // push final message
        decryptedMessages.push({
          ...msg,
          content: decryptedContent, // override with plaintext if decrypted
        });
      }
  
      console.log('[Socket] personal_chat_list => Decrypted messages:', decryptedMessages);
      setMessages(decryptedMessages);  // store in local state
    },
    [userInfo.UserUID, setIsChatLoading, setMessages]
  );

  // Register socket events
  useEffect(() => {
    emitUserJoin();
    SocketServices.on('recieve_personal_message', recievePersonalMessage);
    SocketServices.on('new_chat_started', newChatStarted);
    SocketServices.on('personal_chat_list', recieve_personal_chat_list);

    return () => {
      SocketServices.removeListener('recieve_personal_message');
      SocketServices.removeListener('new_chat_started');
      SocketServices.removeListener('personal_chat_list');
    };
  }, [recievePersonalMessage, newChatStarted, recieve_personal_chat_list, emitUserJoin]);

  /******************************************
   * 4) SELECT CHAT & HANDLE MESSAGES
   ******************************************/

  const handleChatSelect = async (chatId: string) => {
    setSelectedChatId(chatId);
    setIsChatLoading(true);
    setMessages([]); // clear out old messages
  
    if (chatId) {
      SocketServices.emit('reset_unread_count', {
        ChatID: chatId,
        UserID: userInfo.UserUID
      });
  
      // This call returns the newly decrypted messages
      const decryptedMessages = await dispatch(fetchMessageList(chatId));
  
      if (decryptedMessages && Array.isArray(decryptedMessages)) {
        setMessages(decryptedMessages);
      }
  
      // Optionally also ask the socket for personal_chat_list
      SocketServices.emit('personal_chat_list', {
        ChatID: chatId,
        UserID: userInfo.UserUID
      });
  
      await dispatch(fetchCHatList());
      setIsChatLoading(false);
    }
  };

  // Helper: read file as ArrayBuffer
  const readFileAsArrayBuffer = (file: File): Promise<{
    fileName: string;
    fileType: string;
    fileData: ArrayBuffer | string;
  }> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        if (event.target?.result) {
          resolve({
            fileName: file.name,
            fileType: file.type,
            fileData: event.target.result,
          });
        } else {
          reject(new Error('Failed to read file'));
        }
      };
      fileReader.onerror = () => reject(new Error('Error reading file'));
      fileReader.readAsArrayBuffer(file);
    });
  };

  /********************************************
   * 5) ENCRYPT & SEND
   ********************************************/
  const handleSendMessage = async (plainText: string, attachments: any[]) => {
    try {
      if (!selectedChatId) return;

      // who is the recipient?
      const recipientUserId =
        selectedEmployee?.User?.UserUID ||
        (selectedChat?.SenderID === userInfo.UserUID
          ? selectedChat?.RecieverID
          : selectedChat?.SenderID);

      // retrieve recipient's public key
      const recipientPublicKey = await getRecipientPublicKey(recipientUserId, token);
      if (!recipientPublicKey) {
        console.error('Cannot encrypt - no recipient public key!');
        return;
      }

      // ephemeral keys
      const { ephemeralKeyPair, aesKey } = await deriveEphemeralAESKey(recipientPublicKey);

      // encrypt
      const encryptedPayload = await encryptMessage(aesKey, ephemeralKeyPair.publicKey, plainText);
      const finalCipherText = JSON.stringify({
        ephemeralPubKey: encryptedPayload.ephemeralPubKey,
        iv: encryptedPayload.iv,
        ct: encryptedPayload.ct,
      });

      // send
      SocketServices.emit('send_personal_message', {
        ChatID: selectedChatId,
        SenderID: userInfo.UserUID,
        RecieverID: recipientUserId,
        Message: finalCipherText,
        attachments,
      });

      // update local state
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now(),
          content: plainText,
          timestamp: new Date(),
          sender: userInfo.UserUID,
          attachments,
        },
      ]);
    } catch (err) {
      console.error('[E2EE] handleSendMessage error:', err);
    }
  };

  /******************************************
   * 6) SELECT EMPLOYEE / NEW CHAT
   ******************************************/
  const handleEmplyeeList = () => {
    setOpenListView(true);
  };

  const handleSelectEmployee = (employee: Lawyer) => {
    // see if we have a chat with them
    let existingChat = chatList.find(
      (c: any) => c.userData.Email === employee.Email
    );
    if (existingChat) {
      // open it
      handleChatSelect(existingChat.ChatID);
      setSelectedEmployee(existingChat);
      setOpenListView(false);
    } else {
      // no existing, create new (client side)
      setSelectedChatId(null);
      setSelectedEmployee(employee);
      setOpenListView(false);
      setNewChat(true);
      setMessages([]);
    }
  };

  return (
    <div className="flex h-full">
      {/* Column 1: Chat List */}
      <div className="w-1/3 border-r border-gray-300 dark:border-gray-700 relative">
        {/* <button
          onClick={() => {
            generateAndRegisterLongTermKeys(userInfo.UserUID, token);
            console.log('Manually triggered E2EE key generation');
          }}
          className="m-2 p-2 bg-blue-600 text-white rounded"
        >
          Generate Keys
        </button> */}

        <ChatList
          chatList={chatList}
          selectedChat={selectedChatId}
          onChatSelect={handleChatSelect}
          unreadMessages={unreadCounts}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleSelectEmployee={handleSelectEmployee}
        />
      </div>

      {/* Column 2: Chat Window */}
      <div className="flex-grow">
        {selectedChat && selectedChatId ? (
          <ChatWindow
            chatId={selectedChatId}
            messages={messages}
            selectedChat={selectedChat}
            onSendMessage={handleSendMessage}
            isLoading={isChatLoading}
          />
        ) : newChat ? (
          <ChatWindow
            chatId=""
            messages={messages}
            selectedChat={selectedEmployee}
            onSendMessage={handleSendMessage}
            isLoading={isChatLoading}
          />
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500 dark:text-gray-400">
              {t('Select a chat to start messaging')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageCenter;
