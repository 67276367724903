import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { Case } from '../shared/types';
import Badge from '../../shared/TailwindComponents/Badge';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import RequestAccessComponent from './RequestAccessComponent';
import Tooltip from '@mui/material/Tooltip';
import { PiCalendarCheckLight, PiMoneyLight } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

interface RouteParams {
  caseId: string;
}

const MarketplaceCaseDetails: React.FC = () => {
  const { t } = useTranslation();
  const { caseId } = useParams<RouteParams>();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();

  const caseItem = (location.state as any)?.caseItem as Case;

  useEffect(() => {
    if (!caseItem) {
      history.push('/marketplace');
    }
  }, [caseItem, history]);

  useEffect(() => {
    if (caseItem) {
      setBreadcrumbs([
        { name: t('Marketplace'), href: '/marketplace', current: false },
        { name: caseItem.CaseType || `${t('Case')} ${caseId}`, href: `/marketplace/case/${caseId}`, current: true },
      ]);
    }
    return () => {
      setBreadcrumbs([]);
    };
  }, [caseItem, caseId, setBreadcrumbs, t]);

  if (!caseItem) {
    return null; // Optionally, you can show a spinner here
  }

  const {
    CaseID,
    AnonymizedDescription,
    BudgetRange,
    Deadline,
    Categories,
    Tags,
    CaseType,
    AdditionalNotes,
    DesiredOutcome,
  } = caseItem;

  // Format budget range using Intl for proper currency formatting
  const formattedBudget = BudgetRange
    ? `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(BudgetRange.Min)} – ${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(BudgetRange.Max)}`
    : '';

  // Format deadline as a short, friendly date
  const formattedDeadline = Deadline
    ? new Date(Deadline).toLocaleDateString('en-UK', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : '';

  return (
    <div className="h-full bg-primary-50 dark:bg-primary-900 shadow-md dark:shadow-dark-md rounded mx-4 animate-fade-in-up">
      <div className="mx-auto px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
        <div className="lg:grid grid-cols-1 lg:gap-x-8 xl:gap-x-16">
          {/* Case Details Section */}
          <div className="lg:col-span-4">
            {/* Header */}
            <div className="mb-6">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <h1 className="text-lg font-bold dark:text-white text-gray-900">
                  {CaseType}
                </h1>
                <div className="flex flex-col sm:flex-row sm:space-x-4 mt-4 sm:mt-0">
                  {BudgetRange && (
                      <Badge color='green' tooltip={t('Budget')}>
                      <div className="flex items-center space-x-2">
                        <PiMoneyLight className="text-green-600" size={20} />
                        <span className="text-base text-md text-green-700">
                          {formattedBudget}
                        </span>
                      </div>
                      </Badge>
                  )}
                  {Deadline && (
                      <Badge color='yellow' tooltip={t('Deadline')}>
                      <div className="flex items-center space-x-2">
                        <PiCalendarCheckLight className="text-yellow-600" size={20} />
                        <span className="text-base text-md text-yellow-700">
                          {formattedDeadline}
                        </span>
                      </div>
                      </Badge>
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8 gap-2">
              {/* Case Description */}
              {AnonymizedDescription && (
                <div className="mb-6">
                  <h2 className="text-md font-semibold dark:text-white text-gray-900 mb-2">
                    {t('Case Description')}
                  </h2>
                  <p className="text-sm dark:text-gray-200 text-gray-700 whitespace-normal break-words">
                    {AnonymizedDescription}
                  </p>
                </div>
              )}

              {/* Desired Outcome */}
              {DesiredOutcome && (
                <div className="mb-6">
                  <h2 className="text-md font-semibold dark:text-white text-gray-900 mb-2">
                    {t('Desired Outcome')}
                  </h2>
                  <p className="text-sm dark:text-gray-200 text-gray-700 whitespace-normal break-words">
                    {DesiredOutcome}
                  </p>
                </div>
              )}

              {/* Categories */}
              {Categories && Categories.length > 0 && (
                <div className="mb-6">
                  <h2 className="text-md font-semibold dark:text-white text-gray-900 mb-2">
                    {t('Categories')}
                  </h2>
                  <div className="flex flex-wrap gap-2">
                    {Categories.map((category: any) => (
                      <Badge key={category} color="blue">
                        {category}
                      </Badge>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Additional Notes */}
            {AdditionalNotes && (
              <div className="mb-6">
                <h2 className="text-md font-semibold dark:text-white text-gray-900 mb-2">
                  {t('Additional Notes')}
                </h2>
                <p className="text-sm dark:text-gray-200 text-gray-700">
                  {AdditionalNotes}
                </p>
              </div>
            )}

            {/* Tags */}
            {/* {Tags && Tags.length > 0 && (
              <div className="mb-6">
                <h2 className="text-md font-semibold dark:text-white text-gray-900 mb-2">
                  {t('Tags')}
                </h2>
                <div className="flex flex-wrap gap-2">
                  {Tags.map((tag: any) => (
                    <Badge key={tag} color="indigo">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
            )} */}
          </div>

          {/* Request Access Section */}
          <div className="mt-8 lg:mt-0 lg:col-span-3 self-stretch h-full">
            <RequestAccessComponent caseId={CaseID} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceCaseDetails;
