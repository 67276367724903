import apis from "../../utils/apis";
import { FETCH_ALL_LAWYERS } from "../types";
import { showFeedbackModal } from "./UserFeedbackActions";
import { handleApiError } from "./utils/utils";

interface FetchLawyersParams {
  searchTerm?: string;
  filters?: { [key: string]: string[] };
  sortBy?: string;
  sortOrder?: string;
}

export const fetchAllLawyers = (params: FetchLawyersParams = {}) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const {
        searchTerm = "",
        filters = {},
        sortBy = "",
        sortOrder = "asc",
      } = params;
      const filtersJson = JSON.stringify(filters);

      const resp = await apis({
        method: "GET",
        url: "/users/lawyers/list",
        params: { searchTerm, filters: filtersJson, sortBy, sortOrder },
      });

      if (resp && resp.status === 200) {
        // the endpoint might return { "lawyers": [...] }
        let lawyers: any[] = [];

        if ("lawyers" in resp.data && Array.isArray(resp.data.lawyers)) {
          lawyers = resp.data.lawyers;
        } else {
          lawyers = [];
        }

        dispatch({ type: FETCH_ALL_LAWYERS, payload: lawyers });
        return true;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      handleApiError(err, dispatch)
    }
    return false;
  };
};

/**
 * Remove a lawyer from the user's list
 * calls POST /users/lawyers/remove
 */
export const removeLawyer = (lawyerId: string) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const resp = await apis({
        method: "POST",
        url: "/users/lawyers/remove",
        data: { LawyerID: lawyerId },
      });

      if (resp && resp.status === 200) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Lawyer removed successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        // Optionally re-fetch the updated list
        dispatch(fetchAllLawyers());
        return true;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      handleApiError(err, dispatch)
    }
    return false;
  };
};
