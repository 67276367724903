import React, { useState, useEffect } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { setTutorialCompletedAction } from '../../store/actions/SignInAction';

const UserGuidance: React.FC = () => {
  const dispatch = useAppDispatch();
  const userType = useSelector((state: RootState) => state.user.userInfo?.UserTypeName);
  const tutorialCompleted = useSelector((state: RootState) => state.user.tutorialCompleted);
  
  // 1) Check if we're on beta/demo environment
  const isBetaOrDemo =
    window.location.hostname.includes('beta.pons.io') ||
    window.location.hostname.includes('demo.pons.io') ||
    window.location.hostname.includes('localhost:3000');

  const [isTourActive, setIsTourActive] = useState(false);

  const steps: Step[] = [
    {
      title: 'Help Menu',
      target: '[data-tour-id="helpIcon"]',
      content: 'This menu includes tutorials, FAQs, and support options. Click next or skip.',
      disableOverlayClose: true,
    },
    {
      title: 'Profile & Settings',
      target: '[data-tour-id="profileIcon"]',
      content: 'Manage your personal info, security settings, and organization details here.',
      disableOverlayClose: true,
    },
    {
      title: 'Encrypted Message Center',
      target: '[data-tour-id="messageCenter"]',
      content: 'Chat with others privately. All conversations are end-to-end encrypted!',
    },
    {
      title: 'Main Navigation',
      target: '[data-tour-id="sidebar"]',
      content: 'Access all your modules and tools here. Have a great time exploring!',
    },
  ];

  useEffect(() => {
    // 2) Skip entirely if environment is beta/demo
    if (isBetaOrDemo) {
      return;
    }
    if (!tutorialCompleted && userType) {
      setIsTourActive(true);
    }
  }, [tutorialCompleted, userType, isBetaOrDemo]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status as 'skipped' | 'finished')) {
      setIsTourActive(false);
      dispatch(setTutorialCompletedAction(true));
    }
  };

  if (isBetaOrDemo) {
    return null; 
  }

  return (
    <Joyride
      steps={steps}
      run={isTourActive}
      continuous
      scrollToFirstStep
      showSkipButton
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 10000,
          arrowColor: '#fff',
          backgroundColor: '#fff',
          primaryColor: '#1379F3', 
          textColor: '#2D3748',
        },
        tooltip: {
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
          padding: '16px',
          color: '#2D3748', 
        },
        tooltipTitle: {
          color: '#1379F3', 
          fontSize: '16px',
          marginBottom: '8px'
        },
        tooltipContent: {
          fontSize: '14px'
        },
        tooltipFooter: {
          display: 'flex',
          justifyContent: 'flex-end'
        },
        buttonBack: {
          background: 'none',
          color: '#1379F3',
          border: 'none',
          marginRight: 8,
          cursor: 'pointer'
        },
        buttonNext: {
          backgroundColor: '#1379F3',
          color: '#fff',
          borderRadius: '4px',
          padding: '6px 12px',
          cursor: 'pointer'
        },
        buttonSkip: {
          background: 'none',
          color: '#718096',
          border: 'none',
          marginRight: 8,
          cursor: 'pointer'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        spotlight: {
          borderRadius: '4px',
          boxShadow: '0 0 0 3px rgba(38, 96, 168, 0.5)'
        }
      }}
    />
  );
};

export default UserGuidance;
