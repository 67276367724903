import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchIndexes } from '../../../store/actions/AIStudioAction';
import Button from '../../shared/TailwindComponents/Button';
import { useTranslation } from 'react-i18next';
import { showSidePanel } from '../../../store/actions/sidePanelActions';
import { useHistory } from 'react-router-dom';
import Table from '../../shared/TailwindComponents/Table';
import Badge from '../../shared/TailwindComponents/Badge';

const AIStudioIndexListPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const indexes = useSelector((state: RootState) => state.aistudio.indexes);

  useEffect(() => {
    dispatch(fetchIndexes() as any);
  }, [dispatch]);

  const handleCreateIndexClick = () => {
    dispatch(
      showSidePanel({
        type: 'createIndex',
        showSidePanel: true,
        width: '50%',
      })
    );
  };

  const handleRowClick = (row: any) => {
    // Now row.IndexName will be defined since we stored it below
    history.push(`/ai-studio/${encodeURIComponent(row.IndexName)}`);
    console.log(row);
    console.log(row.IndexName);
  };

  const formatAgentType = (type: string) => {
    if (type === 'CompanyAgent') return t('Company Agent');
    if (type === 'CustomAgent') return t('Custom Agent');
    return type;
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return t('N/A');
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(date);
  };

  const columns = [t('Agent Name'), t('Type'), t('Created At')];

  // Preserve the original IndexName in the object to access in onRowClick
  const tableData = indexes.map((idx: any) => ({
    id: idx.id,
    IndexName: idx.IndexName, // Store original IndexName field
    [t('Agent Name')]: idx.IndexName,
    [t('Type')]: formatAgentType(idx.AgentType),
    [t('Created At')]: formatDate(idx.CreatedAt),
  }));

  return (
    <div className="container-example my-10 space-y-6 p-6 dark:bg-gray-800 dark:text-white rounded-lg shadow mx-4">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-heading mb-2">{t('AI Studio')}</h1>
          <p className="text-sm text-gray-600 dark:text-gray-300">{t('Manage your AI agents. Create new agents, view their details, and integrate them into your workflow.')}</p>
        </div>
        <Button variant="primary" onClick={handleCreateIndexClick}>
          {t('Create New Agent')}
        </Button>
      </div>

      {indexes && indexes.length > 0 ? (
        <Table
          columns={columns}
          data={tableData}
          onRowClick={handleRowClick}
        />
      ) : (
        <div className='flex justify-center items-center'>
        <Badge color='gray'>{t('No agents found. Click "Create New Agent" to get started.')}</Badge>
        </div>
      )}
    </div>
  );
};

export default AIStudioIndexListPage;
