import { AppThunk, SET_CURRENT_FOLDER, CLEAR_CURRENT_FOLDER, RENAME_FOLDER,  CREATE_SUBFOLDER_SUCCESS,  FETCH_FILE_TYPES_SUCCESS, FETCH_STRUCTURE_SUCCESS } from '../types';
import { getUserType } from './ChatAction';
import apis from '../../utils/apis';
import { showFeedbackModal } from './UserFeedbackActions';
import { FileProps, FolderProps } from '../../components/Documents/types';
import { handleApiError } from './utils/utils';

interface FetchStructureSuccessPayload {
  structure: FolderProps[];
  flatFiles: FileProps[];
}

export const setCurrentFolder = (folderId: string) => ({
  type: SET_CURRENT_FOLDER,
  payload: folderId,
});

export const clearCurrentFolder = () => ({
  type: CLEAR_CURRENT_FOLDER,
});

export const setFolderName = (newFolderName: string) => ({
  type: RENAME_FOLDER,
  payload: newFolderName
});

const fetchFileCategorysSuccess = (fileTypes: any[]): { type: string; payload: any[] } => ({
  type: FETCH_FILE_TYPES_SUCCESS,
  payload: fileTypes,
});


const fetchStructureSuccess = (payload: FetchStructureSuccessPayload): { type: string; payload: FetchStructureSuccessPayload } => ({
  type: FETCH_STRUCTURE_SUCCESS,
  payload: payload,
});


const createSubFolderSuccess = (subFolder: any): { type: string; payload: any } => ({
  type: CREATE_SUBFOLDER_SUCCESS,
  payload: subFolder,
});



export const renameFolder = (folderId: string, newFolderName: string): AppThunk<Promise<FolderProps[]>> => async (dispatch) => {
  try {
    const folderResponse = await apis({
      method: "POST",
      url: '/files/rename_folder',
      data: { FolderID: folderId, NewFolderName: newFolderName }
    });

    if (folderResponse.status === 200) {
      let folderName = folderResponse.data;
      dispatch(setFolderName(folderName));
      dispatch(showFeedbackModal({
        showModal: true,
        message: ("Folder renamed"),
        modalType: 'success',
        duration: 3000
      }));
      return Promise.resolve(folderName);
    } else {
      handleApiError(folderResponse, dispatch)
      return Promise.reject(new Error('Failed to rename folder'));
    }
  } catch (error) {
    handleApiError(error, dispatch)
    return Promise.reject(error);
  }
};


export const createFolder = (folderName: string, callback?: () => void): AppThunk => async (dispatch) => {
  try {
    const response = await apis({
      method: 'POST',
      url: "/files/create_folder",
      data: { FolderName: folderName }
    });
    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: response.data.message || 'Folder created',
        modalType: 'success',
        duration: 3000
      }));

      if (callback) callback();
      return true
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error) {
    handleApiError(error, dispatch)
  }
};

export const deleteFolder = (folderId: string, callback: () => void): AppThunk => async (dispatch) => {
  try {
    const response = await apis({
      method: 'POST',
      url: "files/delete_folder",
      data: { FolderID: folderId }
    });

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Folder deleted',
        modalType: 'success',
        duration: 3000
      }));
      if (callback) callback();
      return true
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error) {
    handleApiError(error, dispatch)
  }
};

export const fetchFileCategorys = (): AppThunk => async (dispatch) => {
  try {
    const response = await apis.get('/files/get_file_types');
    if(response && response.status === 200){
      dispatch(fetchFileCategorysSuccess(response.data));
    }else{
      handleApiError(response, dispatch)
    }
  } catch (error: any) {
    handleApiError(error, dispatch)
  }
};

export const fetchStructure = (params: {searchTerm?: string; filters?: {[key:string]: string[]}; sortBy?: string; sortOrder?: string} = {}): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { searchTerm = '', filters = {}, sortBy = '', sortOrder = 'asc' } = params;
    const filtersJson = JSON.stringify(filters);
    const response = await apis.get('/files/get_file_structure', {
      params: { searchTerm, filters: filtersJson, sortBy, sortOrder }
    });

    if (response.status === 200) {
      // Check if we got files or data
      if ('files' in response.data) {
        // Filtered mode: flatFiles returned
        const flatFiles = response.data.files || [];
        dispatch(fetchStructureSuccess({ structure: [], flatFiles }));
      } else if ('data' in response.data) {
        // Unfiltered mode: full structure returned
        const structureData = response.data.data || [];
        dispatch(fetchStructureSuccess({ structure: structureData, flatFiles: [] }));
      } else {
        dispatch(fetchStructureSuccess({ structure: [], flatFiles: [] }));
      }
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error: any) {
    handleApiError(error, dispatch)
  }
};

export const createSubFolder = (
  folderName: string,
  parentFolderId: string
): AppThunk<Promise<any>> => async (dispatch) => {
  const userType = getUserType();
  try {
    const response = await apis({
      method: 'POST',
      url: '/files/create_subfolder',
      data: { UserType: userType, FolderName: folderName, ParentFolderID: parentFolderId },
    });

    if (response.status === 200) {
      const subFolder = response.data;
      dispatch(createSubFolderSuccess(subFolder));
      return Promise.resolve(subFolder);
    } else {
      const error = 'Failed to create sub folder';
      handleApiError(response, dispatch)
      return Promise.reject(new Error(error));
    }
  } catch (error: any) {
    handleApiError(error, dispatch)
    return Promise.reject(error);
  }
};



export const deleteFile = (FileID: string, callback: () => void): AppThunk => async (dispatch) => {
  try {
    const response = await apis({
      method: 'POST',
      url: "/files/delete_file",
      data: { FileID}
    });

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Folder deleted',
        modalType: 'success',
        duration: 3000
      }));
      if (callback) callback();
      return true
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error) {
    handleApiError(error, dispatch)
  }
};
