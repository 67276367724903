import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { fetchAllUserTickets } from '../../store/actions/HelpAction';
import { useHistory } from 'react-router-dom';
import Badge from '../shared/TailwindComponents/Badge';
import { format } from 'date-fns';
import Button from '../shared/TailwindComponents/Button';
import { showSupportTicketFormSidePanel } from '../../store/actions/sidePanelActions';
import Header from '../shared/TailwindComponents/Header';
import ApiLoader from '../ApiLoader';

export interface TicketItem {
  Category: string;
  ClientEmail: string;
  CreatedAt: string;
  Description: string;
  Resolved: boolean;
  Status: string;
  Subject: string;
  TicketID: string;
  UpdatedAt: string;
  UserID: string;
  UserType: string;
}

const SupportTicketSystem: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userTickets = useSelector((state: RootState) => state.help.allUserTickets);
  const [isTicketsLoading, setIsTicketsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsTicketsLoading(true);
    dispatch(fetchAllUserTickets()).finally(() => setIsTicketsLoading(false));
  }, [dispatch]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Open':
        return 'green';
      case 'Resolved':
        return 'blue';
      default:
        return 'gray';
    }
  };

  const handleTicketClick = (ticketId: string) => {
    history.push(`/support/${ticketId}`);
  };

  const handleCreateTicket = () => {
    dispatch(showSupportTicketFormSidePanel());
  };

  return (
    <div className="p-4">
      <Header 
      title={t('Support Tickets')}
      actionLabel={t('Create New Ticket')} 
      onActionClick={handleCreateTicket}
      subtitle={t('Leave us a support ticket and we will get back to you within 48 hours.')}
      />
      {/* <div className="flex justify-between items-center mb-4">

        <h2 className="text-2xl font-semibold">{t('Support Tickets')}</h2>
        <Button variant="primary" onClick={handleCreateTicket}>
          {t('Create New Ticket')}
        </Button>
      </div> */}
      {isTicketsLoading ? (
        <ApiLoader />
      ) : userTickets && userTickets.length > 0 ? (
        <div className="overflow-x-auto mt-3">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-100 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  {t('Subject')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  {t('Category')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  {t('Status')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  {t('Resolved')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">
                  {t('Created At')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {userTickets.map((ticket: TicketItem) => (
                <tr
                  key={ticket.TicketID}
                  className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                  onClick={() => handleTicketClick(ticket.TicketID)}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
                    {ticket.Subject}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {ticket.Category}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <Badge color={getStatusColor(ticket.Status)}>
                      {t(ticket.Status)}
                    </Badge>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {ticket.Resolved ? (
                      <Badge color="green">
                        <i className="fas fa-check-circle mr-1"></i> {t('Yes')}
                      </Badge>
                    ) : (
                      <Badge color="red">
                        <i className="fas fa-times-circle mr-1"></i> {t('No')}
                      </Badge>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                    {format(new Date(ticket.CreatedAt), 'yyyy-MM-dd HH:mm')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='w-full justify-center items-center flex h-full mt-20'>
        <Badge color='gray'>{t('No tickets')}</Badge>
        </div>
      )}
    </div>
  );
};

export default SupportTicketSystem;
