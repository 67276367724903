import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import { BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';
import Badge from '../shared/TailwindComponents/Badge';
import Table from '../shared/TailwindComponents/Table';

import { Squares2X2Icon, TableCellsIcon } from '@heroicons/react/24/solid';
import Button from '../shared/TailwindComponents/Button';
import { fetchAllLawyers } from '../../store/actions/LawyerAction';
import ApiLoader from '../ApiLoader';

const Lawyers: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  // From Redux
  // We'll store an array of lawyers under "lawyer.allLawyers" in the store
  const allLawyers = useSelector((state: RootState) => state.lawyer.allLawyers || []);

  // Local state
  const [isLawyersLoading, setIsLawyersLoading] = useState(false);
  const [isAddingLawyer, setIsAddingLawyer] = useState(false);

  // Searching / filtering / sorting
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('name_asc');

  // Toggle between grid and table
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');

  // Scroll reference
  const lawyerListRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = () => {
    const scrollPosition = lawyerListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    if (lawyerListRef.current) {
      lawyerListRef.current.addEventListener('scroll', handleScroll);
      return () => {
        lawyerListRef.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // Main effect to fetch the lawyers whenever search/filters/sort changes
  useEffect(() => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (sortValue === 'name_asc') {
      sortBy = 'name';
      sortOrder = 'asc';
    } else if (sortValue === 'name_desc') {
      sortBy = 'name';
      sortOrder = 'desc';
    } else if (sortValue === 'created_at_desc') {
      sortBy = 'created_at';
      sortOrder = 'desc';
    }
    // add more if needed

    setIsLawyersLoading(true);
    dispatch(
      fetchAllLawyers({
        searchTerm: searchQuery,
        filters: selectedFilters,
        sortBy,
        sortOrder,
      })
    ).finally(() => {
      setIsLawyersLoading(false);
    });
  }, [searchQuery, selectedFilters, sortValue, dispatch]);

  // Switch grid/table
  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'grid' ? 'table' : 'grid'));
  };

  // If you had an "AddLawyer" flow for the user to add them, you can replicate
  // the logic from "AddClientForm". But let's skip that or just a placeholder:
  const handleAddLawyerClick = () => {
    setIsAddingLawyer(true);
  };
  const handleAddLawyerClose = () => {
    setIsAddingLawyer(false);
  };

  // For clicking on a lawyer -> go to /lawyers/:LawyerID
  const handleLawyerClick = (lawyer: any) => {
    history.push(`/my-lawyers/${lawyer.LawyerID}`);
  };

  // FilterSearchBar
  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };
  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };
  const handleSortChange = (value: string) => {
    setSortValue(value);
  };

  // Example filters
  const filters = [
    {
      id: 'LawyerType',
      name: 'Lawyer Type',
      options: [
        { value: 'IndependentLawyer', label: 'Independent Lawyer' },
        { value: 'LawFirmAdmin', label: 'Law Firm Admin' },
        { value: 'LawFirmEmployee', label: 'Law Firm Employee' },
      ],
    },
  ];

  // Sort Options
  const [sortOptions] = useState([
    { value: 'name_asc', label: t('Name Ascending'), current: true },
    { value: 'name_desc', label: t('Name Descending'), current: false },
    { value: 'created_at_desc', label: t('Newest First'), current: false },
  ]);

  // Sticky header logic
  const shrinkThreshold = 150;
  const isShrunk = scrollTop >= shrinkThreshold;

  // Table columns for lawyers
  const columns = ['Name', 'Email', 'Lawyer Type'];

  // Format the data for the table
  const tableData = useMemo(() => {
    return allLawyers.map((lawyer: any) => {
      const lwType = lawyer.UserTypeName;
      const lwTypeLabel =
        lwType === 'IndependentLawyer'
          ? 'Independent Lawyer'
          : lwType === 'LawFirmAdmin'
          ? 'Law Firm Admin'
          : lwType === 'LawFirmEmployee'
          ? 'Law Firm Employee'
          : 'Unknown';
      const fullName = `${lawyer.FirstName} ${lawyer.LastName}`.trim() || 'No name';
      return {
        id: lawyer.LawyerID,
        Name: fullName,
        Email: lawyer.Email || 'No email',
        'Lawyer Type': lwTypeLabel,
      };
    });
  }, [allLawyers]);

  return (
    <div className="bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      <div
        className={`${
          isShrunk && !isAddingLawyer ? 'sticky top-[80px] z-20' : ''
        } transition-all duration-200 bg-background-light dark:bg-background-dark`}
      >
        <Header
          title={isAddingLawyer ? t('Add New Lawyer') : t('Lawyers')}
          subtitle={
            isAddingLawyer
              ? t('Fill in the details below to add a new lawyer.')
              : t('Manage your lawyers and track engagements.')
          }
          actionLabel={isAddingLawyer ? t('Cancel') : t('Add Lawyer')}
          onActionClick={isAddingLawyer ? handleAddLawyerClose : handleAddLawyerClick}
        />
        {!isAddingLawyer && (
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
            sortValue={sortValue}
            extraControls={
              <Button
                variant="tertiary"
                onClick={toggleViewMode}
                tooltip={
                  viewMode === 'grid'
                    ? t('Switch to Table View')
                    : t('Switch to Grid View')
                }
              >
                {viewMode === 'grid' ? (
                  <TableCellsIcon className="h-5 w-5" />
                ) : (
                  <Squares2X2Icon className="h-5 w-5" />
                )}
              </Button>
            }
          />
        )}
      </div>

      <div className="flex-grow overflow-y-auto h-full" ref={lawyerListRef}>
        {isAddingLawyer ? (
          <div className="w-2/3 mx-auto">
            {/* If you had an AddLawyerForm, you'd place it here */}
            <div className="p-4 text-center text-gray-500 dark:text-gray-300">
              {t('AddLawyerForm Not Implemented')}
            </div>
          </div>
        ) : isLawyersLoading ? (
                <ApiLoader />
        ) : (
          <>
            {viewMode === 'grid' ? (
              // ------------- GRID -------------
              <div className="p-4 grid grid-cols-2 gap-2">
                {allLawyers.length > 0 ? (
                  allLawyers.map((lawyer: any) => {
                    // Prepare badges
                    const badges: BadgeItem[] = [];
                    const lwType = lawyer.UserTypeName;
                    badges.push({
                      label:
                        lwType === 'IndependentLawyer'
                          ? 'Independent Lawyer'
                          : lwType === 'LawFirmAdmin'
                          ? 'Law Firm Admin'
                          : lwType === 'LawFirmEmployee'
                          ? 'Law Firm Employee'
                          : 'Unknown Type',
                      color: lwType === 'IndependentLawyer' ? 'blue' : 'gray',
                    });

                    const details = [
                      { label: t('Email'), value: lawyer.Email || t('No email') },
                    ];
                    const fullName =
                      `${lawyer.FirstName} ${lawyer.LastName}`.trim() || 'No name';

                    return (
                      <ListItem
                        key={lawyer.LawyerID}
                        title={fullName}
                        subtitle={lawyer.OrganizationName || ''}
                        details={details}
                        badges={badges}
                        onClick={() => handleLawyerClick(lawyer)}
                        link={`/my-lawyers/${lawyer.LawyerID}`}
                      />
                    );
                  })
                ) : (
                  <div className="h-[calc(100vh-200px)] flex align-center justify-center">
                    <Badge color="gray" className="self-center">
                      {t('No lawyers available')}
                    </Badge>
                  </div>
                )}
              </div>
            ) : (
              // ------------- TABLE -------------
              <div className="p-4">
                {allLawyers.length > 0 ? (
                  <Table
                    columns={columns}
                    data={tableData}
                    onRowClick={(row) => {
                      history.push(`/my-lawyers/${row.id}`);
                    }}
                  />
                ) : (
                  <div className="flex justify-center items-center h-[calc(100vh-200px)]">
                    <Badge color="gray">{t('No lawyers available')}</Badge>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Lawyers;
