import React from 'react';
import { useMicrosoftGraph } from '../contexts/MicrosoftGraphContext';
import clsx from 'clsx';
import { CgMicrosoft } from "react-icons/cg";

const baseBtnClasses = "inline-flex justify-between items-center px-3 py-2 font-medium rounded-md transition text-sm";
const oneDriveBtnClasses = "bg-blue-200 hover:bg-blue-300 text-blue-800";

interface OneDrivePickerButtonProps {
  onFileSelected: (file: any) => void;
}

const OneDrivePickerButton: React.FC<OneDrivePickerButtonProps> = ({ onFileSelected }) => {
  const { handleOpenMicrosoftPicker } = useMicrosoftGraph();

  return (
    <button
      type='button'
      onClick={() => handleOpenMicrosoftPicker(onFileSelected)}
      className={clsx(baseBtnClasses, oneDriveBtnClasses)}
    >
      {('Microsoft OneDrive')}
      {/* <i className="fab fa-microsoft"></i> */}
      <CgMicrosoft size={20} />
    </button>
  );
};

export default OneDrivePickerButton;
