import apis from "../../utils/apis";
import { FETCH_DOCUMENT_PREVIEW_REQUEST, FETCH_DOCUMENT_PREVIEW_SUCCESS, RESET_DOCUMENT_STATE,SIGN_DOCUMENT_REQUEST, SIGN_DOCUMENT_SUCCESS } from "../types";
import { handleApiError } from "./utils/utils";


export const fetchDocumentPreview = (fileId: string ) => async (dispatch: any) => {
  dispatch({ type: FETCH_DOCUMENT_PREVIEW_REQUEST });
  try {
    const response = await apis({
        method: "GET",
        url: `/files/${fileId}/preview`,
    })
    if(response && response.status === 200){

      dispatch({ type: FETCH_DOCUMENT_PREVIEW_SUCCESS, payload: response.data });
    }else{
      handleApiError(response, dispatch)
    }
  } catch (error:any) {
    handleApiError(error, dispatch)
  }
};

export const signDocument = (fileId: string, callback?: () => void) => async (dispatch: any) => {
  dispatch({ type: SIGN_DOCUMENT_REQUEST });
  try {
    const response : any = await apis({
        method: "POST",
        url: `/files/${fileId}/sign`,
    })
    if(response && response.status === 200){
      dispatch({ type: SIGN_DOCUMENT_SUCCESS, payload: response.data });
      if (callback) callback();

    }else{
      handleApiError(response, dispatch)
    }
  } catch (error: any) {
    handleApiError(error, dispatch)
  }
};

export const resetDocumentState = () => ({
  type: RESET_DOCUMENT_STATE,
});