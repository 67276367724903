import { Dispatch } from 'redux';
import { showFeedbackModal } from '../UserFeedbackActions';
import apis from '../../../utils/apis';
import { handleApiError } from '../utils/utils';
import { AppThunk } from '../../types';
import { fetchCaseSummary } from '../marketplace2/caseActions';

export const generateMemo = (caseId: string) => async (dispatch: Dispatch<any>) => {

  try {
    const response = await apis({
      method: 'POST',
      url: '/ai/generate_memo',
      data: { CaseID: caseId },
    });

    if (response.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Memo generated successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      await dispatch(getCaseMemo(caseId))
      dispatch(fetchCaseSummary(caseId))
      return response.data.memo;
    } else {
      throw response;
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export interface MemoData {
  memo: string;
}

export const getCaseMemo = (caseId: string): AppThunk<Promise<MemoData | null>> => {
  return async dispatch => {
    try {
      const response = await apis.post('/cases/get_memo', { CaseID: caseId });
      if (response.status === 200) {
        const dataObj = { memo: response.data.memo || '' };

        dispatch({
          type: 'UPDATE_CASE_MEMO',
          payload: { caseId, memo: dataObj.memo },
        });

        return dataObj; // returning
      }else{
        handleApiError(response, dispatch);
        return null;
      }
    } catch (err) {
      handleApiError(err, dispatch);
      return null;
    }
  };
};