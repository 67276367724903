import React from 'react';
import Badge from '../../shared/TailwindComponents/Badge';
import { useHistory } from 'react-router-dom';
import { Lawyer } from '../../Marketplace/shared/types';
import { FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface LawyerCardProps {
  lawyer: Lawyer;
  onClick?: () => void;
}

const LawyerCard: React.FC<LawyerCardProps> = ({ lawyer, onClick }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleViewProfile = () => {
    if (onClick) {
      onClick();
    } else {
      history.push(`/marketplace/lawyer/${lawyer.UserID}`, { lawyer });
    }
  };

  const {
    FirstName,
    LastName,
    ProfilePicture,
    RatingsAverage,
    RatingsCount,
    ExperienceYears,
    Specializations,
    CompanyRole,
  } = lawyer;

  const fullName = `${FirstName} ${LastName}`;

  return (
    <div
      onClick={handleViewProfile}
      className="flex bg-gradient-to-br from-white to-gray-100 dark:from-gray-800 dark:to-gray-700 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg p-6 duration-300 hover:shadow-2xl transition animate-fade-in-up cursor-pointer"
    >
      {/* Profile Picture in Top Left */}
      <div className="flex-shrink-0">
        <div className="w-20 h-20 rounded-full overflow-hidden border-1 border-gray-300">
          {ProfilePicture ? (
            <img
              src={ProfilePicture}
              alt={fullName}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-primary-100 text-3xl text-primary-500">
              {FirstName[0]}
              {LastName[0]}
            </div>
          )}
        </div>
      </div>

      {/* Lawyer Details */}
      <div className="ml-6 flex-grow">
        <h3 className="text-xl font-bold text-gray-900 dark:text-white">
          {fullName}
        </h3>

        {/* Ratings and Experience */}
        <div className="mt-1 flex flex-col space-y-2">
          <div className="flex items-center">
            <FaStar className="text-yellow-400" />
            <span className="ml-1 text-sm text-gray-600 dark:text-gray-300">
              {RatingsAverage ? RatingsAverage.toFixed(1) : t('N/A')}
            </span>
            <span className="ml-1 text-xs text-gray-500 dark:text-gray-400">
              ({RatingsCount || 0} {t('reviews')})
            </span>
          </div>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {ExperienceYears ? `${ExperienceYears} ${t('years of experience')}` : t('Experience not specified')}
          </p>
        </div>

        {/* Affiliation Badge */}
        <div className="mt-3">
          {CompanyRole ? (
            <Badge color="green">{t('Law Firm Affiliated')}</Badge>
          ) : (
            <Badge color="blue">{t('Independent Lawyer')}</Badge>
          )}
        </div>

        {/* Specializations */}
        <div className="mt-3">
          {Specializations && Specializations.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {Specializations.map((spec, index) => (
                <Badge key={index} color="blue" className="text-xs">
                  {spec}
                </Badge>
              ))}
            </div>
          ) : (
            <p className="text-xs text-gray-500 dark:text-gray-400">{t('No specializations specified')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LawyerCard;
