// CaseCycle.tsx
import React from 'react';
import { TbProgressBolt } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

export interface CycleStep {
  action: string;
  details: string;
  performed_by: string;
  timestamp: string;
}

interface CaseCycleProps {
  caseCycleHistory: CycleStep[];
  cycleProgress: number;
}

const CaseCycle: React.FC<CaseCycleProps> = ({ caseCycleHistory, cycleProgress }) => {
  const { t } = useTranslation();

  // Formats the timestamp nicely.
  const formatTimestamp = (timestamp: string) => {
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(timestamp));
  };

  return (
    <div className="animate-fadeIn">
      {/* Progress Bar Section */}
      <div className="my-6">
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
          {t('Case Cycle Progress')}
        </h2>
        <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2 mt-2">
          <div
            className="bg-primary-500 h-2 rounded-full transition-all duration-500"
            style={{ width: `${cycleProgress}%` }}
          />
        </div>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          {t('Progress')}: {cycleProgress}%
        </p>
      </div>

      {/* Timeline Section */}
      <div className="relative border-l-2 border-gray-200 dark:border-gray-700 ml-4">
        {caseCycleHistory.length === 0 ? (
          <div className="p-4 text-center text-gray-500 dark:text-gray-400">
            {t('No cycle history available.')}
          </div>
        ) : (
          caseCycleHistory.map((step, index) => (
            <div key={index} className="mb-8 ml-4 animate-slideIn pl-4">
              {/* Icon on the timeline */}
              <div className="absolute -left-3.5 mt-1.5 flex h-7 w-7 items-center justify-center rounded-full bg-primary-500 text-white">
                <TbProgressBolt className="h-4 w-4" />
              </div>
              {/* Header: action and timestamp */}
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <h3 className="text-lg font-medium text-gray-800 dark:text-gray-100">
                  {t(step.action)}
                </h3>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {formatTimestamp(step.timestamp)}
                </span>
              </div>
              {/* Step details */}
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                {t(step.details)}
              </p>
              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                {t('Performed by')}: {step.performed_by}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CaseCycle;
