// actions/caseAnalysisActions.ts

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..'; // Adjust the path as needed
import { getUserType } from '../../../utils/authUtils';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';
import { fetchCaseSummary } from './caseActions';
import { handleApiError } from '../utils/utils';
import { ProgressStep } from '../../reducer/marketplace2/caseAnalysisReducer';

// Action Types
export const RUN_ANALYSIS_REQUEST = 'RUN_ANALYSIS_REQUEST';
export const RUN_ANALYSIS_SUCCESS = 'RUN_ANALYSIS_SUCCESS';
export const RUN_ANALYSIS_FAILURE = 'RUN_ANALYSIS_FAILURE';
export const FETCH_ANALYSIS_SUCCESS = 'FETCH_ANALYSIS_SUCCESS';
export const FETCH_ANALYSIS_FAILURE = 'FETCH_ANALYSIS_FAILURE';
export const SET_ANALYSIS_PROGRESS = 'SET_ANALYSIS_PROGRESS';
export const FETCH_PROGRESS_REQUEST = 'FETCH_PROGRESS_REQUEST';
export const FETCH_PROGRESS_SUCCESS = 'FETCH_PROGRESS_SUCCESS';
export const FETCH_PROGRESS_FAILURE = 'FETCH_PROGRESS_FAILURE';


// Action Creators
export const runAnalysisRequest = (): AnyAction => ({
  type: RUN_ANALYSIS_REQUEST,
});

export const runAnalysisSuccess = (analysisData: any): AnyAction => ({
  type: RUN_ANALYSIS_SUCCESS,
  payload: analysisData,
});

export const runAnalysisFailure = (error: string): AnyAction => ({
  type: RUN_ANALYSIS_FAILURE,
  payload: error,
});

export const fetchAnalysisSuccess = (analysisData: any): AnyAction => ({
  type: FETCH_ANALYSIS_SUCCESS,
  payload: analysisData,
});

export const fetchAnalysisFailure = (error: string): AnyAction => ({
  type: FETCH_ANALYSIS_FAILURE,
  payload: error,
});

export const setAnalysisProgress = (progress: any): AnyAction => ({
  type: SET_ANALYSIS_PROGRESS,
  payload: progress,
});

export const fetchProgressRequest = (): AnyAction => ({
  type: FETCH_PROGRESS_REQUEST,
});

export const fetchProgressSuccess = (data: any): AnyAction => ({
  type: FETCH_PROGRESS_SUCCESS,
  payload: data,
});

export const fetchProgressFailure = (error: string): AnyAction => ({
  type: FETCH_PROGRESS_FAILURE,
  payload: error,
});

// Thunk Action to Run Analysis
export const runCaseAnalysis = (caseId: string, userIndexes?: Record<string, string>) => {
  return (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(runAnalysisRequest());

    const userType = getUserType();
    let endpoint = determineEndpoint(userType);

    const data: any = { CaseID: caseId };
    if (userIndexes && Object.keys(userIndexes).length > 0) {
      data.UserIndexes = userIndexes;
    }

    return apis({
      method: 'POST',
      url: endpoint,
      data
    })
      .then(() => {
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: 'Analysis completed',
            duration: 3000,
          })
        );
        dispatch(fetchCaseSummary(caseId))
      })
      .catch((error: any) => {
        dispatch(runAnalysisFailure(error.toString()));
        handleApiError(error, dispatch);
      });
  };
};


const determineEndpoint = (userType: string) => {
  switch (userType) {
    case 'IndividualClient':
      return '/ai/run_full_case_analysis_client';
    case 'LawFirmAdmin':
    case 'LawFirmEmployee':
    case 'IndependentLawyer':
      return '/ai/run_full_case_analysis_lawyer';
    case 'BusinessAdmin':
    case 'BusinessEmployee':
      return '/ai/run_full_case_analysis_business';
    default:
      return '/ai/run_full_case_analysis_client';
  }
};

export const fetchAnalysisProgress = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(fetchProgressRequest());
    try {
      const response = await apis({
        method: "POST",
        url: "/cases/get_progress",
        data: { CaseID: caseId },
      });
      // Extract the progress arrays.
      const clientProgress: ProgressStep[] = response.data.clientProgress || [];
      const lawyerProgress: ProgressStep[] = response.data.lawyerProgress || [];
      
      // Unify them.
      const unifiedProgress = unifyProgressSteps(clientProgress, lawyerProgress);
      const analysis_results = response.data; // additional analysis data if any
      
      // Dispatch the progress update immediately.
      dispatch(fetchProgressSuccess({ progress: unifiedProgress, analysis_results }));
      
      // Only mark as complete if there is at least one step and every step is "Completed"
      const isCompleted =
        unifiedProgress.length > 0 && unifiedProgress.every((step) => step.status === "Completed");
      
      if (isCompleted) {
        // Analysis is complete; dispatch success and polling should stop.
        dispatch(runAnalysisSuccess(analysis_results));
      }
    } catch (error: any) {
      dispatch(fetchProgressFailure(error.toString()));
      dispatch(runAnalysisFailure(error.toString()));
      handleApiError(error, dispatch);
    }
  };
};

export const CLEAR_ANALYSIS_DATA = 'CLEAR_ANALYSIS_DATA';

export const clearAnalysisData = (): AnyAction => ({
  type: CLEAR_ANALYSIS_DATA,
});

export const fetchCaseAnalysisData = (caseId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis.post('/cases/get_analysis', { CaseID: caseId });
      if (response && response.status === 200) {
        const analysis = response.data.analysis; 
        dispatch(runAnalysisSuccess(analysis));
      }else{
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      handleApiError(error, dispatch);
      dispatch(runAnalysisFailure(error.toString()));
    }
  };
};


/**
 * Unify the progress arrays from the client and lawyer.
 * For steps that exist on both sides, we mark overall status as:
 * - "Completed" if both are Completed
 * - "Pending" if one side is not complete (or if the lawyer’s side is missing when the case is marketplace)
 * - Otherwise, use the value from whichever exists.
 */
export function unifyProgressSteps(
  clientProgress: ProgressStep[] = [],
  lawyerProgress: ProgressStep[] = []
): ProgressStep[] {
  const progressMap: { [step: string]: { client?: ProgressStep; lawyer?: ProgressStep } } = {};

  // Build a map from the client progress.
  clientProgress.forEach((step) => {
    progressMap[step.step] = { client: step };
  });
  // Build (or merge) the lawyer progress.
  lawyerProgress.forEach((step) => {
    if (progressMap[step.step]) {
      progressMap[step.step].lawyer = step;
    } else {
      progressMap[step.step] = { lawyer: step };
    }
  });

  // Now produce a unified list.
  const unified: ProgressStep[] = [];
  Object.keys(progressMap).forEach((stepKey) => {
    const { client, lawyer } = progressMap[stepKey];
    let status = "Not Started";
    let messages: string[] = [];

    if (client && lawyer) {
      // If both exist, set Completed only if both are completed.
      if (client.status === "Completed" && lawyer.status === "Completed") {
        status = "Completed";
      } else {
        status = "Pending";
      }
      messages = [...(client.messages || []), ...(lawyer.messages || [])];
    } else if (client) {
      status = client.status;
      messages = client.messages || [];
    } else if (lawyer) {
      status = lawyer.status;
      messages = lawyer.messages || [];
    }

    unified.push({
      step: stepKey,
      status,
      messages,
    });
  });

  // Optionally, sort steps in a predefined order.
  const order = [
    "Initial Overview",
    "applicable_laws",
    "relevant_cases",
    "interpretation", // exists only for client analysis
    "legal_analysis",  // exists only for lawyer analysis
    "risk_assessment",
    "legal_strategy",  // lawyer
    "recommended_strategy", // client
  ];
  unified.sort((a, b) => {
    const idxA = order.indexOf(a.step);
    const idxB = order.indexOf(b.step);
    return idxA - idxB;
  });

  return unified;
}
