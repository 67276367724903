import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tabs from '../shared/TailwindComponents/Tabs';
import Badge from '../shared/TailwindComponents/Badge';
import ListItem from '../shared/TailwindComponents/ListItem';
import Button from '../shared/TailwindComponents/Button';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import apis from '../../utils/apis';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { useAppDispatch } from '../../store';
import { showModal, hideModal } from '../../store/actions/modalActions';
import { fetchCaseById } from '../../store/actions/marketplace2/caseActions';
import { removeLawyer } from '../../store/actions/LawyerAction';
import ApiLoader from '../ApiLoader';

interface RouteParams {
  lawyerId: string;
}

const LawyerDetails: React.FC = () => {
  const { lawyerId } = useParams<RouteParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { setBreadcrumbs } = useBreadcrumbs();

  const [lawyer, setLawyer] = useState<any | null>(null);
  const [cases, setCases] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('lawyer-info');

  // 1) Fetch the lawyer details from backend
  const fetchLawyerDetails = useCallback(async () => {
    if (!lawyerId) return;
    setIsLoading(true);
    try {
      const resp = await apis({
        method: 'POST',
        url: '/users/lawyers/get_details',
        data: { LawyerID: lawyerId },
      });
      if (resp && resp.status === 200) {
        if (resp.data.lawyer) {
          setLawyer(resp.data.lawyer);
        }
        if (Array.isArray(resp.data.cases)) {
          setCases(resp.data.cases);
        }
      } else {
        console.error('Failed to fetch lawyer details', resp.data);
      }
    } catch (err) {
      console.error('Error fetching lawyer details', err);
    } finally {
      setIsLoading(false);
    }
  }, [lawyerId]);

  // 2) On mount or if lawyerId changes
  useEffect(() => {
    fetchLawyerDetails();
  }, [fetchLawyerDetails]);

  // 3) Update breadcrumbs
  useEffect(() => {
    if (lawyer) {
      const fullName = `${lawyer.FirstName || ''} ${lawyer.LastName || ''}`.trim() || 'Unknown Lawyer';
      setBreadcrumbs([
        { name: t('My Lawyers'), href: '/my-lawyers', current: false },
        { name: fullName, href: `/my-lawyers/${lawyerId}`, current: true },
      ]);
    }
    return () => {
      setBreadcrumbs([]);
    };
  }, [lawyer, lawyerId, setBreadcrumbs, t]);

  // 4) Deletion / Removal from list
  const handleRemoveLawyer = () => {
    if (!lawyerId) return;
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to remove this lawyer from your list?'),
        onConfirm: async () => {
          dispatch(hideModal());
          const success = await dispatch(removeLawyer(lawyerId));
          if (success) {
            history.push('/my-lawyers');
          }
        },
        onCancel: () => dispatch(hideModal()),
      })
    );
  };

  // 5) If we want to open a new case with this lawyer, or do something else
  const handleEngageLawyer = () => {
    // Placeholder for your logic
    console.log('Engage lawyer with ID=', lawyerId);
  };

  // 6) Clicking a case
  const handleCaseClick = (caseDoc: any) => {
    dispatch(fetchCaseById(caseDoc.CaseID));
    history.push(`/cases/${caseDoc.CaseID}`);
  };

  if (isLoading || !lawyer) {
    return (
        <ApiLoader />
    );
  }

  // Build some quick fields
  const lwType = lawyer.UserTypeName;
  const lwTypeLabel =
    lwType === 'IndependentLawyer'
      ? 'Independent Lawyer'
      : lwType === 'LawFirmAdmin'
      ? 'Law Firm Admin'
      : lwType === 'LawFirmEmployee'
      ? 'Law Firm Employee'
      : 'Unknown';

  const tabs = [
    { label: t('Lawyer Information'), value: 'lawyer-info' },
    { label: t('Cases'), value: 'cases' },
    { label: t('Financial'), value: 'financial-data', rightAligned: true },
  ];

  return (
    <div className="min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 p-6">
      {/* Header */}
      <div className="mb-4 flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold">
            {`${lawyer.FirstName || ''} ${lawyer.LastName || ''}`.trim() || 'Unknown Lawyer'}
          </h1>
          <div className="mt-1 flex gap-2">
            <Badge color={lwType === 'IndependentLawyer' ? 'blue' : 'gray'}>{lwTypeLabel}</Badge>
            {lawyer.HourlyRate && (
              <Badge color="green">{`Hourly Rate: $${lawyer.HourlyRate}`}</Badge>
            )}
          </div>
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button
              className="inline-flex justify-center px-2 py-2 text-sm font-medium text-gray-700 bg-white rounded-md 
                         hover:bg-gray-100 focus:outline-none dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700"
            >
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition as={Fragment}>
            <Menu.Items
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white 
                         ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 z-50"
            >
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleEngageLawyer}
                      className={`${
                        active ? 'bg-gray-100 dark:bg-gray-600' : ''
                      } group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 w-full text-left`}
                    >
                      {t('Engage Lawyer')}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleRemoveLawyer}
                      className={`
                        ${active ? 'bg-danger-50 dark:bg-danger-700' : ''}
                        group flex items-center px-4 py-2 text-sm text-red-700 dark:text-red-400 w-full text-left
                      `}
                    >
                      <TrashIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      {t('Remove Lawyer')}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />

      {activeTab === 'lawyer-info' && (
        <section className="mb-6 bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">{t('Lawyer Information')}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <p className="text-sm font-medium text-gray-500">{t('Name')}</p>
              <p className="text-sm">
                {`${lawyer.FirstName || ''} ${lawyer.LastName || ''}`.trim() || 'Unknown'}
              </p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">{t('Email')}</p>
              <p className="text-sm">{lawyer.Email || 'No email'}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">{t('Organization')}</p>
              <p className="text-sm">{lawyer.OrganizationName || 'N/A'}</p>
            </div>
            {lawyer.HourlyRate && (
              <div>
                <p className="text-sm font-medium text-gray-500">{t('Hourly Rate')}</p>
                <p className="text-sm">${lawyer.HourlyRate}</p>
              </div>
            )}
          </div>
        </section>
      )}

      {activeTab === 'cases' && (
        <section className="mb-6">
          <h3 className="text-xl font-semibold mb-4">{t('Shared Cases')}</h3>
          {cases && cases.length > 0 ? (
            <div className="grid grid-cols-2 gap-2 p-2 overflow-y-auto h-[calc(100vh-470px)]">
              {cases.map((caseDoc: any) => (
                <ListItem
                  key={caseDoc.CaseID}
                  title={caseDoc.CaseName || 'Case'}
                  subtitle={`Status: ${caseDoc.case_status || 'Unknown'}`}
                  details={[
                    {
                      label: 'Created At',
                      value: new Intl.DateTimeFormat('en-GB', {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                      }).format(new Date(caseDoc.created_at)),
                    },
                  ]}
                  onClick={() => handleCaseClick(caseDoc)}
                  link=""
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-32">
              <p className="text-center text-gray-500">{t('No shared cases available')}</p>
            </div>
          )}
        </section>
      )}

      {activeTab === 'financial-data' && (
        <section className="mb-6">
          <h3 className="text-xl font-semibold mb-4">{t('Financial Data')}</h3>
          <p className="text-gray-500 italic">{t('Coming soon: Financial arrangement, invoices, etc.')}</p>
        </section>
      )}
    </div>
  );
};

export default LawyerDetails;
