import React from 'react';
import { useTranslation } from 'react-i18next';

interface TabItem {
  label: string;
  value: string;
  rightAligned?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

interface TabsProps {
  tabs: TabItem[];
  activeTab: string;
  onTabClick: (tabValue: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onTabClick }) => {
  const { t } = useTranslation();
  return (
    <div className="border-b border-gray-200 dark:border-gray-700 mb-4">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {tabs.map((tab) => {
          const isActive = activeTab === tab.value;
          const tabClasses = isActive
            ? 'border-primary-500 text-primary-600 dark:text-primary-400'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400';
          const disabledClasses = tab.disabled ? 'opacity-50 cursor-not-allowed' : '';

          return (
            <button
              key={tab.value}
              onClick={() => !tab.disabled && onTabClick(tab.value)}
              disabled={tab.disabled}
              title={tab.disabled ? tab.tooltip : ''}
              style={tab.rightAligned ? { marginLeft: 'auto' } : {}}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${tabClasses} ${disabledClasses}`}
            >
              {t(tab.label)}
            </button>
          );
        })}
      </nav>
    </div>
  );
};

export default Tabs;
