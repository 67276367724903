import { AppThunk, FETCH_CHATGROUPS_SUCCESS, FETCH_CONTRACTS_SUCCESS, SET_CHAT_GROUP_ID, SET_DOCUMENT_VISIBILITY, SET_FOLDER_VISIBILITY, SET_SELECTED_MODEL } from "../types";
import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import { ChatHistoryResponse } from "../../components/Chat/types";
import { handleApiError } from "./utils/utils";

export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';

export const getUserID = () => {
  const userData = localStorage.getItem("token") || sessionStorage.getItem("token");
  if (userData) {
    return JSON.parse(userData).userId;
  }
  return null;
};
export const getUserType = () => {
  const userData = localStorage.getItem("token") || sessionStorage.getItem("token");
  if (userData) {
    return JSON.parse(userData).userType;
  }
  return null;
}

export const getKycStatus = () => {
  const userData = localStorage.getItem("token") || sessionStorage.getItem("token");
  if (userData) {
    return JSON.parse(userData).kycStatus;
  }
  return null;
}

export interface Citation {
  id: string;
  law: string;
  paragraph: string;
  section: string;
  snippet: string;
  title: string;
  url: string;
}

export interface SomeInputType {
  LCMID: string;
  UserID: string;
  Section: string;
  SectionData: string;
  UserType: string
}


export interface ApiResponse {
  ConfirmMessage: string;
  DataID: string;
  NextSection: string;
  Output: string;
  Section: string;
  SectionData: string;
  UserFullName: string;
  UserID: string;
  LCM?: any;
}

export interface AIModel {
  model_content: string;
  model_display_name: string;
  model_id: number;
  model_name: string;
  model_role: string;
  total_available_queries?: string | number;
  is_user_index?: boolean;
}


export interface ServiceAPIResponse {
  LawResearchResponse?: string;
  ContractAnalysisResponse?: string;
  ContractGeneratorResponse?: string;
  CaseResearchResponse?: string;
  TaxResearchResponse?: string;
  ChatResponse?: string;
  ChatGroupID?: string; // New property
  ChatID?: string; // New property
  ChatTitle?: string;    // New property
  ContractGenerationResponse?: string
  message?: string;
  Citations?: Citation[];
}

export interface ChatGroupData {
  AIType: string,
  ChatGroupID: string,
  ChatTitle: string,
  CreationDate: string,
  LastModified: string,
  UserID: string,
  id: string,
  status: string
}


/**
 * Call the Chat API with a message and optionally a file.
 *
 * @param {string} prompt - The message to be sent to the API.
 * @param {number} max_tokens - Maximum tokens for the response.
 * @param {File} [file] - An optional file to be sent to the API.
 * @returns {Promise<any>} - The API response.
 */



export const setSelectedModel = (model: AIModel) => {
  localStorage.setItem('selectedModel', JSON.stringify(model));
  // localStorage.setItem('chatGroupID', '');
  return {
    type: SET_SELECTED_MODEL,
    payload: model,
  };
};


export const setChatGroupIDStore = (chatGroupID: string) => {
  localStorage.setItem('chatGroupID', chatGroupID);
  return {
    type: SET_CHAT_GROUP_ID,
    payload: chatGroupID,
  };
};

export const addChatGroup = (chatGroup: ChatGroupData) => ({
  type: 'ADD_CHAT_GROUP',
  payload: chatGroup,
});

export const callLCMAPI = async (data: SomeInputType): Promise<ApiResponse> => {
  try {
    const response = await apis({
      method: "POST",
      url: "ponsai/lcm/generateLCM",
      data: data
    });

    return response.data;
  } catch (error) {
    console.error('Error calling LCM API:', error);
    throw error;
  }
};


export const callServiceAPI = async (
  service: string,
  data: any,
  files: File[] = [],
  groupID: string = ''
): Promise<ServiceAPIResponse> => {
  let endpoint;
  let payload: FormData | Record<string, any>;
  let headers = {};

  // Include common parameters
  const commonData = {
    ...data,
    GroupID: groupID,
  };

  switch (service) {
    case 'LawResearch':
      endpoint = 'ai/generate_law_research';
      payload = commonData;
      break;
    case 'TaxResearch':
      endpoint = 'ai/generate_tax_research';
      payload = commonData;
      break;
    case 'CaseResearch':
      endpoint = 'ai/generate_case_research';
      payload = commonData;
      break;
      case 'ContractAdvisor':
        endpoint = '/ai/generate_contract_analysis';
        const formData = new FormData();
        formData.append('GroupID', groupID);
        // Always append 'Files' field, even if empty
        if (files.length > 0) {
          files.forEach((file) => {
            formData.append('Files', file);
          });
        } else {
          formData.append('Files', new Blob(), ''); 
        }
        // Append other data
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        payload = formData;
        break;
    case 'ContractGenerator':
      endpoint = 'ponsai/contractGenerator';
      payload = commonData;
      break;
    case 'CompanyAgent':
      endpoint = 'ai/company_agent/chat';
      payload = {
        ...data,
      };
      break;
    case 'CustomAgent':
      endpoint = 'ai/custom_agent/chat';
      payload = {
        ...data,
      };
      break;
    default:
      throw new Error('Service not recognized');
  }

  try {
    const response = await apis({
      method: 'POST', 
      url: endpoint,
      data: payload,
      headers: headers,
    });
    return response.data;
  } catch (error: any) {
    console.error(`Error calling ${endpoint}:`, error);
    throw error;
  }
};


export interface ChatGroupData {
  AIType: string,
  ChatGroupID: string,
  ChatTitle: string,
  CreationDate: string,
  LastModified: string,
  UserID: string,
  id: string,
  status: string
}

export const chatGroupApi = (AIType: string): AppThunk => async (dispatch) => {
  try {
    let response = await apis({
      method: "POST",
      url: "ai/get_chat_groups",
      data: {
        AIType,
      }
    })

    if (response && response.status === 200) {
      dispatch({
        type: FETCH_CHATGROUPS_SUCCESS,
        payload: response.data.Chats
      });
      return response.data.Chats
    } else {
      handleApiError(response, dispatch)
    }

  } catch (err) {
    handleApiError(err, dispatch)
  }

}


// Modify your chatHistoryApi to correctly type the return value
export const chatHistoryApi = (
  AIType: string,
  GroupID: string,
  signal: AbortSignal
): AppThunk<Promise<ChatHistoryResponse>> => async (dispatch) => {
  try {
    let response = await apis({
      method: "POST",
      url: "ai/get_ai_chat_history",
      data: { AIType, GroupID },
      signal: signal, // Pass the abort signal to fetch
    });
    if (response && response.status === 200) {
      return response.data as ChatHistoryResponse;
    } else {
      handleApiError(response, dispatch)
      throw new Error('Unable to fetch chat history');

    }
  } catch (err: any) {
    if (err.name !== 'AbortError') {
      handleApiError(err, dispatch)
    }
    throw err;
  }
};

export const deleteChatGroup = (GroupID: string,): AppThunk => async (dispatch) => {
  try {
    let response = await apis({
      method: "POST",
      url: "ai/delete_chat_group",
      data: {
        GroupID,
      }
    })

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Chat deleted',
        modalType: 'success',
        duration: 3000
      }));
      return response.data
    } else {
      handleApiError(response, dispatch)
    }

  } catch (err) {
    handleApiError(err, dispatch)
  }
}


export const submitChatFeedback = (
  ChatID: string,
  GroupID: string,
  Like: string,
  Dislike: string,
  Comment: string
): AppThunk => async (dispatch) => {
  try {
    let response = await apis({
      method: "POST",
      url: "ai/chat_feedback",
      data: {
        UserID: getUserID(),
        UserType: getUserType(),
        ChatID,
        GroupID,
        Like,
        Dislike,
        Comment
      }
    });

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Feedback submitted',
        modalType: 'success',
        duration: 3000
      }));
      return response.data;
    } else {
      handleApiError(response, dispatch)
    }
  } catch (err) {
    handleApiError(err, dispatch)
  }
};


export const setFolderVisibility = (isVisible: boolean) => ({
  type: SET_FOLDER_VISIBILITY,
  payload: isVisible,
});

export const setDocumentVisibility = (isVisible: boolean) => ({
  type: SET_DOCUMENT_VISIBILITY,
  payload: isVisible,
});

export const fetchContracts = () => async (dispatch: any) => {
  try {
    const response = await apis({
      method: "GET",
      url: "contract/types",
    })
    if (response.status === 200) {
      dispatch({
        type: FETCH_CONTRACTS_SUCCESS,
        payload: response.data
      });
    } else {
      handleApiError(response, dispatch)
    }
  } catch (error: any) {
    handleApiError(error, dispatch)
  }
};


export const getAiModels = (project_id?: string) => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: "POST",
        url: "/ai/models",
        data:{
          project_id
        }
      })
      if (response && response.status === 200) {
        dispatch({ type: FETCH_SERVICES_SUCCESS, payload: response.data.services });
        return response.data
      } else {
        handleApiError(response, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }

  }
}

export const deleteChatHistory = (GroupID: string, UserID: string, UserType: string) => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: "POST",
        url: "ponsai/deleteChatHistory",
        data: {
          GroupID,
          UserID,
          UserType
        }
      })
      if (response && response.status === 200) {
        return response.data
      } else {
        handleApiError(response, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }

  }
}