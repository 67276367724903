import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  Fragment,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { getUserType } from '../../store/actions/ChatAction';
import {
  createFolder,
  createSubFolder,
  deleteFile,
  deleteFolder,
  fetchStructure,
  renameFolder,
} from '../../store/actions/FolderAction';
import { hideModal, showCreateFolderModal, showModal } from '../../store/actions/modalActions';
import { showAddFileSidePanel } from '../../store/actions/sidePanelActions';
import { fetchRecommendedDocuments, updateDocumentOrder, updateFolderOrder } from '../../store/actions/DocumentsAction';
import { FileProps, FolderProps } from '../Documents/types';
import { usePermission } from '../../hooks/userPermissions';
import { LayoutContext } from '../../contexts/LayoutContext';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  FolderPlusIcon,
  DocumentPlusIcon,
  TableCellsIcon,
  Squares2X2Icon,
  PlusIcon,
  SparklesIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  DocumentMagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { DocumentIcon, FolderIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ClipLoader } from 'react-spinners';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import Table from '../shared/TailwindComponents/Table';
import WordEditor from '../Documents/WordEditor';

import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Tooltip } from '@mui/material';
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoDocumentTextOutline } from "react-icons/io5";
import ApiLoader from '../ApiLoader';
import { PiFile, PiFileMagnifyingGlassLight, PiFilePlusLight, PiFilesLight, PiFileTextLight, PiFolderLight, PiFolderPlusLight, PiFolderSimpleLight, PiGridNineLight, PiMagicWandLight, PiPlusLight, PiTableLight } from 'react-icons/pi';

export function getFileIconForGrid(file: FileProps, w: string, h: string) {
  const fileCategory = file.FileCategory?.toLowerCase() || '';
  if (fileCategory.includes('general') || fileCategory.includes('officedocument')) {
    return <PiFile className={`${w} ${h} text-info-500`} />;
  } else if (fileCategory.includes('contract')) {
    return <PiFileTextLight className={`${w} ${h} text-red-500`} />;
  } else if (fileCategory.includes('legal document')) {
    return <PiFileMagnifyingGlassLight className={`${w} ${h} text-green-500`} />;
  } else {
    return <PiFile className={`${w} ${h} text-gray-500`} />;
  }
}

function getFolderIcon(w: string, h: string) {
  return <PiFolderLight className={`text-primary-500 ${w} ${h}`} />;
}

const FilesExplorer: React.FC = () => {
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const { t } = useTranslation();
  const { setIsContentScrollable } = useContext(LayoutContext);
  const { hasAccess, isReadOnly } = usePermission('documents');
  
  const [isLoading, setIsLoading] = useState(false);
  const structure = useSelector((state: RootState) => state.folders.structure || []);
  const recommendedFiles = useSelector((state: RootState) => state.documents.recommendedDocuments || []);
  const [pathStack, setPathStack] = useState<FolderProps[]>([]);
  const [currentView, setCurrentView] = useState<'grid' | 'table'>('grid');
  
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const [editorOpen, setEditorOpen] = useState(false);
  
  const [contextMenu, setContextMenu] = useState<{ x: number; y: number; target?: FolderProps | FileProps; type?: 'file' | 'folder' | 'empty' }>({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Filters, search, and sort states
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('name_asc');
  const flatFiles = useSelector((state: RootState) => state.folders.flatFiles || []);

  // Debounce timer for search
  const searchDebounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const [recommendedView, setRecommendedView] = useState(false);

  const fetchData = useCallback(async () => {
    if (!userType) return;
    setIsLoading(true);
    // Initial fetch without filters/sort/search
    await dispatch(fetchStructure());
    await dispatch(fetchRecommendedDocuments());
    setIsLoading(false);
  }, [dispatch, userType]);

  useEffect(() => {
    setIsContentScrollable(true);
    return () => { setIsContentScrollable(true); };
  }, [setIsContentScrollable]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Current folder logic
  const currentFolder = recommendedView ? null : (pathStack.length > 0 ? pathStack[pathStack.length - 1] : null);
  const currentFolders = recommendedView
    ? []
    : currentFolder && Array.isArray(currentFolder.SubFolders)
      ? currentFolder.SubFolders
      : Array.isArray(structure) ? structure : [];
  const currentFiles = recommendedView
    ? recommendedFiles
    : currentFolder && Array.isArray(currentFolder.Files)
      ? currentFolder.Files
      : [];

  const getSortParams = (value: string) => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (value === 'default') {
      sortBy = 'order';
      sortOrder = 'asc';
    } else if (value === 'name_asc') {
      sortBy = 'name';
      sortOrder = 'asc';
    } else if (value === 'name_desc') {
      sortBy = 'name';
      sortOrder = 'desc';
    } else if (value === 'date_asc') {
      sortBy = 'modified_at';
      sortOrder = 'asc';
    } else if (value === 'date_desc') {
      sortBy = 'modified_at';
      sortOrder = 'desc';
    }
    return { sortBy, sortOrder };
  };  

  // Refetch whenever filters/sort/search change
  useEffect(() => {
    // Debounce search
    if (searchDebounceTimeout.current) {
      clearTimeout(searchDebounceTimeout.current);
    }
    searchDebounceTimeout.current = setTimeout(async () => {
      const { sortBy, sortOrder } = getSortParams(sortValue);
      setIsLoading(true);
      await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
      setIsLoading(false);
    }, 300);

    return () => {
      if (searchDebounceTimeout.current) {
        clearTimeout(searchDebounceTimeout.current);
      }
    };
  }, [searchTerm, filters, sortValue, dispatch]);

  const handleBreadcrumbClick = (index: number) => {
    setPathStack(pathStack.slice(0, index + 1));
  };

  const handleNavigateFolder = (folder: FolderProps) => {
    setPathStack([...pathStack, folder]);
  };

  const handleGoBack = () => {
    const newStack = [...pathStack];
    newStack.pop();
    setPathStack(newStack);
  };

  const handleCreateFolder = async (folderName: string) => {
    if (folderName.trim()) {
      const resp: any = await dispatch(createFolder(folderName));
      if (resp) {
        const { sortBy, sortOrder } = getSortParams(sortValue);
        await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
      }
    }
  };

  const handleCreateSubFolder = async (folderName: string, parentFolderId: string) => {
    if (folderName.trim()) {
      const resp: any = await dispatch(createSubFolder(folderName, parentFolderId));
      if (resp) {
        const { sortBy, sortOrder } = getSortParams(sortValue);
        await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
      }
    }
  };

  const handleCreateFolderClick = () => {
    dispatch(showCreateFolderModal(handleCreateFolder));
  };

  const handleCreateSubFolderClick = () => {
    if (!currentFolder) return;
    dispatch(showCreateFolderModal(undefined, currentFolder.FolderID, handleCreateSubFolder, true));
  };

  const handleAddFile = () => {
    dispatch(showAddFileSidePanel(structure, currentFolder?.FolderID));
  };

  const handleContextMenu = (e: React.MouseEvent, item?: FolderProps | FileProps) => {
    e.preventDefault();
    const rect = containerRef.current?.getBoundingClientRect();
    const offsetX = rect ? e.clientX - rect.left : e.clientX;
    const offsetY = rect ? e.clientY - rect.top : e.clientY;
    if (item) {
      let type: 'file' | 'folder' = 'file';
      if ((item as FolderProps).SubFolders !== undefined) type = 'folder';
      setContextMenu({ x: offsetX, y: offsetY, target: item, type });
    } else {
      setContextMenu({ x: offsetX, y: offsetY, type: 'empty' });
    }
  };

  const closeContextMenu = () => {
    setContextMenu({ x: 0, y: 0 });
  };

  const handleDeleteFolder = async (folder: FolderProps) => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this folder?'),
        onConfirm: async () => {
          await dispatch(deleteFolder(folder.FolderID, async() => {
            const { sortBy, sortOrder } = getSortParams(sortValue);
            await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
          }));
          dispatch(hideModal());
        },
        onCancel: () => {},
        showModal: true,
      })
    );
  };

  const handleDeleteFile = async (file: any) => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this file?'),
        onConfirm: async () => {
          // Implement deleteFile if available
          // await dispatch(deleteFile(file.FileID));
          // const { sortBy, sortOrder } = getSortParams(sortValue);
          // await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));

          await dispatch(deleteFile(file.FileID, async() => {
            const { sortBy, sortOrder } = getSortParams(sortValue);
            await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
          }));
          dispatch(hideModal());
        },
        onCancel: () => {},
        showModal: true,
      })
    );
  };

  const [newFolderNameState, setNewFolderNameState] = useState('');
  const handleRenameFolder = (folder: FolderProps) => {
    dispatch(
      showModal({
        type: 'custom',
        message: t('Rename Folder'),
        showModal: true,
        content: (
          <div className="p-4 space-y-4">
            <Input
              type="text"
              label={t('New Folder Name')}
              onChange={(e) => setNewFolderNameState(e.target.value)}
              id="renameFolderInput"
            />
            <Button
              variant="primary"
              onClick={async () => {
                if (newFolderNameState.trim()) {
                  await dispatch(renameFolder(folder.FolderID, newFolderNameState.trim()));
                  const { sortBy, sortOrder } = getSortParams(sortValue);
                  await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
                  dispatch(hideModal());
                }
              }}
            >
              {t('Rename')}
            </Button>
          </div>
        ),
      })
    );
  };

  

// Determine modes
const isFilteredMode = flatFiles && flatFiles.length > 0;
const isRecommendedMode = recommendedView && !isFilteredMode;

let displayedFolders: FolderProps[] = [];
let displayedFiles: FileProps[] = [];

if (isFilteredMode) {
  // When in filtered mode, we show all flatFiles as a flat list
  displayedFolders = [];
  displayedFiles = flatFiles;
} else {
  // Not filtered mode
  const currentFolder = recommendedView 
    ? null 
    : (pathStack.length > 0 ? pathStack[pathStack.length - 1] : null);

  const currentFolders = recommendedView
    ? []
    : currentFolder && Array.isArray(currentFolder.SubFolders)
      ? currentFolder.SubFolders
      : Array.isArray(structure) ? structure : [];

  const currentFiles = recommendedView
    ? recommendedFiles
    : currentFolder && Array.isArray(currentFolder.Files)
      ? currentFolder.Files
      : [];

  displayedFolders = isRecommendedMode ? [] : currentFolders;
  displayedFiles = isRecommendedMode ? recommendedFiles : currentFiles;
}

  const onFileDoubleClick = (file: FileProps) => {
    // In both modes, we can open the file editor
    setSelectedFile(file);
    setEditorOpen(true);
  };
  
  const onFolderDoubleClick = (folder: FolderProps) => {
    // If filtered mode, we have no folder navigation, do nothing
    if (isFilteredMode || recommendedView) return;
    handleNavigateFolder(folder);
  };
  
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
  
    const { source, destination, draggableId } = result;
  
    // Skip reordering in filtered/recommended mode
    if (recommendedView || isFilteredMode) return;
  
    if (draggableId.startsWith("folder-")) {
      // Reorder folders
      const reordered = Array.from(displayedFolders);
      const [moved] = reordered.splice(source.index, 1);
      reordered.splice(destination.index, 0, moved);
  
      const dataToUpdate = reordered.map((f, index) => ({
        FolderID: f.FolderID,
        newOrder: index,
      }));
  
      await dispatch(updateFolderOrder(dataToUpdate));
  
      const { sortBy, sortOrder } = getSortParams(sortValue);
      await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
    } else if (draggableId.startsWith("file-")) {
      // Reorder files
      const reordered = Array.from(displayedFiles);
      // Adjust index if folders are rendered before files
      const folderCount = displayedFolders.length;
      const [moved] = reordered.splice(source.index - folderCount, 1);
      reordered.splice(destination.index - folderCount, 0, moved);
  
      const dataToUpdate = reordered.map((f: FileProps, index: number) => ({
        FileID: f.FileID,
        newOrder: index,
      }));
  
      await dispatch(updateDocumentOrder(dataToUpdate));
  
      // Refetch the updated structure
      const { sortBy, sortOrder } = getSortParams(sortValue);
      await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
    }
  };  
  
  // Toggle recommended view unchanged:
  const toggleRecommendedView = async () => {
    setRecommendedView(!recommendedView);
    if (!recommendedView) {
      setPathStack([]);
    }
    const { sortBy, sortOrder } = getSortParams(sortValue);
    setIsLoading(true);
    await dispatch(fetchStructure({ searchTerm, filters, sortBy, sortOrder }));
    setIsLoading(false);
  };

  const getRecursiveFileCount = (folder: FolderProps): number => {
    // Count files directly in the folder (guarding against undefined)
    let count = folder.Files ? folder.Files.length : 0;
    // Recursively add file counts from subfolders (if any)
    if (folder.SubFolders && folder.SubFolders.length > 0) {
      folder.SubFolders.forEach((subFolder) => {
        count += getRecursiveFileCount(subFolder);
      });
    }
    return count;
  }

  // Breadcrumb
  const renderBreadcrumbs = () => {
    if (recommendedView && !isFilteredMode) {
      return (
        <div className="flex items-center space-x-2">
          <span className="text-gray-500 text-xs">{t('Recommended Files')}</span>
        </div>
      );
    }
  
    if (isFilteredMode) {
      return (
        <div className="flex items-center space-x-2">
          <span className="text-gray-500 text-xs">{t('All Matching Files')}</span>
        </div>
      );
    }

    return (
      <div className="flex items-center space-x-2">
        {pathStack.length > 0 && (
          <button onClick={handleGoBack} className="text-primary-500 hover:underline flex items-center">
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
        )}
        <span className="text-xs text-gray-500 cursor-pointer text-xs hover:underline" onClick={() => setPathStack([])}>
          {/* <i className='fas fa-folder-open '></i> */}
          <PiFolderSimpleLight size={20} />
        </span>
        {pathStack.map((folder, index) => (
          <div key={folder.FolderID} className="flex items-center space-x-2">
            <ChevronRightIcon className="h-5 w-5 text-gray-500" />
            <button
              onClick={() => handleBreadcrumbClick(index)}
              className="text-gray-500 hover:underline text-xs truncate max-w-xs"
            >
              {folder.FolderName}
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderGrid = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-items" direction="horizontal" type="ITEM">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="p-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4"
            onContextMenu={(e) => handleContextMenu(e)}
          >
            {displayedFolders.map((folder, index) => (
              <Draggable key={folder.FolderID} draggableId={`folder-${folder.FolderID}`} index={index}>
                {(prov) => (
                  <Tooltip title={`${getRecursiveFileCount(folder)} ${t('Files')}`}>
                  <div
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}
                      className="flex flex-col items-center p-4 bg-white dark:bg-gray-800 rounded hover:bg-gray-100 transition dark:hover:bg-gray-700 shadow-md dark:shadow-dark-md ..."
                      onDoubleClick={() => onFolderDoubleClick(folder)}
                      onContextMenu={(e) => { e.stopPropagation(); handleContextMenu(e, folder); }}
                    >
                      {getFolderIcon('w-10', 'h-10')}
                      <p className="mt-2 text-sm text-center text-gray-700 dark:text-gray-300 line-clamp-2 max-w-40 truncate text-ellipsis">
                        {folder.FolderName}
                      </p>
                    </div>
                  </Tooltip>
                )}
              </Draggable>
            ))}
            {displayedFiles.map((file: any, index: number) => (
              <Draggable key={file.FileID} draggableId={`file-${file.FileID}`} index={displayedFolders.length + index}>
                {(prov) => (
                  <Tooltip title={file.FileCategory}>
                    <div
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}
                      className="flex flex-col items-center p-4 bg-white rounded dark:bg-gray-800 hover:bg-gray-100 transition dark:hover:bg-gray-700 shadow-md dark:shadow-dark-md ..."
                      onDoubleClick={() => onFileDoubleClick(file)}
                      onContextMenu={(e) => { e.stopPropagation(); handleContextMenu(e, file); }}
                    >
                      {getFileIconForGrid(file, 'w-10', 'h-10')}
                      <p className="mt-2 text-sm text-center text-gray-700 dark:text-gray-300 max-w-40 truncate line-clamp-2">
                        {file.FileName}
                      </p>
                    </div>
                  </Tooltip>
                )}
              </Draggable>
            ))}
            {displayedFolders.length === 0 && displayedFiles.length === 0 && !isLoading && (
              <div className="col-span-full flex flex-col items-center justify-center py-8">
                <Badge color='gray' className="mt-4">{t('No items found')}</Badge>
              </div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  const columns = ['Name', 'Last Modified', 'Info'];

  const tableData = [
    ...displayedFolders.map((folder, index) => ({
      id: folder.FolderID,
      doc: folder,
      Name: (
        <div className="flex items-center space-x-2">
          {getFolderIcon('w-6', 'h-6')}
          <span className="truncate max-w-xs">{folder.FolderName}</span>
        </div>
      ),
      // For folders, show the recursive file count
      Info: <Badge color="blue">{getRecursiveFileCount(folder)}</Badge>,
      'Last Modified': new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      }).format(new Date(folder.ModifiedAt)),
      isFolder: true,
      index,
    })),
    ...displayedFiles.map((file: FileProps, index: number) => ({
      id: file.FileID,
      doc: file,
      Name: (
        <div className="flex items-center space-x-2">
          {getFileIconForGrid(file, 'w-6', 'h-6')}
          <span className="truncate max-w-xs">{file.FileName}</span>
        </div>
      ),
      // For files, show the file type (using your translation helper)
      Info: <Badge color="blue">{t(file.FileCategory)}</Badge>,
      'Last Modified': new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      }).format(new Date(file.ModifiedAt)),
      isFolder: false,
      index: displayedFolders.length + index,
    })),
  ];
  
  const handleRowClick = (row: any) => {
    if (row.isFolder) {
      onFolderDoubleClick(row.doc as FolderProps);
    } else {
      onFileDoubleClick(row.doc as FileProps);
    }
  };
  
  const toggleView = () => {
    setCurrentView(currentView === 'grid' ? 'table' : 'grid');
  };
  
  const renderTable = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-table" type="FILE">
        {(provided: any) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="px-2 flex flex-col h-full"
            onContextMenu={(e) => handleContextMenu(e)}
          >
            <div className="flex-1 overflow-y-auto">
              <Table
                columns={columns}
                data={tableData}
                onRowClick={handleRowClick}
                renderRow={(row: any) => (
                  <Draggable draggableId={`table-item-${row.id}`} index={row.index} key={row.id}>
                    {(prov: any) => (
                      <tr
                        ref={prov.innerRef}
                        {...prov.draggableProps}
                        {...prov.dragHandleProps}
                        onDoubleClick={() => handleRowClick(row)}
                        onContextMenu={(e) => handleContextMenu(e, row.doc)}
                        className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        {columns.map((col) => (
                          // Adding "truncate" here as well helps ensure that table cells don’t overflow.
                          <td key={col} className="px-4 py-2 truncate">
                            {(row as any)[col]}
                          </td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                )}
              />
              {displayedFolders.length === 0 && displayedFiles.length === 0 && !isLoading && (
                <div className="flex flex-col items-center justify-center">
                  <Badge color="gray" className="mt-4">{t('No items found')}</Badge>
                </div>
              )}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <div className="flex flex-1 px-4 flex-col h-full bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 relative" ref={containerRef}>
      {editorOpen && selectedFile ? (
        <WordEditor
          file={selectedFile.FileID}
          onClose={() => {
            setEditorOpen(false);
            setSelectedFile(null);
          }}
          setEditorOpen={setEditorOpen}
          onFileDeleted={() => setSelectedFile(null)}
        />
      ) : (
        <>
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800 bg-opacity-50 dark:bg-opacity-50 dark:bg-dark z-50">
              <ApiLoader />
            </div>
          )}

          {/* Header */}
          <div className="flex items-center justify-between px-4 py-2 border-b border-gray-300 dark:border-gray-700">
            {renderBreadcrumbs()}
            <div className="flex items-center space-x-2">
              {/* Add Dropdown */}
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="btn btn-primary inline-flex items-center space-x-1">
                  <PiPlusLight className="h-5 w-5" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleCreateFolderClick}
                          className={clsx(
                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                            'flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                          )}
                        >
                          <PiFolderPlusLight className="h-5 w-5 text-primary-500 mr-2" />
                          {t('Create Folder')}
                        </button>
                      )}
                    </Menu.Item>
                    {(!recommendedView && currentFolder) && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleCreateSubFolderClick}
                            className={clsx(
                              active ? 'bg-gray-100 dark:bg-gray-600' : '',
                              'flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                            )}
                          >
                            <PiFolderPlusLight className="h-5 w-5 text-primary-500 mr-2" />
                            {t('Create Subfolder')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {!recommendedView && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleAddFile}
                            className={clsx(
                              active ? 'bg-gray-100 dark:bg-gray-600' : '',
                              'flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                            )}
                          >
                            <PiFilePlusLight className="h-5 w-5 text-primary-500 mr-2" />
                            {t('Create File')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* Extra Controls */}
            </div>
          </div>

          {/* Filter and Search Bar */}
          <div className="border-b border-gray-300 dark:border-gray-700">
            <FilterSearchBar
              filters={[
                {
                  id: 'FileCategory',
                  name: t('File Category'),
                  options: [
                    { value: 'Contract', label: t('Contract') },
                    { value: 'Legal Document', label: t('Legal Document') },
                    { value: 'General', label: t('General') },
                    { value: 'Template', label: t('Template') },
                  ],
                },
              ]}
              sortOptions={[
                { value: 'default', label: t('Default'), current: sortValue === 'Default' },
                { value: 'name_asc', label: t('Name Ascending'), current: sortValue === 'name_asc' },
                { value: 'name_desc', label: t('Name Descending'), current: sortValue === 'name_desc' },
                { value: 'date_asc', label: t('Date Ascending'), current: sortValue === 'date_asc' },
                { value: 'date_desc', label: t('Date Descending'), current: sortValue === 'date_desc' },
              ]}
              selectedFilters={filters}
              onFilterChange={(filterName, selectedValues) => setFilters((prev) => ({ ...prev, [filterName]: selectedValues }))}
              onSortChange={(value) => setSortValue(value)}
              onSearchChange={(term) => setSearchTerm(term)}
              sortValue={sortValue}
              showSearchBar={true}
              extraControls={
                <div className="mr-2 flex flex-row gap-2">
                  <Button
                    onClick={toggleRecommendedView}
                    variant="tertiary"
                    className="mr-2"
                    tooltip={`${recommendedView ? 'Default' : 'Recommended Files'}
                    `}
                    size='small'
                  >
                    {recommendedView ? (
                      <PiFilesLight size={18} />
                    ) : (
                      <PiMagicWandLight size={18} />
                    )}
                 <span className='ml-1'>{recommendedView ? 'All Files' : 'Recommended Files'}</span>
                  </Button>
                  <button
                    onClick={toggleView}
                    className="p-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded hover:bg-gray-100 dark:hover:bg-gray-600 transition"
                  >
                    {currentView === 'grid' ? (
                      <PiTableLight className="h-5 w-5 text-gray-700 dark:text-gray-200" />
                    ) : (
                      <PiGridNineLight className="h-5 w-5 text-gray-700 dark:text-gray-200" />
                    )}
                  </button>
                </div>
              }
            />
          </div>

          {/* Content Area */}
          <div className="flex-1 overflow-auto relative" onContextMenu={(e) => handleContextMenu(e)}>
            {currentView === 'grid' ? renderGrid() : renderTable()}
          </div>

          {/* Context Menu */}
          {contextMenu.type && (
            <div
              className="fixed z-50 bg-white dark:bg-gray-800 rounded shadow-lg border border-gray-300 dark:border-gray-700 text-sm"
              style={{ top: contextMenu.y, left: contextMenu.x }}
              onMouseLeave={closeContextMenu}
            >
              {contextMenu.type === 'empty' && (
                <div className="flex flex-col py-1">
                  {!recommendedView && (
                    <>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                        onClick={handleCreateFolderClick}
                      >
                        {t('Create Folder')}
                      </button>
                      {currentFolder && (
                        <button
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                          onClick={handleCreateSubFolderClick}
                        >
                          {t('Create Subfolder')}
                        </button>
                      )}
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                        onClick={handleAddFile}
                      >
                        {t('Create File')}
                      </button>
                    </>
                  )}
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={toggleRecommendedView}
                  >
                    {currentView === 'grid' ? t('Switch to Table View') : t('Switch to Grid View')}
                  </button>
                </div>
              )}
              {contextMenu.type === 'folder' && contextMenu.target && !recommendedView && (
                <div className="flex flex-col py-1">
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={() => handleRenameFolder(contextMenu.target as FolderProps)}
                  >
                    {t('Rename Folder')}
                  </button>
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={() => handleDeleteFolder(contextMenu.target as FolderProps)}
                  >
                    {t('Delete Folder')}
                  </button>
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={handleCreateSubFolderClick}
                  >
                    {t('Create Subfolder')}
                  </button>
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={handleAddFile}
                  >
                    {t('Create File')}
                  </button>
                </div>
              )}
              {contextMenu.type === 'file' && contextMenu.target && (
                <div className="flex flex-col py-1">
                  <button
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-left"
                    onClick={() => handleDeleteFile(contextMenu.target as FileProps)}
                  >
                    {t('Delete File')}
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FilesExplorer;
