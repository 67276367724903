import React, { ReactNode, useEffect, useCallback, useState } from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import { RootState, useAppDispatch } from '../store';
import { useSelector } from 'react-redux';
import { getPreferences, userInformation } from '../store/actions/DataAction';
import { getUserID, getUserType } from '../store/actions/ChatAction';
import SocketServices from '../utils/SocketServices';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from '../contexts/LayoutContext';
import { BreadcrumbsProvider } from '../contexts/BreadcrumbsContext';
import MaintenancePage from './Maintenance/MaintenancePage';
import UserGuidance from '../components/shared/UserGuidance';

interface PrivateLayoutProps {
  children: ReactNode;
  activeView: string;
  setActiveView: (activeView: string) => void;
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  children,
  activeView,
  setActiveView,
}) => {
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const userType = getUserType();
  const history = useHistory();
  const [isContentScrollable, setIsContentScrollable] = useState<boolean>(true);
  const [maintenanceMode, setMaintenanceMode] = useState(false)

  useEffect(() => {
    if (userType) {
      const fetchData = async () => {
        dispatch(userInformation());
        dispatch(getPreferences());
      };
      fetchData();
    }
  }, [userId, userType, dispatch]);

  const storeSession = useCallback((data: any) => {
    const { session_id } = data;
    if (session_id) {
      sessionStorage.setItem('session_id', session_id);
    }
  }, []);

  const sessionSignOut = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    history.push('/');
  }, [history]);

  useEffect(() => {
    SocketServices.on('session_created', storeSession);
    SocketServices.on('session_signout', sessionSignOut);
    return () => {
      SocketServices.removeListener('session_created');
      SocketServices.removeListener('session_signout');
    };
  }, [storeSession, sessionSignOut]);


  if (maintenanceMode) {
    return <MaintenancePage />;
  }

return (
  <LayoutContext.Provider value={{ isContentScrollable, setIsContentScrollable }}>
    <BreadcrumbsProvider>
      <div
        className={`
          flex flex-col min-h-screen
          bg-background-light text-text-light
          dark:bg-background-dark dark:text-text-dark
          transition-colors duration-200
        `}
      >
        <Sidebar />
        <div
          className={`
            flex-1 flex flex-col p-0 relative overflow-hidden
            transition-all duration-200
            ${isSidebarOpen ? 'ml-52' : 'ml-20'}
          `}
        >
          <Header activeView={activeView} setActiveView={setActiveView} />
          <UserGuidance />
          <main
            className={clsx(
              'flex-1 p-1',
              'custom-scrollbar relative',
              isContentScrollable ? 'overflow-y-auto max-h-[calc(100vh-80px)]' : 'overflow-hidden'
            )}
          >
            {children}
          </main>
        </div>
      </div>
    </BreadcrumbsProvider>
  </LayoutContext.Provider>
);
};

export default PrivateLayout;
