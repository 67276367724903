import React from 'react';

interface ToggleProps {
  enabled: boolean;
  onToggle: (value: boolean) => void;
  value: boolean;
}

const Toggle: React.FC<ToggleProps> = ({ enabled, onToggle, value }) => {
  return (
    <button
      onClick={() => onToggle(value)}
      type='button'
      className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300 focus:outline-none ${
        enabled ? 'bg-primary-600' : 'bg-gray-300 dark:bg-gray-600'
      }`}
    >
      <span
        className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-300 ${
          enabled ? 'translate-x-6' : 'translate-x-1'
        }`}
      />
    </button>
  );
};

export default Toggle;
