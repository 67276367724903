import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../contexts/ThemeContext';

const ThemeToggle: React.FC = () => {
  const { theme, toggleTheme } = useContext(ThemeContext); 
  const [isDark, setIsDark] = useState<boolean>(false);

  useEffect(() => {
    setIsDark(theme === 'dark');
  }, [theme]);
  
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only"
        checked={isDark}
        onChange={toggleTheme}
      />
      <div className="relative">
        <div
          className="w-12 h-6 bg-gray-300 rounded-full shadow-inner dark:bg-gray-600"
        ></div>
        <div
          className={`dot absolute w-6 h-6 bg-white rounded-full shadow left-0 top-0 transition ${
            isDark ? 'transform translate-x-full bg-gray-800' : ''
          }`}
        ></div>
        <div className="absolute left-1 top-1 text-yellow-500">
          {/* <i className="fas fa-sun"></i> */}
        </div> 
        <div className="absolute right-1 top-1 text-gray-200">
          {/* <i className="fas fa-moon"></i> */}
        </div>
      </div>
    </label>
  );
};

export default ThemeToggle;
