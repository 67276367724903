import React, { useEffect, useState } from 'react';
import ApiLoader from '../ApiLoader';
import PDFViewer from '../Documents/PDFViewer';

interface FileReaderComponentProps {
  fileUrl: string;
}

const FileReaderComponent: React.FC<FileReaderComponentProps> = ({ fileUrl }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPdf, setIsPdf] = useState(false)

  useEffect(() => {
    const processUrl = () => {
      // e.g. if it's PDF, we might do something like
      if (fileUrl.includes('.pdf')) {
        setIsPdf(true)
      } else {
        setIsPdf(false)
      }
      setIsLoading(false);
    };

    processUrl();
  }, [fileUrl]);

  if (isLoading) {
    return (
      <ApiLoader />
    );
  }

  return (
    <div className="w-full h-[98vh] mx-auto mt-6" >
      {
        isPdf ? (

          <PDFViewer pdfUrl={fileUrl} />
        ) : (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              fileUrl
            )}`}
            title="DOC File"
            className="w-full h-full"
          />
        )
      }
    </div >


  );
};

export default FileReaderComponent;
