import { useContext, useEffect } from "react";
import MessageCenter from "../components/MessageCenter/MessageCenter";
import { LayoutContext } from "../contexts/LayoutContext";
import { isBetaEnv } from "../utils/authUtils";
import Badge from "../components/shared/TailwindComponents/Badge";
import { useTranslation } from "react-i18next";

function MessageCenterPage() {
    const { setIsContentScrollable } = useContext(LayoutContext);
    const { t } = useTranslation()

    useEffect(() => {
        setIsContentScrollable(false);
    
        // Reset to default when unmounting
        return () => {
          setIsContentScrollable(true);
        };
      }, [setIsContentScrollable]);
    
    return (
      <>
      {isBetaEnv ? (
        <div className="flex h-calc-100vh-90px justify-center items-center"><Badge color='gray'>{t('Coming soon')}</Badge></div>
      ) : (
        <div className="h-calc-100vh-90px">
            <MessageCenter />
        </div>

      )}
      </>
    )
}

export default MessageCenterPage;