import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store';
import {
  getLawFirmProfile,
  updateLawFirmProfile,
} from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import Input from '../../shared/TailwindComponents/Input';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';
import TagInput from '../../shared/TailwindComponents/TagInput';
import Toggle from '../../shared/TailwindComponents/Toggle';
import SingleFileInput from '../../shared/TailwindComponents/SingleFileInput';
import { showModal } from '../../../store/actions/modalActions';
import { showFileReaderSidePanel } from '../../../store/actions/sidePanelActions';
import LawFirmProfileComponent from './LawFirmProfileComponent';
import { useTranslation } from 'react-i18next';
import Badge from '../../shared/TailwindComponents/Badge';
import ApiLoader from '../../ApiLoader';

const LawFirmProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const [lawFirmProfile, setLawFirmProfile] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [termsOfServiceFile, setTermsOfServiceFile] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [termsOfServiceFileName, setTermsOfServiceFileName] = useState<string | null>(null);
  const { t } = useTranslation();
  const fetchDetails = useCallback(async () => {
    setLoading(true);
    const resp = await dispatch(getLawFirmProfile());
    if (resp) {
      setLawFirmProfile(resp);
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  useEffect(() => {
    if (lawFirmProfile) {
      // Set existing logo preview
      setLogoPreview(lawFirmProfile.Logo || null);

      // Set existing Terms of Service file name
      if (lawFirmProfile.TermsOfServiceURL) {
        const urlParts = lawFirmProfile.TermsOfServiceURL.split('/');
        const fileName = urlParts[urlParts.length - 1].split('?')[0];
        setTermsOfServiceFileName(fileName);
      }
    }
  }, [lawFirmProfile]);

  const [formData, setFormData] = useState<any>({
    Description: '',
    PracticeArea: [],
    ExperienceYears: '',
    is_marketplace_active: false,
    Website: '',
    LawFirmEmail: '',
    LawFirmOfficeAddress: '',
    LawFirmPhoneNumber: '',
  });

  useEffect(() => {
    if (lawFirmProfile) {
      setFormData({
        Description: lawFirmProfile.Description || '',
        PracticeArea: lawFirmProfile.PracticeArea
          ? Array.isArray(lawFirmProfile.PracticeArea)
            ? lawFirmProfile.PracticeArea
            : lawFirmProfile.PracticeArea.split(',') 
          : [],
        ExperienceYears: lawFirmProfile.ExperienceYears || '',
        is_marketplace_active: lawFirmProfile.is_marketplace_active || false,
        Website: lawFirmProfile.Website || '',
        LawFirmEmail: lawFirmProfile.LawFirmEmail || '',
        LawFirmOfficeAddress: lawFirmProfile.LawFirmOfficeAddress || '',
        LawFirmPhoneNumber: lawFirmProfile.LawFirmPhoneNumber || '',
      });
    }
  }, [lawFirmProfile]);
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTagChange = (name: string, values: string[]) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: values,
    }));
  };

  const handleFileChange = (name: string, file: File | null) => {
    if (name === 'Logo') {
      setLogoFile(file);
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setLogoPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        setLogoPreview(null);
      }
    } else if (name === 'TermsOfServiceFile') {
      setTermsOfServiceFile(file);
      setTermsOfServiceFileName(file ? file.name : null);
    }
  };

  const handleToggleChange = (name: string, value: boolean) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const updateData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === 'PracticeArea' && Array.isArray(formData[key])) {
        updateData.append(key, formData[key].join(',')); // Join the array into a comma-separated string
      } else {
        updateData.append(key, formData[key]);
      }
    });
  
    // Append files
    if (logoFile) {
      updateData.append('Logo', logoFile);
    }
    if (termsOfServiceFile) {
      updateData.append('TermsOfServiceFile', termsOfServiceFile);
    }
  
    const response = await dispatch(updateLawFirmProfile(updateData));
    if (response) {
      await fetchDetails(); 
    }
  };
  

  const handlePreviewProfile = () => {
    if (!lawFirmProfile) {
      return;
    }

    dispatch(
      showModal({
        type: 'custom',
        message: '',
        showModal: true,
        content: <LawFirmProfileComponent lawFirmProfile={lawFirmProfile} />,
        width: '80%',
      })
    );
  };

  const handleViewTermsOfService = () => {
    if (lawFirmProfile?.TermsOfServiceURL) {
      dispatch(showFileReaderSidePanel(lawFirmProfile.TermsOfServiceURL));
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold">{t('Company Marketplace Profile')}</h2>
        <Button onClick={handlePreviewProfile} variant="primary">
          {t('Preview Profile')}
        </Button>
      </div>
      {isLoading ? (
        <ApiLoader />
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Logo */}
                    <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">{t('Logo')}</label>
            <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t('Upload your law firm’s logo for branding.')}</p>
            <div className="flex items-center mt-1">
              {/* {logoPreview ? (
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  className="h-16 w-16 rounded object-cover mr-4"
                />
              ) : (
                <div className="h-16 w-16 rounded bg-gray-200 mr-4"></div>
              )} */}
              <SingleFileInput
                setFile={(file) => handleFileChange('Logo', file)}
                file={logoFile}
                accept="image/*"
                existingFileUrl={logoPreview || undefined}
              />
            </div>
          </div>

          {/* Terms of Service */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">{t('Terms of Service')}</label>
            <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t('Provide your terms of service for transparency with clients.')}</p>
            <div className="flex items-center mt-1">
              {termsOfServiceFileName ? (
                <button
                  type="button"
                  onClick={handleViewTermsOfService}
                  className="text-blue-600 hover:underline mr-4"
                >
                  {termsOfServiceFileName}
                </button>
              ) : (
                <Badge color='gray' className="mr-4">{t('No file uploaded')}</Badge>
              )}
              <SingleFileInput
                setFile={(file) => handleFileChange('TermsOfServiceFile', file)}
                file={termsOfServiceFile}
                accept=".pdf,.doc,.docx"
                existingFileUrl={lawFirmProfile?.TermsOfServiceURL || undefined}
              />
            </div>
          </div>

          {/* Toggle for Marketplace Active */}
          <div className="flex mt-4 flex-col">
            <div className='mb-2'>
            <label className="text-sm font-medium text-gray-700 dark:text-gray-100">
              {t('Active on Marketplace')}
            </label>
            <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t('Toggle to list your law firm on the marketplace.')}</p>
            </div>
            <Toggle
              enabled={formData.is_marketplace_active}
              onToggle={(value) => handleToggleChange('is_marketplace_active', value)}
              value={formData.is_marketplace_active}
            />
          </div>

          <Input
            label={t("Law Firm Email")}
            description={t('Add a contact email for client inquiries.')}
            name="LawFirmEmail"
            value={formData.LawFirmEmail}
            onChange={handleInputChange}
            type="email"
          />
          <Input
            label={t('Law Firm Office Address')}
            description={t('Specify your office location for client correspondence.')}
            name="LawFirmOfficeAddress"
            value={formData.LawFirmOfficeAddress}
            onChange={handleInputChange}
            type="text"
          />
          <Input
            label={t("Law Firm Phone Number")}
            description={t('Enter a phone number for direct communication.')}
            name="LawFirmPhoneNumber"
            value={formData.LawFirmPhoneNumber}
            onChange={handleInputChange}
            type="text"
          />
          <Textarea
            label={t("Description")}
            description={t('Write a brief overview of your law firm and its services.')}
            name="Description"
            value={formData.Description}
            onChange={handleInputChange}
            id="description"
          />
          <TagInput
            label={t("Practice Areas")}
            tags={formData.PracticeArea}
            onChange={(values) => handleTagChange('PracticeArea', values)}
            id="practiceAreas"
          />
          <Input
            label={t("Experience Years")}
            description={t('Mention the collective years of experience your firm has.')}
            name="ExperienceYears"
            value={formData.ExperienceYears}
            onChange={handleInputChange}
            type="number"
          />
          <Input
            label={t("Website")}
            description={t('Link to your law firm’s official website for more information.')}
            name="Website"
            value={formData.Website}
            onChange={handleInputChange}
            type="text"
          />
          <div className='flex justify-end'>
          <Button type="submit" variant="primary">
            {t('Save Profile')}
          </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default LawFirmProfile;
