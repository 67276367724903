import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import { getOngoingActivitiesApi, getActiveEntriesApi } from '../../store/actions/TimeManagementActions';
import Badge from '../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import ApiLoader from '../ApiLoader';

function formatDuration(durationInHours: string) {
  if (!durationInHours) return 'N/A';
  const totalMinutes = Math.round(parseFloat(durationInHours) * 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`;
}

function formatDateTime(dateStr: string) {
  if (!dateStr) return 'N/A';
  const d = new Date(dateStr);
  return d.toLocaleString(); // Adjust formatting as needed
}

interface Entry {
  entry_id: number;
  description?: string;
  start_time: string;
  end_time?: string;
  duration?: string; // changed to string if backend returns string
  status: string;
  task_id?: string;
  is_billable: boolean;
}

interface Props {
  type: 'ongoing' | 'active';
  setViewType: (type: 'ongoing' | 'active') => void;
  onRowClick: (entryId: number) => void;
  // Add props for filters if needed
  filters: any;
  sortOptions: any;
  selectedFilters: { [key: string]: string[] };
  onFilterChange: (filterName: string, selectedValues: string[]) => void;
  onSortChange: (value: string) => void;
  onSearchChange: (searchQuery: string) => void;
  sortValue?: string; 
  onClearAllFilters?: () => void;
}

const TimekeepingTable: React.FC<Props> = ({
  type,
  setViewType,
  onRowClick,
  filters,
  sortOptions,
  selectedFilters,
  onFilterChange,
  onSortChange,
  onSearchChange,
  sortValue,
  onClearAllFilters
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [entries, setEntries] = useState<Entry[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEntries = async () => {
      setLoading(true);
      let data = [];
      if (type === 'ongoing') {
        data = await dispatch(getOngoingActivitiesApi());
      } else {
        data = await dispatch(getActiveEntriesApi());
      }
      setEntries(data || []);
      setLoading(false);
    };
    fetchEntries();
  }, [dispatch, type]);

  const columns = ['Description', 'Start Time', 'End Time', 'Duration', 'Status', 'Tasks'];

  const titleMessage = type === 'ongoing'
    ? t('Ongoing Activities (These entries have no tasks and are not billable. Assign tasks to make them billable.)')
    : t('Active Entries (These timers are currently running. Stop them and assign tasks to log time.)');

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md">
      {/* Filter Bar */}
      <FilterSearchBar
        filters={filters}
        sortOptions={sortOptions}
        selectedFilters={selectedFilters}
        onFilterChange={onFilterChange}
        onSortChange={onSortChange}
        onSearchChange={onSearchChange}
        sortValue={sortValue}
        onClearAllFilters={onClearAllFilters}
      />

      <div className='flex justify-between items-center mb-4 mt-4'>
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
          {type === 'ongoing' ? t('Ongoing Activities') : t('Active Entries')}
        </h2>
        <Button
          onClick={() => setViewType(type === 'ongoing' ? 'active' : 'ongoing')}
          variant='tertiary'
        >
          {type === 'ongoing' ? t('Show Active Entries') : t('Show Ongoing Activities')}
        </Button>
      </div>

      <p className="mb-4 text-sm text-gray-600 dark:text-gray-300">{titleMessage}</p>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-100 dark:bg-gray-700">
            <tr>
              {columns.map((col) => (
                <th
                  key={col}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"
                >
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {loading ? (
              <tr>
                <td colSpan={columns.length} className="px-6 py-4 text-center text-gray-500">
              <ApiLoader />
                </td>
              </tr>
            ) : entries.length > 0 ? (
              entries.map((entry) => {
                return (
                  <tr
                    key={entry.entry_id}
                    className="hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                    onClick={() => onRowClick(entry.entry_id)}
                  >
                    <td className="px-6 py-4 text-sm text-gray-900 dark:text-gray-200">
                      {entry.description || t('No description')}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-300">
                      {formatDateTime(entry.start_time)}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-300">
                      {entry.end_time ? formatDateTime(entry.end_time) : t('In Progress')}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-300">
                      {formatDuration(entry.duration as string)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <Badge color={entry.status === 'Ongoing' ? 'yellow' : 'blue'}>
                        {entry.status}
                      </Badge>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-300">
                      {entry.task_id ? t('Has tasks assigned') : t('No tasks')}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className="px-6 py-4 text-center text-gray-500">
                  {type === 'ongoing' ? t('No ongoing activities found.') : t('No active entries found.')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimekeepingTable;
