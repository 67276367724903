export enum EngagementStatus {
    ProposalSubmitted = 'ProposalSubmitted',
    NegotiationOngoing = 'NegotiationOngoing',
    EngagementOngoing = 'EngagementOngoing',
    ProposalAccepted = 'ProposalAccepted',
    ProposalDeclined = 'ProposalDeclined',
    ProposalWithdrawn = 'ProposalWithdrawn',
    AccessRequested = 'AccessRequested',
    Invited = 'Invited',
    InvitationAccepted = 'InvitationAccepted',
    TermsSubmitted = 'TermsSubmitted',
    InvitationDeclined = 'InvitationDeclined',
    RequestWithdrawn = 'RequestWithdrawn',
    AccessDeclined = 'AccessDeclined',
    AccessApproved = 'AccessApproved',
  }
  