import React from "react";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { fetchDocumentPreview, signDocument } from "../../store/actions/documentSignActions";

interface DocumentPreviewProps {
  fileId: string;
  onClose: () => void;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ fileId, onClose }) => {
  const dispatch = useAppDispatch();

  // Our Redux slice now holds `fileUrl` (PDF URL) and `signers` instead of `htmlPreview`
  const { fileUrl, signers, status, signStatus, error } = useSelector(
    (state: RootState) => state.signdoc
  );

  const handleSignDocument = async () => {
    // Trigger sign
    await dispatch(signDocument(fileId));
    // Optionally re-fetch to see updated signers
    dispatch(fetchDocumentPreview(fileId));
  };

  if (status === "loading") {
    return <div className="p-4">Loading document...</div>;
  }
  if (status === "failed") {
    return <div className="p-4 text-red-500">Error: {error}</div>;
  }

  // If we have a PDF URL, we can embed it
  return (
    <div className="flex flex-col h-full w-full p-4">
      <div className="flex-1 overflow-auto border border-gray-300 p-2">
        {fileUrl ? (
          <embed
            src={fileUrl}
            type="application/pdf"
            className="w-full"
            style={{ height: "80vh" }}
          />
        ) : (
          <div>No file available.</div>
        )}
        
        {/* Signers list */}
        <div className="mt-4 border-t border-gray-400 pt-2">
          <h2 className="text-sm font-bold mb-2">Signatures:</h2>
          <div className="flex flex-row gap-4">
            {signers.map((signer: any) => (
              <div
                key={signer.UserID}
                className="relative bg-black text-white p-3 w-48 h-28 flex flex-col items-center justify-center"
              >
                <div className="absolute top-1 left-1 text-xs opacity-50">
                  LOGO
                </div>
                <div className="text-xs">Signed by:</div>
                <div className="font-bold">{signer.UserName}</div>
                <div className="mt-1 italic text-xs">[Signature image]</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-end gap-4">
        <button
          onClick={onClose}
          className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded"
        >
          Close
        </button>
        <button
          onClick={handleSignDocument}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
          disabled={signStatus === "loading"}
        >
          {signStatus === "loading" ? "Signing..." : "Sign Document"}
        </button>
      </div>
    </div>
  );
};

export default DocumentPreview;
