import { useContext, useEffect } from "react";
import ChatInterface from "../components/Chat/ChatInterface";
import { LayoutContext } from "../contexts/LayoutContext";
import { useAppDispatch } from "../store";
import { getAISettings } from "../store/actions/DataAction";

function AIAssistantPage() {
    const { setIsContentScrollable } = useContext(LayoutContext);
    const dispatch = useAppDispatch()

    useEffect(() => {
      setIsContentScrollable(true);
      dispatch(getAISettings())
    
        // Reset to default when unmounting
        return () => {
          setIsContentScrollable(true);
        };
      }, [setIsContentScrollable,dispatch]);
    

    return (
        <div className="h-[calc(100vh-90px)]">
            < ChatInterface />
        </div>
    )
}

export default AIAssistantPage;