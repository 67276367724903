
import { AnyAction, Dispatch } from 'redux';
import { SEND_FEEDBACK_REQUEST, SEND_FEEDBACK_SUCCESS, FETCH_FEEDBACK_TYPES_REQUEST, FETCH_FEEDBACK_TYPES_SUCCESS, RootState } from '../types';
import apis from '../../utils/apis';
import { showFeedbackModal } from './UserFeedbackActions';
import { ThunkDispatch } from 'redux-thunk';
import { handleApiError } from './utils/utils';

interface SendFeedbackParams {
  FeedbackContent: string;
  UserType: string;
  FeedbackTypeID: number;
}



export const fetchFeedbackTypes = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_FEEDBACK_TYPES_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "feedback/types"
    });
    if(response && response.status === 200){
      dispatch({
        type: FETCH_FEEDBACK_TYPES_SUCCESS,
        payload: response.data.feedback_types
      });

    }else{
      handleApiError(response, dispatch)
    }
  } catch (error) {
    handleApiError(error, dispatch)
  }
};

export const sendFeedback = ({ FeedbackContent, UserType, FeedbackTypeID }: SendFeedbackParams) =>
  async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: SEND_FEEDBACK_REQUEST });
    const dataToSend = { FeedbackContent, UserType, FeedbackTypeID };

    try {
      const response = await apis({
        method: 'POST',
        url: 'feedback/submit',
        data: dataToSend,
      });

      if (response.status === 200) {
        dispatch({ type: SEND_FEEDBACK_SUCCESS, payload: response.data });
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Feedback sent',
          modalType: 'success',
          duration: 3000
        }));

      } else {
        handleApiError(response, dispatch)
      }
    } catch (error) {
      handleApiError(error, dispatch)
    }
  };


