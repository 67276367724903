import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ReactQuill from 'react-quill';

import { useAppDispatch, RootState } from '../../store';
import { useSelector } from 'react-redux';
import {
  clearAnalysisData,
  fetchAnalysisProgress,
  runCaseAnalysis,
  fetchCaseAnalysisData
} from '../../store/actions/marketplace2/caseAnalysisActions';

import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { usePermission } from '../../hooks/userPermissions';
import { showModal, hideModal } from '../../store/actions/modalActions';
import { showExtractedContentSidePanel, showPublishCaseSidePanel } from '../../store/actions/sidePanelActions';
import { showFileReaderSidePanel } from '../../store/actions/sidePanelActions'; 

import TabStatusNav from '../shared/TailwindComponents/TabStatusNav';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import { fetchIndexes } from '../../store/actions/AIStudioAction';
import { getUserType, isBetaEnv } from '../../utils/authUtils';

import FinancialDataSection from './FinancialDataSection';
import Chronology from './LegalServices/Chronology/Chronology';
import Memo from './LegalServices/Memo/Memo';
import LegalOutline from './LegalServices/LegalOutline/LegalOutline';
import ProgressStepper from './ProgressStepper';

import {
  deleteCase,
  editCase,
  fetchCaseCycle,
  fetchCaseSummary,
  generateDocument,
  getCaseFinancialData
} from '../../store/actions/marketplace2/caseActions';

import { getChronology } from '../../store/actions/LegalServices/chronologyActions';
import { getCaseMemo } from '../../store/actions/LegalServices/memoActions';
import { getCaseLegalOutline } from '../../store/actions/LegalOutlineActions';
import ApiLoader from '../ApiLoader';
import { PiSparkleLight } from "react-icons/pi";
import CaseCycle from './Cycle/CaseCycle';
import LawyerFilesSection, { LawyerFile } from './LawyerFilesSection';
import { ProgressStep } from '../../store/reducer/marketplace2/caseAnalysisReducer';
interface RouteParams {
  caseId: string;
}

type AnalysisView = 'lawyer' | 'client';

// For potential ordering
const analysisStepsLaw = [
  { key: 'applicable_laws', label: 'Applicable Laws' },
  { key: 'relevant_cases', label: 'Relevant Cases' },
  { key: 'legal_analysis', label: 'Legal Analysis' },
  { key: 'risk_assessment', label: 'Risk Assessment' },
  { key: 'legal_strategy', label: 'Legal Strategy' },
];

const analysisStepsClient = [
  { key: 'applicable_laws', label: 'Applicable Laws' },
  { key: 'relevant_cases', label: 'Relevant Cases' },
  { key: 'interpretation', label: 'Interpretation' },
  { key: 'risk_assessment', label: 'Risk Assessment' },
  { key: 'recommended_strategy', label: 'Recommended Strategy' },
];


const CaseDetails: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { setBreadcrumbs } = useBreadcrumbs();
  const { hasAccess, isReadOnly } = usePermission('case_management');
  const { caseId } = useParams<RouteParams>();
  const userType = getUserType();

  const analysisState = useSelector((state: RootState) => state.caseAnalysis);
  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  // Main summary for the case
  const [summary, setSummary] = useState<any | null>(null);
  const [loadingSummary, setLoadingSummary] = useState<boolean>(true);

  // Grabs the case name from the summary (null if summary is not loaded yet)
  const caseName = summary?.CaseName || '';

  // Marketplace/Publish info
  const isPublished = summary?.IsPublishedToMarketplace;
  const isCaseAnalysisCompleted = summary?.clientAnalysisComplete ?? false;  // More local states
  const [chronology, setChronology] = useState<any[] | null>(null);
  const [generatedChronology, setGeneratedChronology] = useState<string>('');
  const [loadingChronology, setLoadingChronology] = useState<boolean>(false);

  const [memoContent, setMemoContent] = useState<string>('');
  const [loadingMemo, setLoadingMemo] = useState<boolean>(false);

  const [legalOutlineData, setLegalOutlineData] = useState<any>(null);
  const [loadingOutline, setLoadingOutline] = useState<boolean>(false);

  const [financialData, setFinancialData] = useState<any>({});
  const [loadingFinancial, setLoadingFinancial] = useState<boolean>(false);

  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);

  // Editing states
  const [isEditingAnalysis, setIsEditingAnalysis] = useState(false);
  const [editedAnalysisData, setEditedAnalysisData] = useState<any>({});

  const [isEditingCaseInfo, setIsEditingCaseInfo] = useState(false);
  const [editedCaseFields, setEditedCaseFields] = useState<any>({});

  // AI Studio indexes
  const indexes = useSelector((state: RootState) => state.aistudio.indexes);
  const [selectedApplicableLawsIndex, setSelectedApplicableLawsIndex] = useState<string>('');
  const [selectedRelevantCasesIndex, setSelectedRelevantCasesIndex] = useState<string>('');
  const [cycle, setCycle] = useState<any>({});

  // Tab selection
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'Case Information';
  const [activeTab, setActiveTab] = useState<string>(initialTab);

  const [selectedAnalysisView, setSelectedAnalysisView] = useState<AnalysisView>(
    userType.includes('Law') ? 'lawyer' : 'client'
  );

  const unifiedProgressSteps = analysisState.progressSteps; 


  const indexOptions = indexes.map((idx: any) => ({
    value: idx.NormalizedIndexName,
    label: idx.IndexName
  }));
  
  const indexOptionsWithNone = [
    { value: 'Default PONS Agent', label: t('Default PONS Agent') },
    ...indexOptions
  ];
  
  useEffect(() => {
    const currentTab = new URLSearchParams(location.search).get('tab') || 'Case Information';
    setActiveTab(currentTab);
  }, [location.search]);

  // ----------
  // Life cycle
  // ----------
  useEffect(() => {
    loadCaseSummary();
  
    const loadCycle = async () => {
      try {
        const cycleData = await dispatch(fetchCaseCycle(caseId));
        if (cycleData) {
          setCycle(cycleData);
        }
      } catch (error) {
        console.error('Failed to load cycle:', error);
      }
    };
  
    loadCycle();
    dispatch(fetchIndexes());

    // Clear analysis state & polling when leaving
    return () => {
      setBreadcrumbs([]);
      dispatch(clearAnalysisData());
      if (pollingRef.current) clearInterval(pollingRef.current);
    };
    // eslint-disable-next-line
  }, [caseId]);

  // Once `summary` is loaded or updated, set the breadcrumbs with the proper case name.
  useEffect(() => {
    if (summary?.CaseName) {
      setBreadcrumbs([
        { name: 'Cases', href: '/cases', current: false },
        { name: summary.CaseName, href: `/cases/${caseId}`, current: true },
      ]);
    } else {
      // If still not available, show a generic placeholder
      setBreadcrumbs([
        { name: 'Cases', href: '/cases', current: false },
        { name: t('Loading...'), href: `/cases/${caseId}`, current: true },
      ]);
    }
  }, [summary, caseId, setBreadcrumbs, t]);

  // Polling for analysis progress 
  useEffect(() => {
    if (analysisState.isRunning) {
      if (!pollingRef.current) startPolling();
    } else {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
        pollingRef.current = null;
      }
    }
  }, [analysisState.isRunning]);  

  const startPolling = () => {
    pollingRef.current = setInterval(() => {
      dispatch(fetchAnalysisProgress(caseId));
    }, 800);
  };

  // -----------------------------------
  // Data loader: fetchCaseSummary
  // -----------------------------------
  const loadCaseSummary = async () => {
    setLoadingSummary(true);
    try {
      const result: any = await dispatch(fetchCaseSummary(caseId));
      if (result && result.summary) {
        setSummary(result.summary);
        setEditedCaseFields(result.fields || {});
      }
    } catch (err) {
      console.error('Failed to load summary:', err);
    } finally {
      setLoadingSummary(false);
    }
  };

  // Add similar data loaders for chronology, memo, outline, financial, etc...
  const loadChronology = async () => {
    setLoadingChronology(true);
    try {
      const result: any = await dispatch(getChronology(caseId));
      if (result && result.Chronology) {
        setChronology(result.Chronology);
        setGeneratedChronology(result.GeneratedChronology || '');
      }
    } catch (err) {
      console.error('Failed to load chronology:', err);
    } finally {
      setLoadingChronology(false);
    }
  };

  const refreshChronology = async () => {
    await loadChronology();
  };

  const loadMemo = async () => {
    setLoadingMemo(true);
    try {
      const result: any = await dispatch(getCaseMemo(caseId));
      if (result && result.memo) {
        setMemoContent(result.memo);
      }
    } catch (err) {
      console.error('Failed to load memo:', err);
    } finally {
      setLoadingMemo(false);
    }
  };

  const loadLegalOutline = async () => {
    setLoadingOutline(true);
    try {
      const result: any = await dispatch(getCaseLegalOutline(caseId));
      if (result && result.LegalOutline) {
        setLegalOutlineData(result.LegalOutline);
      }
    } catch (err) {
      console.error('Failed to load outline:', err);
    } finally {
      setLoadingOutline(false);
    }
  };

  const loadFinancialData = async () => {
    setLoadingFinancial(true);
    try {
      const result: any = await dispatch(getCaseFinancialData(caseId));
      if (result && result.FinancialData) {
        setFinancialData(result.FinancialData);
      }
    } catch (err) {
      console.error('Failed to load financial data:', err);
    } finally {
      setLoadingFinancial(false);
    }
  };

  const loadExistingAnalysis = async () => {
    setAnalysisLoading(true);
    try {
      await dispatch(fetchCaseAnalysisData(caseId));
    } catch (err) {
      console.error('Failed to fetch existing analysis:', err);
    } finally {
      setAnalysisLoading(false);
    }
  };


  const getAnalysisStepsForUserType = (userType: string, view?: AnalysisView) => {
    if (userType.includes('Law')) {
      return view === 'client' ? analysisStepsClient : analysisStepsLaw;
    }
    return analysisStepsClient;
  }

  function getFilteredProgressSteps(
    steps: ProgressStep[],
    view: "client" | "lawyer"
  ): ProgressStep[] {
    if (view === "lawyer") {
      return steps.filter((step) => 
        [
          "caseanalysisstart",  
          "initial overview",
          "applicable_laws",
          "relevant_cases",
          "legal_analysis",
          "risk_assessment",
          "legal_strategy"
        ].includes(step.step.toLowerCase())
      );
    } else {
      return steps.filter((step) =>
        [
          "caseanalysisstart", 
          "initial overview",
          "applicable_laws",
          "relevant_cases",
          "interpretation",
          "risk_assessment",
          "recommended_strategy"
        ].includes(step.step.toLowerCase())
      );
    }
  }

  function normalizeAnalysisKeys(analysisData: any, view: AnalysisView): any {
    if (!analysisData) return {};
    
    if (view === 'lawyer') {
      return {
        applicable_laws: analysisData.lawyerAnalysis?.applicable_laws || "",
        relevant_cases: analysisData.lawyerAnalysis?.relevant_cases || "",
        risk_assessment: analysisData.lawyerAnalysis?.risk_assessment || "",
        legal_analysis: analysisData.lawyerAnalysis?.legal_analysis || "",
        legal_strategy: analysisData.lawyerAnalysis?.legal_strategy || "",
      };
    } else {
      return {
        applicable_laws: analysisData.clientAnalysis?.applicable_laws || "",
        relevant_cases: analysisData.clientAnalysis?.relevant_cases || "",
        risk_assessment: analysisData.clientAnalysis?.risk_assessment || "",
        interpretation: analysisData.clientAnalysis?.interpretation || "",
        recommended_strategy: analysisData.clientAnalysis?.recommended_strategy || "",
      };
    }
  }
  
  // When tab changes, load relevant data if not already loaded
  useEffect(() => {
    if (!summary) return;

    if (activeTab === 'Chronology' && chronology == null) {
      loadChronology();
    } else if (activeTab === 'Memo' && memoContent === '') {
      loadMemo();
    } else if (activeTab === 'Legal Outline' && !legalOutlineData) {
      loadLegalOutline();
    } else if (activeTab === 'Financial Data' && Object.keys(financialData).length === 0) {
      loadFinancialData();
    } else if (
      activeTab === 'Case Analysis' &&
      !analysisState.isRunning &&
      !analysisState.analysisData
    ) {
      loadExistingAnalysis();
    }
  }, [activeTab, summary]);

  // useEffect(() => {
  //   if (userType.includes('Law')) {
  //     // Attempt to find the actual "value" in the index options
  //     // that matches “PONS Law Agent” or “PONS Case Agent”
  //     const ponsLaw = indexOptions.find(opt => opt.label === 'PONS Law Agent');
  //     const ponsCase = indexOptions.find(opt => opt.label === 'PONS Case Agent');
  
  //     // If found, set them as the default
  //     setSelectedApplicableLawsIndex(ponsLaw?.value || 'PONS Law Agent');
  //     setSelectedRelevantCasesIndex(ponsCase?.value || 'PONS Case Agent');
  //   } else {
  //     // If not law => clear them or set them to empty
  //     setSelectedApplicableLawsIndex('');
  //     setSelectedRelevantCasesIndex('');
  //   }
  // }, [userType, indexOptions]);

  // ----------------------------
  // Deletion & Editing Handlers
  // ----------------------------
  const handleDeleteCase = () => {
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: t('Are you sure you want to delete this case?'),
        onConfirm: async () => {
          dispatch(hideModal());
          await dispatch(deleteCase(caseId));
          history.push('/cases');
        },
        onCancel: () => dispatch(hideModal())
      })
    );
  };

  const handleEditCaseInfo = () => setIsEditingCaseInfo(true);
  const handleCancelEditCaseInfo = () => {
    setIsEditingCaseInfo(false);
    if (summary?.fields) setEditedCaseFields(summary.fields);
  };
  const handleSaveCaseInfo = async () => {
    if (!summary) return;
    
    const updated = {
      CaseID: caseId,
      fields: editedCaseFields, 
    };
    await dispatch(editCase(updated));  
    await loadCaseSummary();  
    setIsEditingCaseInfo(false);
  };
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedCaseFields((prev: any) => ({ ...prev, [name]: value }));
  };

  // Analysis triggers
  const handleRunAnalysis = async () => {
    await dispatch(runCaseAnalysis(caseId));
    setActiveTab('Case Analysis');
    loadCaseSummary();
  };
  const handleRunAnalysisWithIndexes = async () => {
    const userIndexes: Record<string, string> = {};
    if (selectedApplicableLawsIndex) userIndexes['applicable_laws'] = selectedApplicableLawsIndex;
    if (selectedRelevantCasesIndex) userIndexes['relevant_cases'] = selectedRelevantCasesIndex;
    await dispatch(runCaseAnalysis(caseId, userIndexes));
    setActiveTab('Case Analysis');
    loadCaseSummary();
  };
  const handleGenerateDocument = () => {
    dispatch(generateDocument(caseId));
  };

  // Tabs to show
  const tabs = [{ label: 'Case Information', value: 'Case Information' }];
  if (userType.includes('Law')) {
    tabs.push(
      { label: 'Chronology', value: 'Chronology' },
      { label: 'Case Analysis', value: 'Case Analysis' },
      { label: 'Memo', value: 'Memo' }
    );
  } else {
    tabs.push({ label: 'Case Analysis', value: 'Case Analysis' });
  }
  if (userType.includes('Law')) {
    tabs.push({ label: 'Legal Outline', value: 'Legal Outline' });
    tabs.push({ label: 'Financial Data', value: 'Financial Data' });
    // Just an example of ensuring 'Financial Data' is last
    tabs.sort((a, b) => {
      if (a.label === 'Financial Data') return 1;
      if (b.label === 'Financial Data') return -1;
      return 0;
    });
  }

  const getTabStatuses = () => {
    // If summary is still loading, mark all as "Not Started"
    if (loadingSummary || !summary) {
      return tabs.map((tab) => ({ ...tab, status: "Not Started" }));
    }
  
    const statuses: any[] = [];
  
    // --------------------------------------
    // CASE INFORMATION
    // --------------------------------------
    const hasCaseID = summary.CaseID && summary.CaseID.trim() !== "";
    const caseInfoStatus = hasCaseID ? "Completed" : "Not Started";
    statuses.push({
      label: "Case Information",
      value: "Case Information",
      status: caseInfoStatus,
    });
  
    // --------------------------------------
    // CHRONOLOGY (only for lawyers)
    // --------------------------------------
    if (userType.includes("Law")) {
      const eventCount = summary.chronologyCount || 0;
      const hasEvents = eventCount > 0;
      const hasGenerated = summary.chronologyGenerated;
      let chronoStatus = "Not Started";
      if (hasEvents && !hasGenerated) {
        chronoStatus = "Pending";
      } else if (hasGenerated) {
        chronoStatus = "Completed";
      }
      statuses.push({
        label: "Chronology",
        value: "Chronology",
        status: chronoStatus,
      });
    }

  // --------------------------------------
// CASE ANALYSIS
// --------------------------------------
if (userType.includes("Law") && summary.IsMarketplaceCase) {
  const clientCompleted = Boolean(summary.clientAnalysisComplete);
  const lawyerCompleted = Boolean(summary.analysisCompleted);
  
  // If both analysis complete => Completed
  if (clientCompleted && lawyerCompleted) {
    statuses.push({
      label: "Case Analysis",
      value: "Case Analysis",
      status: "Completed",
    });
  }
  // If exactly one is complete => Pending
  else if (clientCompleted || lawyerCompleted || analysisState.isRunning) {
    statuses.push({
      label: "Case Analysis",
      value: "Case Analysis",
      status: "Pending",
    });
  }
  // Otherwise => Not Started
  else {
    statuses.push({
      label: "Case Analysis",
      value: "Case Analysis",
      status: "Not Started",
    });
  }
} else {
  // Not a marketplace case
  if (userType.includes("Law")) {
    // Lawyers on a normal (non-marketplace) case => use "analysisCompleted"
    const analysisStatus = summary.analysisCompleted
      ? "Completed"
      : analysisState.isRunning
      ? "Pending"
      : "Not Started";
    statuses.push({
      label: "Case Analysis",
      value: "Case Analysis",
      status: analysisStatus,
    });
  } else {
    // Clients on a normal (non-marketplace) case => use "clientAnalysisComplete"
    const analysisStatus = summary.clientAnalysisComplete
      ? "Completed"
      : analysisState.isRunning
      ? "Pending"
      : "Not Started";
    statuses.push({
      label: "Case Analysis",
      value: "Case Analysis",
      status: analysisStatus,
    });
  }
}
  
    // --------------------------------------
    // MEMO (for lawyers)
    // --------------------------------------
    if (userType.includes("Law")) {
      const memoStatus = summary.memoGenerated ? "Completed" : "Not Started";
      statuses.push({
        label: "Memo",
        value: "Memo",
        status: memoStatus,
      });
    }
  
    // --------------------------------------
    // LEGAL OUTLINE (for lawyers)
    // --------------------------------------
    if (userType.includes("Law")) {
      // Replace the false condition with your actual check if needed.
      const outlineStatus = false ? "Pending" : "Not Started";
      statuses.push({
        label: "Legal Outline",
        value: "Legal Outline",
        status: outlineStatus,
      });
    }
  
    // --------------------------------------
    // FINANCIAL DATA (for lawyers)
    // --------------------------------------
    if (userType.includes("Law")) {
      const hasFin = summary.FinancialData && Object.keys(summary.FinancialData).length > 0;
      const finStatus = hasFin ? "Completed" : "Not Started";
      statuses.push({
        label: "Financial Data",
        value: "Financial Data",
        status: finStatus,
        rightAligned: true,
      });
    }
  
    // --------------------------------------
    // CYCLE (for clients only)
    // --------------------------------------
    if (!userType.includes("Law")) {
      let cycleStatus = "Not Started";
      const progressPercent = summary.CaseCycleProgress || 0;
      if (progressPercent > 0 && progressPercent < 100) {
        cycleStatus = "Active";
      } else if (progressPercent >= 100) {
        cycleStatus = "Completed";
      }
      statuses.push({
        label: "Cycle",
        value: "Cycle",
        status: cycleStatus,
        rightAligned: true,
      });
    }
  
    return statuses;
  };

  const handleTabClick = (tabValue: string) => {
    setActiveTab(tabValue);
    queryParams.set('tab', tabValue);
    history.push({ search: queryParams.toString() });
  };


// --------------------------
// Render: Case Information
// --------------------------
const renderCaseInformation = () => {
  if (loadingSummary) {
    return <ApiLoader />;
  }

  if (!summary) {
    return <div>{t('No summary data available')}</div>;
  }

  // These fields show up on the left side of the top card
  const topFields: { label: string; value: any }[] = [
    { label: 'Case Type', value: summary.CaseType },
    { label: 'Status', value: summary.case_status },
    // omit Client Name for now
    {
      label: 'Created At',
      value: summary.created_at
        ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(summary.created_at))
        : '',
    },
    {
      label: 'Updated At',
      value: summary.updated_at
        ? new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(summary.updated_at))
        : '',
    },
  ];
  
  if (userType.includes('Law')) {
    topFields.push({ label: t('Client Name'), value: summary.ClientName });
  }

  if (summary.Categories && summary.Categories.length > 0) {
    topFields.push({ label: t('Categories'), value: summary.Categories.join(', ') });
  }

  const FIELD_LABELS: Record<string, string> = {
    AI_Description: 'Description',
    bulk_files: 'Files',
  };
  const SKIP_FIELDS = ['ClientUserType'];

  // Renders the field value
  const renderFieldValue = (key: string, val: any) => {
    // If editing and it's a plain string, use a Textarea.
    if (isEditingCaseInfo && typeof val === 'string') {
      return (
        <Textarea
          id={key}
          name={key}
          value={val}
          onChange={handleFieldChange}
          rows={4}
          label=""
        />
      );
    }
    // Check if val is an object with label and content.
    if (val && typeof val === 'object' && 'label' in val && 'content' in val) {
      return (
        <Button
          variant="tertiary"
          className='my-2'
          size='small'
          onClick={() =>
            dispatch(showExtractedContentSidePanel(val.label, val.content))
          }
        >
          {val.label}
        </Button>
      );
    }
    // If it's an array (e.g. bulk_files), render each item as a button.
    if (Array.isArray(val)) {
      return val.map((item, i) => {
        if (item && typeof item === 'object' && 'label' in item && 'content' in item) {
          return (
            <Button
              key={i}
              variant="tertiary"
              className='my-2'
              size='small'
              onClick={() =>
                dispatch(showExtractedContentSidePanel(item.label, item.content))
              }
            >
              {item.label}
            </Button>
          );
        }

        if (item && typeof item === 'object' && 'extracted_text' in item && 'filename' in item) {
          return (
            <Button
              key={i}
              variant="tertiary"
              className='my-2'
              size='small'
              onClick={() =>
                dispatch(showExtractedContentSidePanel(item.filename, item.extracted_text))
              }
            >
              {item.filename}
            </Button>
          );
        }
        return <p key={i}>{String(item)}</p>;
      });
    }

    // Fallback: render as plain text.
    return <p className="text-sm whitespace-pre-wrap">{String(val)}</p>;
  };

  // Identify the keys for description / files
  const descriptionKey = Object.keys(editedCaseFields).find((k) => k === 'AI_Description');
  const filesKey = Object.keys(editedCaseFields).find((k) => k === 'bulk_files');

  // Gather all the “other” fields besides AI_Description, bulk_files, and ClientUserType
  const otherFieldEntries = Object.entries(editedCaseFields).filter(([key]) => {
    if (SKIP_FIELDS.includes(key)) return false;
    if (key === 'AI_Description') return false;
    if (key === 'bulk_files') return false;
    return true;
  });

  return (
    <div className="mt-4 space-y-6">
      {/* CARD #1: Basic Info + Description in a 2-column layout */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        {/* Header row */}
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
            {t('Case Information')}
          </h3>

          {!isEditingCaseInfo && (
            <Button variant="tertiary" onClick={handleEditCaseInfo}>
              {t('Edit')}
            </Button>
          )}
        </div>

        {/* Two columns: Left => top fields, Right => description */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* LEFT COLUMN: top fields (Case Type, Status, ClientName, CreatedAt, UpdatedAt) */}
          <div className="space-y-4">
            {topFields.map(({ label, value }) => (
              <div key={label}>
                <p className="text-sm font-medium text-gray-500">{label}</p>
                {label === 'Status' ? (
                  <Badge color={value === 'open' ? 'green' : 'gray'}>{value}</Badge>
                ) : (
                  <p className="mt-1 text-sm text-gray-700 dark:text-gray-200">
                    {value || '-'}
                  </p>
                )}
              </div>
            ))}
          </div>

          {/* RIGHT COLUMN: Description (if we have AI_Description) */}
          <div className="md:border-l md:border-gray-200 md:dark:border-gray-700 md:pl-6">
            {descriptionKey ? (
              <>
                <p className="text-sm font-medium text-gray-500 mb-1">
                  {FIELD_LABELS[descriptionKey] || 'Description'}
                </p>
                {renderFieldValue(descriptionKey, editedCaseFields[descriptionKey])}
              </>
            ) : (
              <p className="text-sm text-gray-400 italic">
                {t('No description provided')}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* CARD #2: Client Files & Other Fields */}
      {(filesKey || otherFieldEntries.length > 0) && (
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              {filesKey && (
                <div className="mb-6">
                  <p className="text-sm font-medium text-gray-500 mb-1">
                    {FIELD_LABELS[filesKey] || 'Client Files'}
                  </p>
                  <div className="mt-1">
                    {renderFieldValue(filesKey, editedCaseFields[filesKey])}
                  </div>
                </div>
              )}
              {otherFieldEntries.map(([key, val]) => {
                const label = FIELD_LABELS[key] || key.replace(/_/g, ' ');
                return (
                  <div key={key} className="mb-6">
                    <p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
                    <div className="mt-1">{renderFieldValue(key, val)}</div>
                  </div>
                );
              })}
            </div>
          )}

      {/* NEW: Lawyer Files Section – visible only for lawyers */}
      {userType.includes('Law') && summary.IsMarketplaceCase === true && (
              <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
                <LawyerFilesSection
                  initialFiles={editedCaseFields.lawyer_files || []}
                  onFilesUpdate={(files: LawyerFile[]) =>
                    setEditedCaseFields((prev: any) => ({ ...prev, lawyer_files: files }))
                  }
                />
              </div>
            )}

      {/* If editing => show Save/Cancel at the bottom */}
      {isEditingCaseInfo && (
        <div className="flex items-center gap-4">
          <Button variant="primary" onClick={handleSaveCaseInfo}>
            {t('Save')}
          </Button>
          <Button variant="neutral" onClick={handleCancelEditCaseInfo}>
            {t('Cancel')}
          </Button>
        </div>
      )}
    </div>
  );
};

const handleEditAnalysis = () => {
  const view = userType.includes('Law') ? selectedAnalysisView : 'client';
  const normalized = normalizeAnalysisKeys(analysisState.analysisData, view);
  
  setEditedAnalysisData(normalized);
  setIsEditingAnalysis(true);
};

const handleCancelEditAnalysis = () => {
  const view = userType.includes('Law') ? selectedAnalysisView : 'client';
  const normalized = normalizeAnalysisKeys(analysisState.analysisData, view);
  
  setEditedAnalysisData(normalized);
  setIsEditingAnalysis(false);
};

const handleSaveAnalysis = async () => {
  const nestedUpdate = {
    lawyerCaseAnalysis: {
      applicable_laws: editedAnalysisData.applicable_laws,
      relevant_cases: editedAnalysisData.relevant_cases,
      legal_analysis: editedAnalysisData.legal_analysis,
      risk_assessment: editedAnalysisData.risk_assessment,
      legal_strategy: editedAnalysisData.legal_strategy
    }
  };
  setEditedAnalysisData(nestedUpdate)
  const updated = {
    CaseID: caseId,
    Analysis: editedAnalysisData,
  };
  await dispatch(editCase(updated));
  setIsEditingAnalysis(false);
};

// function normalizeAnalysisKeys(analysisObj: any, userType: string) {
//   if (!analysisObj) return {};

//   // If the API returns a namespaced object, choose the right one.
//   if (analysisObj.clientAnalysis && analysisObj.lawyerAnalysis) {
//     const chosen =
//       userType.includes('Law') ? analysisObj.lawyerAnalysis : analysisObj.clientAnalysis;
//     return {
//       applicable_laws: chosen.applicable_laws || "",
//       relevant_cases: chosen.relevant_cases || "",
//       legal_analysis: chosen.legal_analysis || "",
//       risk_assessment: chosen.risk_assessment || "",
//       legal_strategy: chosen.legal_strategy || "",
//       interpretation: chosen.interpretation || "",
//       recommended_strategy: chosen.recommended_strategy || "",
//     };
//   }

//   // Otherwise, fallback to the legacy structure.
//   return {
//     applicable_laws: analysisObj["ApplicableLaws"] || "",
//     relevant_cases: analysisObj["RelevantCases"] || "",
//     legal_analysis: analysisObj["LegalAnalysis"] || "",
//     risk_assessment: analysisObj["RiskAssessment"] || "",
//     legal_strategy: analysisObj["LegalStrategy"] || "",
//     interpretation: analysisObj["Interpretation"] || "",
//     recommended_strategy: analysisObj["RecommendedStrategy"] || "",
//   };
// }

const renderAnalysisToggle = () => {
  return (
    <div className="flex flex-col items-end my-4">
      <div className="inline-flex rounded overflow-hidden border border-gray-300">
        <button
          onClick={() => setSelectedAnalysisView('lawyer')}
          className={`px-6 py-2 text-sm font-medium transition-colors duration-300 ${
            selectedAnalysisView === 'lawyer'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-800 hover:bg-gray-100'
          }`}
        >
          {t('My Analysis')}
        </button>
        <button
          onClick={() => setSelectedAnalysisView('client')}
          className={`px-6 py-2 text-sm font-medium transition-colors duration-300 ${
            selectedAnalysisView === 'client'
              ? 'bg-blue-600 text-white'
              : 'bg-white text-gray-800 hover:bg-gray-100'
          }`}
        >
          {t('Client Analysis')}
        </button>
      </div>
      {selectedAnalysisView === 'lawyer' &&
        (!analysisState.analysisData?.lawyerAnalysis ||
          Object.keys(analysisState.analysisData.lawyerAnalysis).length === 0) && (
          <p className="mt-2 text-xs text-gray-500">
            {t('No My Analysis available. Please run your analysis.')}
          </p>
        )}
      {selectedAnalysisView === 'client' &&
        (!analysisState.analysisData?.clientAnalysis ||
          Object.keys(analysisState.analysisData.clientAnalysis).length === 0) && (
          <p className="mt-2 text-xs text-gray-500">
            {t('No Client Analysis available.')}
          </p>
        )}
    </div>
  );
};


// --------------------------------------------------------------------------
// Render the Case Analysis view
const renderCaseAnalysis = () => {
  if (analysisLoading && !analysisState.analysisData && !analysisState.isRunning) {
    return <ApiLoader />;
  }

  if (analysisState.isRunning) {
    const analysisView: "client" | "lawyer" = userType.includes("Law") ? selectedAnalysisView : "client";
    const filteredSteps = getFilteredProgressSteps(unifiedProgressSteps, analysisView);
    
    return (
      <div className="mt-4">
        <ProgressStepper steps={filteredSteps} />
      </div>
    );
  }
  
  if (analysisState.error) {
    return (
      <div className="flex flex-col items-center justify-center mt-8">
        <p className="text-red-500">{analysisState.error}</p>
        <Button onClick={handleRunAnalysis}>{t('Retry Analysis')}</Button>
      </div>
    );
  }

  if (analysisState.analysisData) {
    let normalizedDataObj: any = {};
    let stepsForUser: { key: string; label: string }[] = [];
    
    if (userType.includes("Law")) {
      // For lawyers, use the selected toggle value.
      normalizedDataObj = normalizeAnalysisKeys(analysisState.analysisData, selectedAnalysisView);
      stepsForUser = getAnalysisStepsForUserType(userType, selectedAnalysisView);
    } else {
      normalizedDataObj = normalizeAnalysisKeys(analysisState.analysisData, "client");
      stepsForUser = getAnalysisStepsForUserType(userType); // defaults to client analysis steps
    }
    
    const anyFieldNonEmpty = stepsForUser.some(({ key }) => normalizedDataObj[key]?.trim());
    
    return (
      <>
        {(userType.includes("Law") && summary.clientAnalysisComplete === true) && renderAnalysisToggle()}
        {anyFieldNonEmpty
          ? renderAnalysisWithData(normalizedDataObj, stepsForUser)
          : renderNoAnalysisFallback()}
      </>
    );
  }

  return renderNoAnalysisFallback();
};

// --------------------------------------------------------------------------
// Fallback view if no analysis has been run
const renderNoAnalysisFallback = () => {
  const isLawyer = userType.includes('Law');
  const isLawyerAndNoChrono = isLawyer && summary && !summary.chronologyGenerated;

  return (
    <div className="flex flex-col items-center justify-center mt-8 space-y-4">
      <Badge color="gray">{t('No analysis has been run for this case')}</Badge>
      {/* {isLawyer && renderAnalysisToggle()} */}
      {isLawyer ? (
        <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-light-sm dark:shadow-dark-sm w-full max-w-md space-y-4">
          <h3 className="text-lg font-heading">
            {t('Select Custom Agents (Optional)')}
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {t('You can select your custom AI Studio Agent for each step. If not selected, default PONS Agent is used.')}
          </p>
          <Dropdown
            label={t('Applicable Laws Agent')}
            options={indexOptionsWithNone}
            value={selectedApplicableLawsIndex}
            onChange={(val) => setSelectedApplicableLawsIndex(val as string)}
          />
          <Dropdown
            label={t('Relevant Cases Agent')}
            options={indexOptionsWithNone}
            value={selectedRelevantCasesIndex}
            onChange={(val) => setSelectedRelevantCasesIndex(val as string)}
          />
          {isLawyerAndNoChrono && (
            <div className="text-sm text-yellow-700 dark:text-yellow-300 bg-yellow-50 dark:bg-yellow-900 p-2 rounded">
              {t('No chronology is found. You can still proceed, but generating it is highly recommended.')}
            </div>
          )}
          <div className="flex justify-end space-x-2">
            <Button
              variant="primary"
              onClick={handleRunAnalysisWithIndexes}
              disabled={analysisState.isRunning}
            >
              <span>{t('Run Analysis')}</span>
              <PiSparkleLight size={20} className="ml-2" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="p-4 rounded w-full max-w-md space-y-4">
          <div className="flex justify-center space-x-2">
            <Button
              variant="primary"
              onClick={handleRunAnalysis}
              disabled={analysisState.isRunning}
            >
              <span>{t('Run Analysis')}</span>
              <PiSparkleLight size={20} className="ml-2" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

// --------------------------------------------------------------------------
// Renders the analysis data (read-only or in edit mode)
const renderAnalysisWithData = (dataObj: any, stepsForUser: { key: string; label: string }[]) => {
  const progressArray = summary?.progress || [];

  // Create an array of step objects in the correct order.
  const sortedSteps = stepsForUser.map((step) => {
    const stepProgress = progressArray.find((p: any) => p.step === step.key);
    return {
      ...step,
      content: dataObj[step.key] || '',
      status: stepProgress ? stepProgress.status : '',
    };
  });

  if (isEditingAnalysis) {
    return (
      <div className="mt-4 space-y-6">
        {sortedSteps.map(({ key, label, content }) => {
          const val = editedAnalysisData[key] || '';
          return (
            <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <h4 className="text-lg font-medium mb-2">{label}</h4>
              <ReactQuill
                value={val}
                onChange={(newContent) =>
                  setEditedAnalysisData((prev: any) => ({
                    ...prev,
                    [key]: newContent,
                  }))
                }
                theme="snow"
              />
            </div>
          );
        })}
        <div className="flex space-x-4">
          <Button variant="primary" onClick={handleSaveAnalysis}>
            {t('Save')}
          </Button>
          <Button variant="destructive" onClick={handleCancelEditAnalysis}>
            {t('Cancel')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4 space-y-6">
      {userType.includes('Law') && !isBetaEnv && (
        <div className="flex justify-end mt-4">
          <Button variant="tertiary" onClick={handleEditAnalysis}>
            {t('Edit Analysis')}
          </Button>
        </div>
      )}
      {sortedSteps.map(({ key, label, content }) => {
        const trimmed = content.trim();
        if (!trimmed) {
          return (
            <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <h4 className="text-lg font-medium mb-1">{label}</h4>
              <Badge color="gray">{t('No data')}</Badge>
            </div>
          );
        }
        return (
          <div key={key} className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h4 className="text-lg font-medium mb-1">{label}</h4>
            <div className="mt-2 text-sm prose dark:!prose-invert">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{trimmed}</ReactMarkdown>
            </div>
          </div>
        );
      })}
    </div>
  );
};

  const renderChronology = () => {
    if (loadingChronology) {
      return (
          <ApiLoader />
      );
    }
    return (
      <Chronology
        caseId={caseId}
        localChronologyData={chronology ?? []}
        generatedChronology={generatedChronology ?? ''}
        refreshChronology={refreshChronology}
        loadCaseSummary={loadCaseSummary}
      />
    );
  };

  const renderMemo = () => {
    // If analysis is not completed, block the memo
    if (userType.includes('Law') && !summary?.analysisCompleted) {
      return (
        <div className="mt-4 flex justify-center">
          <Badge color="gray">
            {t('Please complete the case analysis before accessing the Memo.')}
          </Badge>
        </div>
      );
    }
    if (loadingMemo) {
      return (
        <ApiLoader />
      );
    }
    return <Memo caseId={caseId} localMemo={memoContent} isAnalysisCompleted={summary.analysisCompleted} setIsRunning={setLoadingMemo} loadCaseSummary={loadCaseSummary} loadMemo={loadMemo} />;
  };

  const renderLegalOutline = () => {
    if (loadingOutline) {
      return (
        <ApiLoader />
      );
    }
    return (
      <LegalOutline
        caseId={caseId}
        onUpdate={() => loadCaseSummary()}
        legalOutlineData={legalOutlineData || {}}
      />
    );
  };

  const renderFinancialData = () => {
    if (loadingFinancial) {
      return (
        <ApiLoader />
      );
    }
    return <FinancialDataSection financialData={financialData} caseId={caseId} />;
  };


  if (loadingSummary && !summary) {
    return (
      <ApiLoader />
    );
  }

  return (
    <div className="h-[calc(100vh-90px)] relative bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300">
      {/* HEADER */}
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center">
          <button onClick={() => history.push('/cases')} className="mr-4">
            <ChevronLeftIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
          </button>
          <div>
            <h1 className="text-xl font-semibold">{caseName}</h1>
            {userType.includes('Law') ? (
              <p className="text-sm text-gray-500">
                {summary?.ClientName || t('No Client')}
              </p>
            ) : (
              summary.LawyerName !== "" ? (
                <p className="text-sm text-gray-500">
                {summary?.LawyerName || t('No Lawyer')}
                </p>
              ) : (
              <Badge color={isPublished ? 'green' : summary.case_status === "EngagementOngoing" ? 'blue' : 'gray'}>
                {isPublished ? t('Published') : summary.case_status === "EngagementOngoing" ? t('Active') : t('Not Published')}
              </Badge>
              )
            )}
          </div>
        </div>

        <div className="flex items-center gap-2">
            {!isBetaEnv && (
              <>
              {!userType.includes('Law') && !isPublished && summary.case_status !== "EngagementOngoing" && (
                isCaseAnalysisCompleted ? (
                  <Button
                  onClick={() => dispatch(showPublishCaseSidePanel(summary))}
                  variant="primary"
                  >
          {t('Publish Case')}
        </Button>
      ) : (
        <div className="flex flex-col items-end">
          <Button variant="primary" disabled>
            {t('Publish Case')}
          </Button>
          <p className="text-xs text-yellow-600 mt-1">
            {t('Please complete the case analysis before publishing.')}
          </p>
        </div>
      )
    )}
    </>
  ) }

      {summary.IsMarketplaceCase === true && userType.includes('Law') && (
                <Badge color='blue' tooltip={t('Case from Marketplace')}>{t('Marketplace')}</Badge>
              )}

          {/* Ellipsis Menu */}
          <Menu as="div" className="relative inline-block text-left ml-4">
            <Menu.Button className="flex items-center">
              <EllipsisVerticalIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="origin-top-right absolute right-0 mt-2 w-56 
                           rounded-md shadow-lg bg-white dark:bg-gray-800 
                           ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setActiveTab('Case Information');
                          handleEditCaseInfo();
                        }}
                        className={`${
                          active ? 'bg-gray-100 dark:bg-gray-700' : ''
                        } block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200`}
                      >
                        {t('Edit Case')}
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleDeleteCase}
                        className={`${
                          active ? 'bg-gray-100 dark:bg-gray-700' : ''
                        } block w-full text-left px-4 py-2 text-sm text-red-700 dark:text-red-400`}
                      >
                        {t('Delete Case')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      {/* TAB BAR */}
      {!analysisState.isRunning && (
        <div className="px-4">
          <TabStatusNav
            tabs={getTabStatuses()}
            activeTab={activeTab}
            onTabClick={handleTabClick}
          />
        </div>
      )}

      {/* TAB CONTENT */}
      <div className="px-4 pb-6">
        {activeTab === 'Case Information' && renderCaseInformation()}
        {activeTab === 'Chronology' && userType.includes('Law') && renderChronology()}
        {activeTab === 'Case Analysis' && renderCaseAnalysis()}
        {activeTab === 'Memo' && userType.includes('Law') && renderMemo()}
        {activeTab === 'Legal Outline' && userType.includes('Law') && renderLegalOutline()}
        {activeTab === 'Financial Data' && userType.includes('Law') && renderFinancialData()}
        {activeTab === 'Cycle' && !userType.includes('Law') && (
          <CaseCycle
            caseCycleHistory={cycle.CaseCycleHistory || []}
            cycleProgress={cycle.CaseCycleProgress || 0}
          />
        )}
      </div>
    </div>
  );
};

export default CaseDetails;