import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { getUserType } from '../../utils/authUtils';
import { BadgeColor, BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';
import AddCaseForm from './AddCaseForm';
import { usePermission } from '../../hooks/userPermissions';
import Badge from '../shared/TailwindComponents/Badge';

// Import your shared Table component
import Table from '../shared/TailwindComponents/Table';

// Tailwind Heroicons
import { Squares2X2Icon, TableCellsIcon } from '@heroicons/react/24/solid';
import Button from '../shared/TailwindComponents/Button';
import ApiLoader from '../ApiLoader';
import { PiGridFourLight, PiTableLight } from "react-icons/pi";

const Cases: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as any;

  // Redux store
  const allCases = useSelector((state: RootState) => state.casesM.cases || []);
  const userTypeName = getUserType();
  const totalClients = useSelector((state: RootState) => state.client.allClients.length);

  // Local state
  const [isCasesLoading, setIsCasesLoading] = useState(false); 
  const [isAddingCase, setIsAddingCase] = useState(locationState?.isAddingCase || false);

  const [viewMode, setViewMode] = useState<'grid' | 'table'>('table');

  // Search, filters, sort
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('creation_desc'); 

  // Debounce reference for search
  const searchDebounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // Scroll handling for sticky header
  const caseListRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const shrinkThreshold = 150;
  const isShrunk = scrollTop >= shrinkThreshold;

  // Permission example
  const { hasAccess, isReadOnly, loading } = usePermission('case_management');

  // If you pass clientId / clientUserType from location
  const clientIdFromLocation = locationState?.clientId;
  const clientUserTypeFromLocation = locationState?.clientUserType;

  useEffect(() => {
    // Clear previous search debounce if any
    if (searchDebounceTimeout.current) {
      clearTimeout(searchDebounceTimeout.current);
    }

    const performFetch = () => {
      setIsCasesLoading(true);

      const { sortBy, sortOrder } = getSortParams(sortValue);

      dispatch(
        fetchCases({
          searchTerm: searchQuery,
          filters: selectedFilters,
          sortBy,
          sortOrder,
        })
      ).finally(() => {
        setIsCasesLoading(false);
      });
    };

    // Debounce search to avoid spamming the server
    searchDebounceTimeout.current = setTimeout(performFetch, 300);

    return () => {
      if (searchDebounceTimeout.current) {
        clearTimeout(searchDebounceTimeout.current);
      }
    };
  }, [searchQuery, selectedFilters, sortValue, dispatch]);

  // Helper to map sortValue -> { sortBy, sortOrder }
  const getSortParams = (value: string) => {
    let sortBy = '';
    let sortOrder = 'asc';
    if (value.startsWith('creation')) {
      sortBy = 'creation';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('price')) {
      sortBy = 'price';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('last_interaction')) {
      sortBy = 'last_interaction';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    } else if (value.startsWith('case_cycle_progression')) {
      sortBy = 'case_cycle_progression';
      sortOrder = value.endsWith('desc') ? 'desc' : 'asc';
    }
    return { sortBy, sortOrder };
  };

  // Scroll event
  const handleScroll = () => {
    const scrollPosition = caseListRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    if (caseListRef.current) {
      caseListRef.current.addEventListener('scroll', handleScroll);
      return () => {
        caseListRef.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // FilterSearchBar callbacks
  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
    setSortOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        current: option.value === value,
      }))
    );
  };

  // ===== NEW: The function that toggles between Grid vs Table ====
  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'grid' ? 'table' : 'grid'));
  };

  // Handler for toggling the "Add Case" form
  const handleAddCaseClick = () => {
    setIsAddingCase(true);
  };
  const handleAddCaseClose = () => {
    setIsAddingCase(false);
  };

  const handleCaseClick = (caseItem: any) => {
    history.push(`/cases/${caseItem.CaseID}`);
  };

  // Define filters and sortOptions for the UI
  const filters = [
    {
      id: 'CaseStatus',
      name: 'Case Status',
      options: [
        { value: 'Draft', label: 'Draft' },
        { value: 'EngagementOngoing', label: 'Active' },
        { value: 'Completed', label: 'Completed' },
      ],
    },
  ];

  const [sortOptions, setSortOptions] = useState([
    { value: 'creation_asc', label: 'Creation (Oldest First)', current: true },
    { value: 'creation_desc', label: 'Creation (Newest First)', current: false },
    { value: 'price_asc', label: 'Price (Low to High)', current: false },
    { value: 'price_desc', label: 'Price (High to Low)', current: false },
    { value: 'last_interaction_asc', label: 'Last Interaction (Oldest First)', current: false },
    { value: 'last_interaction_desc', label: 'Last Interaction (Newest First)', current: false },
    // { value: 'case_cycle_progression_asc', label: 'Case Progression (Fewest Completed Steps)', current: false },
    // { value: 'case_cycle_progression_desc', label: 'Case Progression (Most Completed Steps)', current: false },
  ]);

  const statsData = [
    {
      name: 'Total Cases',
      stat: allCases.length.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
    {
      name: 'Total Clients',
      stat: totalClients.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
  ];
  const columns = useMemo(() => {
    if (userTypeName === 'IndividualClient') {
      return ['Case Name', 'Status', 'Created At', 'Lawyer Name'];
    } else {
      return ['Case Name', 'Status', 'Created At', 'Client Name'];
    }
  }, [userTypeName]);

  const handleClearAllFilters = () => {
    setSelectedFilters({});
    setSearchQuery('');
    setSortValue('creation_asc');
  };

  const tableData = useMemo(() => {
    const now = new Date();
    return allCases.map((caseItem: any) => {
      const createdDate = new Date(caseItem.created_at);
      const hoursDiff = (now.getTime() - createdDate.getTime()) / 3600000;
      const isNew = hoursDiff < 24;
      // Create a badge for the creation date
      const createdAtBadge = (
        <Badge color="purple">
          {new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }).format(createdDate)}
        </Badge>
      );
  
      return {
        id: caseItem.CaseID,
        'Case Name': (
          <span className="flex items-center gap-2">
            {caseItem.CaseName || `Case ${caseItem.CaseID}`}
            {isNew && <Badge color='green'>{t('New')}</Badge>}
          </span>
        ),
        'Status': (
            caseItem.case_status === 'EngagementOngoing' ? 'Active' : caseItem.case_status
        ),
        'Created At': createdAtBadge,
        // For lawyer views only, show the client's name (or "No Client" if not available)
        ...(userTypeName.includes('Law') && {
          'Client Name': caseItem.ClientName ? caseItem.ClientName : 'No Client',
        }),

        ...(!userTypeName.includes('Law') && {
          'Lawyer Name': caseItem.LawyerName ? caseItem.LawyerName : 'No Client',
        }),
      };
    });
  }, [allCases, t, userTypeName]);

  // For grid (card) view
  const gridData = useMemo(() => {
    return allCases.map((caseItem: any) => {
      const createdDate = new Date(caseItem.created_at);
      const isNew = (new Date().getTime() - createdDate.getTime()) / 3600000 < 24;
      const badges: BadgeItem[] = [];
  
      // Map status to badge: "Draft" = yellow; "EngagementOngoing" (display as Active) = blue; etc.
      let statusLabel = caseItem.case_status;
      let statusColor = 'gray';
      if (caseItem.case_status === 'Draft') {
        statusColor = 'yellow';
      } else if (caseItem.case_status === 'EngagementOngoing') {
        statusLabel = 'Active';
        statusColor = 'blue';
      } else if (caseItem.case_status === 'Completed') {
        statusColor = 'gray';
      } else if (caseItem.case_status === 'Overdue') {
        statusColor = 'red';
      } else if (caseItem.case_status === 'Closed') {
        statusColor = 'dark';
      }
      badges.push({ label: statusLabel, color: statusColor as BadgeColor });
  
      // Creation date badge
      badges.push({
        label: new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(createdDate),
        color: 'purple',
      });
  
      // For lawyer view, add a badge for the client name
      if (
        userTypeName === 'IndependentLawyer' ||
        userTypeName === 'LawFirmAdmin' ||
        userTypeName === 'LawFirmEmployee'
      ) {
        badges.push({
          label: caseItem.ClientName ? caseItem.ClientName : 'No Client',
          color: caseItem.ClientName ? 'green' : 'red',
        });
      }
  
      const details = [
        { label: 'Case Type', value: caseItem.CaseType },
        // { label: 'Created At', value: new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(createdDate) },
      ];
      if (caseItem.MarketplaceData?.Deadline) {
        details.push({
          label: 'Deadline',
          value: new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(caseItem.MarketplaceData.Deadline)),
        });
      }
  
      return (
        <ListItem
          key={caseItem.CaseID}
          title={caseItem.CaseName || `Case ${caseItem.CaseID}`}
          subtitle={caseItem.AnonymizedDescription || ''}
          details={details}
          badges={badges}
          onClick={() => handleCaseClick(caseItem)}
          link={`/cases/${caseItem.CaseID}`}
        />
      );
    });
  }, [allCases, t, userTypeName]);

  return (
    <div className="h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      <div
        className={`
          ${isShrunk && !isAddingCase ? 'sticky top-[80px] z-20' : ''}
          transition-all duration-200 bg-background-light dark:bg-background-dark
        `}
      >
        {!isAddingCase && (
          <Header
            title={isAddingCase ? t('Add New Case') : t('Cases')}
            subtitle={
              isAddingCase
                ? t('Fill in the details below to create a new case.')
                : t('Manage your cases and track their progress.')
            }
            actionLabel={isAddingCase ? t('Cancel Adding Case') : t('Add Case')}
            onActionClick={isAddingCase ? handleAddCaseClose : handleAddCaseClick}
            isAddingCase={isCasesLoading}
          />
        )}
        {!isAddingCase && (
          <FilterSearchBar
            filters={filters}
            sortOptions={sortOptions.map((opt) => ({
              ...opt,
              current: opt.value === sortValue,
            }))}
            selectedFilters={selectedFilters}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortChange}
            onSearchChange={handleSearchChange}
            sortValue={sortValue}
            onClearAllFilters={handleClearAllFilters}
            extraControls={
              <Button
                variant="tertiary"
                onClick={toggleViewMode}
                tooltip={viewMode === 'grid' ? t('Switch to Table View') : t('Switch to Grid View')}
              >
                {viewMode === 'grid' ? (
                  <PiTableLight className="h-5 w-5" />
                ) : (
                  <PiGridFourLight className="h-5 w-5" />
                )}
              </Button>
            }
          />
        )}
      </div>
      <div className="flex-grow overflow-y-auto h-full" ref={caseListRef}>
        {isAddingCase ? (
          <AddCaseForm
            setIsAddingCase={setIsAddingCase}
            onClose={handleAddCaseClose}
            clientId={clientIdFromLocation}
            clientUserType={clientUserTypeFromLocation}
            setIsCaseLoading={setIsCasesLoading}
          />
        ) : isCasesLoading ? (
          <ApiLoader />
        ) : viewMode === 'grid' ? (
          <div className="gap-4 p-4 grid grid-cols-2">{gridData}</div>
        ) : (
          <div className="p-4">
            {allCases.length > 0 ? (
              <Table
                columns={columns}
                data={tableData}
                onRowClick={(row) => history.push(`/cases/${row.id}`)}
              />
            ) : (
              <div className="flex justify-center items-center">
                <Badge color="gray">{t('No cases available')}</Badge>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cases;