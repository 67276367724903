import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import {
  fetchTranslationLanguages,
  translateDocument,
} from '../../store/actions/DocumentsAction';
import { hideModal } from '../../store/actions/modalActions';
import Dropdown from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import ApiLoader from '../ApiLoader';

interface TranslationModalProps {
  fileID: string | undefined;
}

const TranslationModal: React.FC<TranslationModalProps> = ({ fileID }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const translationLanguages = useSelector(
    (state: RootState) => state.documents.translationLanguages
  );
  const translationLoading = useSelector(
    (state: RootState) => state.documents.translationLoading
  );
  const translationError = useSelector(
    (state: RootState) => state.documents.translationError
  );

  const [selectedLanguageId, setSelectedLanguageId] = useState<string | string[]>('');

  useEffect(() => {
    dispatch(fetchTranslationLanguages());
  }, [dispatch]);

  const handleTranslate = () => {
    if (!selectedLanguageId) {
      alert(t('Please select a language to translate.'));
      return;
    }
    dispatch(translateDocument(fileID!, selectedLanguageId))
      .then((resp: any) => {
          dispatch(hideModal());
      })
  };

  return (
    <div className="bg-background-light dark:bg-background-dark p-6 rounded-lg max-w-md mx-auto">
      {translationLoading ? (
        <ApiLoader />
      ) : (
        <>
          {translationError && (
            <p className="text-danger-500 mb-4 text-center">{t(translationError)}</p>
          )}
          <div className="mb-4">
            <Dropdown
              options={translationLanguages.map((lang: any) => ({
                value: lang.TranslationCode,
                label: lang.Language,
              }))}
              placeholder={t('Select Language')}
              onChange={(value) => setSelectedLanguageId(value) }
            />
          </div>
          <div className="flex justify-end">
            <Button
              variant="primary"
              onClick={handleTranslate}
              disabled={!selectedLanguageId}
              className='w-full'
            >
              {t('Translate')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TranslationModal;
