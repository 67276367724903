import { FETCH_DOCUMENT_PREVIEW_FAILURE, FETCH_DOCUMENT_PREVIEW_REQUEST, FETCH_DOCUMENT_PREVIEW_SUCCESS, RESET_DOCUMENT_STATE, SIGN_DOCUMENT_FAILURE, SIGN_DOCUMENT_REQUEST, SIGN_DOCUMENT_SUCCESS } from "../types";

  
  const initialState = {
    fileUrl: "",
    signers: [],
    status: "idle",
    error: null,
    signStatus: "idle",
  };
  
  const documentSignReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case FETCH_DOCUMENT_PREVIEW_REQUEST:
        return { ...state, status: "loading", error: null };
  
      case FETCH_DOCUMENT_PREVIEW_SUCCESS:
        return {
          ...state,
          status: "succeeded",
          fileUrl: action.payload.fileUrl,
          signers: action.payload.signers || [],
        };
  
      case FETCH_DOCUMENT_PREVIEW_FAILURE:
        return { ...state, status: "failed", error: action.payload };
  
      case SIGN_DOCUMENT_REQUEST:
        return { ...state, signStatus: "loading", error: null };
  
      case SIGN_DOCUMENT_SUCCESS:
        return { ...state, signStatus: "succeeded" };
  
      case SIGN_DOCUMENT_FAILURE:
        return { ...state, signStatus: "failed", error: action.payload };
  
      case RESET_DOCUMENT_STATE:
        return { ...initialState };
  
      default:
        return state;
    }
  };
  
  export default documentSignReducer;
  