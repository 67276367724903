import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../store';
import { fetchTicket } from '../store/actions/HelpAction';
import ApiLoader from '../components/ApiLoader';
import TicketDetail from '../components/Feedback/TicketDetail';
import { useBreadcrumbs } from '../contexts/BreadcrumbsContext';

const TicketDetailPage: React.FC = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    const fetchTicketData = async () => {
      setIsLoading(true);
      try {
        const resp = await dispatch(fetchTicket(ticketId));
        const fetchedTicket = resp && resp.payload;
        if (fetchedTicket) {
          setTicket(fetchedTicket);
          // Set breadcrumbs
          setBreadcrumbs([
            { name: 'Support', href: '/support', current: false },
            { name: fetchedTicket.Subject, href: `/support/${ticketId}`, current: true },
          ]);
        } else {
          alert(t('Failed to fetch ticket details.'));
        }
      } catch (error) {
        console.error('Error fetching ticket details:', error);
        alert(t('An error occurred while fetching ticket details.'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchTicketData();

    // Cleanup breadcrumbs on unmount
    return () => {
      setBreadcrumbs([]);
    };
  }, [dispatch, ticketId, t, setBreadcrumbs]);

  if (isLoading) {
    return <ApiLoader />;
  }

  if (!ticket) {
    return <div>{t('Ticket not found.')}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <TicketDetail ticket={ticket} isLoading={false} />
    </div>
  );
};

export default TicketDetailPage;
