import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { fetchTaskDetails, updateTask, assignTask, completeTask, deleteTask } from '../../store/actions/TasksActions';
import { fetchProjects } from '../../store/actions/ProjectActions'; // For listing projects
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Tabs from '../shared/TailwindComponents/Tabs';
import { getUserType, getUserID } from '../../store/actions/ChatAction';
import dayjs from 'dayjs';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { fetchCaseById, fetchCases } from '../../store/actions/marketplace2/caseActions';
import ApiLoader from '../ApiLoader';

interface TaskDetailParams {
  taskId: string;
}

const TaskDetail: React.FC = () => {
  const { taskId } = useParams<TaskDetailParams>();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const userId = getUserID();
  const { setBreadcrumbs } = useBreadcrumbs();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const history = useHistory();

  const [taskDetails, setTaskDetails] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [projectDetails, setProjectDetails] = useState<any>(null);
  const caseItem = useSelector((state: RootState) => state.casesM.selectedCase);
  const [selectedCaseId, setSelectedCaseId] = useState<string>('');

  // For tabs
  const [activeTab, setActiveTab] = useState('details');

  // Lists for dropdowns

  const [projectOptions, setProjectOptions] = useState<DropdownOption[]>([]);
  
  const isAdmin = userType === 'LawFirmAdmin' || userType === 'BusinessAdmin';
  const isCreator = taskDetails && (taskDetails.UserID === userInfo.UserID && taskDetails.AssignedBy === userInfo.UserID);
  const canEdit = isAdmin || isCreator;
  const hasProjectAccess = userType === 'BusinessAdmin' || userType === 'BusinessEmployee';
  const cases = useSelector((state: RootState) => state.casesM.cases)
  
  const caseOptions: DropdownOption[] = cases.map((caseItem) => ({
    value: caseItem.CaseID,
    label: caseItem.Title || caseItem.CaseName || `Case ${caseItem.CaseID}`,
  }));

  const fetchDetails = useCallback(async () => {
    setLoading(true);
    const details = await dispatch(fetchTaskDetails(taskId));
    if (details) {
      setTaskDetails(details);
    }
    setLoading(false);
  }, [dispatch, taskId]);

  // Fetch case details if CaseID is present
  // const loadCaseDetails = useCallback(async () => {
  //   if (taskDetails?.CaseID) {
  //     const caseData = await dispatch(fetchCaseById(taskDetails.CaseID));
  //       setCaseDetails(caseData);
  //   } else {
  //     setCaseDetails(null);
  //   }
  // }, [dispatch, taskDetails]);

  // Fetch project details if ProjectID is present and user has project access
  const loadProjectDetails = useCallback(async () => {
    if (hasProjectAccess && taskDetails?.ProjectID) {
      // Assume we have a fetchProjectById action similar to fetchCaseById:
      // If not implemented, create an action similar to fetchCaseById
      const projectRes = await dispatch({type:'FETCH_PROJECT_DETAILS', projectId: taskDetails.ProjectID});
      // Implement a similar action in store/actions/ProjectActions if not present
      // projectRes should return { projectDetails: {...}}
      const projectFromStore = (projectRes as any)?.payload; 
      setProjectDetails(projectFromStore);
    } else {
      setProjectDetails(null);
    }
  }, [dispatch, taskDetails, hasProjectAccess]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  useEffect(() => {
    if (taskDetails) {
      setBreadcrumbs([
        { name: 'Tasks', href: '/tasks', current: false },
        { name: taskDetails.Title || 'Task Details', href: `/tasks/${taskId}`, current: true },
      ]);
      // loadCaseDetails();
      loadProjectDetails();
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [taskDetails, taskId, setBreadcrumbs, loadProjectDetails]);

  // Fetch all cases and projects for dropdown assignment if needed
  useEffect(() => {
    const fetchCaseList = async () => {
      const res = await dispatch(fetchCases());
    };
    const fetchProjectList = async () => {
      const res = await dispatch(fetchProjects());
      const projList = Object.values((res as any).payload || {});
      const formattedProjects = (projList as any[]).map((p: any) => ({
        value: p.ProjectID,
        label: p.ProjectName,
      }));
      setProjectOptions(formattedProjects);
    };

    // Always can have cases
    fetchCaseList();
    // Only fetch projects if user has project access
    if (hasProjectAccess) {
      fetchProjectList();
    }
  }, [dispatch, hasProjectAccess]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Title: taskDetails?.Title || '',
      Description: taskDetails?.Description || '',
      TaskDeadline: taskDetails?.TaskDeadline
        ? dayjs(taskDetails.TaskDeadline).toISOString()
        : '',
      CaseID: taskDetails?.CaseID || '',
      ProjectID: taskDetails?.ProjectID || '',
    },
    validationSchema: Yup.object({
      Title: Yup.string().required('Title is required'),
      Description: Yup.string().required('Description is required'),
      TaskDeadline: Yup.date().required('Deadline is required'),
    }),
    onSubmit: async (values) => {
      try {
        await dispatch(
          updateTask({
            TaskID: taskId,
            Title: values.Title,
            Description: values.Description,
            TaskDeadline: values.TaskDeadline,
            CaseID: values.CaseID || null,
            ProjectID: values.ProjectID || null,
          })
        );
        dispatch(showFeedbackModal({
          modalType: 'success',
          message: 'Task updated successfully.',
          showModal: true,
          duration: 3000
        }));
        // Refetch to update the displayed details
        fetchDetails();
      } catch (error) {
        console.error('Error updating task:', error);
      }
    },
  });

  const getStatusBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'green';
      case 'active':
        return 'blue';
      case 'pending':
        return 'yellow';
      case 'deleted':
        return 'red';
      default:
        return 'gray';
    }
  };

  const [reassignEmail, setReassignEmail] = useState('');
  
  const handleReassign = async () => {
    if (!reassignEmail.trim()) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Please enter a user email to assign the task.',
        modalType: 'error',
        duration: 3000
      }));
      return;
    }
    try {
      await dispatch(assignTask(taskId, [reassignEmail], formik.values.ProjectID, formik.values.CaseID));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Task reassigned successfully.',
        modalType: 'success',
        duration: 3000
      }));
      setReassignEmail('');
      fetchDetails();
    } catch (error) {
      console.error("Error reassigning task:", error);
    }
  };

  const handleComplete = async () => {
    try {
      await dispatch(completeTask(taskId));
      fetchDetails();
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteTask(taskId));
      // Refresh
      fetchDetails();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleChangeCase = async (option: DropdownOption) => {
    if (!canEdit) return;
    setSelectedCaseId(option.value)
    await dispatch(updateTask({TaskID: taskId, CaseID: option.value}));
    dispatch(showFeedbackModal({
      modalType: 'success',
      message: 'Case assigned successfully.',
      showModal: true,
      duration: 3000
    }));
    fetchDetails();
  };

  const handleChangeProject = async (option: DropdownOption) => {
    if (!canEdit) return;
    await dispatch(updateTask({TaskID: taskId, ProjectID: option.value}));
    dispatch(showFeedbackModal({
      modalType: 'success',
      message: 'Project assigned successfully.',
      showModal: true,
      duration: 3000
    }));
    fetchDetails();
  };

  if (loading || !taskDetails) {
    return (
        <ApiLoader />
    );
  }
  console.log(caseDetails)

  const tabs = [
    { label: 'Details', value: 'details' },
    { label: 'Case', value: 'case' },
    ...(hasProjectAccess ? [{ label: 'Project', value: 'project' }] : []),
  ];

  return (
    <div className="p-6 mx-2 bg-white dark:bg-gray-800 rounded-lg shadow transition-colors duration-300 flex flex-col gap-8">
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={(val) => setActiveTab(val)} />

      {activeTab === 'details' && (
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left/Main Section: Task Info and Edit Form */}
          <div className="flex-1 space-y-6">
            <h2 className="text-2xl font-bold mb-4">Task Details</h2>

              {/* Status and other info (non-editable) */}
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col space-y-2 mt-2">
                  <span className="font-medium text-gray-700 dark:text-gray-300">Status</span>
                  <Badge className='w-fit' color={getStatusBadgeColor(taskDetails.Status as string)}>
                    {taskDetails.Status}
                  </Badge>
                </div>

                <div className="flex flex-col space-y-2 mt-2">
                  <span className="font-medium text-gray-700 dark:text-gray-300">
                    Creation Date
                  </span>
                  <Badge className='w-fit' color='purple'>
                    {dayjs(taskDetails.CreationDate).format('MMMM D, YYYY h:mm A')}
                  </Badge>
                </div>

                <div className="flex flex-col space-y-2 mt-2">
                  <span className="font-medium text-gray-700 dark:text-gray-300">
                    Assigned By
                  </span>
                  <Badge className='w-fit' color="indigo">{taskDetails.AssignedByUserType}</Badge>
                </div>

                <div className="flex flex-col space-y-2 mt-2">
                  <span className="font-medium text-gray-700 dark:text-gray-300">
                    Modification Date
                  </span>
                  <Badge className='w-fit' color='pink'>
                    {dayjs(taskDetails.ModificationDate).format('MMMM D, YYYY h:mm A')}
                  </Badge>
                </div>
              </div>
            <form onSubmit={formik.handleSubmit} className="space-y-6">
              {/* Task Title */}
              <Input
                label="Title"
                name="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                disabled={!canEdit}
                placeholder="Enter task title..."
                type="text"
              />
              {formik.touched.Title && formik.errors.Title && (
                <p className="text-sm text-red-600">{formik.errors.Title as string}</p>
              )}

              {/* Task Description */}
              <Textarea
                label="Description"
                name="Description"
                value={formik.values.Description}
                onChange={formik.handleChange}
                disabled={!canEdit}
                placeholder="Enter task description..."
                id="Description"
              />
              {formik.touched.Description && formik.errors.Description && (
                <p className="text-sm text-red-600">{formik.errors.Description as string}</p>
              )}

              {/* Task Deadline */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Deadline
                </label>
                <CustomDateTimePicker
                  value={formik.values.TaskDeadline ? dayjs(formik.values.TaskDeadline) : null}
                  onChange={(newValue: dayjs.Dayjs | null) => {
                    formik.setFieldValue('TaskDeadline', newValue ? newValue.toISOString() : '');
                  }}
                  minDateTime={dayjs()}
                  // disabled={!canEdit}
                />
                {formik.touched.TaskDeadline && formik.errors.TaskDeadline && (
                  <p className="mt-2 text-sm text-red-600">{formik.errors.TaskDeadline as string}</p>
                )}
              </div>

              {canEdit && (
                <div className="flex justify-end space-x-4 pt-4">
                  <Button type="submit" variant="primary">
                    Update Task Details
                  </Button>
                </div>
              )}
            </form>
          </div>

          {/* Right/Secondary Section: Management Actions (Admin/Creator actions) */}
          <div className="w-full lg:w-1/3 bg-gray-50 dark:bg-gray-700 rounded-md p-4 flex flex-col space-y-6 h-fit">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">Actions</h3>

            {isAdmin && (
              <div className="space-y-4">
                <h4 className="text-md font-semibold text-gray-800 dark:text-gray-200">
                  Reassign Task
                </h4>
                <Input
                  label="User Email"
                  name="ReassignEmail"
                  value={reassignEmail}
                  onChange={(e) => setReassignEmail(e.target.value)}
                  placeholder="Enter new assignee email"
                  type="email"
                  className="mb-2"
                />
                <Button variant="primary" disabled onClick={handleReassign}>Assign Task</Button>
                <p className="text-sm text-gray-500 dark:text-gray-300">
                  Assigning the task will create/update a task record for that user.  
                </p>
              </div>
            )}

            {/* Complete Task (if allowed and not already completed/deleted) */}
            {taskDetails.Status === 'Active' && (
              <Button variant="success" onClick={handleComplete}>
                Complete Task
              </Button>
            )}

            {/* Delete Task (if allowed) */}
            {(isAdmin || isCreator) && taskDetails.Status !== 'Deleted' && (
              <Button variant="destructive" onClick={handleDelete}>
                Delete Task
              </Button>
            )}

            {!(isAdmin || isCreator) && (
              <p className="text-sm text-gray-500 dark:text-gray-300">
                You do not have permission to reassign, complete, or delete this task.
              </p>
            )}
          </div>
        </div>
      )}

      {activeTab === 'case' && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Case Information</h2>
          {taskDetails.CaseID ? (
            caseDetails ? (
              <div className="space-y-4">
                <p>
                  <strong>Case Name:</strong> {caseDetails.CaseName}
                </p>
                <p>
                  <strong>Case Type:</strong> {caseDetails.CaseType}
                </p>
                <Button variant="primary" onClick={() => history.push(`/cases/${taskDetails.CaseID}`)}>
                  Navigate to Case
                </Button>
                {canEdit && (
                  <div className="mt-4">
                    <h4 className="font-semibold mb-2">Change Case</h4>
                    <Dropdown
                      label="Select a Case"
                      options={caseOptions}
                      onSelect={handleChangeCase}
                      value={selectedCaseId}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>Case Details coming soon...</div>
            )
          ) : (
            <div>
              <p>No case assigned to this task.</p>
              {canEdit && (
                <>
                  <p className="mt-2">Assign a case:</p>
                  <Dropdown
                    options={caseOptions}
                    placeholder="Select new a case"
                    onSelect={handleChangeCase}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'project' && hasProjectAccess && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Project Information</h2>
          {taskDetails.ProjectID ? (
            projectDetails ? (
              <div className="space-y-4">
                <p>
                  <strong>Project Name:</strong> {projectDetails.ProjectName}
                </p>
                <p>
                  <strong>Priority:</strong> {projectDetails.PriorityName}
                </p>
                <p>
                  <strong>Category:</strong> {projectDetails.CategoryName}
                </p>
                <Button variant="primary" onClick={() => history.push(`/projects/${taskDetails.ProjectID}`)}>
                  Navigate to Project
                </Button>
                {canEdit && (
                  <div className="mt-4">
                    <h4 className="font-semibold mb-2">Change Project</h4>
                    <Dropdown
                      options={projectOptions}
                      placeholder="Select a new project"
                      onSelect={handleChangeProject}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>Loading project details...</div>
            )
          ) : (
            <div>
              <p>No project assigned to this task.</p>
              {canEdit && (
                <>
                  <p className="mt-2">Assign a project:</p>
                  <Dropdown
                    options={projectOptions}
                    placeholder="Select a project"
                    onSelect={handleChangeProject}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskDetail;
