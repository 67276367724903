import React, { useState } from 'react';
import { useAppDispatch } from '../../../store';
import { requestCaseAccess } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { PiCheckCircleLight } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

interface RequestAccessProps {
  caseId: string;
}

const RequestAccessComponent: React.FC<RequestAccessProps> = ({ caseId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleRequestAccess = async () => {
    setLoading(true);
    await dispatch(requestCaseAccess({ CaseID: caseId, Message: message }));
    setLoading(false);
    setSuccess(true);
    setMessage('');
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <CircularProgress size={40} />
        <p className="mt-4 text-base font-semibold dark:text-white text-gray-900">
          {t('Submitting your request...')}
        </p>
      </div>
    );
  }

  if (success) {
    return (
      <div className="flex flex-col items-center justify-center h-full animate-fade-in-up">
        <PiCheckCircleLight className="text-green-500" style={{ fontSize: 40 }} />
        <p className="mt-4 text-base font-semibold dark:text-white text-gray-900">
          {t('Request Sent!')}
        </p>
      </div>
    );
  }

  return (
    <div className="h-full bg-gray-100 dark:bg-gray-800 p-6 rounded-md shadow-md animate-fade-in-up flex flex-col justify-between">
      <div>
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
        {t('Request Access')}
      </h2>
      <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">
        {t(
          "Requesting access to this case is non-committal. Once approved, you'll be able to view the client's detailed case analysis. You may then choose to place a bid, but no commitment is made by simply requesting access."
        )}
      </p>
      </div>
      <div className='h-full flex flex-col justify-between'>
      <Textarea
        rows={5}
        placeholder={t('Add a request message (optional)')}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        id="12385"
        h='h-56'
        label="Message"
      />
      <Button
        onClick={handleRequestAccess}
        disabled={loading}
        variant="primary"
        className="w-full mt-2"
      >
        {loading ? t('Requesting...') : t('Request Access')}
      </Button>
      </div>
    </div>
  );
};

export default RequestAccessComponent;
