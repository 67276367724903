// src/components/shared/TailwindComponents/LawyerFilesSection.tsx
import React, { useState } from 'react';
import { FiPlus, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import TextOrFileInput from '../shared/TailwindComponents/TextOrFileInput';
import { showExtractedContentSidePanel } from '../../store/actions/sidePanelActions';
import { useAppDispatch } from '../../store';

export interface LawyerFile {
  label: string;
  content: string;
}

interface LawyerFilesSectionProps {
  initialFiles: LawyerFile[];
  onFilesUpdate: (files: LawyerFile[]) => void;
}

const LawyerFilesSection: React.FC<LawyerFilesSectionProps> = ({ initialFiles, onFilesUpdate }) => {
  const { t } = useTranslation();
  const [lawyerFiles, setLawyerFiles] = useState<LawyerFile[]>(initialFiles || []);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');
  const [inputFiles, setInputFiles] = useState<File[]>([]);
  const dispatch = useAppDispatch();
  // Toggle the input area for adding a new file/text entry.
  const handleToggleInput = () => {
    setShowInput(!showInput);
    if (showInput) {
      // Reset inputs if the form is closed.
      setInputText('');
      setInputFiles([]);
    }
  };

  // Update the text value.
  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  };

  // Update the file input state.
  const handleFilesChange = (files: File[]) => {
    setInputFiles(files);
  };

  // Helper: Read a file as text using FileReader.
  const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);
      reader.readAsText(file);
    });
  };

  // Handle the addition of new lawyer file(s).
  const handleAddFile = async () => {
    let newEntries: LawyerFile[] = [];

    // If one or more files were uploaded, extract their content.
    if (inputFiles.length > 0) {
      try {
        const fileEntries = await Promise.all(
          inputFiles.map(async (file) => ({
            label: file.name,
            content: await readFileAsText(file)
          }))
        );
        newEntries = fileEntries;
      } catch (error) {
        console.error("Error reading files", error);
        return;
      }
    } else if (inputText.trim() !== '') {
      // Otherwise, if text was entered, create an entry from the text.
      newEntries.push({
        label: t('Text Input'),
        content: inputText.trim()
      });
    }

    if (newEntries.length > 0) {
      const updatedFiles = [...lawyerFiles, ...newEntries];
      setLawyerFiles(updatedFiles);
      onFilesUpdate(updatedFiles);
      // Reset the input state and hide the input form.
      setInputText('');
      setInputFiles([]);
      setShowInput(false);
    }
  };

  const handleFileDisplay = (label: string, content: string) => {
    dispatch(showExtractedContentSidePanel(label, content))
  }

  return (
    <div className="mt-6">
      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
        {t('Lawyer Files')}
      </h3>
      <div className="mt-4">
        {lawyerFiles && lawyerFiles.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {lawyerFiles.map((file, index) => (
              <Button key={index} variant="tertiary" size="small" onClick={() => handleFileDisplay(file.label, file.content)}>
                {file.label}
              </Button>
            ))}
          </div>
        ) : (
          <p className="text-sm text-gray-500">{t('No lawyer files added yet.')}</p>
        )}
      </div>
      <div className="mt-4">
        <button onClick={handleToggleInput} className="text-gray-400 hover:text-gray-600 transition">
          {showInput ? <FiX className="inline mr-2" /> : <FiPlus className="inline mr-2" />}
        </button>
      </div>
      {showInput && (
        <div className="mt-4">
          <TextOrFileInput
            id="lawyer_files_input"
            label={t('Lawyer File')}
            description={t('Enter text or upload a file for the lawyer file')}
            value={inputText}
            files={inputFiles}  // Pass the actual file objects
            onTextChange={handleTextChange}
            onFilesChange={handleFilesChange}
          />
          <div className="mt-2">
            <Button variant="primary" onClick={handleAddFile}>
              {t('Save File')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LawyerFilesSection;
