import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useOptionalBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { Tab } from '@headlessui/react';
import { showFileReaderSidePanel } from '../../../store/actions/sidePanelActions';
import Button from '../../shared/TailwindComponents/Button';
import { getUserType } from '../../../utils/authUtils';
import { getLawFirmProfile } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import ApiLoader from '../../ApiLoader';

interface RouteParams {
  firmId: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface LawFirmProfileComponentProps {
  lawFirmProfile?: any; // Adjust type as per your LawFirmProfile type
}

const LawFirmProfileComponent: React.FC<LawFirmProfileComponentProps> = ({ lawFirmProfile: propLawFirmProfile }) => {
  const { firmId } = useParams<RouteParams>();
  const location = useLocation();
  const breadcrumbsContext = useOptionalBreadcrumbs();
  const setBreadcrumbs = breadcrumbsContext ? breadcrumbsContext.setBreadcrumbs : undefined;
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const [lawFirmProfile, setLawFirmProfile] = useState<any>(
    propLawFirmProfile || (location.state as any)?.lawFirm || null
  );
  const [loading, setLoading] = useState(false);
  const { PracticeArea } = lawFirmProfile;
const practiceAreas = PracticeArea
  ? Array.isArray(PracticeArea)
    ? PracticeArea
    : PracticeArea.split(',')
  : [];

  console.log(propLawFirmProfile)
  // const error = useSelector((state: RootState) => state.marketplaceReceiver.error);

  const fetchDetails = useCallback(async () => {
    if (!propLawFirmProfile) {
      setLoading(true);
      const profile = await dispatch(getLawFirmProfile());
      setLawFirmProfile(profile);
      setLoading(false);
    }
  }, [dispatch, propLawFirmProfile]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  useEffect(() => {
    if (lawFirmProfile && !userType.includes('Law') && setBreadcrumbs) {
      setBreadcrumbs([
        { name: 'Marketplace', href: '/marketplace', current: false },
        { name: lawFirmProfile.OrganizationName, href: `/marketplace/lawfirm/${firmId}`, current: true },
      ]);
    }
    return () => {
      if (setBreadcrumbs) {
        setBreadcrumbs([]);
      }
    };
  }, [lawFirmProfile, firmId, setBreadcrumbs, userType]);

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  if (loading || !lawFirmProfile) {
    return (
      <ApiLoader />
    );
  }

  const {
    Description,
    ExperienceYears,
    Website,
    LawFirmEmail,
    LawFirmOfficeAddress,
    LawFirmPhoneNumber,
    Logo,
    TermsOfServiceURL,
  } = lawFirmProfile;

  const tabs = [
    { label: 'Services', value: 'Services' },
    { label: 'Contact', value: 'Contact' },
    // Add more tabs as needed
  ];

  return (
    <div className="bg-white dark:bg-gray-800 mx-4 rounded shadow-md dark:shadow-dark-md">
      <div className="mx-auto px-4 py-10 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
        {/* Profile */}
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 xl:gap-x-16">
          {/* Logo */}
          <div className="lg:col-span-4 lg:row-end-1">
            {Logo ? (
              <img
                alt={lawFirmProfile.OrganizationName}
                src={Logo}
                className="aspect-[1] w-40 rounded-lg bg-primary-50 object-cover"
              />
            ) : (
              <div className="aspect-[1] w-40 rounded-lg bg-primary-50 flex items-center justify-center">
                <span className="text-4xl text-sky-300">
                  {lawFirmProfile.OrganizationName?.charAt(0)}
                </span>
              </div>
            )}
          </div>

          {/* Profile details */}
          <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  {lawFirmProfile.OrganizationName}
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  {ExperienceYears
                    ? `${ExperienceYears} years in operation`
                    : 'Experience not specified'}
                </p>
              </div>
            </div>

            <p className="mt-6 text-gray-500">{Description || 'No description available'}</p>

            {/* <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
              {/* Add any buttons if needed 
              {TermsOfServiceURL && (
                <Button
                  onClick={() => dispatch(showFileReaderSidePanel(TermsOfServiceURL))}
                  variant="tertiary"
                  size="medium"
                >
                  Terms of Service
                </Button>
              )}
            </div> */}

            {/* Practice Areas */}
            {practiceAreas.length > 0 && (
                    <div className="mt-10">
                        <h3 className="text-sm font-medium text-gray-900">Practice Areas</h3>
                        <div className="mt-4">
                        <ul className="list-disc space-y-1 pl-5 text-sm text-gray-500">
                            {practiceAreas.map((area: string) => (
                            <li key={area}>{area}</li>
                            ))}
                        </ul>
                        </div>
                    </div>
                    )}
          </div>

          {/* Tabs Section */}
          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
            <Tab.Group>
              <div className="border-b border-gray-200">
                <Tab.List className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? 'border-primary-600 text-primary-600'
                            : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300',
                          'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                        )
                      }
                    >
                      {tab.label}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                {/* Services Tab */}
                <Tab.Panel className="text-sm text-gray-500">
                  {/* Display services or other relevant information */}
                  <p>No services information available.</p>
                </Tab.Panel>
                {/* Contact Tab */}
                <Tab.Panel className="text-sm text-gray-500">
                  <h3 className="font-medium text-gray-900">Contact Information</h3>
                  <p className="mt-2">Email: {LawFirmEmail || 'Not provided'}</p>
                  <p>Phone: {LawFirmPhoneNumber || 'Not provided'}</p>
                  <p>Address: {LawFirmOfficeAddress || 'Not provided'}</p>
                  <p>
                    Website:{' '}
                    {Website ? (
                      <a
                        href={Website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {Website}
                      </a>
                    ) : (
                      'Not provided'
                    )}
                  </p>
                </Tab.Panel>
                {/* Add more tabs and panels as needed */}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawFirmProfileComponent;
