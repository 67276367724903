import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Button from '../../../shared/TailwindComponents/Button';
import Badge from '../../../shared/TailwindComponents/Badge';
import { useAppDispatch } from '../../../../store';
import { showChronologyFileContentSidePanel, showSidePanel } from '../../../../store/actions/sidePanelActions';
import { deleteChronologyEvent, generateChronology } from '../../../../store/actions/LegalServices/chronologyActions';
import { useTranslation } from 'react-i18next';
import ApiLoader from '../../../ApiLoader';
import { hideModal, showModal } from '../../../../store/actions/modalActions';
import { PiFilesLight, PiPlus, PiPlusLight, PiSparkleLight, PiTrashLight } from "react-icons/pi";
import { Tooltip } from '@mui/material';

interface ChronologyEvent {
  date: string;
  description: string;
  file_content?: string;
}
interface ChronologyProps {
  caseId: string;
  localChronologyData: ChronologyEvent[]; // or any[]
  generatedChronology: string; // Pass the generated summary explicitly
  refreshChronology?: () => void;
  loadCaseSummary: () => void;
}

const Chronology: React.FC<ChronologyProps> = ({ caseId, localChronologyData, generatedChronology, refreshChronology, loadCaseSummary }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [chronology, setChronology] = useState<ChronologyEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // On mount or if props change
  useEffect(() => {
    setChronology(localChronologyData);
  }, [localChronologyData]);

  const handleViewFileContent = (fileContent: string) => {
    dispatch(showChronologyFileContentSidePanel(fileContent));
  };

  const handleOpenAddEventSidePanel = () => {
    dispatch(showSidePanel({
      type: 'addChronologyEvent',
      showSidePanel: true,
      width: '50%',
      onConfirm: () => {},
      onCancel: () => {},
      caseId: caseId,
      onSuccess: refreshChronology
    }));
  };

  const handleGenerateChronology = async () => {
    if (chronology.length > 0) {
      setIsLoading(true);
      await dispatch(generateChronology(caseId));
      if (refreshChronology) {
        refreshChronology();
      }
      loadCaseSummary();
      setIsLoading(false);
    }
  };

  const handleDeleteEvent = (index: number) => {
    dispatch(showModal({
      showModal: true,
      type: 'confirmation',
      message: t('Are you sure you want to delete this event?'),
      onConfirm: async () => {
        await dispatch(deleteChronologyEvent(caseId, index));
  
        if (refreshChronology) {
          refreshChronology();
        }
  
        dispatch(hideModal());
      },
    }));
  };
  

  if (isLoading) {
    return (
      <ApiLoader />
    );
  }

  return (
    <div className="mt-4 space-y-6">
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">{t('Chronology of Events')}</h3>
          <Button variant="primary" size='small' onClick={handleOpenAddEventSidePanel}>
            <span>{t('Add Event')}</span>
            <PiPlusLight size={20} className='ml-2' />
          </Button>
        </div>

        {chronology.length > 0 ? (
          <div className="space-y-4">
            {chronology.map((event, index) => (
              <div key={index} className="border-b pb-4 relative">
                <Tooltip title={'Remove event'}>
                <button
                  type="button"
                  onClick={() => handleDeleteEvent(index)}
                  className="absolute top-0 right-0 mt-2 mr-2 text-gray-400 hover:text-red-600"
                >
                  <PiTrashLight size={20} />
                </button>
                </Tooltip>
                <p className="text-sm text-gray-500">{event.date}</p>
                <p className="mt-1 text-xs whitespace-pre-wrap">{event.description}</p>
                {event.file_content && (
                  <Button
                    variant="tertiary"
                    onClick={() => handleViewFileContent(event.file_content!)}
                    className="mt-2"
                    size="small"
                  >
                    <span>{t('View Attached Content')}</span>
                    <PiFilesLight size={18} className='ml-2' />
                  </Button>
                )}
              </div>
            ))}
          </div>
        ) : (
          <Badge color="gray">{t('No events added yet.')}</Badge>
        )}
      </div>

      {chronology.length > 0 && (
        <div className="flex justify-end">
          <Button
            onClick={handleGenerateChronology}
            variant="primary"
          >
            <span>{t('Run Chronology')}</span>
            <PiSparkleLight size={20} className='ml-2' />
          </Button>
        </div>
      )}

      {generatedChronology && (
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">{t('Generated Chronology')}</h3>
          <div className="mt-2 text-sm prose dark:!prose-invert">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {generatedChronology}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chronology;
