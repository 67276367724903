import { AnyAction } from 'redux';
import { Description, Section } from '../../components/Help/types';
import { Lawyer } from '../../components/Dashboard/AdminFirm/interface/IFirmLawyer';
import { IProject } from '../../components/Projects/ProjectsInterfaces';
import { FolderProps } from '../../components/Documents/types';

interface CommonSidePanelState {
  showSidePanel: boolean;
  width?: string;
  isExpanded?: boolean;
  onConfirm?: (data?: any) => void;
  onCancel?: () => void;
}

interface ExtractedContentSidePanelState extends CommonSidePanelState {
  type: 'extractedContent';
  content: string; 
  onCancel: () => void;
  filename: string;
}

interface EmployeeTaskDetailSidePanelState extends CommonSidePanelState {
  type: 'employeeTaskDetail';
  taskData: any; // Or a stricter interface
}

interface EmployeeCaseDetailSidePanelState extends CommonSidePanelState {
  type: 'employeeCaseDetail';
  caseData: any; // Or a stricter interface
}

interface ProposeCaseSidePanelState extends CommonSidePanelState {
  type: 'proposeCase';
  lawyerId: string;
}

interface AddChronologyEventSidePanelState extends CommonSidePanelState {
  type: 'addChronologyEvent';
  caseId: string;
  onSuccess?: () => void; 
}


interface AddLegalOutlineTemplateState extends CommonSidePanelState {
  type: 'addLegalOutlineTemplate';
  caseId: string;
  onFinish: () => void;
}
interface CreateIndexState extends CommonSidePanelState {
  type: 'createIndex';
}

interface LegalOutlineContentSidePanelState extends CommonSidePanelState {
  type: 'legalOutlineContent';
  sectionName: string;
  content: string;
  fieldData: { text: string; files: File[] };
  onSave: (newContent: string | null, newFiles: File[]) => void;
}

interface LegalOutlineGeneratedContentSidePanelState extends CommonSidePanelState {
  type: 'legalOutlineGeneratedContent';
  sectionName: string;
  content: string;
}

interface ChronologyFileContentSidePanelState extends CommonSidePanelState {
  type: 'chronologyFileContent';
  content: string;
}

interface FileReaderSidePanelState extends CommonSidePanelState {
  type: 'fileReader';
  fileUrl: string;
}

interface SupportTicketFormSidePanelState extends CommonSidePanelState {
  type: 'supportTicketForm';
}

interface PlaceBidSidePanelState extends CommonSidePanelState {
  type: 'placeBid';
  engagement: any;
}

interface AddTaskSidePanelState extends CommonSidePanelState {
  type: 'addTask';
}

interface AddFileSidePanelState extends CommonSidePanelState {
  type: 'addFile';
  folders?: FolderProps[];
  preSelectedFolderId?: string;
}

interface SupportTicketSidePanelState extends CommonSidePanelState {
  type: 'supportTicket';
}

interface HelpContentSidePanelState extends CommonSidePanelState {
  type: 'helpContent';
  content: {
    section: Section;
    description: Description;
  };
}

interface OnboardingSidePanelState extends CommonSidePanelState {
  type: 'onboarding';
}

interface CaseViewSidePanelState extends CommonSidePanelState {
  type: 'caseView';
  caseItem: any;
}

interface CustomSidePanelState extends CommonSidePanelState {
  type: 'custom';
}

interface AddClientSidePanelState extends CommonSidePanelState {
  type: 'addClient';
}
interface TimekeepingSidePanelState extends CommonSidePanelState {
  type: 'timekeepingView';
  day: string | null;
  data: any;
  isLoading: boolean;
  stats: {
    clocked_hours: number;
    negative_hours: number;
    overtime: number;
    total_hours_worked: number;
    tasks: string[];
    casesWorkedOn: number;
  } | null;
}

interface ProjectViewSidePanelState extends CommonSidePanelState {
  type: 'projectView';
  project: IProject;
}

interface CreateProjectSidePanelState extends CommonSidePanelState {
  type: 'createProject';
}

interface AddCaseSidePanelState extends CommonSidePanelState {
  type: 'addCase';
  clientId: string | undefined;
  clientUserType: string | undefined;
}

interface PublishCasePanelState extends CommonSidePanelState {
  type: 'publishCase';
  caseItem: any;
}

interface AddTeamPanelState extends CommonSidePanelState {
  type: 'addTeam';
}

interface TimeEntryDetailsSidePanelState extends CommonSidePanelState {
  type: 'timeEntryDetails';
  data: any; 
}

export type SidePanelState =
  | AddTaskSidePanelState
  | AddFileSidePanelState
  | SupportTicketSidePanelState
  | HelpContentSidePanelState
  | OnboardingSidePanelState
  | CaseViewSidePanelState
  | AddClientSidePanelState
  | CustomSidePanelState
  | TimekeepingSidePanelState
  | ProjectViewSidePanelState
  | CreateProjectSidePanelState
  | AddCaseSidePanelState
  | PublishCasePanelState
  | AddTeamPanelState
  | PlaceBidSidePanelState
  | ProposeCaseSidePanelState
  | SupportTicketFormSidePanelState
  | FileReaderSidePanelState
  | LegalOutlineContentSidePanelState
  | LegalOutlineGeneratedContentSidePanelState
  | ChronologyFileContentSidePanelState
  | AddChronologyEventSidePanelState
  | AddLegalOutlineTemplateState
  | CreateIndexState
  | TimeEntryDetailsSidePanelState
  | EmployeeTaskDetailSidePanelState
  | EmployeeCaseDetailSidePanelState
  | ExtractedContentSidePanelState;


const initialState: SidePanelState = {
  showSidePanel: false,
  type: 'custom',
  width: '50%',
  isExpanded: false,
};

export const sidePanelReducer = (
  state = initialState,
  action: AnyAction
): SidePanelState => {
  switch (action.type) {
    case 'SHOW_SIDE_PANEL':
      return {
        ...state,
        ...action.payload,
        showSidePanel: true,
      };
    case 'HIDE_SIDE_PANEL':
      return {
        ...state,
        showSidePanel: false,
        isExpanded: false,
      };
    case 'EXPAND_SIDE_PANEL':
      return {
        ...state,
        isExpanded: true,
        width: '85%',
      };
    case 'COLLAPSE_SIDE_PANEL':
      return {
        ...state,
        isExpanded: false,
        width: '50%',
      };
      case 'UPDATE_SIDE_PANEL':
        return {
          ...state,
          ...action.payload,
        };
    default:
      return state;
  }
};

export default sidePanelReducer;
