import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { toggleSidebar } from '../../store/actions/SidebarActions';
import { GoDependabot, GoLaw, GoTasklist, GoWorkflow } from "react-icons/go";
import { PiBriefcase, PiFile, PiSparkleLight, PiUserCircleCheckLight, PiUserListLight } from "react-icons/pi";
import { FiBarChart2 } from "react-icons/fi";
import { LiaCoinsSolid, LiaUsersCogSolid } from "react-icons/lia";
import { LuLayoutGrid } from "react-icons/lu";
import { SlPeople } from "react-icons/sl";

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const userType = getUserType();
  const isDevEnv =
    window.location.hostname.includes('dev.pons.io') ||
    window.location.hostname.includes('localhost');

    const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    if (!isOpen) {
      setIsTextVisible(false);
    } else {
      const timer = setTimeout(() => {
        setIsTextVisible(true);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <div
      className={clsx(
        'fixed top-0 left-0 h-full flex flex-col bg-background-light shadow-lg dark:shadow-dark-md dark:bg-background-dark text-text-light dark:text-text-dark transition-all duration-300',
        isOpen ? 'w-52' : 'w-15'
      )}
    >
      <div className={clsx("flex items-center self-center justify-start my-4",
        isOpen ? 'px-5 bg-white dark:bg-gray-900 shadow-md dark:shadow-dark-sm rounded w-fit h-fit ml-2' 
        : 'ml-1 mr-1 bg-white dark:bg-gray-900 shadow-md dark:shadow-dark-sm rounded w-fit h-fit'
      )}>
        <NavLink
          exact
          to="/dashboard"
          className="flex items-center space-x-2"
        >
          <i className={clsx("fa-kit dark:text-sky-400/100 text-primary-500 fa-logo text-3xl",
            !isOpen ? 'pl-0.5' : 'ml-[-3px]'
          )}></i>
          {isOpen && (
            <h1
            style={{ fontSize: 30 }}
              className={clsx(
                'text-grey-50 transition-opacity duration-300',
                !isTextVisible && 'opacity-0 transform -translate-x-4'
              )}
              // style={{ fontFamily: '"Mulish", sans-serif' }}
            >
              PONS
            </h1>
          )}
        </NavLink>
      </div>

      {/* Navigation Section */}
      <nav className="flex-1 px-2 space-y-2 overflow-y-auto">
        <ul className="space-y-1">
          {/* Summary */}
          {/* {userType !== 'IndividualClient' && ( */}
            <li>
            <Tooltip title={!isOpen ? t('Dashboard') : ''} placement="right">
              <NavLink
                exact
                to="/dashboard"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center' 
                )}
                >
                  <LuLayoutGrid size={20} />
                {/* <i className="bi bi-grid-fill text-sm"></i> */}
                {isOpen && <span className="ml-3">{t('Dashboard')}</span>}
              </NavLink>
            </Tooltip>
          </li>
              {/* )} */}

      <li>
            <Tooltip title={!isOpen ? t('Tasks') : ''} placement="right">
              <NavLink
                exact
                to="/tasks"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center' 
                )}
              >
                <GoTasklist size={20} />
                {/* <i className="fas fa-square-list text-sm"></i> */}
                {isOpen && <span className="ml-3">{t('Tasks')}</span>}
              </NavLink>
            </Tooltip>
          </li>

              {/* Artefacts */}
              {isOpen &&<p className='py-1 text-gray-500 dark:text-gray-200 text-xs'>{t('Vault')}</p>}

          {(userType === 'LawFirmAdmin' ||
            userType === 'LawFirmEmployee' ||
            userType === 'IndependentLawyer') && (
            <li>
              <Tooltip title={!isOpen ? t('My Clients') : ''} placement="right">
                <NavLink
                  to="/my-clients"
                  activeClassName="bg-primary-500 text-white"
                  className={clsx(
                    'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                    !isOpen && 'justify-center'
                  )}
                >
                  {/* <i className="fas fa-screen-users text-sm"></i> */}
                  <PiUserListLight size={20}/>
                  {isOpen && <span className="ml-3">{t('My Clients')}</span>}
                </NavLink>
              </Tooltip>
            </li>
          )}

          {(userType === 'BusinessmAdmin' ||
            userType === 'BusinessEmployee' ||
            userType === 'IndividualClient') && (
            <li>
              <Tooltip title={!isOpen ? t('My Lawyers') : ''} placement="right">
                <NavLink
                  to="/my-lawyers"
                  activeClassName="bg-primary-500 text-white"
                  className={clsx(
                    'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                    !isOpen && 'justify-center'
                  )}
                >
                  {/* <i className="fas fa-user-graduate text-sm"></i> */}
                  <PiUserCircleCheckLight size={20} />
                  {isOpen && <span className="ml-3">{t('My Lawyers')}</span>}
                </NavLink>
              </Tooltip>
            </li>
          )}

          {(userType === 'BusinessAdmin' || userType === 'BusinessEmployee') && (
            <li>
              <Tooltip title={!isOpen ? t('Projects') : ''} placement="right">
                <NavLink
                  to="/projects"
                  activeClassName="bg-primary-500 text-white"
                  className={clsx(
                    'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                    !isOpen && 'justify-center'
                  )}
                >
                  <GoWorkflow size={20}/>
                  {/* <i className="fas fa-briefcase text-sm"></i> */}
                  {isOpen && <span className="ml-3">{t('Projects')}</span>}
                </NavLink>
              </Tooltip>
            </li>
          )}

          <li>
            <Tooltip title={!isOpen ? t('Cases') : ''} placement="right">
              <NavLink
                to="/cases"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <PiBriefcase size={20}/>
                {/* <i className="fas fa-book-section text-sm"></i> */}
                {isOpen && <span className="ml-3">{t('Cases')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          <li>
            <Tooltip title={!isOpen ? t('Files') : ''} placement="right">
              <NavLink
                to="/files"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <PiFile size={20}/>
                {/* <i className="fas fa-folder-open text-sm"></i> */}
                {isOpen && <span className="ml-3">{t('Files')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          {/* Tools */}
          {isOpen &&<p className='py-1 text-gray-500 dark:text-gray-200 text-xs'>{t('Tools')}</p>}
          <li>
            <Tooltip title={!isOpen ? t('Legal Agents') : ''} placement="right">
              <NavLink
                to="/legal-agents"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <GoDependabot size={20} />
                {/* <i className="fas fa-message-bot text-sm"></i> */}
                {isOpen && <span className="ml-3">{t('Legal Agents')}</span>}
              </NavLink>
            </Tooltip>
          </li>

          <li>
            <Tooltip title={!isOpen ? t('Marketplace') : ''} placement="right">
              <NavLink
                to="/marketplace"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                {/* <i className="fas fa-scale-balanced text-sm"></i> */}
                <GoLaw size={20} />
                {isOpen && <span className="ml-3">{t('Marketplace')}</span>}
              </NavLink>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={!isOpen ? t('Analytics') : ''} placement="right">
              <NavLink
                to="/analytics"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
              >
                <FiBarChart2 size={20}/>
                {/* <i className="fas fa-chart-simple text-sm"></i> */}
                {isOpen && <span className="ml-3">{t('Analytics')}</span>}
              </NavLink>
            </Tooltip>
          </li>
          {!userType.includes('Client') && (
            <li>
            <Tooltip title={!isOpen ? t('AI Studio') : ''} placement="right">
              <NavLink
                to="/ai-studio"
                activeClassName="bg-primary-500 text-white"
                className={clsx(
                  'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                  !isOpen && 'justify-center'
                )}
                >
                  <PiSparkleLight size={20} />

                {/* <i className="bi bi-stars text-md"></i> */}
                {isOpen && <span className="ml-3">{t('AI Studio')}</span>}
              </NavLink>
            </Tooltip>
          </li>
              )}
          
        {/* Operations */}
        {(isOpen && userType.includes('Admin')) && <p className='py-1 text-gray-500 dark:text-gray-200 text-xs'>{t('Operations')}</p>}

          {/* Admin and Finance for LawFirmAdmin */}
          {(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') && (
            <>
              <li>
                <Tooltip title={!isOpen ? t('Management') : ''} placement="right">
                  <NavLink
                    to="/management"
                    activeClassName="bg-primary-500 text-white"
                    className={clsx(
                      'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                      !isOpen && 'justify-center'
                    )}
                  >
                    <LiaUsersCogSolid size={20} />
                    {/* <i className="fas fa-user-cog text-sm"></i> */}
                    {isOpen && <span className="ml-3">{t('Management')}</span>}
                  </NavLink>
                </Tooltip>
              </li>
            </>
          )}
          {(userType === 'LawFirmAdmin' || userType === 'BusinessAdmin') && (
              <li>
                <Tooltip title={!isOpen ? t('Finance') : ''} placement="right">
                  <NavLink
                    to="/finance"
                    activeClassName="bg-primary-500 text-white"
                    className={clsx(
                      'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                      !isOpen && 'justify-center'
                    )}
                  >
                    <LiaCoinsSolid size={20} />
                    {/* <i className="fas fa-coins text-sm"></i> */}
                    {isOpen && <span className="ml-3">{t('Finance')}</span>}
                  </NavLink>
                </Tooltip>
              </li>
          )}


          {/* Design System (Development Only) */}
          {/* {isDevEnv && (
            <>
         {isOpen && <p className='py-1 text-gray-500 dark:text-gray-200 text-xs'>{t('Development')}</p>}
            <li>
              <Tooltip title={!isOpen ? t('Design System') : ''} placement="right">
                <NavLink
                  to="/design-system"
                  activeClassName="bg-primary-500 text-white"
                  className={clsx(
                    'flex items-center p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
                    !isOpen && 'justify-center'
                  )}
                >
                  <i className="fas fa-drafting-compass text-sm"></i>
                  {isOpen && <span className="ml-3">{t('Design System')}</span>}
                </NavLink>
              </Tooltip>
            </li>
          </>
          )} */}
        </ul>
      </nav>

      {/* Help & Get Started */}
      {/* <div className="p-4">
        <Tooltip title={!isOpen ? t('Help') : ''} placement="right">
          <NavLink
            to="/help"
            activeClassName="bg-primary-500 text-white"
            className={clsx(
              'flex items-center w-full p-2 text-sm font-medium rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300',
              !isOpen && 'justify-center'
            )}
          >
            <i className="bi bi-question-circle text-sm"></i>
            {isOpen && (
              <span className="text-nowrap ml-3">{t('Help & Get Started')}</span>
            )}
          </NavLink>
        </Tooltip>
      </div> */}

      {/* Collapse/Expand Button */}
      <div
        className="absolute bottom-12 right-0 transform -translate-y-1/2 w-1 h-12 bg-gray-900 dark:bg-gray-200 rounded hover:dark:bg-transparent hover:bg-transparent cursor-pointer flex items-center justify-center group"
        onClick={handleToggleSidebar}
      >
        <i
          className={clsx(
            'fas',
            isOpen ? 'fa-chevron-left' : 'fa-chevron-right',
            'text-gray-800 dark:text-gray-200 opacity-0 group-hover:opacity-100 transition-opacity duration-300'
          )}
        ></i>
      </div>
    </div>
  );
};

export default Sidebar;
