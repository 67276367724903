import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { RootState, useAppDispatch } from '../../store';
import { GetNotifications, updateNotification } from '../../store/actions/Notifications';
import { useHistory } from 'react-router-dom';
import Badge from '../shared/TailwindComponents/Badge';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import SocketServices from '../../utils/SocketServices';
import { useSelector } from 'react-redux';

interface Notification {
    notification_id: string;
    user_id: string;
    content: string;
    link: string;
    is_read: boolean;
    created_at: string;
}

interface Notification {
  notification_id: string;
  user_id: string;
  content: string;
  link: string;
  is_read: boolean;
  created_at: string;
}

interface NotificationsProps {
  isOpen: boolean;           // <--- Control from parent
  onToggle: () => void;      // <--- Parent handles toggling
  onClose: () => void;       // <--- Parent or child can close
}

const Notifications: React.FC<NotificationsProps> = ({
    isOpen,
    onToggle,
    onClose,
  }) => {    
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { t } = useTranslation()
    const userInfo = useSelector((state: RootState) => state.user.userInfo)

    const fetchNotifications = useCallback( async () => {
        try {
            const response = await dispatch(GetNotifications());
            if (response.data.length > 0) {
                setNotifications(response.data);
                setUnreadCount(response.data.filter((n: any) => !n.is_read).length);
            }
            // fetchNotifications(); // Recursively call after response
        } catch (error) {
            console.error('Error fetching notifications', error);
            // setTimeout(fetchNotifications, 5000); // Retry after 5 seconds if an error occurs
        }
    },[dispatch])

    useEffect(() => {
        // fetchNotifications();
        // const interval = setInterval(fetchNotifications, 1000 * 60); // Fetch every second
        // return () => clearInterval(interval);
        // SocketServices.emit("user_join", { 'username': userInfo.UserUID, 'caseid': "" });
        fetchNotifications()
    }, [userInfo.UserUID,fetchNotifications]);

    const recieve_new_notification  = useCallback((data: any) => {
        console.log("Data recieved", data)
        setNotifications((prevNotifications: any[]) => [data, ...prevNotifications]);

        setUnreadCount((prevUnreadCount) => prevUnreadCount + (!data.is_read ? 1 : 0));
    },[]) 

    useEffect(() => {
        SocketServices.on('new_notification', recieve_new_notification)
        return () => {
            SocketServices.removeListener('new_notification')
        }
    }, [recieve_new_notification])

    const handleNotificationClick = async (notification: Notification) => {
        try {
            await dispatch(updateNotification(notification.notification_id));
            fetchNotifications()
            // setNotifications((prev) =>
            //     prev.map((n) =>
            //         n.id === notification.id ? { ...n, is_read: true } : n
            //     )
            // );
            // setUnreadCount((prev) => prev - 1);
            // window.location.href = notification.link; // Redirect to the link
            history.push(notification.link)
            onClose();
        } catch (error) {
            console.error('Error marking notification as read', error);
        }
    };
    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    return (
        <div className="relative">
            {/* Bell Icon */}
            <Tooltip title={t('Notifications')}>
            <div
                className={clsx(
                    'relative cursor-pointer p-2 rounded-full transition-colors duration-300',
                    isOpen
                      ? 'bg-primary-500 text-white'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
                  )}
                onClick={onToggle}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                </svg>
                {unreadCount > 0 && (
                    <span className="absolute -top-1 right-0 bg-red-500 text-white text-xs font-medium rounded-full px-1 font-mono">
                        {unreadCount}
                    </span>
                )}
            </div>
            </Tooltip>
            {/* Dropdown */}
            {isOpen && (
                <div className="absolute right-0 w-64 mt-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-lg rounded-lg overflow-hidden z-10">
                    <div className="p-4 bg-gray-100 text-sm dark:bg-gray-700 text-gray-700 dark:text-gray-200">
                        {t('Notifications')}
                    </div>
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700 max-h-96 overflow-y-auto">
                        {notifications.map((notification) => (
                            <li
                                key={notification.notification_id}
                                className={`p-3  cursor-pointer ${notification.is_read
                                    ? 'bg-gray-100 text-gray-500 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-500'
                                    : 'bg-white text-black dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-900'
                                    }  transition-colors`}
                                onClick={() => handleNotificationClick(notification)}
                            >
                                <p className="text-sm dark:text-white font-medium">{notification.content}</p>
                                <span className="text-xs dark:text-gray-200 text-gray-400">
                                    {new Date(notification.created_at).toLocaleString()}
                                </span>
                            </li>
                        ))}
                    </ul>
                    {notifications.length === 0 && (
                        <div className='flex justify-center'>
                        <Badge color='gray' className="my-4">{t('No notifications')}</Badge>
                        </div>
                    )}
                </div>
            )}
        </div>
    );

};

export default Notifications;
