import paymentApis from "../../utils/paymentApis"
import { showFeedbackModal } from "./UserFeedbackActions"
import { SET_EXTERNAL_ACCOUNTS } from "../types";
import { getUserType } from "./ChatAction";
import { hideModal, showModal } from "./modalActions";
import { handleApiError } from "./utils/utils";

export const setExternalAccounts = (external_accounts: any[]) => {
    return {
        type: SET_EXTERNAL_ACCOUNTS,
        external_accounts,
    };
};


export const createConnectedAccount = (Token: string | undefined, Email: string, CustomerID: string) => {
    return async (dispatch: any) => {
        const UserType = getUserType()
        try {

            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/createConnectedAccount",
                data: {
                    Token,
                    Email,
                    CustomerID,
                    UserType
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: resp.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
                return resp.data

            } else {
                 handleApiError(resp, dispatch)
            }
        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}

export const listRecieversAccount = (CustomerID: string) => {
    return async (dispatch: any) => {
        try {

            const resp: any = await paymentApis({
                method: 'POST',
                url: "stripe/listAccounts",
                data: {
                    CustomerID: CustomerID,
                }
            })

            if (resp && resp.status === 200) {
                const accounts = resp.data.user_accounts
                if (accounts && accounts.length > 0) {
                    if (accounts[0].account_info.Details_submitted) {
                        dispatch(setExternalAccounts(accounts[0].external_accounts))
                    } else {
                        dispatch(setExternalAccounts([accounts[0].account_info]))
                    }
                } else {
                    dispatch(setExternalAccounts([]))
                }
                return resp.data
            } else {
                handleApiError(resp, dispatch)
            }

        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}

export const createVerificationSession = (CustomerID: string) => {
    return async (dispatch: any) => {
        try {

            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/verifySession",
                data: {
                    CustomerID
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data?.message),
                    modalType: 'success',
                    duration: 3000
                }));
                return resp.data

            }
            else {
                handleApiError(resp, dispatch)
            }

        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}

export const createCheckoutSession = (Payload: any) => {
    return async (dispatch: any) => {


        try {

            const formData = new FormData()

            Object.entries(Payload).forEach(([key, value]) => {
                if (value !== undefined && value !== null && value !== "") {
                    if (typeof value === 'boolean') {
                        formData.append(key, value ? 'yes' : 'no');
                    } else {
                        formData.append(key, value as string);
                    }
                }
            });
            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/createCheckoutSession",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData
            })

            if (resp && resp.status === 200) {
                return resp.data
            }
            else {
                handleApiError(resp, dispatch)
            }

        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}

export const createAccountLink = (AccountID: string) => {
    return async (dispatch: any) => {
        try {

            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/createAccountLink",
                data: {
                    AccountID,
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: resp.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
                return resp.data

            } else {
                handleApiError(resp, dispatch)
            }
        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}

export const createPaymentSession = (CaseID: string, LawyerID: string, ClientEmail: string) => {
    return async (dispatch: any) => {


        try {
            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/createDirectPaymentSession",
                data: {
                    CaseID,
                    LawyerID,
                    ClientEmail
                }
            })

            if (resp && resp.status === 200) {
                if (resp.data.session) {
                    dispatch(showModal({
                        type: "redirect",
                        message: "You are being redirected to stripe. Please wait...",
                        onConfirm: () => { console.log("redirected") },
                        onCancel: () => hideModal(),
                        showModal: true,
                    }))
                    window.location.href = resp.data.session;
                }
            }
            else {
                handleApiError(resp, dispatch)
            }

        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}


export const fetchCaseDeposit = (CaseID: string) => {
    return async (dispatch: any) => {


        try {
            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/fetchDeposite",
                data: {
                    CaseID,
                }
            })

            if (resp && resp.status === 200) {
                return resp.data
            }
            else {
                handleApiError(resp, dispatch)
            }

        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}


export const createDepositePaySession = (CaseID: string, DepositeID: string, ClientEmail: string) => {
    return async (dispatch: any) => {


        try {
            const resp = await paymentApis({
                method: 'POST',
                url: "stripe/payDeposite",
                data: {
                    CaseID,
                    DepositeID,
                    ClientEmail
                }
            })

            if (resp && resp.status === 200) {
                if (resp.data.session) {
                    dispatch(showModal({
                        type: "redirect",
                        message: "You are being redirected to stripe. Please wait...",
                        onConfirm: () => { console.log("redirected") },
                        onCancel: () => hideModal(),
                        showModal: true,
                    }))
                    window.location.href = resp.data.session;
                }
            }
            else {
                handleApiError(resp, dispatch)
            }

        } catch (err) {
            console.error(err)
            handleApiError(err, dispatch)
        }
    }
}