import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../store';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Button from '../../../shared/TailwindComponents/Button';
import Badge from '../../../shared/TailwindComponents/Badge';
import { generateMemo, getCaseMemo } from '../../../../store/actions/LegalServices/memoActions';
import { fetchCaseById, fetchCaseSummary } from '../../../../store/actions/marketplace2/caseActions';
import { useTranslation } from 'react-i18next';
import ApiLoader from '../../../ApiLoader';

interface MemoProps {
  caseId: string;
  localMemo: string;
  // optionally add a prop:
  isAnalysisCompleted?: boolean | null; 
  setIsRunning: (isRunning: boolean) => void;
  loadCaseSummary: () => void;
  loadMemo: () => void;
}

const Memo: React.FC<MemoProps> = ({ caseId, localMemo, isAnalysisCompleted, setIsRunning, loadCaseSummary, loadMemo }) => {
  const dispatch = useAppDispatch();
  const [memo, setMemo] = useState<string>(localMemo || '');
  const { t } = useTranslation();

  useEffect(() => {
    // If you want to automatically set the memo from localMemo
    setMemo(localMemo || '');
  }, [localMemo]);

  const handleGenerateMemo = async () => {
    if (!isAnalysisCompleted) {
      // Just in case
      return;
    }
    setIsRunning(true)
    const result: any = await dispatch(generateMemo(caseId));
    if (result) {
      setMemo(result.memo);  
    }
    loadCaseSummary();
    loadMemo();
    setIsRunning(false)
  };

  if (!isAnalysisCompleted) {
    // Fallback guard if user tries to access anyway
    return (
      <div className="mt-4 flex justify-center">
        <Badge color="gray">
          {t('Please complete the case analysis before generating the Memo.')}
        </Badge>
      </div>
    );
  }

  if (memo) {
    return (
      <div className="mt-4">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">{t('Memo')}</h3>
          <div className="mt-2 text-sm prose dark:!prose-invert">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{memo}</ReactMarkdown>
          </div>
        </div>
      </div>
    );
  }

  // if there's no memo => show "generate" button
  return (
    <div className="mt-4 flex flex-col items-center gap-4">
      <Badge color="gray">{t('No memo has been generated for this case.')}</Badge>
      <Button onClick={handleGenerateMemo}>
        {t('Generate Memo')}
      </Button>
    </div>
  );
};

export default Memo;
