import { Dispatch } from "react";
import apis from "../../utils/apis";
import { handleApiError } from "./utils/utils";



export const GetNotifications = () => async (dispatch: Dispatch<any>) => {
    try {
      const response: any = await apis({
        method: 'GET',
        url: '/notifications',
        data: {},
      });
  
      if(response && response.status === 200){
        return response
      }else{
        handleApiError(response, dispatch)
        return null
      }
  
    } catch (error) {
      handleApiError(error, dispatch)
    }
  };

  export const updateNotification = (notification_id: string) => async (dispatch: Dispatch<any>) => {
    try {
      const response: any = await apis({
        method: 'POST',
        url: '/notifications/update',
        data: {
            notification_id: notification_id
        },
      });
  
      if(response && response.status === 200){
        return response
      }else{
        handleApiError(response, dispatch)
        return null
      }
  
    } catch (error) {
      handleApiError(error, dispatch)
    }
  };