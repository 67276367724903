import { FETCH_ALL_LAWYERS } from "../types";


interface LawyerState {
  allLawyers: any[];
}

const initialState: LawyerState = {
  allLawyers: [],
};

export const lawyerReducer = (state = initialState, action: any): LawyerState => {
  switch (action.type) {
    case FETCH_ALL_LAWYERS:
      return {
        ...state,
        allLawyers: action.payload, // override with new array
      };
    default:
      return state;
  }
};
