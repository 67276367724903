import React, { useState } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

interface PDFViewerProps {
  pdfUrl: string; // URL of the PDF file
}

const PDFViewer: React.FC<PDFViewerProps> = ({ pdfUrl }) => {
  const [isLoading, setIsLoading] = useState(true);

  // Initialize default layout plugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-gray-100">
      {/* PDF Viewer Container */}
      <div className="w-full h-full border bg-white shadow-lg rounded-md overflow-hidden">
        {/* PDF Viewer */}
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
          <Viewer
            fileUrl={pdfUrl}
            plugins={[defaultLayoutPluginInstance]}
            defaultScale={SpecialZoomLevel.PageWidth}
            onDocumentLoad={() => setIsLoading(false)}
          />
        </Worker>
      </div>
    </div>
  );
};

export default PDFViewer;
