import { AnyAction } from 'redux';
import {
  RUN_ANALYSIS_REQUEST,
  RUN_ANALYSIS_SUCCESS,
  RUN_ANALYSIS_FAILURE,
  FETCH_ANALYSIS_SUCCESS,
  FETCH_ANALYSIS_FAILURE,
  SET_ANALYSIS_PROGRESS,
  FETCH_PROGRESS_REQUEST,
  FETCH_PROGRESS_SUCCESS,
  FETCH_PROGRESS_FAILURE,
  CLEAR_ANALYSIS_DATA,
} from '../../actions/marketplace2/caseAnalysisActions';

interface AnalysisResults {
  [key: string]: string; 
}

export interface ProgressStep {
  step: string;
  status: string;
  messages?: string[];
}

export interface AnalysisData {
  lawyerAnalysis?: { [key: string]: string };
  clientAnalysis?: { [key: string]: string };
}

export interface AnalysisState {
  isFetching: boolean;
  isRunning: boolean;
  analysisData: AnalysisData | null;
  error: string | null;
  progressSteps: ProgressStep[];
  analysisResults: { [key: string]: string };
}

const initialState: AnalysisState = {
  isFetching: false,
  isRunning: false,
  analysisData: null,
  error: null,
  progressSteps: [],
  analysisResults: {},
};

export const caseAnalysisReducer = (state = initialState, action: AnyAction): AnalysisState => {
  switch (action.type) {
    case RUN_ANALYSIS_REQUEST:
      return {
        ...state,
        isRunning: true,
        error: null,
        progressSteps: [],
        analysisResults: {},
      };
    case SET_ANALYSIS_PROGRESS:
      return {
        ...state,
        progressSteps: action.payload.progress || [],
        analysisResults: action.payload.analysis_results || {},
      };
    case RUN_ANALYSIS_SUCCESS:
      return {
        ...state,
        isRunning: false,
        analysisData: action.payload,  
        error: null,
      };
    case RUN_ANALYSIS_FAILURE:
      return {
        ...state,
        isRunning: false,
        error: action.payload,
      };
    case FETCH_PROGRESS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
      case FETCH_PROGRESS_SUCCESS:
        const progressArray = action.payload.progress || [];
        // Only consider analysis completed if the array is nonempty and every step is "Completed"
        const isCompleted = progressArray.length > 0 && progressArray.every((step: any) => step.status === 'Completed');
        return {
          ...state,
          isFetching: false,
          // If completed, mark isRunning false; otherwise, keep it true so polling continues.
          isRunning: isCompleted ? false : true,
          progressSteps: progressArray,
          // Only update analysisData when all steps are complete.
          analysisData: isCompleted ? action.payload.analysis_results : state.analysisData,
          error: null,
        };
    case FETCH_PROGRESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isRunning: false,
        error: action.payload,
      };
    case CLEAR_ANALYSIS_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default caseAnalysisReducer;