import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/24/solid';
import Badge from '../../shared/TailwindComponents/Badge';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import { fetchEmployeeDetails } from '../../../store/actions/organization/employeeActions';
import Tabs from '../../shared/TailwindComponents/Tabs';
import { showFileReaderSidePanel } from '../../../store/actions/sidePanelActions';
import { showSidePanel } from '../../../store/actions/sidePanelActions';
import apis from '../../../utils/apis';

// NEW: We import the ListItem component you provided
import ListItem from '../../shared/TailwindComponents/ListItem';
import { BadgeColor, BadgeItem } from '../../shared/TailwindComponents/types/badgeTypes';
import ApiLoader from '../../ApiLoader';

// Helper to parse arrays, JSON strings, or comma-separated
function parseToItemsArray(field: any): Array<{ title: string; fileUrl?: string }> {
  if (!field) return [];
  if (Array.isArray(field)) {
    return field.map((item: any, idx: number) => ({
      title: typeof item.title === 'string' ? item.title : `Item (${idx + 1})`,
      fileUrl: typeof item.fileUrl === 'string' ? item.fileUrl : undefined,
    }));
  }
  if (typeof field === 'string') {
    try {
      const parsed = JSON.parse(field);
      if (Array.isArray(parsed)) {
        return parsed.map((item: any, idx: number) => ({
          title: typeof item.title === 'string' ? item.title : `Item (${idx + 1})`,
          fileUrl: typeof item.fileUrl === 'string' ? item.fileUrl : undefined,
        }));
      }
    } catch {
      // fallback to comma-separated
      const items = field.split(',').map((x: string) => x.trim()).filter(Boolean);
      return items.map((val, i) => ({ title: val }));
    }
  }
  return [];
}

// Types
interface LawFirmProfile {
  ProfilePicture?: string;
  Designation?: string;
  Bio?: string;
  ExperienceYears?: number;
  HourlyRate?: number;
  LanguagesSpoken?: string;
  Specializations?: string;
  Awards?: any;
  Certifications?: any;
  Publications?: any;
}

interface Employee {
  UserID: string;
  FirstName: string;
  LastName: string;
  Email?: string;
  PhoneNumber?: string;
  is_deleted?: boolean;
  ProfilePicture?: string | null;
  LawFirmProfile?: LawFirmProfile;
  RatingsAverage?: number;
  RatingsCount?: number;
  // If backend no longer returns tasks/cases, remove them
  Tasks?: any[];
  Case?: any[];
}

interface EmployeeDetailParams {
  employeeId: string;
}

////////////////////////////////////////////////////////////////////////////////
// EmployeeDetail Component
const EmployeeDetail: React.FC = () => {
  const { employeeId } = useParams<EmployeeDetailParams>();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();
  const employeesState = useSelector((state: RootState) => state.employees);
  const { error } = employeesState;

  // Basic employee info
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Tabs
  const [activeTab, setActiveTab] = useState<'info' | 'tasks' | 'cases' | 'timekeeping'>('info');

  // Tasks + Cases
  const [tasks, setTasks] = useState<any[]>([]);
  const [tasksLoading, setTasksLoading] = useState<boolean>(false);
  const [tasksError, setTasksError] = useState<string>('');

  const [cases, setCases] = useState<any[]>([]);
  const [casesLoading, setCasesLoading] = useState<boolean>(false);
  const [casesError, setCasesError] = useState<string>('');

  // 1) Load employee details
  const loadDetails = useCallback(async () => {
    setLoading(true);
    try {
      const details: any = await dispatch(fetchEmployeeDetails(employeeId));
      if (details) {
        setEmployee(details);
      } else {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: 'Could not load employee details.',
            modalType: 'error',
            duration: 3000,
          })
        );
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, employeeId]);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  // 2) Tab-based fetching for tasks/cases
  useEffect(() => {
    if (!employee || !employee.UserID) return;

    // If "tasks" tab is active, load tasks if not loaded
    if (activeTab === 'tasks' && tasks.length === 0) {
      fetchEmployeeTasks(employee.UserID);
    }
    // If "cases" tab is active, load cases if not loaded
    if (activeTab === 'cases' && cases.length === 0) {
      fetchEmployeeCases();
    }
  }, [activeTab, employee, tasks.length, cases.length]);

  // 3) fetch tasks
  const fetchEmployeeTasks = async (empUserId: string) => {
    setTasksLoading(true);
    setTasksError('');
    try {
      const resp = await apis.post('/tasks/get_assigned_tasks', {
        UserEmail: employee?.Email,
      });
      if (resp?.status === 200 && resp.data.Tasks) {
        setTasks(resp.data.Tasks);
      } else {
        setTasksError('Could not fetch tasks');
      }
    } catch (err: any) {
      console.error('Error fetching tasks', err);
      setTasksError('Failed to fetch tasks');
    } finally {
      setTasksLoading(false);
    }
  };

  // 4) fetch cases
  const fetchEmployeeCases = async () => {
    setCasesLoading(true);
    setCasesError('');
    try {
      const resp = await apis.post('/cases/get_assigned_cases', {
        UserEmail: employee?.Email,
      });
      if (resp?.status === 200 && resp.data.cases) {
        setCases(resp.data.cases);
      } else {
        setCasesError('Could not fetch cases');
      }
    } catch (err) {
      console.error('Error fetching cases', err);
      setCasesError('Failed to fetch cases');
    } finally {
      setCasesLoading(false);
    }
  };

  // 5) Breadcrumbs
  useEffect(() => {
    if (employee) {
      const empName = `${employee.FirstName} ${employee.LastName}`;
      setBreadcrumbs([
        { name: 'Management', href: '/management', current: false },
        { name: 'Employees', href: '/management/employees', current: false },
        { name: empName, href: `/management/employees/${employeeId}`, current: true },
      ]);
    }
    return () => {
      setBreadcrumbs([]);
    };
  }, [employee, employeeId, setBreadcrumbs]);

  // If loading the main employee info
  if (loading) {
    return (
      <ApiLoader />
    );
  }

  // If employee not found / error
  if (error || !employee) {
    return (
      <div className="text-red-600 p-4">
        {error || 'Employee not found.'}
      </div>
    );
  }

  // Employee status
  const employeeStatus = employee.is_deleted ? 'Inactive' : 'Active';

  // Profile pic
  const lawFirmPic = employee.LawFirmProfile?.ProfilePicture;
  const finalProfilePic = lawFirmPic || employee.ProfilePicture;

  // Ratings
  const ratingStars = [0, 1, 2, 3, 4];
  const avgRating = employee.RatingsAverage || 0;
  const totalReviews = employee.RatingsCount || 0;

  // LawFirm fields
  const {
    Designation,
    Bio,
    ExperienceYears,
    HourlyRate,
    LanguagesSpoken,
    Specializations,
    Awards,
    Certifications,
    Publications,
  } = employee.LawFirmProfile || {};

  // Convert to arrays
  const specializationsArr = Specializations
    ? Specializations.split(',').map((s) => s.trim())
    : [];
  const languagesArr = LanguagesSpoken
    ? LanguagesSpoken.split(',').map((l) => l.trim())
    : [];

  // Parse Awards/Certs/Pubs
  const awardsList = parseToItemsArray(Awards);
  const certsList = parseToItemsArray(Certifications);
  const publicationsList = parseToItemsArray(Publications);

  // Open file in side panel
  const handleOpenFile = (fileUrl?: string) => {
    if (fileUrl) {
      dispatch(showFileReaderSidePanel(fileUrl));
    }
  };

  // Open a Task detail in side panel
  const handleOpenTaskDetail = (taskObj: any) => {
    dispatch(
      showSidePanel({
        type: 'employeeTaskDetail',
        showSidePanel: true,
        width: '50%',
        taskData: taskObj,
      })
    );
  };

  // Open a Case detail in side panel
  const handleOpenCaseDetail = (caseObj: any) => {
    dispatch(
      showSidePanel({
        type: 'employeeCaseDetail',
        showSidePanel: true,
        width: '50%',
        caseData: caseObj,
      })
    );
  };

  // Tabs
  const tabs = [
    { label: 'Info', value: 'info' },
    { label: 'Tasks', value: 'tasks' },
    { label: 'Cases', value: 'cases' },
    { label: 'Timekeeping', value: 'timekeeping', rightAligned: true },
  ];

  // Render tasks as ListItem cards
  const renderTasksTab = () => {
    if (tasksLoading) {
      return (
        <ApiLoader />
      );
    }
    if (tasksError) {
      return <div className="text-red-600 p-4 text-center">{tasksError}</div>;
    }
    if (!tasks || tasks.length === 0) {
      return (
        <div className="flex justify-center mt-5">
          <Badge color="gray">No tasks available</Badge>
        </div>
      );
    }

    return (
      <div className="grid gap-4 mt-4">
        {tasks.map((task: any) => {
          // Build badges array
          const badges: BadgeItem[] = [];
          if (task.Status) {
            // e.g. Active -> green, Completed -> indigo, etc.
            const colorMap: Record<string, string> = {
              Active: 'green',
              Completed: 'indigo',
              Deleted: 'red',
            };
            const badgeColor = colorMap[task.Status] as BadgeColor || 'gray';
            badges.push({ label: task.Status, color: badgeColor });
          }
          // Could add more badges, e.g. Priority if it exists

          // Build details
          const detailsArray = [];
          if (task.TaskDeadline) {
            detailsArray.push({
              label: 'Deadline',
              value: new Date(task.TaskDeadline).toLocaleDateString(),
            });
          }
          if (task.CreationDate) {
            detailsArray.push({
              label: 'Created',
              value: task.CreationDate,
            });
          }

          return (
            <ListItem
              key={task.TaskID || task.id}
              title={task.Title || 'Untitled Task'}
              subtitle={task.Description || ''}
              details={detailsArray}
              badges={badges}
              link="#"
              onClick={() => handleOpenTaskDetail(task)}
            />
          );
        })}
      </div>
    );
  };

  // Render cases as ListItem cards
  const renderCasesTab = () => {
    if (casesLoading) {
      return (
      <ApiLoader />
      );
    }
    if (casesError) {
      return <div className="text-red-600 p-4 text-center">{casesError}</div>;
    }
    if (!cases || cases.length === 0) {
      return (
        <div className="flex justify-center mt-5">
          <Badge color="gray">No cases found</Badge>
        </div>
      );
    }

    return (
      <div className="grid gap-4 mt-4">
        {cases.map((caseItem: any) => {
          // Build badges
          const badges: BadgeItem[] = [];
          if (caseItem.case_status) {
            const colorMap: Record<string, string> = {
              open: 'green',
              closed: 'red',
            };
            const badgeColor = colorMap[caseItem.case_status.toLowerCase()] as BadgeColor || 'gray';
            badges.push({ label: caseItem.case_status, color: badgeColor });
          }

          // Build details
          const detailsArray = [];
          if (caseItem.CaseType) {
            detailsArray.push({ label: 'Type', value: caseItem.CaseType });
          }
          if (caseItem.created_at) {
            const formatted = new Date(caseItem.created_at).toLocaleDateString();
            detailsArray.push({ label: 'Created', value: formatted });
          }
          if (caseItem.ClientName) {
            detailsArray.push({ label: 'Client', value: caseItem.ClientName });
          }

          return (
            <ListItem
              key={caseItem.CaseID || caseItem.id}
              title={caseItem.CaseName || `Case ${caseItem.CaseID}`}
              subtitle={caseItem.AnonymizedDescription || ''}
              details={detailsArray}
              badges={badges}
              link="#"
              onClick={() => handleOpenCaseDetail(caseItem)}
            />
          );
        })}
      </div>
    );
  };

  // Render main tab content
  const renderTabContent = () => {
    switch (activeTab) {
      case 'info':
        return (
          <div>
            {/* Basic badges for Designation, Experience, Rate */}
            <div className="flex flex-wrap gap-2 mb-4">
              {Designation && <Badge color="green">{Designation}</Badge>}
              {ExperienceYears && <Badge color="indigo">{ExperienceYears} yrs</Badge>}
              {HourlyRate && <Badge color="yellow">${HourlyRate.toFixed(2)}/hr</Badge>}
            </div>

            {Bio && (
              <div className="mt-2 mb-4 text-gray-700 dark:text-gray-300">
                <p className="leading-relaxed text-sm">{Bio}</p>
              </div>
            )}

            {/* Specializations */}
            {specializationsArr.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-2">
                  Specializations
                </h3>
                <div className="flex flex-wrap gap-2">
                  {specializationsArr.map((spec) => (
                    <Badge key={spec} color="gray">
                      {spec}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            {/* Languages */}
            {languagesArr.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-2">
                  Languages Spoken
                </h3>
                <div className="flex flex-wrap gap-2">
                  {languagesArr.map((lang) => (
                    <Badge key={lang} color="gray">
                      {lang}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            {/* AWARDS / CERTS / PUBS */}
            <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* AWARDS */}
              {awardsList.length > 0 && (
                <div>
                  <h4 className="text-sm font-semibold text-gray-600 dark:text-gray-300 mb-1">
                    Awards
                  </h4>
                  <ul className="space-y-1">
                    {awardsList.map((awardItem, idx) => (
                      <li key={idx}>
                        {awardItem.fileUrl ? (
                          <button
                            type="button"
                            onClick={() => handleOpenFile(awardItem.fileUrl)}
                            className="text-blue-600 underline"
                          >
                            {awardItem.title || 'Untitled'}
                          </button>
                        ) : (
                          <span>{awardItem.title || 'Untitled'}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* CERTIFICATIONS */}
              {certsList.length > 0 && (
                <div>
                  <h4 className="text-sm font-semibold text-gray-600 dark:text-gray-300 mb-1">
                    Certifications
                  </h4>
                  <ul className="space-y-1">
                    {certsList.map((cert, idx) => (
                      <li key={idx}>
                        {cert.fileUrl ? (
                          <button
                            type="button"
                            onClick={() => handleOpenFile(cert.fileUrl)}
                            className="text-blue-600 underline"
                          >
                            {cert.title || 'Untitled'}
                          </button>
                        ) : (
                          <span>{cert.title || 'Untitled'}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* PUBLICATIONS */}
              {publicationsList.length > 0 && (
                <div>
                  <h4 className="text-sm font-semibold text-gray-600 dark:text-gray-300 mb-1">
                    Publications
                  </h4>
                  <ul className="space-y-1">
                    {publicationsList.map((pub, idx) => (
                      <li key={idx}>
                        {pub.fileUrl ? (
                          <button
                            type="button"
                            onClick={() => handleOpenFile(pub.fileUrl)}
                            className="text-blue-600 underline"
                          >
                            {pub.title || 'Untitled'}
                          </button>
                        ) : (
                          <span>{pub.title || 'Untitled'}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        );

      case 'tasks':
        return renderTasksTab();

      case 'cases':
        return renderCasesTab();

      case 'timekeeping':
        return (
          <div className="flex justify-center mt-5">
            <Badge color="gray">No Timekeeping data yet</Badge>
          </div>
        );

      default:
        return null;
    }
  };

  ///////////////////////////////////////////////////////////////////////
  // Main return
  return (
    <div className="mx-4 p-6 bg-white dark:bg-gray-800 rounded shadow-lg">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row md:items-center md:space-x-6">
        {/* Profile Picture */}
        <div className="flex-shrink-0 mb-4 md:mb-0">
          {finalProfilePic ? (
            <img
              src={finalProfilePic}
              alt={`${employee.FirstName} ${employee.LastName}`}
              className="w-32 h-32 md:w-40 md:h-40 rounded object-cover border border-gray-300"
            />
          ) : (
            <div className="flex items-center justify-center w-32 h-32 md:w-40 md:h-40 bg-gray-200 dark:bg-gray-700 rounded text-4xl font-bold text-gray-600">
              {employee.FirstName?.[0]}
              {employee.LastName?.[0]}
            </div>
          )}
        </div>

        <div className="flex-1">
          {/* Name & Status */}
          <div className="flex items-center justify-between">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-gray-100">
              {employee.FirstName} {employee.LastName}
            </h2>
            <Badge
              color={employeeStatus === 'Active' ? 'green' : 'gray'}
              className="text-sm font-medium"
            >
              {employeeStatus}
            </Badge>
          </div>

          {/* Ratings */}
          <div className="mt-3 flex items-center space-x-2">
            {ratingStars.map((idx) => (
              <StarIcon
                key={idx}
                className={
                  avgRating > idx ? 'text-yellow-400 h-5 w-5' : 'text-gray-300 h-5 w-5'
                }
              />
            ))}
            <p className="text-gray-600 dark:text-gray-300 text-sm">
              {avgRating.toFixed(1)} / 5.0 <span className="ml-1">({totalReviews} reviews)</span>
            </p>
          </div>

          {/* Contact Badges */}
          <div className="mt-4 flex flex-wrap items-center gap-2">
            {employee.Email && (
              <Badge color="blue" tooltip="Email Address">
                {employee.Email}
              </Badge>
            )}
            {employee.PhoneNumber && (
              <Badge color="blue" tooltip="Phone Number">
                {employee.PhoneNumber}
              </Badge>
            )}
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-8">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={(val) => setActiveTab(val as any)}
        />
        <div className="mt-4">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default EmployeeDetail;
