import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import {
  AiOutlineQuestionCircle,
  AiOutlineSetting,
  AiOutlineDelete,
  AiOutlineMenu,
  AiOutlineFileSearch,
} from 'react-icons/ai';
import { BsPlus, BsReverseLayoutSidebarReverse } from 'react-icons/bs';
import { AIModel, getAiModels, getUserType, setSelectedModel } from '../../store/actions/ChatAction';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { FaBalanceScale, FaGavel, FaFileContract, FaFileSignature, FaFileInvoiceDollar } from 'react-icons/fa';
import { MdOutlineBusiness, MdOutlineDescription } from 'react-icons/md';
import Badge from '../shared/TailwindComponents/Badge';
import { GoHistory } from "react-icons/go";
import { LiaFileContractSolid, LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { PiFilesLight } from 'react-icons/pi';

interface AIHeaderProps {
  onToggleSidebar: () => void;
  currentView: 'Folders' | 'Projects' | 'ChatHistory' | 'AISettings';
  setCurrentView: React.Dispatch<
    React.SetStateAction<'Folders' | 'Projects' | 'ChatHistory' | 'AISettings'>
  >;
  onNewChat: () => void;
  onDeleteChat: (chatGroupId: string) => void;
  selectedModel: AIModel;
  modelDetails: any;
  models: any
  selectedProjectName?: string;
}

const AIHeader: React.FC<AIHeaderProps> = ({
  onToggleSidebar,
  currentView,
  setCurrentView,
  onDeleteChat,
  selectedModel,
  onNewChat,
  modelDetails,
  models,
  selectedProjectName
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [isInfoDropdownOpen, setIsInfoDropdownOpen] = useState(false);
  // const [models, setModels] = useState<string[]>([]);
  const chatGroupId = useSelector((state: RootState) => state.model.chatGroupID);
  const userType = getUserType();

  // const [modelDetails, setModelDetails] = useState<AIModel[]>([]);
  // useEffect(() => {
  //   const fetchServices = async () => {
  //     try {
  //       const response = await dispatch(getAiModels());
  //       if (response) {
  //         const allModels = response as AIModel[];
  //         // Filter services based on userType
  //         const userServices = getUserServices(userType, allModels);
  //         setModels(userServices.map((model) => model.model_display_name));
  //         setModelDetails(userServices);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching services:', error);
  //     }
  //   };
  //   fetchServices();
  // }, [dispatch, userType]);

  const getUserServices = (userType: string, allModels: AIModel[]) => {
    const userServiceMapping: { [key: string]: string[] } = {
      shared: ['Law Agent', 'Contract Analysis','Tax Agent'],
      IndependentLawyer: ['Case Agent'],
      LawFirmEmployee: ['Case Agent', 'Company Agent'],
      LawFirmAdmin: ['Case Agent', 'Company Agent'],
      IndividualClient: ['Legal Case Mapping (Clients)'],
      BusinessEmployee: ['Legal Case Mapping (Business)', 'Company Agent'],
      BusinessAdmin: ['Legal Case Mapping (Business)', 'Company Agent'],
    };

    let services = [...userServiceMapping.shared];
    if (userServiceMapping[userType]) {
      services = services.concat(userServiceMapping[userType]);
    } else {
      console.warn(`User type '${userType}' not recognized. No additional services added.`);
    }

    // Filter models based on services
    return allModels.filter((model) => services.includes(model.model_display_name));
  };

  const handleModelSelect = (displayName: string) => {
    const modelObj = modelDetails.find((m: AIModel) => m.model_display_name === displayName);
    if (modelObj) {
      dispatch(setSelectedModel(modelObj));
      console.log(modelObj);
      setIsModelDropdownOpen(false);
    }
  };

  const handleOpenAISettings = () => {
    setCurrentView('AISettings');
  };

  const selectedModelDetails = modelDetails.find(
    (model: AIModel) => model.model_display_name === selectedModel.model_display_name
  );
  const modelIcons: { [key: string]: JSX.Element } = {
    'Law Agent': <AiOutlineFileSearch size={24} className="text-primary-500" />,
    'Contract Analysis': <LiaFileContractSolid size={24} className="text-primary-500" />,
    'Case Agent': <PiFilesLight size={24} className="text-red-500" />,
    'Company Agent': <MdOutlineBusiness size={24} className="text-primary-500" />,
    'Custom Agent': <MdOutlineDescription size={24} className="text-primary-500" />,
    'Tax Agent': <LiaFileInvoiceDollarSolid size={24} className="text-indigo-500" />,
    // Add other models and icons as needed
  };

  // Assuming you have a variable for remaining queries
  const remainingQueries = 5;
  
  return (
    <header className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 shadow">
      {/* Left Side: Model Dropdown */}
      <div className="relative">
        <button
          onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
          className="flex items-center px-3 py-2 text-white dark:text-white bg-primary-600 rounded"
        >
          {/* {modelIcons[selectedModel] || <AiOutlineQuestionCircle size={20} className="mr-2" />} */}
          {t(selectedModel.model_display_name)}
          <svg
            className="w-4 h-4 ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.584l3.71-4.354a.75.75 0 111.14.976l-4.25 5a.75.75 0 01-1.14 0l-4.25-5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <Transition
          show={isModelDropdownOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 z-10">
            <div className="py-1">
              {models.map((model: any) => (
                <button
                  key={model}
                  onClick={() => handleModelSelect(model.model_display_name)}
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 flex flex-row justify-between"
                >
                  <span>{t(model.model_display_name)}</span>
                  </button>
              ))}
            </div>
          </div>
        </Transition>
      </div>

      {/* Right Side: Actions */}
      <div className="flex items-center space-x-2">
        {selectedProjectName && (
          <Badge color='purple' tooltip={`${t('Current Project')}`}>
            {selectedProjectName}
          </Badge>
        )}
        <Badge tooltip={`${t('Queries left')}`} color='indigo'>{selectedModelDetails?.total_available_queries ? selectedModelDetails.total_available_queries : 'You dont have any more queries'}</Badge>
        <Tooltip title={t('New Chat')} placement="top">
          <button
            className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
            onClick={onNewChat}
          >
            <BsPlus size={20} />
          </button>
        </Tooltip>
        <Tooltip title={t('AI Settings')} placement="top">
          <button
            onClick={handleOpenAISettings}
            className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
          >
            <AiOutlineSetting size={20} />
          </button>
        </Tooltip>
        <Tooltip title={t('Delete Chat')} placement="top">
          <button
            className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded disabled:hover:bg-white disabled:text-gray-300"
            onClick={() => onDeleteChat(chatGroupId)}
            disabled={!chatGroupId}
          >
            <AiOutlineDelete size={20} />
          </button>
        </Tooltip>
        {/* Info Dropdown */}
        <div className="relative">
          <Tooltip title={t('AI Model Info')} placement="top">
            <button
              onClick={() => setIsInfoDropdownOpen(!isInfoDropdownOpen)}
              className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
            >
              <AiOutlineQuestionCircle size={20} />
            </button>
          </Tooltip>
          <Transition
            show={isInfoDropdownOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 z-10">
              <div className="p-4 text-sm text-gray-700 dark:text-white space-y-2">
                <div className="flex items-center space-x-2">
                  {modelIcons[selectedModel.model_display_name] || <AiOutlineQuestionCircle size={24} />}
                  <span className="text-lg font-semibold">{t(selectedModel.model_display_name)}</span>
                </div>
                <p>{t(selectedModelDetails?.model_content) || t('No description available.')}</p>
                {/* <div className='flex justify-between items-center'>
                <p>
                  {t('Queries Left')} 
                </p>
                <Badge 
                color='indigo'
                // color={selectedModelDetails?.total_available_queries && (selectedModelDetails.total_available_queries > 5 && 'indigo') && (selectedModelDetails.total_available_queries === "Unlimited" && 'green') && (selectedModelDetails.total_available_queries < 5 && 'purple') && (selectedModelDetails.total_available_queries === 0 && 'red') }
                >
                  {selectedModelDetails?.total_available_queries}
                  </Badge>
                  </div> */}
              </div>
            </div>
          </Transition>
        </div>
        <Tooltip title={t('Toggle Sidebar')} placement="top">
          <button
            className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
            onClick={onToggleSidebar}
          >
            <BsReverseLayoutSidebarReverse size={20} title={t('Toggle Sidebar')} />
          </button>
        </Tooltip>
        <Tooltip title={t('Toggle History')} placement="top">
          <button
            className="p-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
            onClick={() => setCurrentView('ChatHistory')}
            >
              <GoHistory size={20} title={t('Toggle History')} />
                </button>
        </Tooltip>
      </div>
    </header>
  );
};

export default AIHeader;
