import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import {
  ONBOARD_CLIENT,
  CREATE_CASE,
  FETCH_CASE,
  FETCH_CLIENT,
  DELETE_CASE_FIELD,
  FETCH_ALL_CLIENTS,
  FETCH_ALL_CLIENT_DETAILS_FAILURE,
  FETCH_ALL_CLIENT_DETAILS_SUCCESS
} from "../types";
import { CaseData, ClientData } from "../reducer/ClientReducer";
import { showModal } from "./modalActions";
import { createTask } from "./TasksActions";
import { handleApiError } from "./utils/utils";

// In fetchAllClients action
export const fetchAllClients = (params: {searchTerm?: string; filters?: {[key:string]: string[]}; sortBy?: string; sortOrder?: string} = {}) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const { searchTerm = '', filters = {}, sortBy = '', sortOrder = 'asc' } = params;
      const filtersJson = JSON.stringify(filters);

      const resp = await apis({
        method: "GET",
        url: "/lawyers/clients",
        params: { searchTerm, filters: filtersJson, sortBy, sortOrder }
      });

      if (resp && resp.status === 200) {
        let returnedClients: any[] = [];

        // Check which key is present in resp.data
        if ('data' in resp.data) {
          // Unfiltered mode: resp.data.data is the array
          returnedClients = Array.isArray(resp.data.data) ? resp.data.data : [];
        } else if ('clients' in resp.data) {
          // Filtered mode: resp.data.clients is the array
          returnedClients = Array.isArray(resp.data.clients) ? resp.data.clients : [];
        } else {
          // Neither data nor clients, empty array fallback
          returnedClients = [];
        }

        dispatch({ type: FETCH_ALL_CLIENTS, payload: returnedClients });
        return true;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }
    return false;
  };
};

export const fetchAllClientDetails = (ClientID: string) => {
  return async (dispatch: any): Promise<any> => {
    try {
      const resp = await apis({
        method: 'POST',
        url: 'clients/get_client_details',
        data: { ClientID: ClientID },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: FETCH_ALL_CLIENT_DETAILS_SUCCESS, payload: resp.data });
        return resp.data;
      } else {
        handleApiError(resp, dispatch)
        return null;
      }
    } catch (err) {
      handleApiError(err, dispatch)
      dispatch({ type: FETCH_ALL_CLIENT_DETAILS_FAILURE, payload: err });
      return null;
    }
  };
};

export const onboardClient = (clientData: ClientData) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const url = 'clients/onboard_external';
      const data = { ...clientData };

      const resp = await apis({
        method: 'POST',
        url: url,
        data: data,
        headers: { 'Content-Type': 'application/json' }
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ONBOARD_CLIENT, payload: resp.data });
        // If suggested tasks are returned, show them in a modal
        if (resp.data.SuggestedTasks && resp.data.SuggestedTasks.length > 0) {
          dispatch(showModal({
            type: 'suggestedTasks', // a new modal type for suggested client tasks, or reuse 'addTask'
            message: 'Suggested Next Steps for Your New Client',
            tasks: resp.data.SuggestedTasks,
            onConfirm: (selectedTasks: any[]) => {
              // handle creating these tasks if user selects them
              selectedTasks.forEach((task: any) => {
                dispatch(createTask(task));
              });
            },
            onCancel: () => {},
            showModal: true,
          }));
        } else {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: resp.data.message || 'Client onboarded',
              modalType: 'success',
              duration: 3000,
            })
          );
        }
        return true;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (err) {
      handleApiError(err, dispatch)
    }
    return false;
  };
};
  
  // Create Case
  export const createCase = (caseData: CaseData) => {
    return async (dispatch: any): Promise<boolean> => {
      try {
        const resp = await apis({
          method: "POST",
          url: "/cases/create",
          data: caseData,
        });
  
        if (resp && (resp.status === 200 || resp.status === 201)) {
          dispatch({ type: CREATE_CASE, payload: resp.data });
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: resp.data.message || "Case created successfully!",
              modalType: "success",
              duration: 3000,
            })
          );
          return true;
        } else {
          handleApiError(resp, dispatch)
        }
      } catch (err) {
        handleApiError(err, dispatch)
      }
      return false;
    };
  };
  
  // Fetch Case
  export const fetchCase = (caseId: string) => {
    return async (dispatch: any): Promise<any> => {
      try {
        const resp = await apis({
          method: "GET",
          url: `/cases`,
          data: { case_id: caseId }
        });
  
        if (resp && resp.status === 200) {
          dispatch({ type: FETCH_CASE, payload: resp.data });
          return resp.data;
        } else {
          handleApiError(resp, dispatch)
        }
      } catch (err) {
        handleApiError(err, dispatch)
      }
      return null;
    };
  };
  
  // Fetch Client
    export const fetchClient = (clientId: string) => {
      return async (dispatch: any): Promise<any> => {
        try {
          const resp = await apis({
            method: "GET",
            url: `/clients/${clientId}`,
          });

          if (resp && resp.status === 200) {
            dispatch({ type: FETCH_CLIENT, payload: resp.data });
            return resp.data;
          } else {
            handleApiError(resp, dispatch)
          }
        } catch (err) {
          handleApiError(err, dispatch)
        }
        return null;
      };
    };
  
  // Delete Case Field
  export const deleteCaseField = (caseId: string, fieldName: string) => {
    return async (dispatch: any): Promise<boolean> => {
      try {
        const resp = await apis({
          method: "DELETE",
          url: `/cases/${caseId}/fields/${fieldName}`,
        });
  
        if (resp && resp.status === 200) {
          dispatch({ type: DELETE_CASE_FIELD, payload: { caseId, fieldName } });
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: resp.data.message || "Field deleted successfully!",
              modalType: "success",
              duration: 3000,
            })
          );
          return true;
        } else {
          handleApiError(resp, dispatch)
        }
      } catch (err) {
        handleApiError(err, dispatch)
      }
      return false;
    };
  };
  

  export const deleteClient = (clientId: string) => {
    return async (dispatch: any): Promise<boolean> => {
      try {
        const resp = await apis({
          method: 'POST',
          url: '/clients/remove_from_list',
          data: { ClientID: clientId },
        });
  
        if (resp && resp.status === 200) {
          // Optionally show success feedback
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: resp.data.message || 'Client removed successfully!',
              modalType: 'success',
              duration: 3000,
            })
          );
  
          // Re-fetch the updated client list
          await dispatch(fetchAllClients());
  
          return true;
        } else {
          handleApiError(resp, dispatch)
        }
      } catch (err) {
        handleApiError(err, dispatch)
      }
      return false;
    };
  };