import { Dispatch } from 'redux';
import {
  GET_MARKETPLACE_SETTINGS_REQUEST,
  GET_MARKETPLACE_SETTINGS_SUCCESS,
  GET_MARKETPLACE_SETTINGS_FAILURE,
  UPDATE_MARKETPLACE_SETTINGS_REQUEST,
  UPDATE_MARKETPLACE_SETTINGS_SUCCESS,
  UPDATE_MARKETPLACE_SETTINGS_FAILURE,
  UPLOAD_FIRM_LOGO_REQUEST,
  UPLOAD_FIRM_LOGO_SUCCESS,
  UPLOAD_FIRM_LOGO_FAILURE,
  UPLOAD_PROFILE_PICTURE_REQUEST,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAILURE,
  GET_LAWYER_TOS_REQUEST,
  GET_LAWYER_TOS_SUCCESS,
  GET_LAWYER_TOS_FAILURE,
  UPDATE_LAWYER_TOS_REQUEST,
  UPDATE_LAWYER_TOS_SUCCESS,
  UPDATE_LAWYER_TOS_FAILURE,
} from '../../types';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';
import { handleApiError } from '../utils/utils';

export const getMarketplaceSettings = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_MARKETPLACE_SETTINGS_REQUEST });
    try {
      const response = await apis({
        method: 'GET',
        url: '/marketplace/get_settings',
      });
      if (response.status === 200) {
        dispatch({
          type: GET_MARKETPLACE_SETTINGS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_MARKETPLACE_SETTINGS_FAILURE,
          error: 'Failed to fetch marketplace settings.',
        });
        handleApiError(response, dispatch);
      }
    } catch (error) {
      dispatch({
        type: GET_MARKETPLACE_SETTINGS_FAILURE,
        error: 'Failed to fetch marketplace settings.',
      });
      handleApiError(error, dispatch);
    }
  };
};

export const updateMarketplaceSettings = (settings: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_MARKETPLACE_SETTINGS_REQUEST });
    try {
      const response = await apis({
        method: 'POST',
        url: '/marketplace/update_settings',
        data: settings,
      });
      if (response.status === 200) {
        dispatch({
          type: UPDATE_MARKETPLACE_SETTINGS_SUCCESS,
          payload: response.data,
        });
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: 'Marketplace settings updated successfully.',
            duration: 3000,
          })
        );
      } else {
        dispatch({
          type: UPDATE_MARKETPLACE_SETTINGS_FAILURE,
          error: 'Failed to update marketplace settings.',
        });
        handleApiError(response, dispatch);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_MARKETPLACE_SETTINGS_FAILURE,
        error: 'Failed to update marketplace settings.',
      });
      handleApiError(error, dispatch);
    }
  };
};

export const uploadFirmLogo = (file: File) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UPLOAD_FIRM_LOGO_REQUEST });
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await apis({
        method: 'POST',
        url: '/marketplace/upload_firm_logo',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        dispatch({
          type: UPLOAD_FIRM_LOGO_SUCCESS,
          payload: response.data,
        });
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: 'Firm logo uploaded successfully.',
            duration: 3000,
          })
        );
      } else {
        dispatch({
          type: UPLOAD_FIRM_LOGO_FAILURE,
          error: 'Failed to upload firm logo.',
        });
        handleApiError(response, dispatch);
      }
    } catch (error) {
      dispatch({
        type: UPLOAD_FIRM_LOGO_FAILURE,
        error: 'Failed to upload firm logo.',
      });
      handleApiError(error, dispatch);
    }
  };
};

export const uploadProfilePicture = (file: File) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UPLOAD_PROFILE_PICTURE_REQUEST });
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await apis({
        method: 'POST',
        url: '/marketplace/upload_profile_picture',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        dispatch({
          type: UPLOAD_PROFILE_PICTURE_SUCCESS,
          payload: response.data,
        });
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: 'Profile picture uploaded successfully.',
            duration: 3000,
          })
        );
      } else {
        dispatch({
          type: UPLOAD_PROFILE_PICTURE_FAILURE,
          error: 'Failed to upload profile picture.',
        });
        handleApiError(response, dispatch);
      }
    } catch (error) {
      dispatch({
        type: UPLOAD_PROFILE_PICTURE_FAILURE,
        error: 'Failed to upload profile picture.',
      });
      handleApiError(error, dispatch);
    }
  };
};

export const getLawyerTOS = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_LAWYER_TOS_REQUEST });
    try {
      const response = await apis({
        method: 'POST',
        url: '/marketplace/get_lawyer_tos',
      });

      if (response.status === 200) {
        dispatch({
          type: GET_LAWYER_TOS_SUCCESS,
          payload: response.data,
        });
      } else {
        handleApiError(response, dispatch);
        dispatch({
          type: GET_LAWYER_TOS_FAILURE,
          error: 'Failed to get lawyer TOS.',
        });
      }
    } catch (error) {
      handleApiError(error, dispatch);
      dispatch({
        type: GET_LAWYER_TOS_FAILURE,
        error: 'Failed to get lawyer TOS.',
      });
    }
  };
};

export const updateLawyerTOS = (file: File) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_LAWYER_TOS_REQUEST });
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await apis({
        method: 'POST',
        url: '/marketplace/upload_tos',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        dispatch({
          type: UPDATE_LAWYER_TOS_SUCCESS,
          payload: response.data,
        });
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: 'Terms of Service updated successfully.',
            duration: 3000,
          })
        );
      } else {
        handleApiError(response, dispatch);
        dispatch({
          type: UPDATE_LAWYER_TOS_FAILURE,
          error: 'Failed to update Terms of Service.',
        });
      }
    } catch (error) {
      handleApiError(error, dispatch);
      dispatch({
        type: UPDATE_LAWYER_TOS_FAILURE,
        error: 'Failed to update Terms of Service.',
      });
    }
  };
};
