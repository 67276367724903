import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserType } from '../../../store/actions/ChatAction';
import { fetchAllEmployees } from '../../../store/actions/organization/employeeActions';
import { showOnboardingSidePanel } from '../../../store/actions/sidePanelActions';
import Badge from '../../shared/TailwindComponents/Badge';
import Header from '../../shared/TailwindComponents/Header';
import Table from '../../shared/TailwindComponents/Table';
import Pagination from '../../shared/TailwindComponents/Pagination';
import { fetchUserTypes } from '../../../store/actions/DataAction';
import { useHistory } from 'react-router-dom';
import { usePermission } from '../../../hooks/userPermissions';
import { useTranslation } from 'react-i18next';
import FilterSearchBar from '../../shared/TailwindComponents/FilterSearchBar';
import ApiLoader from '../../ApiLoader';

interface EmployeeRow {
  id: string;
  Name: string;
  Email: string;
  Designation: string;
  Status: React.ReactNode; 
}

const EmployeeList: React.FC = () => {
  const dispatch = useAppDispatch();

  const { employees, loading, error, currentPage, totalPages } = useSelector((state: RootState) => state.employees);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState('name_asc');
  const [page, setPage] = useState(1); // local state for page

  const userType = getUserType();
  const history = useHistory();
  const { isReadOnly } = usePermission('admin');
  const { t } = useTranslation();
  const employeesPerPage = 5;

  const filters = [
    {
      id: 'Status',
      name: 'Status',
      options: [
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
      ],
    }
  ];

  const sortOptions = [
    { value: 'name_asc', label: 'Name Ascending', current: true },
    { value: 'name_desc', label: 'Name Descending', current: false },
    { value: 'email_asc', label: 'Email Ascending', current: false },
    { value: 'email_desc', label: 'Email Descending', current: false },
  ];

  const getQueryParams = useCallback(() => {
    let sortBy = '';
    let sortOrder = 'asc';

    if (sortValue === 'name_asc') {
      sortBy = 'name';
      sortOrder = 'asc';
    } else if (sortValue === 'name_desc') {
      sortBy = 'name';
      sortOrder = 'desc';
    } else if (sortValue === 'email_asc') {
      sortBy = 'email';
      sortOrder = 'asc';
    } else if (sortValue === 'email_desc') {
      sortBy = 'email';
      sortOrder = 'desc';
    }

    const filtersObj: { [key: string]: string[] } = {};
    if (selectedFilters.Status && selectedFilters.Status.length > 0) {
      filtersObj.Status = selectedFilters.Status;
    }

    return {
      searchTerm,
      filters: filtersObj,
      sortBy,
      sortOrder,
      page,
      limit: employeesPerPage
    };
  }, [sortValue, selectedFilters, searchTerm, page, employeesPerPage]);

  const refetchEmployees = useCallback(async () => {
    const { searchTerm, filters, sortBy, sortOrder, page, limit } = getQueryParams();
    await dispatch(fetchAllEmployees({ searchTerm, filters, sortBy, sortOrder, page, limit }));
  }, [dispatch, getQueryParams]);

  useEffect(() => {
    dispatch(fetchUserTypes());
  }, [dispatch]);

  useEffect(() => {
    refetchEmployees();
  }, [refetchEmployees]);

  const handleAddEmployee = () => {
    dispatch(showOnboardingSidePanel());
  };

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
    setPage(1);
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
    setPage(1);
  };

  const handleSearchChange = (query: string) => {
    setSearchTerm(query);
    setPage(1);
  };

  const columns = [t('Name'), t('Email'), t('Designation'), t('Status')];

  const data: EmployeeRow[] = employees
    .map((employee): EmployeeRow | null => {
      if (!employee) {
        console.warn(t('Employee or Employee.User is undefined:'), employee);
        return null; 
      }
      const employeeStatus = employee.Status;
      return {
        id: employee.UserID,
        Name: `${employee.FirstName} ${employee.LastName}`,
        Email: employee.Email,
        Designation: employee.Designation || 'N/A',
        Status: (
          <Badge color={employeeStatus === 'Active' ? 'green' : 'gray'}>
            {t(employeeStatus)}
          </Badge>
        ),
      };
    })
    .filter((item): item is EmployeeRow => item !== null);

  if (userType !== 'BusinessAdmin' && userType !== 'LawFirmAdmin') {
    return <div className="p-4">{t('You do not have permission to view this page.')}</div>;
  }

  return (
    <div className="px-4">
      <Header
        title={t('Employees')}
        subtitle={t('Manage your employees')}
        actionLabel={t('Add Employee')}
        onActionClick={handleAddEmployee}
      />

      <FilterSearchBar
        filters={filters}
        sortOptions={sortOptions}
        selectedFilters={selectedFilters}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortChange}
        onSearchChange={handleSearchChange}
        sortValue={sortValue}
      />

      <div className="mt-4 relative">
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60 dark:bg-gray-800 dark:bg-opacity-60 z-10">
          <ApiLoader />          
      </div>
        )}
        <Table
          columns={columns}
          data={data}
          onRowClick={(row) => {
            const employee = employees.find((emp) => emp.UserID === row.id);
            if (employee) {
              history.push(`/management/employees/${employee.UserID}`);
            }
          }}
        />
      </div>

      <Pagination
        currentPage={page} // Use local page state (page) since that's what triggers refetch
        totalPages={totalPages}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
      />
    </div>
  );
};

export default EmployeeList;
