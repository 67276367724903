import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PiArrowsCounterClockwiseLight } from "react-icons/pi";
import { TbProgressAlert, TbProgressCheck } from 'react-icons/tb';

interface ProgressStep {
  step: string;
  status: string;
  messages?: string[];
}

interface ProgressStepperProps {
  steps: ProgressStep[];
}

const ProgressStepper: React.FC<ProgressStepperProps> = ({ steps }) => {
  // 1) Find "CaseAnalysisStart" step, rename to "Initial Overview," and move it to the front if found
  const reorderedSteps = reorderInitialOverview(steps);

  // 2) **Include** "Initial Overview" in the main list. We do NOT filter it out anymore!
  const totalSteps = reorderedSteps.length;
  const completedSteps = reorderedSteps.filter((step) => step.status === 'Completed').length;
  const progressPercentage = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;

  return (
    <div className="w-full p-4">
      {/* Progress bar */}
      <div className="mb-6">
        <div className="relative">
          <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200 dark:bg-gray-700">
            <div
              style={{ width: `${progressPercentage}%`, transition: 'width 0.5s ease-in-out' }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"
            />
          </div>
        </div>
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
          {completedSteps} of {totalSteps} steps completed
        </p>
      </div>

      {/* Step List */}
      <div className="space-y-4">
        {reorderedSteps.map((step, index) => {
          // Choose an icon for the step based on its status
          const icon =
            step.status === 'Completed' ? (
              <TbProgressCheck className="h-6 w-6 text-green-500 transition duration-500" />
            ) : step.status === 'In Progress' ? (
              <PiArrowsCounterClockwiseLight className="h-6 w-6 text-blue-500 animate-spin" />
            ) : (
              <TbProgressAlert className="h-6 w-6 text-yellow-500 transition duration-500" />
            );

          // Check if it's "Initial Overview"
          const isInitialOverview = step.step.toLowerCase() === 'initial overview';

          // If it's “Initial Overview,” we can show the label or choose to hide it. 
          // If you want to keep the label, remove the conditional below:
          const stepLabel = isInitialOverview ? 'Initial Overview' : step.step.replace('_', ' ');

          return (
            <div key={index} className="flex flex-col">
              {/* Row for step icon & title */}
              <div className="flex items-center ml-10">
                {icon}
                <p className="ml-4 text-sm font-medium capitalize">{stepLabel}</p>
              </div>

              {/* If step has messages, display them (indented under the row) */}
              {step.messages && step.messages.length > 0 && (
                <StepMessageList messages={step.messages} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
 * Finds any step named "CaseAnalysisStart" (case-insensitive),
 * renames it to "Initial Overview", and places it as the first step in the array
 * so it appears at the top of the list in the final rendering.
 */
function reorderInitialOverview(steps: ProgressStep[]): ProgressStep[] {
  const copy = [...steps];
  const idx = copy.findIndex(
    (s) => s.step.toLowerCase() === 'caseanalysisstart'
  );
  if (idx > -1) {
    copy[idx].step = 'Initial Overview'; 
    const [overview] = copy.splice(idx, 1);
    copy.unshift(overview);
  }
  return copy;
}

interface StepMessageListProps {
  messages: string[];
}

const StepMessageList: React.FC<StepMessageListProps> = ({ messages }) => {
  // We'll align messages at "ml-16"
  return (
    <div className="flex flex-col space-y-1 ml-16 mt-1">
      {messages.map((msg, i) => (
        <StepMessage key={i} message={msg} />
      ))}
    </div>
  );
};

interface StepMessageProps {
  message: string;
}
const StepMessage: React.FC<StepMessageProps> = ({ message }) => {
  const [displayMessage, setDisplayMessage] = useState(message);

  useEffect(() => {
    setDisplayMessage(message);
  }, [message]);

  return (
    <Transition
      appear
      show
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div className="text-xs text-gray-500 dark:text-gray-300 transition-opacity duration-500 prose dark:!prose-invert">
        <MessageMarkdown content={displayMessage} />
      </div>
    </Transition>
  );
};

interface MessageMarkdownProps {
  content: string;
}
const MessageMarkdown: React.FC<MessageMarkdownProps> = ({ content }) => (
  <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
);

export default ProgressStepper;
