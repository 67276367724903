import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import {
  chatGroupApi,
  ChatGroupData,
  getUserID,
  getUserType,
  setChatGroupIDStore,
} from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { fetchStructure } from '../../store/actions/FolderAction';
import { RootState, useAppDispatch } from '../../store';
import {
  showAddFileSidePanel,
  showCreateProjectSidePanel,
} from '../../store/actions/sidePanelActions';
import AISettingsComponent from '../Settings/AccountSettings/AISettings';
import ApiLoader from '../ApiLoader';
import { FileProps } from '../Documents/types';
import AIFolderList from './AIFolderList';
import Button from '../shared/TailwindComponents/Button';
import { hideModal, showCreateFolderModal, showModal } from '../../store/actions/modalActions';
import { Tooltip } from '@mui/material';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { AiOutlineDelete } from 'react-icons/ai';
import { usePermission } from '../../hooks/userPermissions';
import { fetchProjects } from '../../store/actions/ProjectActions';
import Badge from '../shared/TailwindComponents/Badge';
import { CiLock, CiUnlock } from "react-icons/ci";
import { BsChatLeftText } from 'react-icons/bs';
import { PiFilePlusLight, PiFolderSimplePlusLight } from 'react-icons/pi';

interface AISidebarProps {
  onFileSelect: (file: FileProps) => void;
  currentView: 'Folders' | 'Projects' | 'ChatHistory' | 'AISettings';
  setCurrentView: React.Dispatch<
    React.SetStateAction<'Folders' | 'Projects' | 'ChatHistory' | 'AISettings'>
  >;
  onSelectChatGroup: (chatGroupId: string) => void;
  handleDeleteChat: (chatGroupId: string) => void;
  removeSpaces: (str: string) => string;
  onProjectSelect: (projectId: string | null) => void; 
}

const AISidebar: React.FC<AISidebarProps> = ({
  onFileSelect,
  currentView,
  setCurrentView,
  onSelectChatGroup,
  handleDeleteChat,
  removeSpaces,
  onProjectSelect
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const userType = getUserType();
  const userId = getUserID();
  const structure = useSelector((state: RootState) => state.folders.structure);
  const chatGroups = useSelector((state: RootState) => state.model.chatGroups);
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const isFoldersLoading = useSelector((state: RootState) => state.folders.loading);
  const selectedChatGroupId = useSelector((state: RootState) => state.model.chatGroupID);
  const [searchQuery, setSearchQuery] = useState('');
  const { hasAccess, isReadOnly, loading } = usePermission('documents');
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);

  useEffect(() => {
    if (currentView === 'Folders') {
      dispatch(fetchStructure());
    } else if (currentView === 'ChatHistory' && chatGroups.length === 0) {
      dispatch(chatGroupApi(selectedModel.model_name));
    }
    // Add other data fetching as needed
  }, [currentView, dispatch, userId, userType, selectedModel, chatGroups.length]);

  const allProjects = useSelector((state: RootState) => Object.values(state.projects.projects));

  useEffect(() => {
    if (currentView === 'Projects') {
      dispatch(fetchProjects());
    }
  }, [currentView, dispatch]);


  const handleOpenCreateProject = () => {
    dispatch(showCreateProjectSidePanel());
  };

  const handleSelectFolder = (folderId: string) => {
    // Handle folder selection if needed
  };

  const handleSelectChatGroup = (chatGroupId: string) => {
    onSelectChatGroup(chatGroupId);
    dispatch(setChatGroupIDStore(chatGroupId)); // Keep track of selected group
  };

  const handleAddFolder = () => {
    dispatch(showCreateFolderModal());
  };

  const handleAddFile = () => {
    dispatch(showAddFileSidePanel(structure));
  };

  // Filtered Data
  const filteredChatGroups = chatGroups.filter((chatGroup: ChatGroupData) =>
    chatGroup.ChatTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const searchFoldersAndFiles = (folders: any, query: string) => {
    const filteredFolders: any[] = [];
  
    folders.forEach((folder: any) => {
      const folderNameMatches = folder.FolderName.toLowerCase().includes(query.toLowerCase());
      const subFolders = folder.SubFolders ? searchFoldersAndFiles(folder.SubFolders, query) : [];
      const files = folder.Files
        ? folder.Files.filter((file: any) =>
            file.FileName.toLowerCase().includes(query.toLowerCase())
          )
        : [];
  
      const shouldOpen = folderNameMatches || subFolders.some((sf) => sf.shouldOpen) || files.length > 0;
  
      if (shouldOpen) {
        filteredFolders.push({
          ...folder,
          SubFolders: subFolders,
          Files: files,
          shouldOpen,
        });
      }
    });
  
    return filteredFolders;
  };

  const handleProjectSelect = (projectId: string) => {
    setSelectedProjectId((prevProjectId) => (prevProjectId === projectId ? null : projectId));
    onProjectSelect(selectedProjectId === projectId ? null : projectId); 
  };
  

  const filteredStructure = searchFoldersAndFiles(structure, searchQuery);

  return (
    <aside className="w-64 h-full bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 flex flex-col">
      {/* Header Section */}
      <div className="flex-shrink-0">
        {/* Search Bar */}
        <div className="px-2 my-4">
          <input
            type="text"
            placeholder={t('Search...')}
            className="w-full px-3 py-2 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white rounded"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        {/* Navigation Buttons */}
        <div className="mt-2 w-full bg-gray-100 dark:bg-gray-700">
          <div className="py-1">
            <button
              onClick={() => setCurrentView('Folders')}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 flex justify-between items-center"
            >
              <span>{t('Vault')}</span>
              {currentView === "Folders" ? <CiUnlock size={20} color='#000'/> :  <CiLock size={20} color='#000'/>}
              
            </button>
            {(userType === 'BusinessAdmin' || userType === 'BusinessEmployee') && (
              <button
                onClick={() => setCurrentView('Projects')}
                className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600"
              >
                {t('Projects')}
              </button>
            )}
            {/* <button
              onClick={() => setCurrentView('ChatHistory')}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 flex justify-between items-center"
            >
              <span>{t('Chats')}</span>
              <BsChatLeftText size={20} color='#000'/>
            </button> */}
          </div>
        </div>
      </div>

      {/* Content Section (Scrollable) */}
      <div className={`flex-1 ${currentView === "AISettings" ? 'overflow-y-visible' : 'overflow-y-auto'}`}>
        {currentView === 'Folders' && (
          <div className="p-2">
            {/* Render folders and files */}
            {isFoldersLoading ? (
              <div className="flex justify-center items-center h-full">
                <ApiLoader />
              </div>
            ) : (
              <AIFolderList
                folders={filteredStructure}
                onSelectFolder={handleSelectFolder}
                selectedFolderId={null}
                isLoading={false}
                handleCreateFolder={(folderName) => {
                  /* Handle folder creation */
                }}
                onFileSelect={onFileSelect}
              />
            )}
          </div>
        )}
        {currentView === 'ChatHistory' && (
          <div className="p-2">
            {/* Render chat history */}
            {isChatLoading ? (
              <div className="flex justify-center items-center h-full">
                <ApiLoader />
              </div>
            ) : filteredChatGroups && filteredChatGroups.length > 0 ? (
              <ul>
                <TransitionGroup component={null}>
                  {filteredChatGroups.map((chatGroup: ChatGroupData) => (
                    <CSSTransition
                      key={chatGroup.ChatGroupID}
                      timeout={300}
                      classNames="chat-group"
                      appear
                    >
                      <li
                        className={`flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer ${
                          selectedChatGroupId === chatGroup.ChatGroupID
                            ? 'bg-gray-200 dark:bg-gray-600'
                            : ''
                        }`}
                      >
                        <div onClick={() => handleSelectChatGroup(chatGroup.ChatGroupID)}>
                          <p className="text-xs font-medium">{chatGroup.ChatTitle}</p>
                          <p className="text-xs text-gray-500">
                            {t(addSpacesBeforeCaps(chatGroup.AIType))}
                          </p>
                        </div>
                        {/* Delete Button */}
                        <Tooltip title={t('Delete Chat')} placement="left">
                          <button
                            className="text-gray-500 hover:text-red-500"
                            onClick={() => handleDeleteChat(chatGroup.ChatGroupID)}
                          >
                            <AiOutlineDelete size={16} />
                          </button>
                        </Tooltip>
                      </li>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </ul>
            ) : (
              <div className='h-[calc(100vh-450px)] flex align-center justify-center'>
                <Badge color='gray' className='h-fit self-center'>{t('No Chat History')}
                  </Badge>
                </div>
            )}
          </div>
        )}
        {currentView === 'AISettings' && (
          <div className="px-2">
            {/* Render AI Settings */}
            <AISettingsComponent
              style={{ flexDirection: 'column', alignItems: 'flex-start', gap: 10 }}
              backgroundColor={'transparent'}
            />
          </div>
        )}

        {currentView === 'Projects' && (
            <div className="p-2">
              {allProjects.length === 0 ? (
                <div className='h-[calc(100vh-450px)] flex align-center justify-center'>
                <Badge color='gray' className='h-fit self-center'>{t('No projects available')}</Badge>
                </div>
              ) : (
                <ul>
                  {allProjects.map((project) => (
                    <li
                      key={project.ProjectID}
                      className={`p-2 cursor-pointer flex items-center ${
                        selectedProjectId === project.ProjectID ? 'bg-blue-200' : ''
                      }`}
                      onClick={() => handleProjectSelect(project.ProjectID)}
                    >
                      <i className="fas fa-briefcase mr-2 text-blue-500"></i>
                      <span>{project.ProjectName}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

      </div>

      {/* Footer Section (Static Buttons) */}
      <div className="flex-shrink-0 p-2 border-t border-gray-200 dark:border-gray-700 flex flex-col space-y-1">
        {/* Place your action buttons here */}
        {/* Example: */}
        {currentView === 'Projects' &&
          (userType === 'BusinessAdmin' || userType === 'BusinessEmployee') && (
            <Button
              variant="primary"
              onClick={handleOpenCreateProject}
              className="w-full justify-between"
            >
              <span>{t('Create new project')}</span>
              <i className="fas fa-file-plus"></i>
            </Button>
          )}
        <Button 
        disabled={isReadOnly}
        variant="primary" onClick={handleAddFolder} className="w-full justify-between">
          <span>{t('Create new folder')}</span>
          <PiFolderSimplePlusLight size={20} />
          </Button>
        <Button 
        disabled={isReadOnly}
        variant="primary" onClick={handleAddFile} className="w-full justify-between">
          <span>{t('Create new file')}</span>
          <PiFilePlusLight size={20}  />
          </Button>
      </div>
    </aside>
  );
};

function addSpacesBeforeCaps(str: string): string {
  return str.replace(/([A-Z])/g, ' $1').trim();
}

export default AISidebar;
