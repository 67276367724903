// EngagementLetterEditor.tsx
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';

export interface PaymentDetails {
  HourlyRate: string;
  DueDate: string;
  BillingMethod: string;
  Discounts: string;
  AgreedFee: string;
  AdditionalFees: string;
  PaymentMethod: string;
  FinancialNotes: string;
  Deposit: string;
  PaymentInterval: string;
  LegalAid: boolean;
}

export interface EngagementLetterResponse {
  jurisdiction: string;
  lawyerName: string;
  clientName: string;
  dateOfAgreement: string;
  subject: string;
  engagementDetails: string;
  paymentDetails: PaymentDetails;
  caseTermInfo: {
    CaseID: string;
    CaseType: string;
  };
  estimatedTimeline: string;
}

interface EngagementLetterEditorProps {
  engagementLetter: EngagementLetterResponse;
  onSave: (updatedEngagementLetter: EngagementLetterResponse) => void;
}

// Default values for payment details in case they’re missing from the backend.
const defaultPaymentDetails: PaymentDetails = {
  HourlyRate: "",
  DueDate: "",
  BillingMethod: "",
  Discounts: "",
  AgreedFee: "",
  AdditionalFees: "",
  PaymentMethod: "",
  FinancialNotes: "",
  Deposit: "",
  PaymentInterval: "",
  LegalAid: false,
};

const EngagementLetterEditor: React.FC<EngagementLetterEditorProps> = ({ engagementLetter, onSave }) => {
  const { t } = useTranslation();
  
  // Ensure that paymentDetails is defined.
  const initialLetter: EngagementLetterResponse = {
    ...engagementLetter,
    paymentDetails: engagementLetter.paymentDetails || defaultPaymentDetails,
  };

  const [letter, setLetter] = useState<EngagementLetterResponse>(initialLetter);

  useEffect(() => {
    // Update local state when the prop changes, ensuring paymentDetails is set.
    setLetter({
      ...engagementLetter,
      paymentDetails: engagementLetter.paymentDetails || defaultPaymentDetails,
    });
  }, [engagementLetter]);

  // Update top-level fields (e.g. engagementDetails, subject, estimatedTimeline)
  const handleChange = (
    field: keyof Omit<EngagementLetterResponse, 'paymentDetails' | 'caseTermInfo'>,
    value: string
  ) => {
    setLetter((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Update fields within paymentDetails
  const handlePaymentChange = (field: keyof PaymentDetails, value: string | boolean) => {
    setLetter((prev) => ({
      ...prev,
      paymentDetails: {
        ...prev.paymentDetails,
        [field]: value,
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(letter);
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6">{t('Engagement Letter Editor')}</h2>

      {/* Payment Details Section */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">{t('Payment Details')}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Textarea
            id="hourlyRate"
            name="hourlyRate"
            label={t('Hourly Rate')}
            value={letter.paymentDetails.HourlyRate}
            onChange={(e) => handlePaymentChange('HourlyRate', e.target.value)}
            rows={1}
          />
          <Textarea
            id="dueDate"
            name="dueDate"
            label={t('Due Date')}
            value={letter.paymentDetails.DueDate}
            onChange={(e) => handlePaymentChange('DueDate', e.target.value)}
            rows={1}
          />
          <Textarea
            id="billingMethod"
            name="billingMethod"
            label={t('Billing Method')}
            value={letter.paymentDetails.BillingMethod}
            onChange={(e) => handlePaymentChange('BillingMethod', e.target.value)}
            rows={1}
          />
          <Textarea
            id="agreedFee"
            name="agreedFee"
            label={t('Agreed Fee')}
            value={letter.paymentDetails.AgreedFee}
            onChange={(e) => handlePaymentChange('AgreedFee', e.target.value)}
            rows={1}
          />
          <Textarea
            id="paymentInterval"
            name="paymentInterval"
            label={t('Payment Interval')}
            value={letter.paymentDetails.PaymentInterval}
            onChange={(e) => handlePaymentChange('PaymentInterval', e.target.value)}
            rows={1}
          />
          <Textarea
            id="deposit"
            name="deposit"
            label={t('Deposit')}
            value={letter.paymentDetails.Deposit}
            onChange={(e) => handlePaymentChange('Deposit', e.target.value)}
            rows={1}
          />
          {/* Add additional payment fields as needed */}
        </div>
      </div>

      {/* Engagement Letter Content Section */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">{t('Engagement Letter Content')}</h3>
        <Textarea
          id="engagementDetails"
          name="engagementDetails"
          label={t('Letter Content')}
          value={letter.engagementDetails}
          onChange={(e) => handleChange('engagementDetails', e.target.value)}
          rows={15}
        />
      </div>

      {/* Additional Information Section */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">{t('Additional Information')}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Textarea
            id="subject"
            name="subject"
            label={t('Subject')}
            value={letter.subject}
            onChange={(e) => handleChange('subject', e.target.value)}
            rows={1}
          />
          <Textarea
            id="estimatedTimeline"
            name="estimatedTimeline"
            label={t('Estimated Timeline')}
            value={letter.estimatedTimeline}
            onChange={(e) => handleChange('estimatedTimeline', e.target.value)}
            rows={1}
          />
        </div>
      </div>

      <div className="flex justify-end">
        <Button type="submit" variant="primary">
          {t('Save Engagement Template')}
        </Button>
      </div>
    </form>
  );
};

export default EngagementLetterEditor;
