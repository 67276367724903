import { Dispatch } from 'redux';
import { IAddProjectPayload, IProject } from '../../components/Projects/ProjectsInterfaces';
import apis from '../../utils/apis';
import { ADD_PROJECT_REQUEST, ADD_PROJECT_SUCCESS, ADD_PROJECT_FAILURE, EDIT_PROJECT_REQUEST, EDIT_PROJECT_SUCCESS, EDIT_PROJECT_FAILURE, ADD_PROJECT_ASSIGNEE_REQUEST, ADD_PROJECT_ASSIGNEE_SUCCESS, ADD_PROJECT_ASSIGNEE_FAILURE, FETCH_PROJECTS_REQUEST, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE, FETCH_PROJECT_DETAILS_REQUEST, FETCH_PROJECT_DETAILS_SUCCESS, FETCH_PROJECT_DETAILS_FAILURE, DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE, APPROVE_PROJECT_REQUEST, APPROVE_PROJECT_SUCCESS, APPROVE_PROJECT_FAILURE, FETCH_USER_PROJECTS_REQUEST, } from '../types';
import { handleApiError } from './utils/utils';



// Action Creator for Adding a Project
export const addProject = (data: any) => {
  return async (dispatch: Dispatch<any>): Promise<IProject | null> => {
    dispatch({ type: ADD_PROJECT_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/add_project',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_PROJECT_SUCCESS, payload: resp.data });
        return resp.data;
      } else {
        dispatch({ type: ADD_PROJECT_FAILURE, payload: resp.data?.message || 'Failed to add project' });
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      dispatch({ type: ADD_PROJECT_FAILURE, payload: err.response?.data?.message || 'Failed to add project' });
      handleApiError(err, dispatch)
    }
    return null;
  };
};

// Action Creator for Editing a Project
export const editProject = (data: IAddProjectPayload) => {
  return async (dispatch: Dispatch<any>): Promise<IProject | null> => {
    dispatch({ type: EDIT_PROJECT_REQUEST });
    try {
      const resp = await apis({
        method: 'PUT',
        url: '/projects/edit_project',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: EDIT_PROJECT_SUCCESS, payload: resp.data });
        return resp.data;
      } else {
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      dispatch({ type: EDIT_PROJECT_FAILURE, payload: err.response?.data?.message || 'Failed to edit project' });
      handleApiError(err, dispatch)
    }
    return null;
  };
};

// Action Creator for Adding Project Assignees
export const addProjectAssignee = (data: { ProjectID: string; Assignees: string[] }) => {
  return async (dispatch: Dispatch<any>): Promise<any> => {
    dispatch({ type: ADD_PROJECT_ASSIGNEE_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/addProjectAssignee',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ADD_PROJECT_ASSIGNEE_SUCCESS, payload: resp.data });
        return resp.data;
      } else {
        dispatch({ type: ADD_PROJECT_ASSIGNEE_FAILURE, payload: resp.data?.message || 'Failed to add assignees' });
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      dispatch({ type: ADD_PROJECT_ASSIGNEE_FAILURE, payload: err.response?.data?.message || 'Failed to add assignees' });
      handleApiError(err, dispatch)
    }
    return null;
  };
};

export const fetchProjects = () => {
  return async (dispatch: Dispatch<any>): Promise<IProject[] | null> => {
    dispatch({ type: FETCH_PROJECTS_REQUEST });
    try {
      const resp = await apis({
        method: 'GET',
        url: 'projects/get_projects',
      });

      if (resp && resp.status === 200) {

        const mappedProjects: IProject[] = resp.data.Projects.length > 0 ? resp.data.Projects.map((project: any) => ({
          ProjectID: project.ProjectID,
          UserID: project.UserID || 0,
          UserType: project.UserType || '',
          AllocatedServices: project.ServiceAllocations || [], // Matches IServiceAllocation[]
          ProjectDescription: project.ProjectDescription || '',
          CategoryType: project.CategoryType || '',
          CategoryName: project.CategoryName || '',
          EndDate: project.EndDate || '',
          PriorityLevel: project.PriorityLevel || '',
          PriorityName: project.PriorityName || '',
          ProjectApprover: project.ProjectApproverID ? {
            ProjectApproverID: project.ProjectApproverID,
            ProjectApproverName: project.ProjectApproverName || '',
          } : null,
          ProjectLead: project.ProjectLeadID ? {
            ProjectLeadID: project.ProjectLeadID,
            ProjectLeadName: project.ProjectLeadName || '',
          } : null,
          ProjectMembers: project.ProjectMembers || [],
          ProjectName: project.ProjectName || '',
          StartDate: project.StartDate || '',
          Status: project.Status || '',
          StatusID: project.StatusID || '',
          CreatedAt: project.CreatedAt || '',
          ApprovedBy: project.ApprovedBy || null,
          ApprovalDate: project.ApprovalDate || null,
          OrganizationID: project.OrganizationID || 0,
          ProjectDocuments: project.ProjectDocuments || [],
        })) : [];

        dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: mappedProjects });
        return mappedProjects;
      } else {
        dispatch({ type: FETCH_PROJECTS_FAILURE, payload: resp.data?.message || 'Failed to fetch projects' });
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      dispatch({ type: FETCH_PROJECTS_FAILURE, payload: err.response?.data?.message || 'Failed to fetch projects' });
      handleApiError(err, dispatch)
    }
    return null;
  };
};

// Action Creator for Fetching Project Details
export const fetchProjectDetails = (data: { ProjectID: string }) => {
  return async (dispatch: Dispatch<any>): Promise<IProject | null> => {
    dispatch({ type: FETCH_PROJECT_DETAILS_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/get_project_details',
        data,
      });

      if (resp && resp.status === 200) {
        const project = resp.data.projectDetails;

        const mappedProject: IProject = {
          ProjectID: project.ProjectID,
          UserID: project.UserID || 0,
          UserType: project.UserType || '',
          AllocatedServices: project.ServiceAllocations || [],
          ProjectDescription: project.ProjectDescription || '',
          CategoryType: project.CategoryType || '',
          CategoryName: project.CategoryName || '',
          EndDate: project.EndDate || '',
          PriorityLevel: project.PriorityLevel || '',
          PriorityName: project.PriorityName || '',
          ProjectApprover: project.ProjectApproverID ? {
            ProjectApproverID: project.ProjectApproverID,
            ProjectApproverName: project.ProjectApproverName || '',
          } : null,
          ProjectLead: project.ProjectLeadID ? {
            ProjectLeadID: project.ProjectLeadID,
            ProjectLeadName: project.ProjectLeadName || '',
          } : null,
          ProjectMembers: project.ProjectMembers || [],
          ProjectName: project.ProjectName || '',
          StartDate: project.StartDate || '',
          Status: project.Status || '',
          StatusID: project.StatusID || '',
          CreatedAt: project.CreatedAt || '',
          ApprovedBy: project.ApprovedBy || null,
          ApprovalDate: project.ApprovalDate || null,
          OrganizationID: project.OrganizationID || 0,
          ProjectDocuments: project.ProjectDocuments || [],
        };


        dispatch({ type: FETCH_PROJECT_DETAILS_SUCCESS, payload: mappedProject });
        return mappedProject;
      } else {
        dispatch({
          type: FETCH_PROJECT_DETAILS_FAILURE,
          payload: resp.data?.message || 'Failed to fetch project details',
        });
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      dispatch({
        type: FETCH_PROJECT_DETAILS_FAILURE,
        payload: err.response?.data?.message || 'Failed to fetch project details',
      });
      handleApiError(err, dispatch)
    }
    return null;
  };
};

// Action Creator for Deleting a Project
export const deleteProject = (data: { ProjectID: string }) => {
  return async (dispatch: Dispatch<any>): Promise<boolean> => {
    dispatch({ type: DELETE_PROJECT_REQUEST });
    try {
      const resp = await apis({
        method: 'DELETE',
        url: '/projects/delete_project',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: DELETE_PROJECT_SUCCESS, payload: data });
        return true;
      } else {
        dispatch({ type: DELETE_PROJECT_FAILURE, payload: resp.data?.message || 'Failed to delete project' });
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      dispatch({ type: DELETE_PROJECT_FAILURE, payload: err.response?.data?.message || 'Failed to delete project' });
      handleApiError(err, dispatch)
    }
    return false;
  };
};

// Action Creator for Approving a Project
export const approveProject = (data: { ProjectID: string }) => {
  return async (dispatch: Dispatch<any>): Promise<boolean> => {
    dispatch({ type: APPROVE_PROJECT_REQUEST });
    try {
      const resp = await apis({
        method: 'POST',
        url: '/projects/approve_project',
        data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: APPROVE_PROJECT_SUCCESS, payload: resp.data });
        return true;
      } else {
        dispatch({ type: APPROVE_PROJECT_FAILURE, payload: resp.data?.message || 'Failed to approve project' });
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      dispatch({ type: APPROVE_PROJECT_FAILURE, payload: err.response?.data?.message || 'Failed to approve project' });
      handleApiError(err, dispatch)
    }
    return false;
  };
};

// Action Creator for Fetching User's Projects
export const fetchUserProjects = () => {
  return async (dispatch: Dispatch<any>): Promise<IProject[] | null> => {
    dispatch({ type: FETCH_USER_PROJECTS_REQUEST });
    try {
      const resp = await apis({
        method: 'GET',
        url: '/projects/get_member_projects',
      });

      if (resp && resp.status === 200) {
        const mappedProjects: IProject[] = resp.data.Projects.length > 0 ? resp.data.Projects.map((project: any) => ({
          ProjectID: project.ProjectID,
          UserID: project.UserID || 0,
          UserType: project.UserType || '',
          AllocatedServices: project.ServiceAllocations || [], // Matches IServiceAllocation[]
          ProjectDescription: project.ProjectDescription || '',
          CategoryType: project.CategoryType || '',
          CategoryName: project.CategoryName || '',
          EndDate: project.EndDate || '',
          PriorityLevel: project.PriorityLevel || '',
          PriorityName: project.PriorityName || '',
          ProjectApprover: project.ProjectApproverID ? {
            ProjectApproverID: project.ProjectApproverID,
            ProjectApproverName: project.ProjectApproverName || '',
          } : null,
          ProjectLead: project.ProjectLeadID ? {
            ProjectLeadID: project.ProjectLeadID,
            ProjectLeadName: project.ProjectLeadName || '',
          } : null,
          ProjectMembers: project.ProjectMembers || [],
          ProjectName: project.ProjectName || '',
          StartDate: project.StartDate || '',
          Status: project.Status || '',
          StatusID: project.StatusID || '',
          CreatedAt: project.CreatedAt || '',
          ApprovedBy: project.ApprovedBy || null,
          ApprovalDate: project.ApprovalDate || null,
          OrganizationID: project.OrganizationID || 0,
          ProjectDocuments: project.ProjectDocuments || [],
        })) : [];

        dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: mappedProjects });
        return mappedProjects;
      } else {
        dispatch({ type: FETCH_PROJECTS_FAILURE, payload: resp.data?.message || 'Failed to fetch user projects' });
        handleApiError(resp, dispatch)
      }
    } catch (err: any) {
      dispatch({ type: FETCH_PROJECTS_FAILURE, payload: err.response?.data?.message || 'Failed to fetch user projects' });
      handleApiError(err, dispatch)
    }
    return null;
  };
};