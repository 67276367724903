import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../store';
import Tabs from '../components/shared/TailwindComponents/Tabs';
import EmployeeTimeTable from '../components/TimeTracker/Admin/EmployeeTimeTable';
import { getEmployeeTimeDataByMode } from '../store/actions/TimeManagementActions';
import { isBetaEnv } from '../utils/authUtils';
import Badge from '../components/shared/TailwindComponents/Badge';

const TimeTrackingPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<any>('daily');
  const [employeeTimeData, setEmployeeTimeData] = useState<any[]>([]);

  const tabs = [
    { label: t('Daily'), value: 'daily' },
    { label: t('Weekly'), value: 'weekly' },
    { label: t('Monthly'), value: 'monthly' },
    { label: t('Yearly'), value: 'yearly' },
  ];

  const handleTabClick = (tabValue: string) => {
    setActiveTab(tabValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getEmployeeTimeDataByMode(activeTab));
      setEmployeeTimeData(data);
    };
    {!isBetaEnv && (
      fetchData()
    )}
  }, [dispatch, activeTab]);

  return (
    <div className="h-[calc(100vh-90px)] flex flex-col px-4">
      {isBetaEnv ? (
        <div className="flex h-calc-100vh-90px justify-center items-center"><Badge color='gray'>{t('Coming soon')}</Badge></div>
      ) : (
        <>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
      <div className="flex-grow overflow-y-auto">
        <EmployeeTimeTable employeeTimeData={employeeTimeData} timeInterval={activeTab}/>
      </div>
      </>
      )}
    </div>
  );
};

export default TimeTrackingPage;
