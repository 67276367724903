import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSidePanel } from '../../store/actions/sidePanelActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { useAppDispatch } from '../../store';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import Toggle from '../shared/TailwindComponents/Toggle';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchCaseById, fetchCases } from '../../store/actions/marketplace2/caseActions';
import { fetchDesiredOutcomes } from '../../store/actions/DropdownActions';
import { RootState } from '../../store';
import { publishCase } from '../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import { useTranslation } from 'react-i18next';
import Badge from '../shared/TailwindComponents/Badge';

interface PublishCaseFormProps {
  caseItem: any; 
}

const PublishCaseForm: React.FC<PublishCaseFormProps> = ({ caseItem }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Map the AI description and categories from the summary (caseItem)
  const [formData, setFormData] = useState({
    IsPublishedToMarketplace: caseItem.IsPublishedToMarketplace || false,
    MarketplaceData: {
      // Map AI_Description from summary (not from MarketplaceData)
      AnonymizedDescription: caseItem.AI_Description || '',
      // For the other fields we still check MarketplaceData (if they exist)
      BudgetRange: {
        Min: caseItem.MarketplaceData?.BudgetRange?.Min || '',
        Max: caseItem.MarketplaceData?.BudgetRange?.Max || '',
      },
      Deadline: caseItem.MarketplaceData?.Deadline
        ? new Date(caseItem.MarketplaceData.Deadline)
        : null,
      DesiredOutcome: caseItem.MarketplaceData?.DesiredOutcome || '',
      AdditionalNotes: caseItem.MarketplaceData?.AdditionalNotes || '',
      // Map Categories directly from summary
      Categories: caseItem.Categories || [],
      AttachedFiles: caseItem.MarketplaceData?.AttachedFiles || [],
    },
  });

  // Fetch desired outcomes
  useEffect(() => {
    dispatch(fetchDesiredOutcomes());
  }, [dispatch]);

  const desiredOutcomes = useSelector((state: RootState) => state.dropdown.desiredOutcomes) || [];

  // Handlers for editable fields
  const handleMarketplaceDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      MarketplaceData: {
        ...prevData.MarketplaceData,
        [name]: value,
      },
    }));
  };

  const handleDeadlineChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      MarketplaceData: {
        ...prevData.MarketplaceData,
        Deadline: date,
      },
    }));
  };

  const handleToggleChange = (enabled: boolean) => {
    setFormData((prevData) => ({
      ...prevData,
      IsPublishedToMarketplace: enabled,
    }));
  };

  const handleBudgetMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      MarketplaceData: {
        ...prevData.MarketplaceData,
        BudgetRange: {
          ...prevData.MarketplaceData.BudgetRange,
          Min: value,
        },
      },
    }));
  };

  const handleBudgetMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      MarketplaceData: {
        ...prevData.MarketplaceData,
        BudgetRange: {
          ...prevData.MarketplaceData.BudgetRange,
          Max: value,
        },
      },
    }));
  };

  const handleDesiredOutcomeSelect = (option: DropdownOption) => {
    setFormData((prevData) => ({
      ...prevData,
      MarketplaceData: {
        ...prevData.MarketplaceData,
        DesiredOutcome: option.value,
      },
    }));
  };

  // Submit handler: assemble the payload and call publishCase
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const caseData = {
        CaseID: caseItem.CaseID,
        MarketplaceData: {
          AnonymizedDescription: formData.MarketplaceData.AnonymizedDescription,
          BudgetRange: {
            Min: parseFloat(formData.MarketplaceData.BudgetRange.Min) || 0,
            Max: parseFloat(formData.MarketplaceData.BudgetRange.Max) || 0,
          },
          Deadline: formData.MarketplaceData.Deadline
            ? formData.MarketplaceData.Deadline.toISOString().split('T')[0]
            : '',
          DesiredOutcome: formData.MarketplaceData.DesiredOutcome,
          AdditionalNotes: formData.MarketplaceData.AdditionalNotes,
          Categories: formData.MarketplaceData.Categories,
          AttachedFiles: formData.MarketplaceData.AttachedFiles,
        },
      };

      if (formData.IsPublishedToMarketplace) {
        await dispatch(publishCase({ ...caseData.MarketplaceData, CaseID: caseItem.CaseID }));
      }

      await dispatch(fetchCaseById(caseItem.CaseID));
      await dispatch(fetchCases());

      dispatch(hideSidePanel());
      dispatch(
        showFeedbackModal({
          modalType: 'success',
          showModal: true,
          message: t('Case updated.'),
          duration: 3000,
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          showModal: true,
          message: t('Failed to update case.'),
          duration: 3000,
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 space-y-6">
      <h2 className="text-2xl font-semibold mb-4">{t('Publish Case to Marketplace')}</h2>

      {/* --- Static Section: AI Description & Categories --- */}
      <div className="bg-gray-50 p-4 rounded-lg border">
        <h3 className="text-lg font-medium mb-2">{t('Description')}</h3>
        <p className="text-sm text-gray-700">
          {formData.MarketplaceData.AnonymizedDescription || t('No description provided.')}
        </p>
        <h3 className="text-lg font-medium mt-4 mb-2">{t('Categories')}</h3>
        {formData.MarketplaceData.Categories.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {formData.MarketplaceData.Categories.map((cat: string, index: number) => (
              <Badge key={index} color="blue">
                {cat}
              </Badge>
            ))}
          </div>
        ) : (
          <p className="text-sm text-gray-500">
            {t('Categories will be generated automatically based on case analysis.')}
          </p>
        )}
      </div>

      {/* --- Editable Section: Budget, Deadline, Desired Outcome, Additional Notes --- */}
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="grid grid-cols-2 gap-4">
          <Input
            id="BudgetMin"
            label={t('Budget Minimum')}
            placeholder={t('Minimum Budget')}
            name="BudgetMin"
            value={formData.MarketplaceData.BudgetRange.Min}
            onChange={handleBudgetMinChange}
            type="number"
          />
          <Input
            id="BudgetMax"
            label={t('Budget Maximum')}
            placeholder={t('Maximum Budget')}
            name="BudgetMax"
            value={formData.MarketplaceData.BudgetRange.Max}
            onChange={handleBudgetMaxChange}
            type="number"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">

        <div className="mt-4 w-full">
          <label className="block text-sm font-medium text-gray-700">{t('Deadline')}</label>
          <DatePicker
            selected={formData.MarketplaceData.Deadline}
            onChange={handleDeadlineChange}
            dateFormat="yyyy-MM-dd"
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all duration-300"
            placeholderText={t('Select a deadline')}
          />
        </div>
          <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">{t('Desired Outcome')}</label>
          <Dropdown
            options={desiredOutcomes}
            onSelect={handleDesiredOutcomeSelect}
            value={formData.MarketplaceData.DesiredOutcome}
            placeholder={t('Select Desired Outcome')}
          />
        </div>
        </div>


        <div className="mt-4">
          <Textarea
            id="AdditionalNotes"
            label={t('Additional Notes')}
            placeholder={t('Any additional notes...')}
            name="AdditionalNotes"
            value={formData.MarketplaceData.AdditionalNotes}
            onChange={handleMarketplaceDataChange}
          />
        </div>
      </div>

      {/* Publish Toggle */}
      <div className="flex items-center mt-4">
        <Toggle
          enabled={formData.IsPublishedToMarketplace}
          onToggle={() => handleToggleChange(true)}
          value={true}
        />
        <label className="ml-3 text-sm font-medium text-gray-700">{t('Publish to Marketplace')}</label>
      </div>

      {/* Form Actions */}
      <div className="flex justify-end mt-6 space-x-3">
        <Button variant="neutral" onClick={() => dispatch(hideSidePanel())}>
          {t('Cancel')}
        </Button>
        <Button variant="primary" type="submit">
          {formData.IsPublishedToMarketplace ? t('Save and Publish') : t('Save')}
        </Button>
      </div>
    </form>
  );
};

export default PublishCaseForm;
