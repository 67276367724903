import React from 'react';
import { useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import { showCreateFolderModal } from '../../store/actions/modalActions';
import { showAddFileSidePanel } from '../../store/actions/sidePanelActions';
import { FileProps, FolderProps } from '../Documents/types';
import AIFolderListItem from './AIFolderListItem';
import Button from '../shared/TailwindComponents/Button';
import ApiLoader from '../ApiLoader';

interface AIFolderListProps {
  folders: FolderProps[];
  onFileSelect: (file: FileProps) => void;
  onSelectFolder: (folderId: string) => void;
  selectedFolderId: string | null;
  isLoading: boolean;
  handleCreateFolder: (folderName: string) => void;
}

const AIFolderList: React.FC<AIFolderListProps> = ({
  folders,
  onFileSelect,
  onSelectFolder,
  selectedFolderId,
  isLoading,
  handleCreateFolder,
}) => {
  const { t } = useTranslation();


  return (
    <div className="h-full relative">
      {isLoading ? (
        <ApiLoader />
      ) : (
        <div className="space-y-2 overflow-y-auto h-full custom-scrollbar">
          {folders.map((folder) => (
            <AIFolderListItem
              key={folder.FolderID}
              folder={folder}
              onFileSelect={onFileSelect}
              onSelectFolder={onSelectFolder}
              selectedFolderId={selectedFolderId}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AIFolderList;
