
import { Dispatch } from 'redux';
import apis from '../../../../utils/apis';
import { showFeedbackModal } from '../../UserFeedbackActions';
import {
  GET_CASE_DETAILS_REQUEST, GET_CASE_DETAILS_SUCCESS, GET_MY_ACCESS_REQUESTS_REQUEST, GET_MY_ACCESS_REQUESTS_SUCCESS,
  GET_MY_ENGAGEMENTS_REQUEST, GET_MY_ENGAGEMENTS_SUCCESS, GET_PUBLISHED_CASES_REQUEST, GET_PUBLISHED_CASES_SUCCESS,
  GET_RELEVANT_CASES_REQUEST, GET_RELEVANT_CASES_SUCCESS, PROPOSE_ENGAGEMENT_REQUEST,
  REQUEST_CASE_ACCESS_REQUEST, REQUEST_CASE_ACCESS_SUCCESS, RESPOND_TO_INVITATION_REQUEST,
  SET_MARKETPLACE_ACTIVE_REQUEST, SET_MARKETPLACE_ACTIVE_SUCCESS,
  UPDATE_LAWYER_PROFILE_REQUEST, UPDATE_LAWYER_PROFILE_SUCCESS, UPLOAD_FINAL_DOCUMENT_REQUEST, UPLOAD_FINAL_DOCUMENT_SUCCESS, UPLOAD_TOS_REQUEST, UPLOAD_TOS_SUCCESS
} from '../../../reducer/marketplace2/MarketplaceProvider/MarketplaceProviderTypes';
import { handleApiError } from '../../utils/utils';
import { EngagementLetterResponse } from '../../../../components/Marketplace/Lawyer/EngagementTermsForm';


interface PublishedCase {
  CaseID: string;
  AnonymizedDescription: string;
  BudgetRange: { Min: number; Max: number };
  Deadline: string;
  // ... other fields
}

interface AccessRequest {
  EngagementID: string;
  CaseID: string;
  LawyerID: string;
  Status: string;
  Message: string;
  // ... other fields
}

interface CaseAccessRequestPayload {
  CaseID: string;
  Message?: string;
}

interface RespondToInvitationPayload {
  EngagementID: string;
  Decision: 'Accepted' | 'Declined';
}

interface ProposeEngagementPayload {
  CaseID: string;
  ProposedBudget: number | undefined;
  BudgetMin?: number | undefined;
  BudgetMax?: number | undefined;
  Message?: string;
  EstimatedTimeline?: string;
  AdditionalTerms?: string;
  EngagementID: string
}

interface SetMarketplaceActivePayload {
  is_active: boolean;
}

interface Engagement {
  EngagementID: string;
  CaseID: string;
  LawyerID: string;
  ClientID: string;
  ProposedBudget: number;
  Status: string;
  // ... other fields
}

// Action to get published cases
export const getPublishedCases = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_PUBLISHED_CASES_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/provider/published_cases',
    });

    if (resp && resp.status === 200) {
      const data = resp.data;
      dispatch({
        type: GET_PUBLISHED_CASES_SUCCESS,
        payload: data.cases as PublishedCase[],
      });

    } else {
      handleApiError(resp, dispatch);
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

// Action to get my access requests
export const getMyAccessRequests = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_MY_ACCESS_REQUESTS_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/provider/get_my_access_requests',
    });

    if (resp && resp.status === 200) {

      const data = resp.data;
      dispatch({
        type: GET_MY_ACCESS_REQUESTS_SUCCESS,
        payload: data.access_requests as AccessRequest[],
      });

    } else {
      handleApiError(resp, dispatch);
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

// Action to get my engagements
export const getMyEngagements = (page = 1, per_page = 10) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_MY_ENGAGEMENTS_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: `/marketplace/provider/get_my_engagements?page=${page}&per_page=${per_page}`,
    });

    if (resp && resp.status === 200) {
      const data = resp.data;
      dispatch({
        type: GET_MY_ENGAGEMENTS_SUCCESS,
        payload: data.engagements as Engagement[],
      });

    } else {
      handleApiError(resp, dispatch);
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const requestCaseAccess = (data: CaseAccessRequestPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: REQUEST_CASE_ACCESS_REQUEST });
  try {
    const resp: any = await apis({
      method: 'POST',
      url: '/marketplace/provider/request_case_access',
      data,
    });

    dispatch({
      type: REQUEST_CASE_ACCESS_SUCCESS,
    });

    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Access request submitted successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

    } else {
      handleApiError(resp, dispatch)
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

// Action to respond to case invitation
export const respondToInvitation = (data: RespondToInvitationPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: RESPOND_TO_INVITATION_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/respond_to_case_invitation',
      data,
    });


    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || `Invitation ${data.Decision.toLowerCase()} successfully`,
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp
    } else {
      handleApiError(resp, dispatch)
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const withdrawEngagement = (engagementId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/withdraw_engagement',
      data: { EngagementID: engagementId },
    });
    if (resp && resp.status === 200) {

      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Engagement withdrawn successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

    } else {
      handleApiError(resp, dispatch);
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const withdrawRequest = (engagementId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const response: any = await apis({
      method: 'POST',
      url: '/marketplace/provider/withdraw_request',
      data: { EngagementID: engagementId },
    });

    if (response && response.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: response.data.message || 'Engagement withdrawn successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return response
    } else {
      handleApiError(response, dispatch)
      return null
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};


// Action to get case details
export const getCaseDetails = (data: string) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_CASE_DETAILS_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/get_case_details',
      data: { CaseID: data }
    });
    if (resp && resp.status === 200) {
      dispatch({
        type: GET_CASE_DETAILS_SUCCESS,
        payload: resp.data.case_details,
      });
      return resp.data.case_details

    } else {
      handleApiError(resp, dispatch);
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const proposeEngagement = (data: ProposeEngagementPayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: PROPOSE_ENGAGEMENT_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/propose_engagement',
      data,
    });

    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Engagement proposal submitted successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

      return resp
    } else {
      handleApiError(resp, dispatch)
      return null
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};

// Action to set marketplace active status
export const setMarketplaceActive = (data: SetMarketplaceActivePayload) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: SET_MARKETPLACE_ACTIVE_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/set_marketplace_active',
      data,
    });

    if (resp && resp.status === 200) {
      dispatch({
        type: SET_MARKETPLACE_ACTIVE_SUCCESS,
      });

      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Marketplace active status updated successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

    } else {
      handleApiError(resp, dispatch);
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};

// Action to upload Terms of Service
export const uploadTOS = (file: File) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: UPLOAD_TOS_REQUEST });
  try {
    const formData = new FormData();
    formData.append('file', file);

    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/upload_tos',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resp && resp.status === 200) {
      dispatch({
        type: UPLOAD_TOS_SUCCESS,
      });

      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Terms of Service uploaded successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

    } else {
      handleApiError(resp, dispatch);
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};

// Action to upload final document
export const uploadFinalDocument = (file: File, caseId: string) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: UPLOAD_FINAL_DOCUMENT_REQUEST });
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('CaseID', caseId);

    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/upload_final_document',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resp && resp.status === 200) {
      dispatch({
        type: UPLOAD_FINAL_DOCUMENT_SUCCESS,
      });

      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Final document uploaded successfully',
          modalType: 'success',
          duration: 3000,
        })
      );

    } else {
      handleApiError(resp, dispatch);
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};


export const getRelevantCases = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: GET_RELEVANT_CASES_REQUEST });
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/provider/get_relevant_cases',
    });

    if (resp && resp.status === 200) {

      const data = resp.data.relevant_cases;
      dispatch({
        type: GET_RELEVANT_CASES_SUCCESS,
        payload: data,
      });
    } else {
      handleApiError(resp, dispatch);
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const getLawyerProfile = (UserID: string) => async (dispatch: Dispatch<any>) => {
  try {

    const resp = await apis({
      method: 'POST',
      url: '/marketplace/receiver/get_lawyer_profile',
      data: {
        LawyerID: UserID,
      },
    });

    if (resp && resp.status === 200) {

      return resp.data.profile
    } else {
      handleApiError(resp, dispatch);
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const updateLawyerProfile = (formData: FormData) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: UPDATE_LAWYER_PROFILE_REQUEST });
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/update_profile',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resp && resp.status === 200) {

      dispatch({ type: UPDATE_LAWYER_PROFILE_SUCCESS });

      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Profile updated successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return true
    } else {
      handleApiError(resp, dispatch);
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const getLawFirmProfile = () => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'GET',
      url: '/marketplace/firm/get_profile',
    });

    if (resp && resp.status === 200) {
      return resp.data.lawfirm_profile;

    } else {
      handleApiError(resp, dispatch);
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const updateLawFirmProfile = (formData: FormData) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/firm/update_profile',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resp && resp.status === 200) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: resp.data.message || 'Law firm profile updated successfully',
          modalType: 'success',
          duration: 3000,
        })
      );
      return resp.data

    } else {
      handleApiError(resp, dispatch);
    }

  } catch (error) {
    handleApiError(error, dispatch);
  }
};

export const sendMessage = (engagementId: string, message: string) => async (dispatch: Dispatch<any>) => {
  try {
    await apis({
      method: 'POST',
      url: '/marketplace/engagements/add_message',
      data: {
        EngagementID: engagementId,
        Message: message,
      },
    });
    // Optionally dispatch an action to update the messages in the store
  } catch (error) {
    handleApiError(error, dispatch);
  }
};


export const getProviderEngagementDetails = (id: string) => async (dispatch: Dispatch<any>): Promise<any | null> => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/get_engagements_details',
      data: { engagement_id: id },
    });
    if (resp && resp.status === 200) {
      return resp.data.engagement
    } else {
      handleApiError(resp, dispatch)
      return null
    }
  } catch (error) {
    handleApiError(error, dispatch);
  }
};


export const setEngagementTerms = (
  engagementId: string,
  terms: any
) => async (dispatch: Dispatch<any>): Promise<boolean> => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/set_engagement_terms',
      data: {
        engagement_id: engagementId,
        terms: terms
      }
    });

    if (resp && resp.status === 200) {
      // dispatch success messages, etc.
      return true; // returns boolean
    } else {
      handleApiError(resp, dispatch);
      return false;
    }
  } catch (error) {
    handleApiError(error, dispatch);
    return false;
  }
};

export const getProviderEngagementTerms = (engagementId: string) => async (dispatch: Dispatch<any>): Promise<any | null> => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/get_engagement_terms',
      data: { engagement_id: engagementId },
    });

    if (resp && resp.status === 200) {
      // The backend returns { "engagementTerms": {...} } or a string
      return resp.data.engagementTerms;
    } else {
      handleApiError(resp, dispatch);
      return null;
    }
  } catch (error) {
    handleApiError(error, dispatch);
    return null;
  }
};


export const fetchClientAnalysisForLawyer = (caseID: string) => async (dispatch: Dispatch<any>) => {
  try {
    const resp = await apis({
      method: 'POST',
      url: '/cases/get_analysis',
      data: { CaseID: caseID },
    });
    if (resp && resp.status === 200) {
      return resp.data.analysis;
    } else {
      handleApiError(resp, dispatch);
      return null;
    }
  } catch (error) {
    handleApiError(error, dispatch);
    return null;
  }
};

export interface EngagementTemplate {
  Parties: string;
  Scope: string;
  Deliverables: string;
  Timeline: string;
  Fees: number;
  Confidentiality: string;
  CommunicationProcess: string;
  Responsibilities: string;
  ConflictOfInterest: string;
  TermAndTermination: string;
  DisputeResolution: string;
  Liability: string;
  IntellectualProperty: string;
  GoverningLaw: string;
  Signatures: string;
  CancellationRights?: string;
  Arbitration?: string;
  AdditionalInfo?: string;
  TermsDocumentURL?: string;
}

export const getEngagementTemplate = (caseId: string, engagementId: string ) => async (dispatch: Dispatch<any>): Promise<EngagementTemplate | null> => {
  try {
    const payload = {
      engagement_id: engagementId,
      CaseID: caseId  
    };

    const resp = await apis({
      method: 'POST',
      url: '/marketplace/provider/get_engagement_template',
      data: payload
    });
    if (resp && resp.status === 200) {
      // Convert Fees from string to number if needed:
      const template = resp.data.engagementLetter;
      // template.Fees = parseFloat(String(template.Fees)) || 0;
      return template;
    } else {
      handleApiError(resp, dispatch);
      return null;
    }
  } catch (error) {
    handleApiError(error, dispatch);
    return null;
  }
};