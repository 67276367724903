import React from 'react';

interface ToggleOption {
  value: string;
  label: string;
}

interface ToggleProps {
  options: ToggleOption[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const ToggleWide: React.FC<ToggleProps> = ({ options, value, onChange, className }) => {
  return (
    <div
      className={`
        flex w-full rounded-md overflow-hidden p-1 gap-2 
        bg-gray-200 dark:bg-gray-700 shadow-md dark:shadow-dark-md 
        transition-colors duration-300
        ${className}
      `}
    >
      {options.map((option) => {
        const isActive = option.value === value;
        return (
          <button
            key={option.value}
            type="button"
            className={`
              w-1/2 py-2 text-center text-sm font-medium rounded 
              transition-colors duration-300
              ${
                isActive
                  ? // Active state
                    'bg-white text-black dark:bg-gray-900 dark:text-white'
                  : // Inactive state
                    'text-gray-500 dark:text-gray-300 hover:bg-white dark:hover:bg-gray-600 hover:text-black dark:hover:text-white'
              }
            `}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
};

export default ToggleWide;
