import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..';
import apis from '../../../utils/apis';
import {
  FETCH_WHITELABEL_SETTINGS_SUCCESS,
  FETCH_WHITELABEL_SETTINGS_FAILURE,
  UPDATE_WHITELABEL_SETTINGS_SUCCESS,
  UPDATE_WHITELABEL_SETTINGS_FAILURE,
} from '../../types';
import { handleApiError } from '../utils/utils';

export const fetchWhiteLabelSettingsSuccess = (settings: any): AnyAction => ({
  type: FETCH_WHITELABEL_SETTINGS_SUCCESS,
  payload: settings,
});

export const fetchWhiteLabelSettingsFailure = (error: string): AnyAction => ({
  type: FETCH_WHITELABEL_SETTINGS_FAILURE,
  payload: error,
});

export const updateWhiteLabelSettingsSuccess = (settings: any): AnyAction => ({
  type: UPDATE_WHITELABEL_SETTINGS_SUCCESS,
  payload: settings,
});

export const updateWhiteLabelSettingsFailure = (error: string): AnyAction => ({
  type: UPDATE_WHITELABEL_SETTINGS_FAILURE,
  payload: error,
});

export const getWhiteLabelSettings = (userID: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'GET',
        url: '/get_whitelabel_settings',
        params: { UserID: userID },
      });
      if(response && response.status === 200){
        const settings = await response.data.whitelabel_settings;
        dispatch(fetchWhiteLabelSettingsSuccess(settings));

      }else{
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      dispatch(fetchWhiteLabelSettingsFailure(error.toString()));
      handleApiError(error, dispatch);
    }
  };
};

export const updateWhiteLabelSettings = (whiteLabelSettings: FormData) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: '/edit_whitelabel_settings',
        data: whiteLabelSettings,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if(response && response.status === 200){
        const settings = await response.data;
        dispatch(updateWhiteLabelSettingsSuccess(settings));

      }else{
        handleApiError(response, dispatch);
      }
    } catch (error: any) {
      dispatch(updateWhiteLabelSettingsFailure(error.toString()));
      handleApiError(error, dispatch);
    }
  };
};
